import React from "react";

function Unmute() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#202C47" fill-opacity="0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5019 20.0001C10.4991 21.2298 10.4442 22.907 11.2049 23.5339C11.9145 24.1187 12.4139 23.968 13.7094 24.0631C15.0059 24.1591 17.742 27.97 19.8512 26.7646C20.9393 25.9089 21.0202 24.1151 21.0202 20.0001C21.0202 15.8852 20.9393 14.0914 19.8512 13.2357C17.742 12.0294 15.0059 15.8412 13.7094 15.9372C12.4139 16.0322 11.9145 15.8816 11.2049 16.4663C10.4442 17.0933 10.4991 18.7705 10.5019 20.0001Z" stroke="#FAE8DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M27.5844 13.9039C30.1344 17.5749 30.1428 22.4173 27.5844 26.0955" stroke="#FAE8DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M25.0813 16.3145C26.3926 18.6051 26.3926 21.4026 25.0813 23.6861" stroke="#FAE8DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <rect x="0.504346" y="0.504346" width="38.9913" height="38.9913" rx="19.4957" stroke="white" stroke-opacity="0.19" stroke-width="1.00869"/>
    </svg>

  );
}

export default Unmute;
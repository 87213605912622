import React, {useState} from 'react'
import './Shownotstarted.scss'
import {useNavigate} from "react-router-dom";

function Shownotstarted(props: any) {
  const navigate = useNavigate();

  const isConnecting = props.isConnecting;

  return (
    <div className="shownot-start">
      {!isConnecting && isConnecting != undefined ? (
        <div>
        <p style={{color: 'white', textAlign: 'center'}}>{props?.showDetails?.description || 'We are looking forward to welcome you in our show!'}</p>

        <button className="squrebtn" type="button" onClick={() => {
          navigate("/shows")
        }}>Explore More</button>
        </div>
      ) : (
        <div className='ConnectingStyle'> <p>Connecting...</p></div>
      )}
    </div>
  )
}

export default Shownotstarted

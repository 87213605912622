import React, { useState } from 'react'
import {  Button, Modal } from 'antd'
import { Close } from '../../../Icons/index'

function GameShowDisclaimer({visible,  emitAcceptDisclaimer=()=>{}, disclaimerText, handleOk, handleCancel}: any) {
  const [modalTitle] = useState<string>('Game Show 🎁');

  return (
    <div>
      <Modal title={modalTitle} className={`themeModal`} width={600} zIndex={100} visible={visible} onOk={handleOk} onCancel={handleCancel} footer={false} centered closeIcon={<Close />} maskClosable={false} keyboard={false} >
        <div className='giveawayModal'>
          <div dangerouslySetInnerHTML={{__html: disclaimerText}} />
          <div className='acceptButton'>
            <Button type='primary' className='transparent' onClick={() => {emitAcceptDisclaimer()}}>Accept</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default GameShowDisclaimer

import {Button, Col, Form, Input, message, Modal, Radio, Row, Select} from 'antd'
import React, {useState} from 'react';
import {images} from '../../../../Assets/ImageConfig/images'
import {Close, Delete, Edit, Tick} from '../../../Icons'
import type {RadioChangeEvent} from 'antd';
import {
  CardElement
} from '@stripe/react-stripe-js';
import API from "../../../../Utils/api"
import {useSelector,useDispatch} from "react-redux";
import {RootState} from "../../../../store";
import { fetchUserDetails } from "../../../../store/Actions/users"
import "../../../AccountSettings/Components/Payment/Payment.scss";
import {StripeCardElementChangeEvent} from '@stripe/stripe-js';
import {displayErrorToast, displayInfoToast} from '../../../../Utils/helper';
import {wallet_card_added} from '../../../../Utils/mParticle';

const CARD_OPTIONS = {
  style: {
    base: {
      fontFamily: `"Inter", sans-serif`,
      letterSpacing: '0.8px',
      color: '#959798',
      '::placeholder': {
        color: '#959798',
      },
    },
  },
  hidePostalCode: true
};

// import './Payment.scss';
const CreditCard = (props: any) => {

  const [value, setValue] = useState(1);
  const [cardName, setCardName] = useState("")
  const [isCardValid, setCardValid] = useState(false)
  const [cardBrand, setCardBrand] = useState("")

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const dispatch = useDispatch()
  const {Option} = Select;
  const [form] = Form.useForm();
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const onSaveCard = async () => {
    const {stripe, elements} = props;

    if (!stripe || !elements) return;

    let clientSecret: any = await API.postDataWithToken('payments/card', {})

    clientSecret = clientSecret.data.data.clientSecret

    var cardElement = elements.getElement('card')!;

    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardName,
          },
        },
      })
      .then(async function (response: any) {
        if (response?.error) {
          displayErrorToast(`${response?.error?.message}`)
          props?.isShowPage && props.setIsCreditCard(false)
        }
        else if(response?.setupIntent) {
          props?.isShowPage && props.setIsCreditCard(false)
          wallet_card_added(users?.user, cardBrand)
          displayInfoToast(`Card added successfully.`)
        }
        if (!props?.isShowPage) {
          await dispatch(fetchUserDetails())
          props.handleCancel()
        }
        // Handle result.error or result.setupIntent
      });
  }
  return (
    <>
      {/* Add a new card */}
      {props?.isShowPage && <div className='paymentcarsTitle'>
        <h3>Credit card</h3>
        <button type='button'
          onClick={() => {
            props.setIsTopUp(false)
            props.setIsCreditCard(false)
          }}
        >
          <Close />
        </button>
      </div>}
      <div className='AddnewcardModalStyle'>
        <Form form={form} name="basic" layout={'vertical'} >
          <Form.Item name="NameonCard"
          >
            <Input placeholder='Name on Card'
              allowClear
              onChange={(e: any) => {
                setCardName(e.target.value.trim())
              }} />
          </Form.Item>
          <Form.Item name="card"
          >
            <CardElement options={CARD_OPTIONS} onChange={(e: StripeCardElementChangeEvent) => {
              if (e.complete && !e.error) {
                setCardBrand(e.brand)
                setCardValid(true)
              }
              else {
                setCardValid(false)
              }
            }} />
            {/* <Input placeholder={' <> <span><Cardnumber /></span><span>Card number</span></>'} allowClear /> */}
          </Form.Item>
          <Form.Item>
            <Button type='primary' className='w100' onClick={onSaveCard} disabled={!cardName.length || !isCardValid}>Save</Button>
          </Form.Item>
        </Form>
        <div className='payments_detail'>
          <div className='Icon'>
            <img src={images.ShieldDone} alt="secure icon" />
          </div>
          <div className='Content'>
            <h5>Your data and payments are safe</h5>
            <p>All your purchases are handled by Stripe. Your data is protected by secure technology and is never shared with any third parties.</p>
          </div>
        </div>
        <div className='payments_detail'>
          <div className='Icon'>
            <img src={images.secureicon} alt="secure icon" />
          </div>
          <div className='Content'>
            <h5>You control your card payments</h5>
            <p>We will never pre-authorise payments on your your card and will charge it only when you choose to use it as a final payment method.</p>
          </div>
        </div>
        <div className='ORText'>
          <span>OR</span>
        </div>
        <div className='otherpayment'>
          <a href='#'><img src={images.otherpayment1} alt="Payment" /></a>
          {/* <a href='#'><img src={images.otherpayment2} alt="Payment" /></a> */}
          {/* <a href='#'><img src={images.otherpayment3} alt="Payment" /></a> */}
          <a href='#' className='giropay'><img src={images.giropaymodal} alt="Payment" /></a>
          <a href='#'><img src={images.otherpayment5} alt="Payment" /></a>
        </div>
        <Button type='ghost' className='w100 topup'
          onClick={() => {
            if (props?.isShowPage) {
              props.setIsTopUp(true)
              props.setIsCreditCard(false)
            }
            else {
              props.openTopUpModal()
            }

          }}
        >Top up your balance</Button>
      </div>

    </>
  );
}

export default CreditCard
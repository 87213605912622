import React from 'react'
import { tearms, termsSections } from './terms'


import './Termsconditions.scss'




function Termsconditions() {
  return (

    <div className="EditProfile-details ">
      <h3 >Objectionable Content Policy</h3>
      <p>The Gavel app, the Gavel GmbH and the Yellowsquid OG maintains a zero tolerance policy regarding objectionable content. Objectionable content may not be uploaded or displayed to the extent such content includes, is in conjunction with, or alongside any, Objectionable Content. Objectionable Content includes, but is not limited to: (i) sexually explicit materials; (ii) obscene, defamatory, libelous, slanderous, violent and/or unlawful content or profanity; (iii) content that infringes upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary right, or that is deceptive or fraudulent; (iv) content that promotes the use or sale of illegal or regulated substances, tobacco products, ammunition and/or firearms; and (v) gambling, including without limitation, any online casino, sports books, bingo or poker. Any user can flag content they deem objectionable for review. Content will be moderated by Gavel and Yellowsquid OG to ensure the timely removal of any and all objectionable content. User accounts which have been confirmed responsible for posting objectionable content will be restricted from access to the Gavel app.</p>
      <h3 >Terms & Conditions</h3>
      <ul className='terms-content'>
            {tearms.map((term:string, index: number) => (
              <li dangerouslySetInnerHTML={{__html: term}} key={index} />
            ))}
      </ul>
      {termsSections.map((term: any,  index: number) => (
        <div className='privacy-box'  key={index}>
          <h5 dangerouslySetInnerHTML={{ __html: term.title}} />
          {term.text.map((paragraph:string,  index: number) => (
              <p dangerouslySetInnerHTML={{__html: paragraph}}  key={index} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Termsconditions

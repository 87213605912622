import { FETCHING_ROOMS, FETCHED_ROOMS, GET_TOPICS, FOLLOW_ACTION, BOOKMARK_ACTION, RESET_FETCHED_ROOMS, GET_REPORT_REASONS,NOTIFY_ME } from "../Types/showDashboard"
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import API from "../../Utils/api"
interface FetchingShows {
  type: 'FETCHING_ROOMS'
  isFetching: boolean
}
interface FetchedShows {
  type: 'FETCHED_ROOMS'
  shows: any,
  status: string
}

interface RESET_FETCHED_SHOWS_CLASS {
  type: 'RESET_FETCHED_ROOMS',
  status: string
}

interface GET_TOPICS_CLASS {
  type: 'GET_TOPICS'
  topics: any
}

interface GET_REPORT_REASONS_CLASS {
  type: 'GET_REPORT_REASONS',
  reasons: any
}

interface FOLLOW_ACTION_CLASS {
  type: 'FOLLOW_ACTION'
  id: number
  following: boolean
}
interface BOOKMARK_ACTION_CLASS {
  type: 'BOOKMARK_ACTION'
  id: number
  bookmarked: boolean
}

interface NOTIFY_ME_CLASS {
  type:'NOTIFY_ME'
  notifyMeSuccess:boolean
}
export const isFetching = (isFetching: boolean): FetchingShows => {
  return { type: FETCHING_ROOMS, isFetching }
}
export const fetchedRooms = (shows: any, status: string): FetchedShows => {
  return { type: FETCHED_ROOMS, shows, status }
}

export const resetFetchedRooms = (status: string): RESET_FETCHED_SHOWS_CLASS => {
  return {type: RESET_FETCHED_ROOMS, status}
}

export const getTopicsAction = (topics: object): GET_TOPICS_CLASS => {
  return { type: GET_TOPICS, topics }
}
export const getReportReasonsAction = (reasons: object[]): GET_REPORT_REASONS_CLASS => {
  return {type: GET_REPORT_REASONS, reasons}
}
export const followShowListAction = (id: number, following: boolean): FOLLOW_ACTION_CLASS => {
  return { type: FOLLOW_ACTION, id, following }
}
export const bookmarkShowListAction = (id: number, bookmarked: boolean): BOOKMARK_ACTION_CLASS => {
  return { type: BOOKMARK_ACTION, id, bookmarked }
}
export const notifyMeSuccessAction =(notifyMeSuccess:boolean):NOTIFY_ME_CLASS=>{
  return {type: NOTIFY_ME,notifyMeSuccess}
}

export const fetchRooms = (userId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(isFetching(true))
      const shows = await API.getDataWithOutToken("api/rooms", { admin: false, app: "collectibles", user_id: userId })
      // const res = await API.get('/api/user/login', {phone: '+918320632488'});
      dispatch(fetchedRooms(shows?.data, ''))
      dispatch(isFetching(false))
    } catch (err) {
      console.log(err)
      dispatch(isFetching(false))
    }
  }
}

export const getAllShows = (status: string, page: number,user: boolean, skipShowIds: Array<number>, filter: any,limit: number = 10,  ): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(isFetching(true))
      let shows
      if (user) {
        shows = await API.postDataWithToken("/shows", {
          status,
          page,
          limit,
          skipShowIds,
          filter: filter ?? '0',
        })
      }
      else {
        shows = await API.postDataWithOutToken("/shows", {
          status,
          page,
          limit,
          skipShowIds,
          filter: filter ?? '0'
        },{
          bypass:true
        })
      }

      dispatch(fetchedRooms(shows.data?.data, status))
      dispatch(isFetching(false))
    }
    catch (err) {
      console.log(err)
      dispatch(isFetching(false))
    }
  }
}

export const resetAllShows = (status: "suggested" | "live" | "game_show" | "featured" | "ready"): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(resetFetchedRooms(status))
    } catch (error) {
     dispatch(isFetching(false))
    }
  }
}

export const notifyMe =(filter:object): ThunkAction<Promise<void>, {}, {}, AnyAction> =>{
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try{
      const url='user/notify';
      const resPayload=await API.putWithToken(url,filter);
      console.log('notifyMe reply ',resPayload);
      dispatch(notifyMeSuccessAction(resPayload?.data?.success));
    }
    catch(error){
      console.log(error);
    }
  }
}

export const getTopics = (pageNumber: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const topics = await API.getDataWithToken('topics',{
        page:pageNumber,
        limit: 5
      })
      dispatch(getTopicsAction(topics?.data?.data));
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export const getReportReasons = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const {data} = await API.getDataWithToken('user/report')

      dispatch(getReportReasonsAction(data?.data))
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}


export type ShowDashboardAction = FetchingShows | FetchedShows | GET_TOPICS_CLASS | FOLLOW_ACTION_CLASS | BOOKMARK_ACTION_CLASS | RESET_FETCHED_SHOWS_CLASS | GET_REPORT_REASONS_CLASS | NOTIFY_ME_CLASS
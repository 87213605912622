import React from "react";

function Youtube() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 6C8.58331 6 5.77358 6.12076 3.81187 6.24224C2.94807 6.29573 2.26725 6.96195 2.19869 7.84148C2.09164 9.21467 2 10.7608 2 12C2 13.2392 2.09164 14.7853 2.19869 16.1585C2.26725 17.0381 2.94807 17.7043 3.81187 17.7578C5.77357 17.8792 8.58331 18 12 18C15.4167 18 18.2264 17.8792 20.1881 17.7578C21.0519 17.7043 21.7327 17.0381 21.8013 16.1585C21.9084 14.7853 22 13.2392 22 12C22 10.7608 21.9084 9.21467 21.8013 7.84148C21.7327 6.96195 21.0519 6.29573 20.1881 6.24224C18.2264 6.12076 15.4167 6 12 6ZM3.68826 4.24606C1.821 4.36169 0.350133 5.82087 0.204737 7.68604C0.0965153 9.07434 0 10.6819 0 12C0 13.3181 0.0965153 14.9257 0.204737 16.314C0.350133 18.1791 1.821 19.6383 3.68826 19.7539C5.68542 19.8776 8.53673 20 12 20C15.4633 20 18.3146 19.8776 20.3117 19.7539C22.179 19.6383 23.6499 18.1791 23.7953 16.314C23.9035 14.9257 24 13.3181 24 12C24 10.6819 23.9035 9.07434 23.7953 7.68604C23.6499 5.82087 22.179 4.36169 20.3117 4.24606C18.3146 4.12239 15.4633 4 12 4C8.53673 4 5.68542 4.12239 3.68826 4.24606Z" fill="currentColor"/>
    <path d="M11.5547 9.03657L14.752 11.1681C15.3457 11.5639 15.3457 12.4363 14.752 12.8322L11.5547 14.9636C10.8902 15.4067 10 14.9303 10 14.1316V9.86862C10 9.06993 10.8902 8.59354 11.5547 9.03657Z" fill="currentColor"/>
    </svg>
    

  );
}

export default Youtube;
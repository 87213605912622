import React from 'react'
import {Button, Modal} from 'antd';
import {Close} from '../../../../Icons';
import {images} from '../../../../../Assets/ImageConfig/images';

type Props = {
    isModalVisible: boolean;
    handleOk: any;
    handleCancel: any;
    onOkClick: any;
    onCancelClick: any
    cardNumber: any;
}

const DeleteCardModal = (props: Props) => {
    return (
        <Modal width={'496px'} title="" className='themeModal' visible={props?.isModalVisible} onOk={props?.handleOk}
            onCancel={props?.handleCancel} footer={false} centered closeIcon={<Close />}>
            <div className='modal-body'>
                <div className='deleteUserModal'>
                    <div className='delteuserImg'>
                        <img src={images.Deletemodalimg} alt="Delete modal img" />
                    </div>
                    <div className='deleteuserContent'>
                        <h5>Delete Card?</h5>
                        <p>Are you sure you want to delete the card ending with {props?.cardNumber}?</p>
                        <div className='deleteuserBtns'>
                            <Button type='ghost' onClick={props?.onCancelClick} >Cancel</Button>
                            <Button type='primary' className='Delete_Btn' onClick={props?.onOkClick} >Delete</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default DeleteCardModal
import React from "react";

function Tick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#0CFDE4"
        fillRule="evenodd"
        d="M7 0a7 7 0 110 14A7 7 0 017 0z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#202C47"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M10.461 4.855l-4.4 4.4-1.801-2.2"
      ></path>
    </svg>
  );
}

export default Tick;
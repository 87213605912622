import React from "react";

function Apple() {
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.7807 17.9428C19.4328 18.7463 19.0211 19.486 18.5441 20.166C17.8939 21.0931 17.3615 21.7348 16.9512 22.0912C16.3152 22.6761 15.6337 22.9756 14.904 22.9927C14.3801 22.9927 13.7483 22.8436 13.0129 22.5412C12.2751 22.2402 11.597 22.0912 10.9771 22.0912C10.3268 22.0912 9.62947 22.2402 8.88355 22.5412C8.1365 22.8436 7.53469 23.0012 7.07456 23.0168C6.37478 23.0466 5.67728 22.7385 4.98105 22.0912C4.53669 21.7036 3.98087 21.0392 3.31503 20.0979C2.60063 19.0927 2.01329 17.9272 1.55317 16.5983C1.06038 15.163 0.813354 13.7731 0.813354 12.4275C0.813354 10.8861 1.14642 9.55669 1.81354 8.44265C2.33784 7.5478 3.03534 6.84192 3.90832 6.32373C4.7813 5.80554 5.72456 5.54147 6.74036 5.52458C7.29618 5.52458 8.02506 5.6965 8.93083 6.03439C9.83405 6.37342 10.414 6.54535 10.6683 6.54535C10.8584 6.54535 11.5026 6.34431 12.5948 5.94353C13.6277 5.57185 14.4994 5.41795 15.2135 5.47858C17.1485 5.63474 18.6023 6.39755 19.5691 7.77183C17.8385 8.82043 16.9824 10.2891 16.9995 12.1732C17.0151 13.6408 17.5475 14.862 18.5938 15.8317C19.068 16.2817 19.5975 16.6295 20.1867 16.8766C20.0589 17.2471 19.9241 17.602 19.7807 17.9428ZM15.3427 0.478929C15.3427 1.62919 14.9224 2.70319 14.0848 3.69727C13.074 4.87903 11.8513 5.56191 10.5254 5.45416C10.5086 5.31616 10.4988 5.17093 10.4988 5.01831C10.4988 3.91406 10.9795 2.73229 11.8331 1.76604C12.2593 1.27681 12.8014 0.870017 13.4587 0.545513C14.1146 0.225851 14.735 0.0490716 15.3185 0.0187988C15.3356 0.172571 15.3427 0.326353 15.3427 0.478915V0.478929Z" fill="currentColor"/>
    </svg>


  );
}

export default Apple;
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Eye, Live, CardShare, Save, Time, ProfileBadge, UnfollowIcon, FollowIcon } from '../../Icons'
import './Featurecardlist.scss'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { addWatchlistItem, deleteWatchlistItem, followUser, openDownloadAppModal, openLoginSignUpModal } from '../../../store/Actions/users';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import Bookmark from '../../Icons/Bookmark/Bookmark';
import { capitalizeFirstLetter, displayInfoToast, localDateTime, openInNewTab, placeHolderProfileHelper } from '../../../Utils/helper';
import { matchRoutes, useLocation } from 'react-router';
import {images} from '../../../Assets/ImageConfig/images';
import {GamesTag} from '../../../Utils/constants';
import ProfileBadges from '../ProfileBadges';
import ModBadge from '../ModBadge';
import {show_reminder_event, user_follow, user_unfollow} from '../../../Utils/mParticle';


function Featurecardlist(props: any) {

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const observer = useRef<any>()

  const lastCardElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting ) {
          props.fetchMore()
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const handleFollow = async (showUserId: number, follow: boolean) => {
    try {
      if (users.user?.id) {
        if (users.user?.id === showUserId)
          displayInfoToast("It's you!")
        else {
          setLoading(true)
          await dispatch(followUser(follow, showUserId, true, props?.isWatchList))
          if(!follow){
            user_follow(users.user, showUserId, 'btn_follow', 'All Shows', 'Shows Screen');
          }else{
            user_unfollow(users.user, showUserId, 'btn_follow', 'All Shows', 'Shows Screen');
          }
          setLoading(false)
        }
      }
      else {
        dispatch(openLoginSignUpModal(true))
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const getRouteForShow = () => {
    if (!matchRoutes([{path: '/:id'}], location.pathname) === null) {
      return 'profile'
    } else {
      let path = location.pathname.split("/")
      return path[path.length - 1]
    }
  };

  const handleBookmark = async (show:any, bookmarked: boolean) => {
    if(users.user?.id){
      try{
        setLoading(true)
        show_reminder_event(!bookmarked,getRouteForShow(),'Shows',users.user, show, '',);
        if(bookmarked){
          await dispatch(deleteWatchlistItem(show?.id, props?.isWatchList))
        }else{
          await dispatch(addWatchlistItem(show?.id, props?.isWatchList))
        }
      }catch(e){
        console.log(e);
      }
      setLoading(false)
    }
    else {
      dispatch(openLoginSignUpModal(true))
    }
  };

  const getShowLanguage = () => {
    return props.show?.languagesData[0]?.slug ? `${capitalizeFirstLetter(props.show?.languagesData[0]?.slug)} Show` : null;
  }

  const onProfileClick = () => {
    if (props.show?.user_id === users?.user?.id) {
      openInNewTab('/my-profile')
    }
    else {
      openInNewTab(`/${props.show?.host}`)
    }
  }

  useEffect(() => {
    props?.setUserLoader && props?.setUserLoader(loading)
  }, [loading])


  return (


    <div className={`featureCrad suggestedSection ${!users.user?.id &&  props.show?.status === 'live' ? 'loggedout' : ''}`}  ref={props.lastElement ? lastCardElementRef : null} >
        <div className="featureImagebox" >
        <div className='Featureoverlay' onClick={() => {
        // if(location.pathname.includes('thisisouryear'))
        if(!props.isSwiping){
          window.location.href = `/show/${props.show.id}`
        }

        // else dispatch(openDownloadAppModal(true))
        }}></div>
          <div className='featureImg'>
            <img src={props.show?.picture_url ?? images.showPlaceholder} alt="img" onError={({currentTarget}) => {
              currentTarget.onerror = null
              currentTarget.src = images.showPlaceholder
            }} />
          </div>
          <div className='imgtop'>
            {
            // BUG: always showing 1 because it waits for socket init instead of reading from JSON response
            props.show?.status === 'live' ? <span className='viewCount countSpan'><Eye />{props.show?.online}</span> : <span></span>
            }
            <div className='rightImgtop'>
            {props.show?.languagesData?.length ? (<span className='viewCount langBadge'>{getShowLanguage()}</span>) : null }
              {
                (props.show?.status !== 'live') && <span className='viewCount'><Time /> {localDateTime(props.show.starts_at)}</span>
              }
              {
                props.show?.status === 'live' && <span className='Livespan'><Live /> Live</span>
              }
              <CopyToClipboard text={`${window.location.host}/show/${props.show?.id}`} onCopy={() => message.success('Show url copied')}>
                <a className='cardShare'><CardShare/></a>
              </CopyToClipboard>
              {
                (props.show?.status !== 'live' && props.show?.user_id !== users?.user?.id) &&
                <a className={`cardShare markiocn ${props.show.isWatchlisted && users.user.id ? 'bookmarked' : ''}`} onClick={() => handleBookmark(props.show, props.show.isWatchlisted)} >{props.show.isWatchlisted && users.user.id ? <Bookmark /> : <Save />}</a>
              }
            </div>
          </div>
          <div className='featureProfile'>
          <div className='profile-left' onClick={onProfileClick}>
              <div className='profileImg'>
                <img src={props.show?.profile_img_url ?? images.profiledummy} onError={placeHolderProfileHelper} alt="img" />
              </div>
              <div className='userName'>
                <p title={props.show?.host}>{props.show?.host}
                  {props.show?.host ? (
                      <ProfileBadges user={props.show} />
                  ) : null}
                  </p>

                <span> {props.show?.followed_by_ids?.length} followers</span>
              </div>
            </div>
            {
              props.show?.user_id !== users?.user?.id && (
                <div className='profile-right'>
                  <button onClick={ () => handleFollow(+props.show.user_id, props.show.followed_by_me) }
                  className={`followBtn ${props.show.followed_by_me && users.user.id ? 'followBtnActive' : ''}`}>{ props.show.followed_by_me && users.user.id ? <UnfollowIcon/> : <FollowIcon/> }</button>
                </div>
              )
            }

          </div>
        </div>
        <div className='Cardtextbox'>
        <p>{props.show?.title}</p>
        <p className='hastagspan'>
        {
          props.show.topicData.map((topic: any, index:number) => (
            <span key={index} className={`${topic?.name === GamesTag ? 'gameHeighlight' : ''}`}>{topic.name} </span>
          ))
        }
        </p>
        </div>
      </div>

  )
}

export default Featurecardlist

import React from "react";

function ChatSend() {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none">
      <rect width="512" height="512" fill="none" />
      <path d="M16 464L496 256L16 48V208L336 256L16 304V464Z" fill="currentColor" />
    </svg>



  );
}

export default ChatSend;
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import Header from "../CommonComponents/Header"

import { createContext, FunctionComponent } from "react";

interface AuthUserInterface {}

const AuthUser = createContext<AuthUserInterface | null>(null);
interface AuthUserProviderProps {
}
const AuthUserProvider: FunctionComponent<AuthUserProviderProps> = ({
  children,
}) => {
  return (
    <>
      <AuthUser.Provider
        value={{}}
      >
        <Header/>
        <div className='app'> {children}</div>
      </AuthUser.Provider>

    </>
  );
};

export { AuthUserProvider as default, AuthUser };

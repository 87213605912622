import React from "react";

function Flag() {
  return (
    <svg width="532" height="642" viewBox="0 0 532 642" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100.312 601.875C94.9916 601.875 89.8886 599.761 86.1262 595.999C82.3637 592.236 80.25 587.133 80.25 581.812V85.4286C80.2514 80.172 81.6297 75.0075 84.2477 70.4492C86.8656 65.8909 90.6319 62.0979 95.1715 59.4477C110.344 50.6327 140.914 40.125 200.625 40.125C247.283 40.125 299.47 58.57 345.514 74.8331C382.592 87.9364 417.613 100.312 441.375 100.312C471.963 100.219 502.227 94.0381 530.402 82.1309C533.833 80.6826 537.57 80.1058 541.278 80.4519C544.986 80.7981 548.551 82.0565 551.654 84.1149C554.758 86.1732 557.304 88.9676 559.066 92.2489C560.827 95.5302 561.749 99.1964 561.75 102.921V377.978C561.745 382.853 560.319 387.621 557.647 391.699C554.974 395.776 551.171 398.986 546.703 400.937C535.782 405.714 495.907 421.312 441.375 421.312C411.106 421.312 373.188 412.36 333.05 402.868C287.934 392.209 241.289 381.188 200.625 381.188C154.393 381.188 130.732 388.184 120.375 392.611V581.812C120.375 587.133 118.261 592.236 114.499 595.999C110.736 599.761 105.633 601.875 100.312 601.875Z" fill="currentColor"/>
</svg>

  );
}

export default Flag;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseAppleConfig = {
  apiKey: "AIzaSyAJXMFFob7ubE3smsvmX9_MEJABC5dwlAk",
  authDomain: "gavel-com.firebaseapp.com",
  projectId: "gavel-com",
  storageBucket: "gavel-com.appspot.com",
  messagingSenderId: "355181956086",
  appId: "1:355181956086:web:18fbc798f56aad13b195d7",
  measurementId: "G-72VVWTP9CN"
};


// Initialize Firebase
const app = initializeApp(firebaseAppleConfig);
const auth = getAuth(app)
export {auth,app}
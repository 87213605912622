import React from 'react'

const Wallet = () => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.38464 9.41523C1.18892 10.2139 2.13696 10.3281 3.1497 10.3257C9.36189 10.3114 15.5733 10.3177 21.7855 10.3169C21.8526 10.3169 21.9197 10.3169 22.0083 10.3169V14.2177C21.9173 14.2177 21.8206 14.2177 21.7248 14.2177C20.252 14.2177 18.7792 14.2113 17.3072 14.2201C15.7761 14.2289 14.5781 15.2225 14.2842 16.7096C13.932 18.4915 15.3401 20.2518 17.1619 20.2758C18.685 20.2965 20.2089 20.2806 21.732 20.2814C21.823 20.2814 21.9141 20.2814 22.0171 20.2814V25.0527C21.9213 25.0527 21.831 25.0527 21.7408 25.0527C15.3848 25.0527 9.02884 25.0527 2.67288 25.0527C1.29115 25.0527 0.369464 24.1454 0.368666 22.7733C0.365471 18.414 0.367068 14.0548 0.368666 9.69557C0.368666 9.62289 0.376653 9.551 0.385438 9.41443L0.38464 9.41523ZM12.3282 12.4598H14.0342V11.6268H12.3282V12.4598ZM17.4949 11.6348H15.7961V12.4654H17.4949V11.6348ZM1.8095 23.7069H3.62092V22.8691H2.01157L1.8095 23.7069ZM10.5751 22.8979H8.8707V23.7277H10.5751V22.8979ZM12.329 23.7293H14.0342V22.8963H12.329V23.7293ZM17.4989 22.9027H15.7937V23.7325H17.4989V22.9027ZM2.01157 11.5996L1.8111 12.4295H3.62092V11.5996H2.01157ZM7.11278 12.479C7.11278 12.2226 7.10719 12.0118 7.11438 11.8009C7.11917 11.6595 7.06646 11.606 6.9235 11.6092C6.6192 11.6164 6.3149 11.6116 6.01139 11.6116C5.54656 11.6116 5.33491 11.8467 5.37644 12.3169C5.37644 12.3256 5.37324 12.3368 5.37804 12.3416C5.42276 12.3879 5.46829 12.4718 5.51541 12.4726C6.03695 12.4822 6.5585 12.4782 7.11358 12.4782L7.11278 12.479ZM10.5719 11.6284H8.87309V12.4622H10.5719V11.6284ZM7.09681 22.9003H5.39161V23.7293H7.09681V22.9003ZM20.724 11.6148C20.2576 11.6148 19.8047 11.6124 19.3527 11.6196C19.3167 11.6196 19.2536 11.6907 19.2528 11.7298C19.2433 11.971 19.248 12.213 19.248 12.4614H20.7248V11.614L20.724 11.6148ZM20.7184 23.7485V22.8811C20.4636 22.8811 20.2288 22.8811 19.9932 22.8811C19.7576 22.8811 19.5108 22.8811 19.2592 22.8811V23.7349C19.3471 23.7397 19.4206 23.7477 19.4948 23.7477C19.899 23.7493 20.3023 23.7477 20.7176 23.7477L20.7184 23.7485Z" fill="white" />
            <path d="M0.981079 8.81076C6.78195 6.60079 12.5261 4.41319 18.2934 2.2168C19.0554 4.62883 19.8101 7.01851 20.5777 9.44971C20.1624 9.44971 19.7854 9.4593 19.41 9.44013C19.3501 9.43693 19.2718 9.31234 19.2431 9.22928C18.8797 8.15664 18.5171 7.084 18.1704 6.00657C18.0722 5.69987 17.9141 5.54653 17.577 5.53614C17.1266 5.52257 16.7392 5.3197 16.4501 4.97467C16.1961 4.67196 15.9341 4.65359 15.5755 4.78697C11.475 6.31486 7.37298 7.83796 3.26373 9.34189C2.44028 9.643 1.69111 9.40099 0.981079 8.81076Z" fill="white" />
            <path d="M24.6349 14.8552V15.1643C24.6349 16.2394 24.6357 17.3136 24.6349 18.3886C24.6341 19.0947 24.313 19.4173 23.6165 19.4173C21.4928 19.4173 19.3691 19.4285 17.2462 19.4109C16.3677 19.4038 15.7031 18.9788 15.323 18.185C14.9436 17.3919 15.0315 16.6155 15.5682 15.9167C16.0218 15.3265 16.6448 15.0805 17.3868 15.0836C19.3156 15.0916 21.2444 15.086 23.1733 15.0868C23.6541 15.0868 24.1381 15.1172 24.6349 14.8552ZM17.2798 15.955C16.5593 15.9502 15.9811 16.5125 15.9731 17.2265C15.9651 17.9565 16.5354 18.5452 17.2566 18.5516C17.9746 18.5579 18.568 17.9685 18.5672 17.2497C18.5672 16.5357 17.9938 15.9606 17.279 15.9558L17.2798 15.955Z" fill="white" />
            <path d="M18.3687 9.44128H5.58252C5.68635 9.38857 5.73507 9.35822 5.78778 9.33905C9.08237 8.12025 12.3778 6.90305 15.6715 5.68026C15.8121 5.62834 15.8984 5.64751 16.0046 5.74895C16.3432 6.07082 16.7514 6.2633 17.2074 6.36633C17.2889 6.3847 17.3967 6.45738 17.4215 6.52926C17.7409 7.45574 18.0476 8.38621 18.3567 9.31588C18.3671 9.34623 18.3639 9.38058 18.3695 9.44128H18.3687Z" fill="white" />
            <path d="M14.0563 2.90165C10.7426 4.16358 7.45438 5.41592 4.16538 6.66747L4.14062 6.62434C7.02389 4.73544 9.90635 2.84655 12.804 0.947266C13.2225 1.59979 13.6274 2.23235 14.0563 2.90165Z" fill="white" />
            <path d="M0.463655 8.64388C0.328677 8.3907 0.33267 8.14869 0.471642 7.91468C0.712846 7.50895 1.15692 7.25576 1.80465 7.22541C2.39249 7.19746 2.97553 7.21104 3.56656 7.20944C3.56656 7.20944 0.84383 7.68865 0.464454 8.64308L0.463655 8.64388Z" fill="white" />
            <path d="M22.0164 7.21039V9.41477C21.9884 9.42915 21.9741 9.44273 21.9589 9.44352C21.649 9.46163 21.4483 9.32452 21.3567 9.0322C20.9749 7.82458 20.7625 7.31981 20.7329 7.20959H22.0164V7.21039Z" fill="white" />
            <path d="M22.9163 12.5025C23.2709 12.5025 23.6095 12.4745 23.941 12.5097C24.3379 12.552 24.6231 12.9186 24.6326 13.3259C24.643 13.7333 24.3771 14.123 23.9841 14.1757C23.6391 14.2221 23.2837 14.1853 22.9163 14.1853V12.5025Z" fill="white" />
            <path d="M17.7013 17.2404C17.7069 17.4736 17.5056 17.6821 17.2724 17.6845C17.0479 17.6869 16.8515 17.5 16.8403 17.2732C16.8283 17.0288 17.0088 16.8315 17.2516 16.8235C17.4936 16.8155 17.6949 17.0024 17.7005 17.2404H17.7013Z" fill="white" />
        </svg>
    );
}

export default Wallet
import React from 'react';
import Myprofile from '../../Components/Profile/Myprofile/Myprofile';

const MyProfilePage = () => {


    return (
       <>
       <Myprofile />
       </>
    );
}

export default MyProfilePage;
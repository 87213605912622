import { useEffect, useRef } from 'react';
import appConfig from './../../../../Utils/config';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux';
import { signInWithGmail, checkUserExistance } from './../../../../store/Actions/users';
import { RootState } from '../../../../store';
import { useState } from 'react';
import { message, Modal } from 'antd';
import Loader from '../../../CommonComponents/Loader';
import UserName from '../UserName';
import { Close } from '../../../Icons';
import PhoneModal from '../Phone';
import {displayErrorToast} from '../../../../Utils/helper';

const Gmail = (props: any) => {

    const dispatch = useDispatch()
    const signInButton = useRef(null)

    const [userExists, setUserExists] = useState(false)
    const [showUserNameModal, setShowUserNameModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userNameFetched, setUserNameFetched] = useState(false)
    const [continueWithPhone, setContinueWithPhone] = useState(false)
    const [isFromGmail, setFromGmail] = useState(false)

    const users = useSelector(
        (state: RootState
        ) => state?.usersReducer
    );

    const handleLogin = async (res: any) => {
        setLoading(true)
        if (res.credential) {
            const credential: any = jwt_decode(res.credential)
            if (credential.sub) {
                try {
                    await dispatch(checkUserExistance({
                        email: credential.email
                    }))
                    setUserNameFetched(true)
                    setFromGmail(true)
                    await dispatch(signInWithGmail(credential))
                } catch (error: any) {
                   displayErrorToast(error.message)
                }
            }
        }
        localStorage.setItem('loggedInFrom','Gmail')
        props.handleCancel()
        setLoading(false)
    }

    useEffect(() => {
        if ((users?.user?.id && users?.user?.email) && !users?.user?.phone) {
            (async () => {
                setLoading(true);
                await dispatch(checkUserExistance({
                    email: users?.user?.email
                }))
                setUserNameFetched(true)
                setFromGmail(true)
            })()
            props.handleCancel()
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        if (users.userExistance?.isUserExist) {
            setUserExists(true)
        }
        else {
            setUserExists(false)
        }
    }, [users.userExistance])

    const displayUserName = () => {
        setShowUserNameModal(true)
        setContinueWithPhone(false)
    };

    useEffect(() => {
        if (isFromGmail) {
            if (userNameFetched && users?.user?.id) {
                setLoading(true)
                if (!userExists) {
                    if (users.user?.phone) {
                        displayUserName()
                    }
                    else {
                        setContinueWithPhone(true)
                        setShowUserNameModal(false)
                    }
                }
                else if (userExists && !users.user?.phone) {
                    setContinueWithPhone(true)
                    setShowUserNameModal(false)
                }
                else if (users?.user?.phone && (!users?.userExistance?.username)) {
                    displayUserName()
                }
                setLoading(false)
            }
        }
    }, [userExists, userNameFetched, users?.user.id, users?.user, isFromGmail,users?.userExistance])


    const reset = () => {
        setShowUserNameModal(false)
        setContinueWithPhone(false)
        setUserExists(true)
        setFromGmail(false)
        props?.handleCancel()
    }
    const finish = () => {
        setUserExists(true)
        setShowUserNameModal(false)
        setContinueWithPhone(false)
        setFromGmail(false)
        props?.handleCancel()
    }

    const userNameCancel = () => {
        if (isFromGmail && !users.userExistance?.username) return
        finish()
    }

    useEffect(() => {
        let googleWidth = '350';
        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        if (window.screen.width < 374) {
            googleWidth = '230'
        }
       else  if (window.screen.width < 430) {
            googleWidth = '280'
        }
        else if (window.screen.width >= 430 && window.screen.width < 600) {
            googleWidth = '342'
        }
        else if (window.screen.width <= 600) {
            googleWidth = '374'
        }
        else if (window.screen.width >= 1980 && window.screen.width <= 800) {
            googleWidth = '350'
        }
        script.onload = () => {
            const google = (window as any).google
            google.accounts.id.initialize({
                client_id: appConfig.googleClientId,
                callback: handleLogin
            });
            google.accounts.id.renderButton(
                signInButton.current,
                {
                    theme: "outline",
                    size: "large",
                    type: 'standard',
                    shape: 'rectangular',
                    width: googleWidth,
                    height:'48',
                    logo_alignment: 'center'
                }  // customization attributes
            );
        }
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [])

    return (
        <>
            <div ref={signInButton} id="buttonDiv"></div>
            {loading && <Loader />}
            {showUserNameModal && isFromGmail && <Modal title="Almost Done" className={`themeModal ${isFromGmail && !users.userExistance?.username ? 'disable-modal-close' : ''}`} width={448} visible={showUserNameModal} onOk={finish} onCancel={userNameCancel} footer={false} centered closeIcon={<Close />}>
                <div className='modal-body'>
                    <p>Choose your username and get started</p>
                    <div className='popupform usernameform'>
                        <UserName isUserName={users?.userExistance?.username} reset={reset} setLoader={setLoading} loader={loading} />
                    </div>
                </div>
            </Modal>}
            {continueWithPhone && <PhoneModal isFromGmail={isFromGmail} logout={() => {
                reset()
                props.logout()
            }} continueWithPhone={continueWithPhone} handleOk={props.handleOk} flow={props.flow} isRegisterPopModal={props.isRegisterPopModal} handleCancel={props.handleCancel} setUserName={props.setUserName} />}
        </>
    )
}

export default Gmail;
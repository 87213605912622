
import {ThunkAction, ThunkDispatch} from 'redux-thunk'
import {AnyAction} from 'redux';
import API from "../../Utils/api"
import {
  GUEST_USERS,
  OPEN_LOGIN_SIGNUP_MODAL,
  OPEN_DOWNLOAD_MODAL,
  IMPLICITE_TOKEN,
  CHECK_USERS_EXISTANCE,
  SIGN_UP_PHONE_USER,
  CHECK_USERNAME_EXIST,
  SET_UP_USERNAME,
  SET_FOLLOWING,
  SET_HOST_USER_DETAILS,
  RESET,
  UPDATE_USER,
  SET_RESET_PASSWORD_TOKEN,
  UPDATE_USER_IMAGE,
  UPDATE_USER_TOPICS,
  UPDATE_USER_COVER_IMAGE,
  UPDATE_USER_WATCHLIST,
  SET_LOADER,
  UPDATE_HOST_USER_SHOWS,
  SET_BOOKMARK,
  UPDATE_USER_CURRENT_SHOW,
  LIST_FOLLOWER_USERS,
  LIST_FOLLOWING_USERS,
  RESET_FOLLOWER_FOLLOWING_USERS,
  UPDATE_WATCH_LIST,
  SOFT_DELETE_USER,
  BLOCK_USER,
  REPORT_USER,
  REMOVE_FOLLOWER,
  FETCHED_USER,
  FETCH_SHIPPING_ADDRESSES,
  VALIDATE_SHIPPING_ADDRESS,
  ADD_SHIPPING_ADDRESS,
  UPDATE_CARD_LIST,
  SEARCH_USER,
  FETCH_TRANSACTION_HISTORY,
  FETCH_ORDER_HISTORY,
  UPDATE_FAILED_ITEMS
} from "../Types/users"
import {bookmarkShowListAction, followShowListAction} from './showDashboard';
import axios from 'axios';
import {message} from 'antd';
import {displayErrorToast, displayInfoToast, getErrorMessage} from '../../Utils/helper';
import {account_created_success, auth_method_selected, identify_user, logout_user, registration_fail, registration_success, sign_in_fail, sign_in_success} from '../../Utils/mParticle';
import {strings} from '../../Utils/constants';
interface IS_GUEST_USERS {
  type: 'GUEST_USERS'
  isGuestUser: boolean
}
interface SET_LOADER {
  type: 'SET_LOADER'
  loader: boolean
}

interface OPENLOGINSIGNUPMODAL {
  type: 'OPEN_LOGIN_SIGNUP_MODAL'
  loginSignUpModal: boolean
}

interface OPEN_DOWNLOAD_MODAL_CLASS {
  type: 'OPEN_DOWNLOAD_MODAL',
  downloadAppModal: boolean
}
interface IMPLICITE_TOKEN_ACTION_CLASS {
  type: 'IMPLICITE_TOKEN'
  impliciteToken: string
}
interface SET_RESET_PASSWORD_TOKEN_ACTION_CLASS {
  type: 'SET_RESET_PASSWORD_TOKEN'
  resetToken: string
}

interface CHECK_USER_EXISTANCE_CLASS {
  type: 'CHECK_USERS_EXISTANCE'
  userExistance: object
}

interface SIGN_UP_PHONE_USER_CLASS {
  type: 'SIGN_UP_PHONE_USER'
  user: object
}
interface HOST_USER_DETAILS_CLASS {
  type: 'SET_HOST_USER_DETAILS'
  hostUser: object
}
interface CHECK_USERNAME_EXIST_CLASS {
  type: 'CHECK_USERNAME_EXIST'
  userNameChecking: object
}
interface SET_UP_USERNAME_CLASS {
  type: 'SET_UP_USERNAME'
  userName: object
}

interface UPDATE_USER_CLASS {
  type: 'UPDATE_USER'
  user: any,
  loader: boolean
}
interface UPDATE_USER_IMAGE_CLASS {
  type: 'UPDATE_USER_IMAGE' | 'UPDATE_USER_COVER_IMAGE'
  url: any
  loader: boolean
}
interface UPDATE_USER_TOPICS_CLASS {
  type: 'UPDATE_USER_TOPICS'
  topics: object
  loader: boolean
}
interface UPDATE_USER_WATCHLIST_CLASS {
  type: 'UPDATE_USER_WATCHLIST'
  watchlist: any
  loader: boolean
}

interface UPDATE_WATCH_LIST_CLASS {
  type: 'UPDATE_WATCH_LIST',
  id: number
  following: boolean
}
interface UPDATE_USER_SHOWS_CLASS {
  type: 'UPDATE_USER_SHOWS' | 'UPDATE_HOST_USER_SHOWS'
  shows: any
  showType: string
}
interface UPDATE_USER_CURRENT_SHOW_CLASS {
  type: 'UPDATE_USER_CURRENT_SHOW'
  show: any
}

interface Follow {
  following: boolean
  id: number
}

interface SET_FOLLOWING_CLASS {
  type: 'SET_FOLLOWING'
  following: Follow,
  shouldUpdateHost: boolean
}
interface SET_BOOKMARK_CLASS {
  type: 'SET_BOOKMARK'
  id: number,
  isBookmarked: boolean,
  isWatchList: boolean
}

interface USERDETAILS {
  type: 'USER'
  user: any
}

interface RESET_CLASS {
  type: 'RESET'
}

interface LIST_FOLLOWER_USERS_CLASS {
  type: 'LIST_FOLLOWER_USERS',
  followersData: any
}

interface LIST_FOLLOWING_USERS_CLASS {
  type: 'LIST_FOLLOWING_USERS',
  followingData: any
}

interface RESET_FOLLOWER_FOLLOWING_USERS_CLASS {
  type: 'RESET_FOLLOWER_FOLLOWING_USERS',
  payload: any
}

interface SOFT_DELETE_USER_CLASS {
  type: 'SOFT_DELETE_USER',
}

interface BLOCK_USER_CLASS {
  type: 'BLOCK_USER',
  hostId: number,
  shouldBlock: boolean
}

interface REPORT_USER_CLASS {
  type: 'REPORT_USER'
}

interface REMOVE_FOLLOWER_CLASS {
  type: 'REMOVE_FOLLOWER',
  id: number
}

interface FETCHED_USER_CLASS {
  type: 'FETCHED_USER',
  isFetchedUser: boolean
}

interface FETCH_SHIPPING_ADDRESSES_CLASS {
  type: 'FETCH_SHIPPING_ADDRESSES',
  addressData: any
}

interface VALIDATE_SHIPPING_ADDRESS_CLASS {
  type: 'VALIDATE_SHIPPING_ADDRESS',
  validatingAddress: any
}

interface ADD_SHIPPING_ADDRESS_CLASS {
  type: 'ADD_SHIPPING_ADDRESS',
  address: any,
  operationType: string
}
interface SEARCH_USERS_CLASS {
  type: 'SEARCH_USER',
  usersData: any,
  actionType?: string
}

interface UPDATE_CARD_LIST_CLASS {
  type: 'UPDATE_CARD_LIST',
  cardsData: any,
  operationType: string
}

interface FETCH_TRANSACTION_HISTORY_CLASS {
  type: 'FETCH_TRANSACTION_HISTORY',
  transactionData: any,
  actionType?: string
}

interface FETCH_ORDER_HISTORY_CLASS {
  type: 'FETCH_ORDER_HISTORY',
  orderHistoryData: any,
  actionType?: string
}

interface UPDATE_FAILED_ITEMS_CLASS {
  type: 'UPDATE_FAILED_ITEMS',
  failedItemsData: {items: [], shippingUpgradeData: {}, totalCount: []},
  actionType?: string
}

export const guestUserSetting = (isGuestUser: boolean): IS_GUEST_USERS => {
  return {type: GUEST_USERS, isGuestUser}
}
export const setLoader = (loader: boolean): SET_LOADER => {
  return {type: SET_LOADER, loader}
}

export const openLoginSignUpModalAction = (loginSignUpModal: boolean): OPENLOGINSIGNUPMODAL => {
  return {type: OPEN_LOGIN_SIGNUP_MODAL, loginSignUpModal}
}

export const openDownloadAppModalAction = (downloadAppModal: boolean): OPEN_DOWNLOAD_MODAL_CLASS => {
  return {type: OPEN_DOWNLOAD_MODAL, downloadAppModal}
}

export const createImpliciteTokenAction = (impliciteToken: string): IMPLICITE_TOKEN_ACTION_CLASS => {
  return {type: IMPLICITE_TOKEN, impliciteToken}
}
export const resetPasswordTokenAction = (resetToken: string): SET_RESET_PASSWORD_TOKEN_ACTION_CLASS => {
  return {type: SET_RESET_PASSWORD_TOKEN, resetToken}
}

export const checkUserExistAction = (userExistance: object): CHECK_USER_EXISTANCE_CLASS => {
  return {type: CHECK_USERS_EXISTANCE, userExistance}
}

export const signUpuserAction = (user: object): SIGN_UP_PHONE_USER_CLASS => {
  return {type: SIGN_UP_PHONE_USER, user}
}

export const checkUserNameExistanceAction = (userNameChecking: object): CHECK_USERNAME_EXIST_CLASS => {
  return {type: CHECK_USERNAME_EXIST, userNameChecking}
}

export const setUpUserNameAction = (userName: object): SET_UP_USERNAME_CLASS => {
  return {type: SET_UP_USERNAME, userName}
}

export const followUserAction = (following: Follow, shouldUpdateHost: boolean): SET_FOLLOWING_CLASS => {
  return {type: SET_FOLLOWING, following, shouldUpdateHost}
}
export const bookmarkShowAction = (id: number, isBookmarked: boolean, isWatchList: boolean): SET_BOOKMARK_CLASS => {
  return {type: SET_BOOKMARK, id, isBookmarked, isWatchList}
}

export const hostUserDetailsAction = (hostUser: object): HOST_USER_DETAILS_CLASS => {
  return {type: SET_HOST_USER_DETAILS, hostUser}
}
export const resetUserDetailsAction = (): RESET_CLASS => {
  return {type: RESET}
}
export const updateUserAction = (user: object, loader: boolean): UPDATE_USER_CLASS => {
  return {type: UPDATE_USER, user, loader}
}
export const updateUserImageAction = (url: string, loader: boolean): UPDATE_USER_IMAGE_CLASS => {
  return {type: UPDATE_USER_IMAGE, url, loader}
}
export const updateUserCoverImageAction = (url: string, loader: boolean): UPDATE_USER_IMAGE_CLASS => {
  return {type: UPDATE_USER_COVER_IMAGE, url, loader}
}
export const updateUserTopicsAction = (topics: number[], loader: boolean): UPDATE_USER_TOPICS_CLASS => {
  return {type: UPDATE_USER_TOPICS, topics, loader}
}
export const updateUserWatchListAction = (watchlist: any, loader: boolean): UPDATE_USER_WATCHLIST_CLASS => {
  return {type: UPDATE_USER_WATCHLIST, watchlist, loader}
}
export const updateWatchListAction = (id: number, following: boolean): UPDATE_WATCH_LIST_CLASS => {
  return {type: UPDATE_WATCH_LIST, id, following}
}
export const updateHostUserShowsAction = (shows: any, type: string): UPDATE_USER_SHOWS_CLASS => {
  return {type: UPDATE_HOST_USER_SHOWS, shows, showType: type}
}

export const setCurrentShow = (show: any): UPDATE_USER_CURRENT_SHOW_CLASS => {
  return {type: UPDATE_USER_CURRENT_SHOW, show}
}

export const getFollowersListAction = (payload: any) => ({
  type: LIST_FOLLOWER_USERS,
  followersData: payload
})

export const getFollowingListAction = (payload: any) => ({
  type: LIST_FOLLOWING_USERS,
  followingData: payload
})

export const resetFollowersFollowingListAction = () => ({
  type: RESET_FOLLOWER_FOLLOWING_USERS,
  data: []
})

export const softDeleteUserAction = (): SOFT_DELETE_USER_CLASS => {
  return {type: SOFT_DELETE_USER}
}

export const blockUserAction = (hostId: number, shouldBlock: boolean): BLOCK_USER_CLASS => {
  return {type: BLOCK_USER, hostId, shouldBlock}
}

export const reportUserAction = (): REPORT_USER_CLASS => {
  return {type: REPORT_USER}
}

export const removeFollowerAction = (id: number): REMOVE_FOLLOWER_CLASS => {
  return {type: REMOVE_FOLLOWER, id}
}

export const searchUsersAction = (usersData: [], actionType?: string): SEARCH_USERS_CLASS => {
  return {type: SEARCH_USER, usersData, actionType}
}

export const fetchedUser = (isFetchedUser: boolean): FETCHED_USER_CLASS => {
  return {type: FETCHED_USER, isFetchedUser}
}

export const shippingAddressAction = (payload: any): FETCH_SHIPPING_ADDRESSES_CLASS => {
  return {type: FETCH_SHIPPING_ADDRESSES, addressData: payload}
}

export const validateAddressAction = (validatingAddress: object): VALIDATE_SHIPPING_ADDRESS_CLASS => {
  return {type: VALIDATE_SHIPPING_ADDRESS, validatingAddress}
}

export const addShippingAddressAction = (address: any, operationType: string): ADD_SHIPPING_ADDRESS_CLASS => {
  return {type: ADD_SHIPPING_ADDRESS, address, operationType}
}

export const updateSavedCardsAction = (payload: any, operationType: string): UPDATE_CARD_LIST_CLASS => {
  return {type: UPDATE_CARD_LIST, cardsData: payload, operationType}
}

export const fetchTransactionHistoryAction = (payload: any, actionType?: string): FETCH_TRANSACTION_HISTORY_CLASS => {
  return {type: FETCH_TRANSACTION_HISTORY, transactionData: payload, actionType}
}

export const orderHistoryAction = (payload: any, actionType?: string): FETCH_ORDER_HISTORY_CLASS => {
  return {type: FETCH_ORDER_HISTORY, orderHistoryData: payload, actionType}
}

export const failedItemsAction = (payload: any, actionType?: string): UPDATE_FAILED_ITEMS_CLASS => {
  return {type: UPDATE_FAILED_ITEMS, failedItemsData: payload, actionType}
}

export const guestUserSet = (flag: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      dispatch(guestUserSetting(flag))
    } catch (err) {
      console.log(err)
      dispatch(guestUserSetting(false))
    }
  }
}

export const openLoginSignUpModal = (flag: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      dispatch(openLoginSignUpModalAction(flag))
    } catch (err) {
      console.log(err)
      // dispatch(openLoginSignUpModalAction(false))
    }
  }
}

export const openDownloadAppModal = (flag: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(openDownloadAppModalAction(flag))
    } catch (error) {
      console.log(error)
    }
  }
}

export const checkUserExistance = (userDetails: object): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      const userExist = await API.postDataWithOutToken('auth/check-user', userDetails)
      dispatch(checkUserExistAction(userExist?.data?.data[0] || {}))
    } catch (err) {
      console.log(err)

    }
  }
}
export const resetUserExistance = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(checkUserExistAction({}))
  }
}

export const createImplicitToken = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      const impliciteToken = await API.postDataWithOutToken("/auth/type", {"token": "implicit"})
      localStorage.setItem("implicite", impliciteToken?.data.data[0].token)
      dispatch(createImpliciteTokenAction(impliciteToken.data.data[0].token))
    } catch (err) {
      console.log(err)

    }
  }
}

export const signUpuser = (userDetails: object): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      const user = await API.postDataWithOutToken("auth/signup", userDetails)

      localStorage.setItem("access-token", user?.data.data[0].accessToken)
      localStorage.setItem("refresh-token", user?.data.data[0].refreshToken)

      account_created_success(user.data.data[0].user, strings.trackTypePhone);
      dispatch(signUpuserAction(user.data.data[0].user))
    } catch (err: any) {
      console.log(err)
      registration_fail('phone', 'API', getErrorMessage(err), err?.response?.data?.code);
    }
  }
}

export const signUpWithEmail = (userDetails: object): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      const user = await API.postDataWithOutToken("auth/signup", userDetails)

      localStorage.setItem("access-token", user?.data.data[0].accessToken)
      localStorage.setItem("refresh-token", user?.data.data[0].refreshToken)

      dispatch(signUpuserAction(user.data.data[0].user))

      account_created_success(user.data.data[0].user, strings.trackTypeEmail);
      registration_success(strings.trackTypeEmail, user.data.data[0].user?.userTopics, user.data.data[0].user);
    } catch (err: any) {
      console.log(err)
      registration_fail(strings.trackTypeEmail, 'API', getErrorMessage(err), err?.response?.data?.code);
      throw err
    }
  }
}
export const resetPassword = (userDetails: object): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (): Promise<void> => {

    try {
      await API.postDataWithOutToken("auth/password/reset", userDetails)
      // localStorage.setItem("access-token", user?.data.data[0].accessToken)
      // localStorage.setItem("refresh-token", user?.data.data[0].refreshToken)

      // dispatch(signUpuserAction(user.data.data[0].user))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export const signInWithEmail = (loginDetails: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      const {data} = await API.postDataWithOutToken('auth/signin', {}, {
        'Authorization': "Basic " + loginDetails,
        'grant-type': 'password'
      })
      if (data.code === 200) {
        localStorage.setItem("access-token", data?.data[0]?.accessToken)
        localStorage.setItem("refresh-token", data?.data[0]?.refreshToken)
        // props.handleCancel()
        dispatch(signUpuserAction(data?.data[0].user))
        sign_in_success(strings.trackTypeEmail,data?.data[0].user)
      }
    } catch (error: any) {
      console.log(error);
      sign_in_fail(strings.trackTypeEmail, 'API', getErrorMessage(error), error?.response?.data?.code);
      throw error
    }
  }
}
export const signInWithGmail = (credential: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      auth_method_selected(strings.trackTypeGoogle);
      const data = await API.postDataWithOutToken('auth/signup', {
        googleId: credential.sub,
        email: credential.email,
        name: credential.name,
        firstname: credential.given_name,
        lastname: credential.family_name,
        pic: credential.picture,
        app: "collectibles",
        source: "google"
      })
      localStorage.setItem("access-token", data?.data?.data[0]?.accessToken)
      localStorage.setItem("refresh-token", data?.data?.data[0]?.refreshToken)
      // setUser(data.data.data[0])
      // dispatch(userSetting(data.data.data[0]))
      if(data.data?.data[0].user?.isUserExists) {
        sign_in_success(strings.trackTypeGoogle,data.data?.data[0].user)
      }else{
        account_created_success(data.data?.data[0].user, strings.trackTypeGoogle);
      }
      await dispatch(signUpuserAction(data.data?.data[0].user))

    } catch (error: any) {
      console.log(error);
      message.error({icon: '💥 ', content: error?.response?.data?.message})
      sign_in_fail(strings.trackTypeGoogle, 'API', getErrorMessage(error), error?.response?.data?.code);
    }
  }
}

export const signInWithApple = (credential: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      auth_method_selected(strings.trackTypeApple);
      const data = await API.postDataWithOutToken('auth/signup', credential)
      localStorage.setItem("access-token", data?.data?.data[0]?.accessToken)
      localStorage.setItem("refresh-token", data?.data?.data[0]?.refreshToken)
      // setUser(data.data.data[0])
      // dispatch(userSetting(data.data.data[0]))
      if(data.data?.data[0].user?.isUserExists) {
        sign_in_success(strings.trackTypeApple,data.data?.data[0].user)
      }else{
        account_created_success(data.data?.data[0].user, strings.trackTypeApple);
      }
      dispatch(signUpuserAction(data.data?.data[0].user))

    } catch (error: any) {
      console.log(error);
      displayErrorToast(error?.response?.data?.message)
      sign_in_fail(strings.trackTypeApple, 'API', getErrorMessage(error), error?.response?.data?.code);
    }
  }
}

export const checkUserNameExistance = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(checkUserNameExistanceAction({
        isUserName: false,
        isuserNameChecking: true
      }))
      const userName = await API.getDataWithToken("user/username/validate", {username})
      dispatch(checkUserNameExistanceAction({
        isUserName: userName.data.data[0].isUserNameExist,
        isuserNameChecking: false
      }))
    } catch (err) {
      console.log(err)
    }
  }
}

export const signInUser = (userDetails: object): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
      const user = await API.postDataWithOutToken("auth/signin", userDetails)

      localStorage.setItem("access-token", user?.data.data[0].accessToken)
      localStorage.setItem("refresh-token", user?.data.data[0].refreshToken)
      dispatch(signUpuserAction(user.data.data[0].user))
      sign_in_success(strings.trackTypePhone,user.data.data[0].user)
    } catch (err: any) {
      console.log(err)
      sign_in_fail(strings.trackTypePhone, 'API', getErrorMessage(err), err?.response?.data?.code)
    }
  }
}

export const setUpUserNameBirthdate = (userNameDetails: object): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {

      dispatch(setUpUserNameAction({
        loader: true,
        userName: {}
      }))
      // const userName = await API.putWithToken("user/username", userNameDetails)
      const response = await API.putWithToken("user/profile", userNameDetails)
      dispatch(fetchUserDetails())
      dispatch(setUpUserNameAction({
        loader: false,
        // userName: userName.data
        userName: response.data,
      }))
    } catch (err: any) {
      console.log(err.response)
      registration_fail(err.response.data?.data[0]?.source, 'API', getErrorMessage(err), err?.response?.data?.code);
      dispatch(setUpUserNameAction({
        loader: false,
        userName: err.response.data
      }))
    }
  }
}

export const fetchUserDetails = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) {
      dispatch(fetchedUser(true))
      return
    }
    try {

      dispatch(fetchedUser(false))
      const publishableKey = await API.getDataWithToken("payments/stripe/publishableKey")
      const cardList = await API.getDataWithToken("payments/card")
      const user = await API.getDataWithToken("user")

      user.data.data[0].publishableKey = publishableKey?.data?.data?.publishableKey || null;
      user.data.data[0].cardList = [...cardList.data.data.cardList] || []
      dispatch(signUpuserAction(user.data.data[0]))
      dispatch(fetchedUser(true))
      identify_user(user?.data?.data[0])
    } catch (err) {

      dispatch(fetchedUser(true))
      console.log(err)
      throw err
    }
  }
}

export const followUser = (follow: boolean, hostId: number, shouldUpdateHost: boolean, isWatchList: boolean = false): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {


    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      if (!follow) {
        const {data} = await API.postDataWithToken("user/follow", {
          userId: hostId
        })
        if (data.success) {
          dispatch(followUserAction({following: false, id: hostId}, shouldUpdateHost))
          dispatch(followShowListAction(hostId, false))
          if (isWatchList) dispatch(updateWatchListAction(hostId, false))
        }
      }
      else {
        const {data} = await API.postDataWithToken("user/unfollow", {
          userId: hostId
        })
        if (data.success) {
          dispatch(followUserAction({following: true, id: hostId}, shouldUpdateHost))
          dispatch(followShowListAction(hostId, true))
          if (isWatchList) dispatch(updateWatchListAction(hostId, true))
        }
      }
    } catch (err) {
      dispatch(setLoader(false))
      console.log(err)
      throw err
    }
    dispatch(setLoader(false))
  }
}

export const logOutUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(signUpuserAction({}))
      dispatch(checkUserExistAction({}))
      dispatch(setUpUserNameAction({}))
      dispatch(openLoginSignUpModalAction(false))
      dispatch(openDownloadAppModalAction(false))
      dispatch(checkUserNameExistanceAction({}))
      await API.getDataWithToken("user/signout")
      logout_user()

      localStorage.removeItem("access-token")
      localStorage.removeItem("refresh-token")
      localStorage.removeItem('loggedInFrom')
      await dispatch(resetUserDetailsAction())
    } catch (err) {
      console.log(err)
    }
  }
}

export const notifyUser = (fcm_token: string, showId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (): Promise<void> => {
    try {
      await API.getDataWithToken('api/reminder', {
        'token': fcm_token,
        'show_id': showId,
      });
    } catch (err) {
      console.log(err)
    }
  }
}

export const sendEmailOtp = (email: string, type: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (): Promise<void> => {
    try {
      await API.postDataWithOutToken('auth/otp', {
        email: email,
        otpType: type
      })

    } catch (err) {
      console.log(err)
      throw err
    }
  }
}
export const verifyEmailOtp = (email: string, otp: string, type: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const verified = await API.putWithOutToken('auth/otp', {
        "email": email,
        "otpType": type,
        "otp": otp

      })
      if (type === 'RESET_PASSWORD')
        dispatch(resetPasswordTokenAction(verified.data?.data[0]?.token))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export const fetchHostUserDetails = (username: string, userId: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      let data
      if (userId)
        data = await API.getDataWithToken(`user/${username}`)
      else
        data = await API.getDataWithOutToken(`user/${username}`, {}, {bypass: true})
      dispatch(hostUserDetailsAction(data.data?.data[0]))
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export const subscribeEmail = (email: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (): Promise<void> => {
    try {
      await axios.get('api/waitlist', {
        params: {
          email: email
        }
      })
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export const updateUser = (userDetails: object): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(updateUserAction({user: {}}, true));
      const user = await API.putWithToken('user/profile', userDetails)
      dispatch(updateUserAction({user: user.data.data[0]}, false));
      identify_user(user?.data?.data[0])
    } catch (err) {
      dispatch(updateUserAction({user: {}}, false));
      console.log(err)
      throw err
    }
  }
}

export const updateUserProfileImage = (formData: FormData): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(updateUserImageAction('', true));
      const user = await API.putWithToken('user/profile-pic', formData, {
        "Content-Type": "multipart/form-data"
      })

      //we are dispatching two actions here because after updating the profile image the url string is same for the updated image which will not trigger the re-render of the component
      //so we are updating the string to an empty string first and after the updated image string to rerender the image
      dispatch(updateUserImageAction('', true));
      dispatch(updateUserImageAction(user.data.data[0]?.Location, false));
    } catch (err) {
      dispatch(updateUserImageAction('', false));
      console.log(err)
      throw err
    }
  }
}
export const updateUserCoverImage = (formData: FormData): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(updateUserCoverImageAction('', true));
      const user = await API.putWithToken('user/cover-pic', formData, {
        "Content-Type": "multipart/form-data"
      })
      //we are dispatching two actions here because after updating the profile image the url string is same for the updated image which will not trigger the re-render of the component
      //so we are updating the string to an empty string first and after the updated image string to rerender the image
      dispatch(updateUserCoverImageAction('', true));
      dispatch(updateUserCoverImageAction(user.data.data[0]?.Location, false));
    } catch (err) {
      dispatch(updateUserImageAction('', false));
      console.log(err)
      throw err
    }
  }
}

export const updateUserTopics = (topics: number[]): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const update = await API.putWithToken('user/topics', {
        topics
      })
      dispatch(updateUserTopicsAction(update.data?.data, false));
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export const getUserWatchList = (page: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setLoader(true));
      const update = await API.getDataWithToken('user/watchlist', {
        page,
        limit: 100
      })
      dispatch(updateUserWatchListAction(update.data?.data, false));
    } catch (err) {
      dispatch(setLoader(false));
      console.log(err)
      throw err
    }
    dispatch(setLoader(false));
  }
}
export const deleteWatchlistItem = (showId: number, isWatchList: boolean = false): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch(setLoader(true))
      await API.deleteWithToken('user/watchlist', {show_id: showId})
      dispatch(bookmarkShowAction(showId, true, isWatchList))
      dispatch(bookmarkShowListAction(showId, true))
    } catch (err: any) {
      dispatch(setLoader(false))
      console.log(err)
      throw err
    }
    dispatch(setLoader(false))
  }
}
export const addWatchlistItem = (showId: number, isWatchList: boolean = false): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setLoader(true))
    try {
      await API.putWithToken('user/watchlist', {
        show_id: showId
      })
      dispatch(bookmarkShowAction(showId, false, isWatchList))
      dispatch(bookmarkShowListAction(showId, false))
    } catch (err) {
      dispatch(setLoader(false))
      console.log(err)
      throw err
    }
    dispatch(setLoader(false))
  }
}
export const getShowById = (showId: number, user: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setLoader(true))
    try {
      let data
      if (localStorage.getItem('access-token'))
        data = await API.getDataWithToken(`shows/${showId}`)
      else
        data = await API.getDataWithOutToken(`shows/${showId}`, {}, {bypass: true})

      dispatch(setCurrentShow({...data.data?.data?.show, disclaimerText: data.data?.data?.disclaimerText ?? ''} || {}))
      dispatch(setLoader(false))
    } catch (err) {
      dispatch(setLoader(false))
      console.log(err)
      throw err
    }
  }
}
export const getHostUserShows = (userId: number, user: boolean, status: string, page: number, search = ''): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setLoader(true))
    let shows
    try {
      if (user) {
        shows = await API.getDataWithToken(`user/shows/${userId}/${status}`, {
          userId,
          status,
          page,
          limit: 10,
          search
        })
      }
      else {
        shows = await API.getDataWithOutToken(`user/shows/${userId}/${status}`, {
          userId,
          status,
          page,
          limit: 10,
          search
        }, {bypass: true})
      }
      dispatch(updateHostUserShowsAction(shows?.data.data, status))
    } catch (err) {
      dispatch(setLoader(false))
      throw err
    }
    dispatch(setLoader(false))
  }
}

export const getFollowersList = (userId: number, page: number, filterBy: number, search = '', limit = 10): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setLoader(true))

    try {
      let response
      if (!localStorage.getItem('access-token')) {
        response = await API.getDataWithOutToken(`user/${userId}/followers`, {
          page,
          search,
          filterBy,
          limit,
        }, {bypass: true})
      }
      else {
        response = await API.getDataWithToken(`user/${userId}/followers`, {
          page,
          search,
          filterBy,
          limit,
        })
      }
      dispatch(getFollowersListAction(response?.data?.data))
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const getFollowingList = (userId: number, page: number, filterBy: number, search = '', limit = 10): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setLoader(true))

    try {
      let response
      if (!localStorage.getItem('access-token')) {
        response = await API.getDataWithOutToken(`user/${userId}/following`, {
          page,
          search,
          filterBy,
          limit
        }, {bypass: true})
      }
      else {
        response = await API.getDataWithToken(`user/${userId}/following`, {
          page,
          search,
          filterBy,
          limit,
        })
      }
      dispatch(getFollowingListAction(response?.data?.data))
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const softDeleteUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(setLoader(true))

    try {
      await API.deleteWithToken(`user/`, {})

      dispatch(signUpuserAction({}))
      dispatch(checkUserExistAction({}))
      dispatch(setUpUserNameAction({}))
      dispatch(openLoginSignUpModalAction(false))
      dispatch(openDownloadAppModalAction(false))
      dispatch(checkUserNameExistanceAction({}))

      localStorage.removeItem("access-token")
      localStorage.removeItem("refresh-token")

      await dispatch(softDeleteUserAction())

    } catch (error:any) {
      dispatch(setLoader(false))
      displayErrorToast(error.response?.data?.message);
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const blockUnblockUser = (hostId: number, block: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      if (!block) {
        const {data} = await API.putWithToken(`user/unblock`, {userId: hostId})
        if (data.success) {
          await dispatch(blockUserAction(hostId, block))
        displayInfoToast(data?.message)
        }
      }
      else {
        const {data} = await API.putWithToken(`user/block`, {userId: hostId})
        if (data.success) {
          await dispatch(blockUserAction(hostId, block))
          displayInfoToast(data?.message)
        }
      }
    } catch (err: any) {
      dispatch(setLoader(false))
      if (err?.response?.data?.message) displayErrorToast(err.response?.data?.message)
      console.log(err)
      throw err
    }
    dispatch(setLoader(false))
  }
}

export const reportUser = (userId: number, reasonId: number, description: string, isShowPage?: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))

      const {data} = await API.postDataWithToken(`user/report`, {userId, reasonId, description})
      if (data.success) {
        displayInfoToast(`${isShowPage? 'Show' : 'User'} reported successfully.`)
        await dispatch(reportUserAction())
      }
    } catch (err) {
      dispatch(setLoader(false))
      // console.log(err)
      throw err
    }
    dispatch(setLoader(false))
  }
}

export const removeFollower = (userId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))

      await API.deleteWithToken(`user/follower`, {userId})

      await dispatch(removeFollowerAction(userId))
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const fetchShippingAddress = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const { data } = await API.getDataWithToken(`shipping/address`);

      await dispatch(shippingAddressAction(data?.data ?? []));
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const validateShippingAddress = (address: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      await dispatch(validateAddressAction({
        isValidating: true,
        deliverability: '',
        lobAddressResponse: null
      }))
      const {data} = await API.lobPostRequestVerifications("v1/intl_verifications", address);

      await dispatch(validateAddressAction({
        isValidating: false,
        deliverability: data?.deliverability ?? '',
        lobAddressResponse: data?.components
      }))
    } catch (err) {
      console.log(err)
      await dispatch(validateAddressAction({
        isValidating: false,
        deliverability: '',
        lobAddressResponse: null
      }))
      throw err
    }
  }
}

export const addShippingAddress = (address: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const { data } = await API.postDataWithToken(`shipping/address`, address);
      console.log({data});

      if (data?.success) {
        await dispatch(addShippingAddressAction(data?.data, 'ADD'))
      }
      else {
        throw new Error('Please try again!')
      }
    } catch (error: any) {
      console.log(error);
      throw error
    }
    finally {
      dispatch(setLoader(false))
    }
  }
}

export const updateShippingAddress = (address: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const { data } = await API.putWithToken(`shipping/address`, address);
      console.log({data});

      if (data?.success) {
        await dispatch(addShippingAddressAction(data?.data, 'UPDATE'))
        data?.message && displayInfoToast(`${data?.message}`)
      }
      else {
        throw new Error('Please try again!')
      }
    } catch (error: any) {
      console.log(error);
      throw error
    }
    finally {
      dispatch(setLoader(false))
    }
  }
}

export const deleteShippingAddress = (addressId: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const { data } = await API.deleteWithToken(`shipping/address`, {id: addressId});
      console.log({data});

      if (data?.success) {
        await dispatch(addShippingAddressAction({id: addressId}, 'DELETE'))
        data?.message && displayInfoToast(`${data?.message}`)
      }
      else {
        throw new Error('Please try again!')
      }
    } catch (error: any) {
      console.log(error);
      throw error
    }
    finally {
      dispatch(setLoader(false))
    }
  }
}
export const searchUsers = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      let response
      if (!localStorage.getItem('access-token')) {
        response = await API.getDataWithOutToken(`api/search/${username.toLowerCase()}`, {bypass: true})
      }
      else {
        response = await API.getDataWithToken(`api/search/${username.toLowerCase()}`)
      }
      dispatch(searchUsersAction(response?.data?.data))
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const updateSavedCards = (cardId: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const { data } = await API.putWithToken(`payments/card/primary`, {id: cardId});

      await dispatch(updateSavedCardsAction(data?.data, 'UPDATE'))
      await dispatch(fetchUserDetails())
      data?.message && displayInfoToast(`${data?.message}`)
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const deleteSavedCards = (cardId: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const { data } = await API.deleteWithToken(`payments/card`, {id: cardId});

      await dispatch(updateSavedCardsAction(data?.data, 'DELETE'))
      await dispatch(fetchUserDetails())
      data?.message && displayInfoToast(`${data?.message}`)
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const fetchTransactionHistory = (page: number, filterBy: string = '', limit = 10): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const {data} = await API.getDataWithToken(`user/payment/history`, {
        page,
        limit,
        filterBy
      });

      await dispatch(fetchTransactionHistoryAction(data?.data));
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const fetchOrderHistory = (page: number, limit = 10): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const {data} = await API.getDataWithToken(`user/orders`, {
        page,
        limit,
      });

      await dispatch(orderHistoryAction(data?.data));
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}

export const getFailedItemsList = (userId: any, airTableId: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    if (!localStorage.getItem('access-token')) return
    try {
      dispatch(setLoader(true))
      const {data} = await API.getDataWithToken(`api/payment/${userId}/${airTableId}/cart/info`);
      await dispatch(failedItemsAction({totalCount: data?.total, items: data?.failed_items, shippingUpgradeData: data?.upgradeShippingTransaction}));
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
    dispatch(setLoader(false))
  }
}


export type UsersAction =
  IS_GUEST_USERS |
  OPENLOGINSIGNUPMODAL |
  OPEN_DOWNLOAD_MODAL_CLASS |
  IMPLICITE_TOKEN_ACTION_CLASS |
  CHECK_USER_EXISTANCE_CLASS |
  SIGN_UP_PHONE_USER_CLASS |
  CHECK_USERNAME_EXIST_CLASS |
  SET_UP_USERNAME_CLASS |
  USERDETAILS |
  SET_FOLLOWING_CLASS |
  HOST_USER_DETAILS_CLASS |
  UPDATE_USER_CLASS |
  RESET_CLASS |
  SET_RESET_PASSWORD_TOKEN_ACTION_CLASS |
  UPDATE_USER_IMAGE_CLASS |
  UPDATE_USER_TOPICS_CLASS |
  UPDATE_USER_WATCHLIST_CLASS |
  UPDATE_WATCH_LIST_CLASS |
  SET_LOADER |
  UPDATE_USER_SHOWS_CLASS |
  SET_BOOKMARK_CLASS |
  UPDATE_USER_CURRENT_SHOW_CLASS |
  LIST_FOLLOWER_USERS_CLASS |
  LIST_FOLLOWING_USERS_CLASS |
  RESET_FOLLOWER_FOLLOWING_USERS_CLASS |
  SOFT_DELETE_USER_CLASS | BLOCK_USER_CLASS | REPORT_USER_CLASS |
  REMOVE_FOLLOWER_CLASS | FETCHED_USER_CLASS | SEARCH_USERS_CLASS |
  FETCH_SHIPPING_ADDRESSES_CLASS |
  VALIDATE_SHIPPING_ADDRESS_CLASS |
  ADD_SHIPPING_ADDRESS_CLASS |
  UPDATE_CARD_LIST_CLASS |
  ADD_SHIPPING_ADDRESS_CLASS |
  FETCH_TRANSACTION_HISTORY_CLASS |
  FETCH_ORDER_HISTORY_CLASS |
  UPDATE_FAILED_ITEMS_CLASS

import React, {useEffect, useState} from 'react'
import {images} from '../../Assets/ImageConfig/images';
import {Form, Input, Button, Radio, Col, Row, message} from 'antd';
import Appstore from '../CommonComponents/AppStore'
import Playstore from '../CommonComponents/Playstore/Playstore'
import {Fade} from "react-awesome-reveal";
import './HomePage.scss'
import {getAllShows} from '../../store/Actions/showDashboard';
import {RootState} from "../../store"
import {Eye, Live, CardShare, Save, Time} from '../Icons'
import CopyToClipboard from 'react-copy-to-clipboard';
import {displayInfoToast, localDateTime, openInNewTab, placeHolderProfileHelper} from '../../Utils/helper';
import {useLocation} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet} from "react-helmet";
import {emailRegex, requiredEmailMsg, requiredFieldMsg} from '../../Utils/constants';
import ProfileBadges from '../CommonComponents/ProfileBadges';
import {discord_button_clicked, host_application_button_clicked} from '../../Utils/mParticle';

function HomePageDe() {

    const dispatch = useDispatch();
    const [user, setUser] = useState<any>({});
    const [interest, setInterest] = useState<any>([]);
    const showDashboard = useSelector(
        (state: RootState) => state?.showDashboard
    );
    const users = useSelector(
        (state: RootState) => state?.usersReducer
    );
    useEffect(() => {
        dispatch(getAllShows('ready', 1, users.user?.id, [], '0', 3))
    }, [])

    const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.setCustomValidity('')
    }

    const onProfileClick = (show: any) => {
        if (show?.user_id === users?.user?.id) {
            openInNewTab('/my-profile')
        }
        else {
            openInNewTab(`/${show?.host}`)
        }
    }

    return (
        <>
            <Helmet>
                <title>GAVEL - Live Stream Auktionen für Sammelkarten und vieles mehrDescription:</title>
                <meta property="og:title" content="GAVEL - Live Stream Auktionen für Sammelkarten und vieles mehrDescription:" />
                <meta property="title" content="GAVEL - Live Stream Auktionen für Sammelkarten und vieles mehrDescription:" />
                <meta property="og:description" content="Tritt der GAVEL Community bei & biete auf deine Lieblingskarten, Booster und Limited Editions. Shoppe bei unseren verifizierten Verkäufern mit 100% Käuferschutz!" />
                <meta property="description" content="Tritt der GAVEL Community bei & biete auf deine Lieblingskarten, Booster und Limited Editions. Shoppe bei unseren verifizierten Verkäufern mit 100% Käuferschutz!" />
                <meta property="og:url" content="https://letsgavel.com/de" />
                <meta property="og:image" content="https://i.ibb.co/v3bRZ0q/Meta-Image-DE.png" />
                <link rel="canonical" href="https://letsgavel.com/de" />
            </Helmet>

            <div className='greeting-block'>
                <div className='b-container'>
                    <Fade>
                        <div className='greeting-inner'>
                            <div className='celebrationimg'>
                                <img src={images.Bcelebration} alt='celebration' />
                            </div>
                            <div className='greeting-left'>
                                <img src={images.Blogo} alt='logo' />
                                <span className='turn'>turned</span>
                            </div>
                            <div className='greeting-center'>
                                <span className='number'>1</span>
                            </div>
                            <div className='greeting-right'>
                                <img src={images.Bcake} alt='cake' />
                                <span className='date-span'>Thank you!</span>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>

            <div className='sellwatch-block'>
                <Fade>
                    <div className='sellwatch-inner'>
                        <div className='sellwatch-left'>
                            <div className='sellwatchBox'>
                                <div className='sellwatchBox-top'>
                                    <h2>ZUSCHAUEN & SAMMELN</h2>
                                    <div className='titleImg'>
                                        <img src={images.watchcollection} alt="" />
                                    </div>
                                </div>
                                <div className='sellwatchBox-bottom'>
                                    <div className='sellwatchBox-title'>
                                        <h5>Demnächst auf GAVEL</h5>
                                        <a href='/shows'>Alle Anzeigen
                                            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.20752 11.2704L4.78752 6.68036L0.20752 2.09036L1.61752 0.680359L7.61752 6.68036L1.61752 12.6804L0.20752 11.2704Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                    </div>
                                    <div className='featuredshow'>
                                        <div className='featuredImg'>
                                            <Row gutter={30}>
                                                {showDashboard.shows?.ready?.shows.map((show: any, index: number) => {
                                                    return (
                                                        <Col xs={24} sm={24} md={12} xl={8} key={index} >
                                                            <div className='featureCardlist '>
                                                                <div className='featureCrad'>
                                                                    {/* {loading && <Loader />} */}
                                                                    <div className="featureImagebox" >
                                                                        {/* <div className='Featureoverlay' onClick={() => {
                                        // dispatch(openLoginSignUpModal(true))
                                      }}></div> */}
                                                                        <a className='Featureoverlay' href={`/show/${show.id}`}>
                                                                        </a>
                                                                        <div className='featureImg'>
                                                                            <img src={show?.picture_url ?? images.showPlaceholder} alt="img" onError={({currentTarget}) => {
                                                                                currentTarget.onerror = null
                                                                                currentTarget.src = images.showPlaceholder
                                                                            }}
                                                                            />
                                                                        </div>
                                                                        <div className='imgtop'>
                                                                            {
                                                                                // BUG: always showing 1 because it waits for socket init instead of reading from JSON response
                                                                                // show?.status === 'live' ? <span className='viewCount'>{show?.online} <Eye /> </span> : <span></span>
                                                                                <span></span>
                                                                            }
                                                                            <div className='rightImgtop'>
                                                                                {
                                                                                    show?.status === 'ready' && <span className='viewCount'><Time /> {localDateTime(show.starts_at)}</span>
                                                                                }
                                                                                {
                                                                                    show?.status === 'live' && <span className='Livespan'><Live /> Live</span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='featureProfile'>
                                                                            <div className='profile-left' onClick={() => {
                                                                                onProfileClick(show)
                                                                            }}>
                                                                                <div className='profileImg'>
                                                                                    <img src={show?.profile_img_url ?? images.profiledummy} alt="img" onError={(e) => {
                                                                                        placeHolderProfileHelper(e)
                                                                                    }} />
                                                                                </div>
                                                                                <div className='userName'>
                                                                                    <div className='profileBadge'>
                                                                                        <p>{show?.host} </p> {show?.host ? (
                                                                                            <ProfileBadges user={show} />
                                                                                        ) : null}
                                                                                    </div>
                                                                                    <span> {show?.followed_by_ids?.length} followers</span>
                                                                                </div>
                                                                            </div>
                                                                            <CopyToClipboard text={`${window.location.host}/show/${show?.id}`} onCopy={() => displayInfoToast('Show url copied')}>
                                                                                <a className='cardShare'><CardShare /></a>
                                                                            </CopyToClipboard>

                                                                        </div>
                                                                    </div>
                                                                    <div className='Cardtextbox'>
                                                                        <p>{show?.title}</p>
                                                                        <p className='hastagspan'>
                                                                            {
                                                                                show.topicData.map((topic: any, index: number) => index < 6 && (
                                                                                    <span key={index}>{topic.name} </span>
                                                                                ))
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='sellwatch-right'>
                            <div className='sellwatchBox'>
                                <div className='sellwatchBox-top'>
                                    <div className='titleImg'>
                                        <img src={images.streamsell} alt="" />
                                    </div>
                                    <h2>STREAMEN & VERKAUFEN</h2>

                                </div>
                                <div className='sellwatchBox-bottom'>
                                    <div className='streamform'>
                                        <form onSubmit={(e) => {
                                            e.preventDefault()
                                            host_application_button_clicked(users.user, 'Home', 'DE Home Screen', 'Home_Screen.Submit_Application_Button');
                                            let int = interest.join(',');
                                            window.location.href =
                                                `https://airtable.com/shrl3kzicYqIz8GfF?prefill_fullname=${user?.fullname}&prefill_email=${user.email}&prefill_selling_products=${int}`
                                        }}>
                                            <div className='formoutbox'>
                                                <div className='formleft'>
                                                    <div className='sellwatchBox-title'>
                                                        <h5>LIVE STREAM SHOWS!</h5>
                                                    </div>
                                                    <div className='featured-text'>
                                                        <p><span>Mach mit bei täglichen Live-Shows, sammel mit anderen oder nimm an Box Breaks teil.</span></p>
                                                        <p>Tauche ein in die Welt von Pokémon, Magic: The Gathering und Yu-Gi-Oh!</p>
                                                        <p><span>Mach deinen Hobby zum Beruf und werde GAVEL Host!</span></p>
                                                        <p>Kreiere dein ganz eigenes Live-Show Format und bewirb dich als GAVEL Host.</p>
                                                    </div>
                                                </div>
                                                <div className='formright'>
                                                    <div className='formInputs '>
                                                        <input type="text"
                                                            placeholder="Dein Name"
                                                            required
                                                            onInput={onInput}
                                                            onInvalid={(e: React.ChangeEvent<HTMLInputElement>) => {e.target.setCustomValidity(requiredFieldMsg)}}
                                                            title={requiredFieldMsg}
                                                            onChange={(e) => {
                                                                setUser({...user, fullname: e.target.value})
                                                            }} />
                                                    </div>
                                                    <div className='formInputs'>
                                                        <input type="email"
                                                            placeholder="Deine Emailadresse"
                                                            required
                                                            onInvalid={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (e.target.value === '') {
                                                                    e.target.setCustomValidity(requiredFieldMsg)
                                                                }
                                                                else if (e.target.validity.typeMismatch) {
                                                                    e.target.setCustomValidity(requiredEmailMsg)
                                                                }
                                                                else if (emailRegex.test(e.target.value)) {
                                                                    onInput(e)
                                                                }
                                                            }}
                                                            title={user?.email?.length > 0 ? requiredEmailMsg : requiredFieldMsg}
                                                            onChange={(e) => {
                                                                setUser({...user, email: e.target.value})
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='checkedbox'>
                                                        <input type="checkbox" className='checkedinput'
                                                            value="Pokemon"
                                                            onChange={(e) => {
                                                                let constructInt = interest;
                                                                if (e.target.checked) {
                                                                    constructInt.push('Pokemon')
                                                                } else {
                                                                    const index = constructInt.indexOf('Pokemon');
                                                                    if (index > -1) {
                                                                        constructInt.splice(index, 1); // 2nd parameter means remove one item only
                                                                    }
                                                                }
                                                                setInterest(constructInt)
                                                            }} />
                                                        <div className='checklabel'>
                                                            <div className='designcheck'> </div>
                                                            <div className='textinputbox'>Pokemon</div>
                                                        </div>
                                                    </div>
                                                    <div className='checkedbox'>
                                                        <input type="checkbox" className='checkedinput' value="Yu-Gi-Oh!"
                                                            onChange={(e) => {
                                                                let constructInt = interest;
                                                                if (e.target.checked) {
                                                                    constructInt.push('Yu-Gi-Oh!')
                                                                } else {
                                                                    const index = constructInt.indexOf('Yu-Gi-Oh!');
                                                                    if (index > -1) {
                                                                        constructInt.splice(index, 1); // 2nd parameter means remove one item only
                                                                    }
                                                                }
                                                                setInterest(constructInt)
                                                            }}
                                                        />
                                                        <div className='checklabel'>
                                                            <div className='designcheck'> </div>
                                                            <div className='textinputbox'>Yu-Gi-Oh!</div>
                                                        </div>
                                                    </div>
                                                    <div className='checkedbox'>
                                                        <input type="checkbox" className='checkedinput' value="Magic: The Gathering"
                                                            onChange={(e) => {
                                                                let constructInt = interest;
                                                                if (e.target.checked) {
                                                                    constructInt.push('Magic: The Gathering')
                                                                } else {
                                                                    const index = constructInt.indexOf('Magic: The Gathering');
                                                                    if (index > -1) {
                                                                        constructInt.splice(index, 1); // 2nd parameter means remove one item only
                                                                    }
                                                                }
                                                                setInterest(constructInt)
                                                            }}
                                                        />
                                                        <div className='checklabel'>
                                                            <div className='designcheck'> </div>
                                                            <div className='textinputbox'>Magic: The Gathering</div>
                                                        </div>
                                                    </div>
                                                    <div className='formBtngreen'>
                                                        <button type='submit' className='grenbtn'>
                                                            <span className='hmmerspan'>
                                                                <span className='mainHmmer'>
                                                                    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.37568 1.45519L6.16631 0.786853C5.24968 0.280578 4.45359 0.552466 3.95289 1.4793L3.12368 3.01419L2.6561 3.87941L2.18851 4.74463L0.890393 7.14475C0.389691 8.07158 0.636069 8.91939 1.5527 9.42566L2.76207 10.094C3.6787 10.6003 4.47611 10.3284 4.97681 9.40156L6.2736 7.00144L6.74119 6.13622L7.20878 5.271L8.03798 3.7361C8.53869 2.80927 8.29231 1.96146 7.37568 1.45519Z" fill="#0CFDE4" />
                                                                        <path d="M22.2353 9.66399L21.0259 8.99565C20.1093 8.48938 19.3119 8.76127 18.8112 9.6881L17.982 11.223L17.5144 12.0882L17.0468 12.9534L15.75 15.3536C15.2493 16.2804 15.4957 17.1282 16.4123 17.6345L17.6217 18.3028C18.5383 18.8091 19.3357 18.5372 19.8364 17.6104L21.1332 15.2102L21.6008 14.345L22.0684 13.4798L22.8976 11.9449C23.3983 11.0181 23.1519 10.1703 22.2353 9.66399Z" fill="#0CFDE4" />
                                                                        <path d="M16.3678 6.42152L12.0761 4.05066C10.9083 3.40554 9.44445 3.83978 8.80643 5.02056L6.46167 9.36002C5.82365 10.5408 6.25311 12.021 7.42089 12.6661L11.7126 15.037C12.8804 15.6821 14.3443 15.2478 14.9823 14.0671L17.327 9.72761C17.9651 8.54683 17.5356 7.06664 16.3678 6.42152Z" fill="#0CFDE4" />
                                                                        <path d="M9.53343 15.3147L8.32407 14.6464C7.40744 14.1401 6.55969 14.5071 5.97156 15.5946L4.99797 17.3961L4.44958 18.4113L3.89987 19.4278L2.3779 22.2445C1.78977 23.3321 1.98449 24.275 2.90112 24.7812L4.11049 25.4496C5.02711 25.9558 5.87486 25.5889 6.46299 24.5013L7.98497 21.6847L8.53468 20.6681L9.08307 19.6529L10.0567 17.8514C10.6448 16.7639 10.4501 15.821 9.53343 15.3147Z" fill="#0CFDE4" />
                                                                    </svg>
                                                                </span>
                                                                <span className='hammerleft'>
                                                                    <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.84441 4.10554C1.62424 3.76766 1.41075 3.4484 1.20926 3.12116C1.1719 3.05997 1.1799 2.97217 1.16656 2.89634C1.24262 2.88969 1.32802 2.8591 1.39473 2.88038C2.186 3.12914 2.97327 3.38455 3.76321 3.63863C3.81391 3.65459 3.86462 3.66789 3.93267 3.68784C3.94468 3.40716 3.95535 3.14111 3.96736 2.87506C3.97003 2.82185 3.96469 2.76731 3.97537 2.71543C4.00072 2.58906 4.1048 2.55181 4.20354 2.63429C4.27426 2.69282 4.33698 2.76332 4.40236 2.8285C5.10556 3.53221 5.8101 4.23591 6.51063 4.9436C6.56134 4.99548 6.57735 5.08195 6.60938 5.15245C6.53866 5.18305 6.46794 5.23892 6.39588 5.24158C4.42238 5.31341 2.4502 5.37992 0.4767 5.44777C0.387299 5.45043 0.29256 5.4624 0.255199 5.35332C0.217837 5.24158 0.309907 5.19901 0.379293 5.14846C0.815626 4.83718 1.25463 4.5259 1.69096 4.21595C1.73766 4.1827 1.78436 4.14678 1.84308 4.10421L1.84441 4.10554Z" fill="#FAE8DC" />
                                                                        <path d="M2.2101 0.834385C2.20476 0.547051 2.35688 0.347514 2.58105 0.194535C2.65577 0.142655 2.7345 0.16394 2.7812 0.245085C3.05741 0.719984 3.33495 1.19488 3.60449 1.6751C3.62584 1.71368 3.61784 1.79882 3.58848 1.83074C3.55912 1.86267 3.47239 1.88129 3.4337 1.86134C3.10945 1.6924 2.7852 1.52346 2.47296 1.33456C2.30217 1.23213 2.21944 1.03259 2.2101 0.835716V0.834385Z" fill="#FAE8DC" />
                                                                    </svg>
                                                                </span>
                                                                <span className='hammerright'>
                                                                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.07791 4.5638C5.56629 4.78729 6.05466 5.01077 6.5417 5.23558C6.61909 5.2715 6.71783 5.29544 6.70182 5.4125C6.68581 5.52557 6.59107 5.53223 6.503 5.54686C4.55218 5.84883 2.60136 6.15212 0.649202 6.44877C0.578482 6.45941 0.498421 6.41817 0.422363 6.40088C0.441044 6.32639 0.439709 6.23859 0.479739 6.1774C1.03349 5.35131 1.59392 4.52922 2.15302 3.70579C2.20506 3.62864 2.25309 3.54882 2.3118 3.47832C2.3932 3.37855 2.50261 3.39584 2.55199 3.51557C2.572 3.56479 2.57734 3.61933 2.59068 3.67121C2.65206 3.9306 2.71211 4.19 2.77749 4.4627C2.84154 4.43078 2.88824 4.40683 2.93495 4.38156C3.66217 3.98514 4.38805 3.58607 5.11928 3.19364C5.18199 3.16039 5.27006 3.17369 5.34745 3.16571C5.34878 3.24286 5.3728 3.328 5.34745 3.39451C5.21001 3.75368 5.06057 4.10753 4.90845 4.48C4.97383 4.51192 5.02587 4.5372 5.07791 4.56114V4.5638Z" fill="#FAE8DC" />
                                                                        <path d="M3.76996 1.8767C3.49909 2.12013 3.2122 2.34761 2.92531 2.57375C2.89196 2.60035 2.80389 2.59902 2.76786 2.57375C2.73317 2.54847 2.70915 2.46733 2.72249 2.42476C2.89729 1.9033 3.0801 1.38583 3.26291 0.867037C3.2936 0.77924 3.36699 0.743323 3.44972 0.77924C3.69924 0.88832 3.88471 1.05593 3.93408 1.33928C3.96344 1.53482 3.91807 1.745 3.76996 1.87803V1.8767Z" fill="#FAE8DC" />
                                                                    </svg>
                                                                </span>
                                                                <span className='hammerplat'>
                                                                    <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.3171 3.21366V2.00978C10.3171 1.09723 9.68192 0.598389 8.60644 0.598389H2.03876C0.96461 0.598389 0.328125 1.13847 0.328125 2.05102V3.2549C0.328125 4.16745 0.963276 4.6663 2.03876 4.6663H8.60644C9.68059 4.6663 10.3171 4.12621 10.3171 3.21366Z" fill="#0CFDE4" />
                                                                    </svg>
                                                                </span>


                                                            </span>
                                                            <span>Bewerbung abschicken</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Fade>
            </div>


            <div className='service-section'>
                <div className='b-container'>
                    <Fade>
                        <div className='service-column'>
                            <div className='service-block'>
                                <div className="s-icon">
                                    <img src={images.bservice1} alt="" />
                                </div>
                                <div className='s-content'>
                                    <h4>SAMMELE VOM OFFICE, STRAND ODER SOFA AUS</h4>
                                    <p>Mit GAVEL einfach von überall Singles und Booster kaufen </p>
                                </div>
                            </div>
                            <div className='service-block'>
                                <div className="s-icon">
                                    <img src={images.bservice2} alt="" />
                                </div>
                                <div className='s-content'>
                                    <h4>100% SICHER &amp;<br />& RISIKOFREI</h4>
                                    <p>Ausschließlich Originale, mit Händlerverifizierung und 100%iger Geld-zurück-Garantie.</p>
                                </div>
                            </div>
                            <div className='service-block'>
                                <div className="s-icon">
                                    <img src={images.bservice3} alt="" />
                                </div>
                                <div className='s-content'>
                                    <h4>CONNECTE DICH MIT GLEICHGESINNTEN</h4>
                                    <p>Für viele unserer GAVELERS ist die App ein Platz um alte und neue Freunde zu treffen und sich auszutauschen.</p>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>

            <div className='b-getStarted'>
                <div className='b-container'>
                    <Fade>
                        <div className='b-getStarted-content'>
                            <div className='contentLeft'>
                                <h4>Starte noch heute mit GAVEL</h4>
                                <p className="subitalic">Echte Originale dank Käuferschutz</p>
                                <p>Wir prüfen jeden Händler sorgfältig und garantieren so die Authentizität deiner Käufe. Bist du mal nicht zufrieden, erhälst du dein Geld zurück.</p>
                                <div className='downloadsLink'>
                                    <Appstore width={180} />
                                    <Playstore width={180} />
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className='contentRight'>
                    <img src={images.floating} alt="" />
                </div>

                <div className='bottomLeftShape'>
                    <img src={images.botttomVector} alt="" />
                </div>
            </div>

            <div className='bottomfooter'>
                <Fade>
                    <div className='b-container'>
                        <div className='finner'>
                            <a href="" className='footerlogo'>
                                <img src={images.Blogo} alt="logo" />
                            </a>
                            <div className='footerright'>
                                <div className='emailblock'>
                                    <Form layout="vertical"  >
                                        <Form.Item label="Melde dich" >
                                            <a type='button' className='ant-input' href='mailto:hello@letsgavel.com'>hello@letsgavel.com</a>

                                        </Form.Item>
                                    </Form>
                                    <div className='footersociallinks'>
                                        <a href="/discord" onClick={()=>{discord_button_clicked('de_home_footer_icon', users?.user);}}  target="_blank">
                                            <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.3296 13.7172C16.571 13.3154 17.6495 12.7518 18.4482 11.7187C18.2804 11.8178 18.1125 11.917 17.9446 12.0161C15.9198 13.2162 13.7474 13.8424 11.392 13.7276C9.77416 13.6493 8.2174 13.2684 6.70135 12.7101C5.99928 12.4544 5.32265 12.1309 4.63585 11.8439C4.60023 11.8283 4.56462 11.8178 4.52392 11.8231C5.3023 12.8301 6.3554 13.3989 7.53569 13.7954C7.1643 14.2755 6.80818 14.7399 6.4368 15.1938C6.3961 15.246 6.29944 15.2669 6.22821 15.2669C4.79864 15.2408 3.46573 14.8442 2.29054 14.0041C1.28831 13.2841 0.87114 12.2457 0.988151 10.9986C1.18147 8.91669 1.62408 6.88692 2.38211 4.94585C3.38942 2.37343 5.25651 0.875886 7.93251 0.458453C8.11565 0.432363 8.30389 0.411491 8.48195 0.427145C8.58879 0.432363 8.69562 0.510632 8.79228 0.588901C7.45938 0.959372 6.19769 1.43942 5.04284 2.1908C4.77321 2.36821 4.51883 2.56649 4.26446 2.7752C9.02122 0.61499 13.7627 0.635862 18.494 2.84826C17.2273 1.82555 15.7723 1.20461 14.2308 0.724566C14.3325 0.515849 14.475 0.427145 14.7039 0.448017C17.5579 0.724566 19.5573 2.21167 20.6511 4.91977C21.4142 6.80865 21.8568 8.78624 22.0552 10.8212C22.1824 12.1414 21.694 13.2006 20.6155 13.9311C19.4301 14.7347 18.1125 15.1312 16.6931 15.1782C16.5405 15.1782 16.4387 15.1417 16.3471 15.0164C16.0215 14.5938 15.6858 14.1763 15.3246 13.7224L15.3296 13.7172ZM16.632 8.8906C16.632 7.82092 15.7723 6.90779 14.7802 6.91822C13.7881 6.92344 12.9385 7.83136 12.9385 8.88016C12.9385 9.94461 13.7932 10.863 14.7853 10.863C15.7875 10.863 16.6422 9.95505 16.6371 8.8906H16.632ZM6.31979 8.86972C6.31979 9.93939 7.15413 10.8525 8.15127 10.863C9.13823 10.8734 10.0184 9.94461 10.0184 8.89581C10.0184 7.83658 9.15349 6.91301 8.16653 6.91822C7.17448 6.91822 6.32488 7.82092 6.31979 8.86972Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://facebook.com/lets.gavel" target="_blank">
                                            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.003 14.9561C3.003 14.8935 2.99791 14.8309 2.99791 14.7683C2.99791 12.6446 2.99791 10.5209 2.99791 8.39721V8.19371H0.779785V5.55345H2.99791C2.99791 5.4804 2.99791 5.423 2.99791 5.3656C2.99791 4.67684 2.98265 3.98286 3.01317 3.29409C3.04878 2.46444 3.28789 1.70263 3.85769 1.0817C4.29521 0.612088 4.83448 0.340758 5.45006 0.21031C5.94863 0.100734 6.45228 0.116387 6.95594 0.13204C7.36293 0.147694 7.77502 0.18422 8.18201 0.21031C8.21254 0.21031 8.24815 0.220744 8.28376 0.23118V2.58446C8.23289 2.58446 8.1871 2.58446 8.13622 2.58446C7.63257 2.58967 7.12891 2.58446 6.62525 2.61055C6.03002 2.64185 5.69425 2.98102 5.66882 3.59151C5.64338 4.2281 5.65864 4.86469 5.65864 5.50127C5.65864 5.51171 5.66373 5.51692 5.6739 5.54301H8.19727L7.86659 8.18328H5.66882C5.66882 8.24067 5.65864 8.28241 5.65864 8.32416C5.65864 10.5 5.65864 12.6811 5.65864 14.857C5.65864 14.8883 5.65864 14.9248 5.66373 14.9561H3.00808H3.003Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://instagram.com/lets_gavel" target="_blank">
                                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.1159 15.7602H3.94772C3.64247 15.6976 3.32705 15.6611 3.03198 15.5619C1.33786 14.988 0.254239 13.4643 0.249151 11.6433C0.238976 9.40481 0.249151 7.16633 0.249151 4.93306C0.249151 4.22342 0.401774 3.55031 0.74772 2.9346C1.54136 1.54664 2.73182 0.826565 4.30384 0.816129C6.45583 0.800475 8.60781 0.810911 10.7598 0.816129C11.0294 0.816129 11.3042 0.831783 11.5636 0.894398C13.1967 1.26487 14.2447 2.28758 14.6924 3.93644C14.7534 4.15559 14.7738 4.38518 14.8145 4.60955V11.9668C14.7891 12.1181 14.7738 12.2747 14.7433 12.426C14.3719 14.1009 13.3849 15.1758 11.7722 15.6402C11.5585 15.7028 11.3347 15.7237 11.121 15.7654L11.1159 15.7602ZM7.52928 4.19212C5.32642 4.19212 3.5509 6.01838 3.53564 8.28296C3.52546 10.5371 5.3315 12.3895 7.52928 12.3842C9.73214 12.3842 11.5127 10.558 11.5229 8.29339C11.5331 6.03926 9.72705 4.1869 7.52928 4.19212ZM12.9779 3.94687C12.9779 3.26333 12.4336 2.70501 11.7671 2.70501C11.1057 2.70501 10.5512 3.26855 10.5512 3.94687C10.5512 4.61999 11.1057 5.18874 11.762 5.19395C12.4183 5.19395 12.9728 4.63042 12.9728 3.94687H12.9779Z" fill="currentColor" />
                                                <path d="M12.113 3.93648C12.113 3.7382 11.9502 3.57645 11.7568 3.58167C11.5737 3.58167 11.416 3.74864 11.4109 3.93648C11.4109 4.12955 11.5737 4.30174 11.7619 4.30174C11.9502 4.30174 12.113 4.13476 12.113 3.93648Z" fill="currentColor" />
                                                <path d="M7.52918 5.07397C9.25891 5.07397 10.6681 6.51412 10.663 8.29342C10.663 10.0623 9.25891 11.5024 7.53427 11.5024C5.80454 11.5024 4.39532 10.0623 4.4004 8.28299C4.4004 6.51411 5.80454 5.07397 7.52918 5.07397Z" fill="currentColor" />
                                                <path d="M12.1131 3.93636C12.1131 4.13464 11.9503 4.30161 11.762 4.30161C11.5738 4.30161 11.4059 4.12942 11.411 3.93636C11.411 3.74852 11.5738 3.58676 11.7569 3.58154C11.9503 3.58154 12.1131 3.73808 12.1131 3.93636Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://twitter.com/lets_gavel" target="_blank">
                                            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.30814 5.86419C1.8118 6.12508 2.32563 6.29728 2.93612 6.31293C2.0153 5.62938 1.49129 4.74755 1.35393 3.65179C1.25218 2.83258 1.40989 2.0499 1.82706 1.31417C3.80608 3.70919 6.3091 5.0241 9.3463 5.23803C9.33104 4.85713 9.30051 4.50231 9.31069 4.14749C9.35139 2.61343 10.5062 1.14198 11.9562 0.761074C13.3145 0.406256 14.5101 0.724547 15.5326 1.70551C15.5835 1.75248 15.6853 1.78378 15.7565 1.76813C16.4687 1.62203 17.1403 1.36113 17.7711 0.995879C17.8169 0.969789 17.8627 0.948918 17.9441 0.907175C17.6643 1.78378 17.1453 2.42559 16.4382 2.93694C17.1199 2.86911 17.766 2.67083 18.3969 2.40471L18.4376 2.45167C18.1425 2.80649 17.8627 3.17174 17.5473 3.51091C17.298 3.77702 17.0029 3.99617 16.7383 4.24663C16.6773 4.30403 16.6264 4.40317 16.6264 4.48144C16.6264 8.35313 15.1358 11.4682 12.063 13.7328C10.7657 14.6877 9.28525 15.2095 7.70306 15.4182C6.20735 15.6217 4.73199 15.5225 3.28207 15.0947C2.36633 14.8233 1.50655 14.4268 0.697649 13.905C0.6773 13.8945 0.667125 13.8789 0.621338 13.8424C2.60544 14.025 4.37587 13.5293 5.98859 12.2874C4.89479 12.2196 4.01466 11.797 3.31768 10.9934C2.99209 10.6177 2.74789 10.1846 2.58509 9.67848C3.12436 9.74631 3.64328 9.75675 4.17746 9.60543C2.17301 9.08886 1.25218 7.2939 1.30305 5.87984L1.30814 5.86419Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className='linkssec'>
                                    <div className='f-downloadlogo'>
                                        <Appstore width={120} /><br />
                                        <Playstore width={120} />
                                    </div>
                                    <div className='footerlinks'>
                                        <a href="https://angel.co/company/letsgavel" target="_blank">AngelList</a>
                                        <a href="https://linkedin.com/company/letsgavel" target="_blank">LinkedIn</a>
                                        <a href="mailto:invest@letsgavel.com" target="_blank">Anlegerbeziehungen </a>
                                        <a href="https://gavel.notion.site/GAVEL-Careers-Mission-Culture-fa339dc6bb3740b2b9a63958fd0ca39e" target="_blank">Karriere </a>
                                    </div>
                                    <div className='footerlinks'>
                                        <a href="/terms#privacy" target="_blank">Datenschutz und Privatsphäre</a>
                                        <a href="/terms#terms" target="_blank">Nutzungsbedingungen</a>
                                        <a href="/imprint" target="_blank">Impressum und Kontakt</a>
                                    </div>
                                    {/* <div className='socials'>
                                        <a href="/discord" target="_blank">
                                            <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.3296 13.7172C16.571 13.3154 17.6495 12.7518 18.4482 11.7187C18.2804 11.8178 18.1125 11.917 17.9446 12.0161C15.9198 13.2162 13.7474 13.8424 11.392 13.7276C9.77416 13.6493 8.2174 13.2684 6.70135 12.7101C5.99928 12.4544 5.32265 12.1309 4.63585 11.8439C4.60023 11.8283 4.56462 11.8178 4.52392 11.8231C5.3023 12.8301 6.3554 13.3989 7.53569 13.7954C7.1643 14.2755 6.80818 14.7399 6.4368 15.1938C6.3961 15.246 6.29944 15.2669 6.22821 15.2669C4.79864 15.2408 3.46573 14.8442 2.29054 14.0041C1.28831 13.2841 0.87114 12.2457 0.988151 10.9986C1.18147 8.91669 1.62408 6.88692 2.38211 4.94585C3.38942 2.37343 5.25651 0.875886 7.93251 0.458453C8.11565 0.432363 8.30389 0.411491 8.48195 0.427145C8.58879 0.432363 8.69562 0.510632 8.79228 0.588901C7.45938 0.959372 6.19769 1.43942 5.04284 2.1908C4.77321 2.36821 4.51883 2.56649 4.26446 2.7752C9.02122 0.61499 13.7627 0.635862 18.494 2.84826C17.2273 1.82555 15.7723 1.20461 14.2308 0.724566C14.3325 0.515849 14.475 0.427145 14.7039 0.448017C17.5579 0.724566 19.5573 2.21167 20.6511 4.91977C21.4142 6.80865 21.8568 8.78624 22.0552 10.8212C22.1824 12.1414 21.694 13.2006 20.6155 13.9311C19.4301 14.7347 18.1125 15.1312 16.6931 15.1782C16.5405 15.1782 16.4387 15.1417 16.3471 15.0164C16.0215 14.5938 15.6858 14.1763 15.3246 13.7224L15.3296 13.7172ZM16.632 8.8906C16.632 7.82092 15.7723 6.90779 14.7802 6.91822C13.7881 6.92344 12.9385 7.83136 12.9385 8.88016C12.9385 9.94461 13.7932 10.863 14.7853 10.863C15.7875 10.863 16.6422 9.95505 16.6371 8.8906H16.632ZM6.31979 8.86972C6.31979 9.93939 7.15413 10.8525 8.15127 10.863C9.13823 10.8734 10.0184 9.94461 10.0184 8.89581C10.0184 7.83658 9.15349 6.91301 8.16653 6.91822C7.17448 6.91822 6.32488 7.82092 6.31979 8.86972Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://facebook.com/lets.gavel" target="_blank">
                                            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.003 14.9561C3.003 14.8935 2.99791 14.8309 2.99791 14.7683C2.99791 12.6446 2.99791 10.5209 2.99791 8.39721V8.19371H0.779785V5.55345H2.99791C2.99791 5.4804 2.99791 5.423 2.99791 5.3656C2.99791 4.67684 2.98265 3.98286 3.01317 3.29409C3.04878 2.46444 3.28789 1.70263 3.85769 1.0817C4.29521 0.612088 4.83448 0.340758 5.45006 0.21031C5.94863 0.100734 6.45228 0.116387 6.95594 0.13204C7.36293 0.147694 7.77502 0.18422 8.18201 0.21031C8.21254 0.21031 8.24815 0.220744 8.28376 0.23118V2.58446C8.23289 2.58446 8.1871 2.58446 8.13622 2.58446C7.63257 2.58967 7.12891 2.58446 6.62525 2.61055C6.03002 2.64185 5.69425 2.98102 5.66882 3.59151C5.64338 4.2281 5.65864 4.86469 5.65864 5.50127C5.65864 5.51171 5.66373 5.51692 5.6739 5.54301H8.19727L7.86659 8.18328H5.66882C5.66882 8.24067 5.65864 8.28241 5.65864 8.32416C5.65864 10.5 5.65864 12.6811 5.65864 14.857C5.65864 14.8883 5.65864 14.9248 5.66373 14.9561H3.00808H3.003Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://instagram.com/lets_gavel" target="_blank">
                                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.1159 15.7602H3.94772C3.64247 15.6976 3.32705 15.6611 3.03198 15.5619C1.33786 14.988 0.254239 13.4643 0.249151 11.6433C0.238976 9.40481 0.249151 7.16633 0.249151 4.93306C0.249151 4.22342 0.401774 3.55031 0.74772 2.9346C1.54136 1.54664 2.73182 0.826565 4.30384 0.816129C6.45583 0.800475 8.60781 0.810911 10.7598 0.816129C11.0294 0.816129 11.3042 0.831783 11.5636 0.894398C13.1967 1.26487 14.2447 2.28758 14.6924 3.93644C14.7534 4.15559 14.7738 4.38518 14.8145 4.60955V11.9668C14.7891 12.1181 14.7738 12.2747 14.7433 12.426C14.3719 14.1009 13.3849 15.1758 11.7722 15.6402C11.5585 15.7028 11.3347 15.7237 11.121 15.7654L11.1159 15.7602ZM7.52928 4.19212C5.32642 4.19212 3.5509 6.01838 3.53564 8.28296C3.52546 10.5371 5.3315 12.3895 7.52928 12.3842C9.73214 12.3842 11.5127 10.558 11.5229 8.29339C11.5331 6.03926 9.72705 4.1869 7.52928 4.19212ZM12.9779 3.94687C12.9779 3.26333 12.4336 2.70501 11.7671 2.70501C11.1057 2.70501 10.5512 3.26855 10.5512 3.94687C10.5512 4.61999 11.1057 5.18874 11.762 5.19395C12.4183 5.19395 12.9728 4.63042 12.9728 3.94687H12.9779Z" fill="currentColor" />
                                                <path d="M12.113 3.93648C12.113 3.7382 11.9502 3.57645 11.7568 3.58167C11.5737 3.58167 11.416 3.74864 11.4109 3.93648C11.4109 4.12955 11.5737 4.30174 11.7619 4.30174C11.9502 4.30174 12.113 4.13476 12.113 3.93648Z" fill="currentColor" />
                                                <path d="M7.52918 5.07397C9.25891 5.07397 10.6681 6.51412 10.663 8.29342C10.663 10.0623 9.25891 11.5024 7.53427 11.5024C5.80454 11.5024 4.39532 10.0623 4.4004 8.28299C4.4004 6.51411 5.80454 5.07397 7.52918 5.07397Z" fill="currentColor" />
                                                <path d="M12.1131 3.93636C12.1131 4.13464 11.9503 4.30161 11.762 4.30161C11.5738 4.30161 11.4059 4.12942 11.411 3.93636C11.411 3.74852 11.5738 3.58676 11.7569 3.58154C11.9503 3.58154 12.1131 3.73808 12.1131 3.93636Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://twitter.com/lets_gavel" target="_blank">
                                            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.30814 5.86419C1.8118 6.12508 2.32563 6.29728 2.93612 6.31293C2.0153 5.62938 1.49129 4.74755 1.35393 3.65179C1.25218 2.83258 1.40989 2.0499 1.82706 1.31417C3.80608 3.70919 6.3091 5.0241 9.3463 5.23803C9.33104 4.85713 9.30051 4.50231 9.31069 4.14749C9.35139 2.61343 10.5062 1.14198 11.9562 0.761074C13.3145 0.406256 14.5101 0.724547 15.5326 1.70551C15.5835 1.75248 15.6853 1.78378 15.7565 1.76813C16.4687 1.62203 17.1403 1.36113 17.7711 0.995879C17.8169 0.969789 17.8627 0.948918 17.9441 0.907175C17.6643 1.78378 17.1453 2.42559 16.4382 2.93694C17.1199 2.86911 17.766 2.67083 18.3969 2.40471L18.4376 2.45167C18.1425 2.80649 17.8627 3.17174 17.5473 3.51091C17.298 3.77702 17.0029 3.99617 16.7383 4.24663C16.6773 4.30403 16.6264 4.40317 16.6264 4.48144C16.6264 8.35313 15.1358 11.4682 12.063 13.7328C10.7657 14.6877 9.28525 15.2095 7.70306 15.4182C6.20735 15.6217 4.73199 15.5225 3.28207 15.0947C2.36633 14.8233 1.50655 14.4268 0.697649 13.905C0.6773 13.8945 0.667125 13.8789 0.621338 13.8424C2.60544 14.025 4.37587 13.5293 5.98859 12.2874C4.89479 12.2196 4.01466 11.797 3.31768 10.9934C2.99209 10.6177 2.74789 10.1846 2.58509 9.67848C3.12436 9.74631 3.64328 9.75675 4.17746 9.60543C2.17301 9.08886 1.25218 7.2939 1.30305 5.87984L1.30814 5.86419Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>

        </>
    )
}
export default HomePageDe
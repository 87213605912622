const CountryList = [
  {
    name: "Austria",
    code: "AT",
  },
  {
    name: "Belgium",
    code: "BE",
  },
  {
    name: "Czech Republic",
    code: "CZ",
  },
  {
    name: "Germany",
    code: "DE",
  },
  {
    name: "Denmark",
    code: "DK",
  },
  {
    name: "Estonia",
    code: "EE",
  },
  {
    name: "Spain",
    code: "ES",
  },
  {
    name: "Finland",
    code: "FI",
  },
  {
    name: "France",
    code: "FR",
  },
  {
    name: "Greece",
    code: "GR",
  },
  {
    name: "Croatia",
    code: "HR",
  },
  {
    name: "Hungary",
    code: "HU",
  },
  {
    name: "Ireland",
    code: "IE",
  },
  {
    name: "Italy",
    code: "IT",
  },
  {
    name: "Lithuania",
    code: "LT",
  },
  {
    name: "Luxembourg",
    code: "LU",
  },
  {
    name: "Latvia",
    code: "LV",
  },
  {
    name: "Monaco",
    code: "MC",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Netherlands",
    code: "NL",
  },
  {
    name: "Poland",
    code: "PL",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Romania",
    code: "RO",
  },
  {
    name: "Sweden",
    code: "SE",
  },
  {
    name: "Slovenia",
    code: "SI",
  },
  {
    name: "Slovakia",
    code: "SK",
  }
];
export default CountryList;

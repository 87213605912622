import React from "react";

function Arrowdown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.352 12.48V1M11 7.832l-4.649 4.649-4.648-4.649"
      ></path>
    </svg>
  );
}

export default Arrowdown;
import React, {useEffect, useState} from 'react'
import LoadingSpinner from '../../../CommonComponents/LoadingSpinner';

const ImageComponent = (props: any) => {

  const [loading, setloading] = useState(false)

  useEffect(() => {
    setloading(false)
  }, [props.imgSrc])

  return (
    <>
      {!loading && <LoadingSpinner />}
      <img src={props.imgSrc} onLoad={() => {
        setloading(true)
      }}
        style={{visibility: loading ? 'visible' : 'hidden'}}
        alt={props.altText}
        onError={props.errorHandler}
      />
    </>
  )
}

export default ImageComponent
import { Modal} from 'antd'
import React  from 'react';
import {Close, } from '../../../Icons'

import TopUp from '../../../ShowScreen/Components/Topup';

const TopUpModal = (props: any) => {


  const {stripe, elements} = props;


  return (
    <>

      <Modal title="Top up" className='themeModal AddNewCard' centered closeIcon={<Close />} width={555} visible={props.isTopupModal} onOk={props.handleOk} footer={false} onCancel={props.handleCancel}>
        <TopUp stripe={stripe} elements={elements} isShowPage={props?.isShowPage} handleOk={props.handleOk} handleCancel={props.handleCancel} openCardModal={props.openCardModal} />
      </Modal>

    </>
  )
}

export default TopUpModal
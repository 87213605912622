import {Col, Row, Form, Input, Button, message, Upload, Spin, DatePicker, Breadcrumb} from 'antd'
import React, {Suspense, useEffect, useState} from 'react'
import {Edit, Facebook, Instagram, RightArrow, Tiktok, Twitch, Twitter, Youtube} from '../../Icons'
import Pagefooter from '../../CommonComponents/PageFooter'
import {images} from '../../../Assets/ImageConfig/images'
import './Editprofile.scss'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store/index';
import {useForm} from 'antd/lib/form/Form'
import {useDispatch} from 'react-redux';
import {checkUserNameExistance, updateUser, updateUserProfileImage} from '../../../store/Actions/users';
import Loader from '../../CommonComponents/Loader';
import TopicsModal from '../Components/TopicsModal'
import {useNavigate} from 'react-router'
import moment from 'moment'
import {dateFormat} from '../../../Utils/constants'
import ImageComponent from '../Components/ImageComponent'
import {displayErrorToast, placeHolderProfileHelper} from '../../../Utils/helper'
import LoadingSpinner from '../../CommonComponents/LoadingSpinner'
import {Helmet} from "react-helmet";

const {TextArea} = Input;


function Editprofile() {
  // const children = [];
  // for (let i = 10; i < 36; i++) {
  //   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  // }
  const dispatch = useDispatch()
  const [form] = useForm()
  const [topicsModalVisible, setTopicsModalVisible] = useState(false)
  const [formValid, setFormValid] = useState(false)

  const navigate = useNavigate()

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  useEffect(() => {
    form.setFieldsValue({
      firstName: users.user?.firstname,
      lastName: users.user?.lastname,
      username: users.user?.username,
      EmailAddress: users.user?.email,
      About: users.user?.profileBio,
      birthdate: users.user?.birthdate ? moment(users.user?.birthdate) : null,
      instagram: users.user?.profileInstagram,
      twitch: users.user?.profileTwitch,
      youtube: users.user?.profileYoutube,
      facebook: users.user?.profileFacebook,
      tiktok: users.user?.profileTikTok,
      twitter: users.user?.profileTwitter,
      hostCompany: users.user?.hostCompany,
      hostVAT: users.user?.hostVAT,
      hostImprint: users.user?.hostImprint
    })
  }, [users.user])

  const handleUpdateProfile = async (values: any) => {

    if (!users.userNameChecking.isUserName && !users.userNameChecking.isuserNameChecking) {
      try {
        let hostImprintData = {}
        if (users.user?.host) {
          hostImprintData = {
            hostCompany: values.hostCompany?.length ? values.hostCompany : null,
            hostVAT: values.hostVAT?.length ? values.hostVAT : null,
            hostImprint: values.hostImprint?.length ? values.hostImprint : null
          }
        }

        await dispatch(updateUser({
          email: values.EmailAddress,
          username: values.username,
          firstname: values.firstName,
          lastname: values.lastName,
          profileInstagram: values?.instagram?.length ? values.instagram : null,
          profileBio: values.About?.length ? values.About : null,
          profileTwitch: values?.twitch?.length ? values.twitch : null,
          profileYoutube: values?.youtube?.length ? values.youtube : null,
          profileFacebook: values?.facebook?.length ? values.facebook : null,
          profileTikTok: values?.tiktok?.length ? values.tiktok : null,
          profileTwitter: values?.twitter?.length ? values.twitter : null,
          birthdate: values.birthdate ? moment(values.birthdate).format(dateFormat) : null,
          ...hostImprintData
        }))
        navigate('/my-profile')
      }
      catch (e: any) {
        if (e.response?.data?.message)
         displayErrorToast(e.response?.data?.message)
        if (e.response?.data?.error)
          e.response?.data?.error.forEach((error: any) => {
           displayErrorToast(error.message)
          })
      }
    }
  }
  const checkUserName = (event: any) => {
    if (event.target.value.length >= 4) {
      dispatch(checkUserNameExistance(event.target.value))
    }
  }


  const handleProfileUpload = async (options: any) => {
    const {file} = options
    const fmData = new FormData()
    await fmData.append("file", file)
    try {
      await dispatch(updateUserProfileImage(fmData))
    }
    catch (e: any) {
      if (e.response?.data?.error)
        e.response?.data?.error.forEach((error: any) => {
          displayErrorToast(error.message)
        })
    }
  }

  const handleOk = () => {
    setTopicsModalVisible(false)
  }
  const handleCancel = () => {
    setTopicsModalVisible(false)
  }
  return (
    <div className="Editprofile">
      <Helmet>
        <title>GAVEL - Edit profile</title>
        <meta property="og:title" content="GAVEL - Live shows for trading cards and much more" />
        <meta property="title" content={`GAVEL - Edit profile`} />
        <meta property="og:description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
        <meta property="description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
        <meta property="og:url" content="https://letsgavel.com" />
        <meta property="og:image" content="https://i.ibb.co/1KqZpJ7/Meta-Image-EN.png" />
        <link rel="canonical" href="https://letsgavel.com" />
      </Helmet>
      { users.loader && <Loader />}

      {/* <div className='Breadcrumpdiv'>
        <div className='container'>
          <Breadcrumb separator={<RightArrow />}>

            <Breadcrumb.Item>
              <a href="/my-profile">Profile</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Edit profile</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div> */}
      <TopicsModal visible={topicsModalVisible} onOk={handleOk} onCancel={handleCancel} />
      <div className='EditeProfile-block'>
        <div className='container'>
          <Row gutter={30}>
            <Col xs={24} sm={12} lg={8}>
              <div className='EditProfile'>
                <div className='userpicWrapper'>
                  <div className='userpic'>
                    <Suspense fallback={<LoadingSpinner />}>
                      <ImageComponent imgSrc={users.user?.pic ?? images.profiledummy} placeholderImg={images.profiledummy} altText='Profile' errorHandler={placeHolderProfileHelper} />
                    </Suspense>
                  </div>
                  <Upload
                    showUploadList={false}
                    accept='image/*'
                    customRequest={handleProfileUpload}
                  >
                    <div className='EditIcon'>
                      <Edit />
                    </div>
                  </Upload>
                </div>
                <div className='Namedetail'>
                  <p>Profile photo</p>
                  <span>We recommend an image of at least 400x400. </span>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <div className='EditProfile-details profile-page'>
                <h3>Account info</h3>
                <Form form={form} onFinish={handleUpdateProfile} name="basic" layout={'vertical'}
                  onFieldsChange={() => setFormValid(form.getFieldsError().filter(({errors}) => errors.length).length !== 0)}
                >
                  <Form.Item
                    label="Email"
                    name="EmailAddress"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter email'
                      }
                    ]}
                  >
                    <Input autoComplete='off' placeholder='Email' allowClear disabled={ users.user?.source === 'google' || users.user?.source === 'apple' }/>
                  </Form.Item>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter first name'
                      }
                    ]}
                  >
                    <Input autoComplete='off' placeholder='Firstname' allowClear />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter last name'
                      }
                    ]}
                  >
                    <Input autoComplete='off' placeholder='Lastname' allowClear />
                  </Form.Item>
                  <div className='usernameLoader'>
                    <Form.Item
                      label="User Name"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter username'
                        },
                        {
                          min: 4,
                          message: 'Username length must be at least 4 characters long.'
                        }
                      ]}
                    >
                      <Input onChange={checkUserName} autoComplete='off' placeholder='Username' allowClear maxLength={20} />
                    </Form.Item>
                    {users.userNameChecking.isuserNameChecking ? <Spin className='userloader' /> : null}
                    {users.userNameChecking.isUserName ? <span style={{color: "red", }}>Username Exist</span> : null}
                  </div>
                  <Form.Item label="BIRTHDATE" name="birthdate" >
                    <DatePicker disabledDate={(current: any) =>  current && current > moment().subtract(14, "years")} format={dateFormat} placeholder="Birthdate" allowClear={false} inputReadOnly />
                  </Form.Item>
                  <Form.Item label="About" name="About"
                    rules={[
                      {
                        required: users.user?.profileBio,
                        message: 'Please enter about info'
                      }
                    ]}  >
                    <TextArea rows={4} placeholder='About' maxLength={230} />
                  </Form.Item>
                  <Form.Item label="Interests" name="Topics">
                    <div className='selectedTopics' onClick={() => {setTopicsModalVisible(true)}}>
                      {
                        users.user?.userTopics?.map((topic: any, index: number) => (
                          <span className='selectedTags' key={index}>{topic.topicName}</span>
                        ))
                      }
                    </div>
                  </Form.Item>
                  <div className='socialinouts'>
                    <Form.Item label="Social Links" className='socialIcons' name="twitch"    >
                      <Input prefix={<Twitch />} className="site-form-item-icon" placeholder="Enter Username" allowClear />
                    </Form.Item>
                    <Form.Item label="" className='socialIcons' name="youtube"    >
                      <Input prefix={<Youtube />} className="site-form-item-icon" placeholder="Enter Username" allowClear />
                    </Form.Item>
                    <Form.Item label="" className='socialIcons' name="facebook"    >
                      <Input prefix={<Facebook />} className="site-form-item-icon" placeholder="Enter Username" allowClear />
                    </Form.Item>
                    <Form.Item label="" className='socialIcons' name="instagram"    >
                      <Input prefix={<Instagram />} className="site-form-item-icon" placeholder="Enter Username" allowClear />
                    </Form.Item>
                    <Form.Item label="" className='socialIcons' name="twitter"    >
                      <Input prefix={<Twitter />} className="site-form-item-icon" placeholder="Enter Username" allowClear />
                    </Form.Item>
                    <Form.Item label="" className='socialIcons' name="tiktok"    >
                      <Input prefix={<Tiktok />} className="site-form-item-icon" placeholder="Enter Username" allowClear />
                    </Form.Item>



                  </div>
                  {
                    users?.user?.host ? (
                      <div className='impritblock'>
                        <h4> Host Imprint</h4>
                        <Form.Item label="Name" name="hostCompany" rules={[
                          {
                            max: 40,
                            message: 'Please enter a valid company name'
                          }
                        ]} >
                          <Input placeholder="Your official (company) name" allowClear />
                        </Form.Item>
                        <Form.Item label="VAT" name="hostVAT" rules={[
                          {
                            max: 40,
                            message: 'Please enter a valid VAT tax number'
                          }
                        ]} >
                          <Input placeholder="Your VAT tax number" allowClear />
                        </Form.Item>
                        <Form.Item label="Disclaimer" name="hostImprint" >
                          <TextArea rows={4} maxLength={1000} placeholder="Your imprint, address, contact details or special rules and disclaimers for your shows" />
                        </Form.Item>
                      </div>
                    ) : ('')
                  }

                  <div className='submitBtn'>
                    <Form.Item >
                      <Button htmlType='submit' type="primary" disabled={formValid || users.userNameChecking.isUserName}>
                        Update Profile
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Pagefooter />
    </div>
  );
}

export default Editprofile

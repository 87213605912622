import React, { useEffect, useState } from 'react'
import { Form, Input, Spin, message, DatePicker } from 'antd'
import {matchRoutes, useLocation} from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserNameExistance, setUpUserNameBirthdate } from "../../../store/Actions/users"
import { RootState } from "../../../store"
import {dateFormat} from '../../../Utils/constants';
import {displayErrorToast, displayInfoToast} from '../../../Utils/helper';

const UserName = (props: any) => {
    const location = useLocation()
    const isShowPage = matchRoutes([{path: '/show/:id'}, {path: '/chat'}], location.pathname);
    const [form] = Form.useForm();
    const [isUserNameExist, setIsUserNameExist] = useState<any>({})
    const [formValid, setFormValid] = useState(false)
    const dispatch = useDispatch();
    const users = useSelector(
        (state: RootState) => state?.usersReducer
    );

    useEffect(() => {
        setIsUserNameExist(users.userNameChecking.isUserName)
    }, [users.userNameChecking])

    useEffect(() => {
        if (users?.userName?.userName?.success) {
            const userObj = users?.userName?.userName?.data?.length && users?.userName?.userName?.data[0]
            if (userObj?.username && userObj?.lastUsernameChangedAt) {
                props?.setLoader(false)
                reset()
                props?.reset()
                displayInfoToast(users.userName.userName.message)
                // if show page is there then refresh current page
                if (isShowPage?.length) {
                    window.location.reload()
                }
            }
        } else if (users?.userName?.userName?.message) {
            props?.setLoader(false)
           displayErrorToast(users.userName.userName.message)
        }
    }, [users.userName])

    const setUserName = async (values: any) => {
        if (!values.username)
            return
        if (!users.userNameChecking.isUserName && !users.userNameChecking.isuserNameChecking) {
            props?.setLoader(true)
            try {
                await dispatch(setUpUserNameBirthdate({
                    username: values.username,
                    birthdate: values.birthdate ? moment(values.birthdate).format(dateFormat) : null,
                }))
            }
            catch (e: any) {
                console.log(e);
                if (e.response?.data?.message)
                   displayErrorToast(e.response?.data?.message)
                if (e.response?.data?.error)
                    e.response?.data?.error.forEach((error: any) => {
                        displayErrorToast(error.message)
                    })
            }
        }
    };
    const checkUserName = (event: any) => {
        if (event.target.value.length >= 4) {
            dispatch(checkUserNameExistance(event.target.value))
        }
    }


    useEffect(() => {
        if (users.user.username && props?.isUserName) {
            form.setFieldsValue({username: users.user.username})
            form.setFieldsValue({birthdate: users.user?.birthdate ? moment(users.user?.birthdate) : null});
        }
    }, [users.user, props?.isUserName])
    const reset = () => {
        setIsUserNameExist(false)
    }
    return (<>
        <Form form={form} name="register-username" layout="vertical" onFinish={setUserName}
        onFieldsChange={() => setFormValid(form.getFieldsError().filter(({ errors }) => errors.length).length !== 0) }>
            <div className='usernameLoader'>
                <Form.Item
                    label="User name"
                    name="username" validateStatus={isUserNameExist ? "error" : "success"}
                    rules={[{
                            required: true,
                            message: 'Please enter username!'
                        },{
                        min: 4,
                        message: 'Username length must be at least 4 characters long.'
                    }]}
                >
                    <Input autoComplete='off' onChange={checkUserName} maxLength={20} />
                </Form.Item>
                {users.userNameChecking.isuserNameChecking ? <Spin className='userloader' /> : null}
                {isUserNameExist ? <span style={{color: "red", }}>Username Exist</span> : null}
            </div>
            <div>
                <Form.Item label="BIRTHDATE" name="birthdate" >
                    <DatePicker disabledDate={(current: any) =>  current && current > moment().subtract(14, "years")} format={dateFormat} placeholder="Birthdate" allowClear={false} inputReadOnly />
                </Form.Item>
            </div>
            <div className='modalbtns'>
                <button type='submit' className='modalbtn filledbtn' disabled={formValid || users.userNameChecking.isUserName}>Let's Gavel </button>
            </div>
        </Form>
    </>)
}

export default UserName
import React from "react";

function Save() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.125" width="31" height="31" rx="15.5" fill="#202C47"/>
    <path d="M13 9.625H19C19.5304 9.625 20.0391 9.80937 20.4142 10.1376C20.7893 10.4658 21 10.9109 21 11.375V20.3161C21 21.7951 21 22.5346 20.5173 22.8264C20.0346 23.1181 19.3798 22.7744 18.0703 22.0869L16.9297 21.4881C16.4743 21.249 16.2465 21.1294 16 21.1294C15.7535 21.1294 15.5257 21.249 15.0703 21.4881L13.9297 22.0869C12.6202 22.7744 11.9654 23.1181 11.4827 22.8264C11 22.5346 11 21.7951 11 20.3161V11.375C11 10.9109 11.2107 10.4658 11.5858 10.1376C11.9609 9.80937 12.4696 9.625 13 9.625Z" stroke="#B5ACED" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <rect x="0.5" y="1.125" width="31" height="31" rx="15.5" stroke="#0CFDE4"/>
    </svg>


  );
}

export default Save;
import React from "react";
import ReactDOM from "react-dom";
import './Style/gavel-custom.scss';
import './Style/gavel.scss';
import "./index.css";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import CustomRoutes from "./Routes";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "./Utils/config";
import '../src/Style/buttons.scss'
import { checkFeatureFlag, growthbook } from "./Utils/helper";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import {init_mParticle} from "./Utils/mParticle";

if(process.env.NODE_ENV === "production"){
  Sentry.init({
    dsn: config.sentryDns,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function () {};
}
// for initializing mParticle
init_mParticle()
// for growthbook feature flag
checkFeatureFlag()
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <GrowthBookProvider growthbook={growthbook} >
      <BrowserRouter>
      <CustomRoutes />
      </BrowserRouter>
      </GrowthBookProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

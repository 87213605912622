import React, { useCallback, useEffect, useState } from 'react'
import { Button, message, Tabs } from 'antd';
import {useNavigate} from 'react-router';

import Showlist from '../../../ShowList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/index';
import { getHostUserShows, getUserWatchList, openDownloadAppModal, updateHostUserShowsAction } from '../../../../store/Actions/users';
import Loader from '../../../CommonComponents/Loader';
import { images } from '../../../../Assets/ImageConfig/images';

import './Profileshowlist.scss'
import {displayErrorToast} from '../../../../Utils/helper';

const {TabPane} = Tabs;


function Profileshowlist() {

  const [shows, setShows] = useState<any>([])
  const [activeTab, setActiveTab] = useState("")
  const [loading, setLoading] = useState(false)
  const [userLoader, setUserLoader] = useState(false)
  const [userId, setUserId] = useState(null)
  const [page, setPage] = useState(1)

  const navigate = useNavigate()
  const dispatch = useDispatch()


  useEffect(() => {

    const fetchWatchListShows = async () => {
      try {
        setLoading(true)
        await dispatch(getUserWatchList(1))

      } catch (e: any) {
        if (e.response?.data?.message)
          displayErrorToast(e.response?.data?.message)
      }
      setLoading(false)
    }
    fetchWatchListShows()
  },[])

  // const showDashboard = useSelector(
  //   (state: RootState) => state?.showDashboard
  // );

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  useEffect(() => {
    setUserId(users?.user?.id ?? null)
  }, [users.user?.id]);

  useEffect(() => {
    if (userId && users.user?.host) {
      const fetchAndResetShows = async () => {
        setLoading(true)
        await dispatch(updateHostUserShowsAction({}, "RESET"))
        try {
          await dispatch(getHostUserShows(users.user?.id, users.user?.id, 'ready', 1))
        } catch (e) {
          console.log(e);
        }
        setLoading(false)
      }

      fetchAndResetShows()
    }
  }, [userId])

  useEffect(() => {
    if (users?.user?.host) {
      setActiveTab("shows")
    }
    else {
      setActiveTab("watchlist")
    }
  }, [users?.user?.host])

  const onTabChange = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  const onCreateShow = () => {
    dispatch(openDownloadAppModal(true))
  }

  const onAddToWatchlist = () => {
    navigate('/shows')
  }

  const fetchMore = useCallback( async () => {
    setLoading(true)

    if (users?.hostUserShows?.ready?.totalCount > users?.hostUserShows?.ready?.shows.length)
      await dispatch(getHostUserShows(users.user?.id, users.user?.id, 'ready', page + 1))

    setPage((prevPage) =>  prevPage + 1);
    setLoading(false)
  }, [page]);

  useEffect(() => {
    setShows([...users?.hostUserShows?.ready?.shows ?? null])
  },[userId,users.hostUserShows])

  return (
    <div className="Profileshowlist" >
      { loading || userLoader && <Loader /> }
      <Tabs activeKey={ activeTab } onChange={ onTabChange } >
        { users.user?.host ? (<TabPane tab="My Shows" key="shows">
          {
            shows.length ? <Showlist setUserLoader={setUserLoader} shows={ shows } fetchMore={fetchMore} /> : (
              <div className='startchatbox mt0'>
                <div className='statrtchatcontent'>
                  <div className='startchat-img'>
                    <img src={ images.userCreateShow } />
                  </div>
                  <h4>No Show</h4>
                  <p>
                    Not started to create any show
                  </p>
                  <Button type='primary' onClick={onCreateShow} >Create a show</Button>
                </div>
              </div>
            ) }
        </TabPane>) : ('') }
        <TabPane tab="Watchlist" key="watchlist">
          {
            users.watchlistShows?.shows?.length ? <Showlist shows={ users.watchlistShows?.shows } fetchMore={() => {}} setUserLoader={setUserLoader} isWatchList /> : (
              <div className='startchatbox mt0'>
                <div className='statrtchatcontent'>
                  <div className='startchat-img'>
                    <img src={ images.userWatchList } />
                  </div>
                  <h4>No Watchlist Items!</h4>
                  <p>Click on the bookmark icon <span>
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1.34766" width="19" height="19" rx="9.5" fill="#202C47"/>
                    <path d="M8.27148 5.64771H12.7286C13.1227 5.64771 13.5006 5.78467 13.7792 6.02847C14.0578 6.27226 14.2143 6.60292 14.2143 6.94771V13.4006C14.2143 14.5838 14.2143 15.1754 13.8282 15.4088C13.442 15.6422 12.9182 15.3672 11.8706 14.8172L11.2438 14.4882C10.8795 14.2969 10.6973 14.2013 10.5001 14.2013C10.3028 14.2013 10.1207 14.2969 9.75632 14.4882L9.1295 14.8172C8.08191 15.3672 7.55811 15.6422 7.17194 15.4088C6.78577 15.1754 6.78577 14.5838 6.78577 13.4006V6.94771C6.78577 6.60292 6.9423 6.27226 7.22092 6.02847C7.49955 5.78467 7.87744 5.64771 8.27148 5.64771Z" stroke="#B2A8EE" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    <rect x="1" y="1.34766" width="19" height="19" rx="9.5" stroke="#0CFDE4"/>
                  </svg>
                    </span> on a show card to add it to your watchlist and get reminders before it starts</p>
                  <Button type='primary' className='large' onClick={onAddToWatchlist} >Add to watchlist</Button>
                </div>
              </div>
            )
          }

        </TabPane>
      </Tabs>
    </div>

  );
}

export default Profileshowlist

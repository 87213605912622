import React, {useCallback, useEffect, useState} from 'react'
import {AutoComplete, Button, Form, Input, Modal} from 'antd';
import {debounce} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import LoadingSpinner from '../../CommonComponents/LoadingSpinner';

import {RootState} from '../../../store';
import {searchUsers, searchUsersAction} from '../../../store/Actions/users';
import { placeHolderProfileHelper, profilePictureHelper} from '../../../Utils/helper';
import {Cancel, Plus} from '../../Icons';
import ProfileBadges from '../../CommonComponents/ProfileBadges';
import ModBadge from '../../CommonComponents/ModBadge';

type Props = {
  isModalVisible: boolean, handleOk: any, handleCancel: any, createChat?: any
};

const {Option} = AutoComplete
const {Search} = Input;

const CreateChatModal = (props: Props) => {
  const debounceTimeout = 1000

  const dispatch = useDispatch()

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const [form] = Form.useForm();

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false)

  const [formValid, setFormValid] = useState(false)

  const [selectedUser, setSelectedUser] = useState<any>(null)

  const onCreateGroup = () => {
    if (!selectedUser) return

    props.createChat(selectedUser)

  }

  const onRemoveSubscriber = () => {
    setSelectedUser(null)
  }

  useEffect(() => {
    setOptions([...users.allUsersList ?? []])
  }, [users.allUsersList])

  useEffect(() => {
    return () => {
      dispatch(searchUsersAction([], 'RESET'))
    }
  }, [])


  const onSearch = async (searchValue: string) => {
    if (!searchValue.length) return

    try {
      setFetching(true);
      await dispatch(searchUsersAction([], 'RESET'))
      await dispatch(searchUsers(searchValue.toLowerCase()))
    } catch (error) {
      console.log({error});
    }
    setFetching(false);
  };

  const debounceFetcher = useCallback(debounce(onSearch, debounceTimeout), [])

  const optionsRenderer = options.map((option: any, index: number) => (
    <Option key={option?.id} value={option?.username} userdata={option}>
      <div className='follow-item create_group_item'>
        <div className='follow-left'>
          <div className='follower-img'>
            <img src={profilePictureHelper(option?.pic)} alt="" onError={placeHolderProfileHelper} />
          </div>
          <div className='follower-name'>
            <p>{option?.username} {option?.admin && <ModBadge />} {
              option?.host ? (
                <ProfileBadges user={option} />
              ) : null
            } </p>
          </div>
        </div>
        <div className='follower-right'>
          <Button onClick={() => { }} type="link"><Plus/></Button>
        </div>
      </div>
    </Option>
  ));

  return (
    <Modal title={`Create New Chat`} className='themeModal followerModal creategroupModal Create_GroupModal' width={640} visible={props.isModalVisible} onOk={props.handleOk} onCancel={props.handleCancel} footer={false} centered closeIcon={<Cancel />} >
      <Form form={form} onFinish={onCreateGroup}
        onFieldsChange={() => setFormValid(form.getFieldsError().filter(({errors}) => errors.length).length !== 0)}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}>
        <div className='createbtn'>
          <Button type='ghost' htmlType='submit' disabled={formValid || !selectedUser} >+ Create</Button>
        </div>

        <div className='modal-body'>
          <div className='chatgroup-body'>
            <Form.Item name='Adduser'>
              <AutoComplete
                autoClearSearchValue
                filterOption={false} notFoundContent={null}
                className='AddUser_Wrapper'
                onSearch={debounceFetcher}
                onSelect={(data: any, option: any) => {
                  setOptions([])
                  setSelectedUser(option?.userdata)
                }
                }
                dataSource={optionsRenderer}
                open={options.length > 0}
              >
                <div className='Input_search_modal'>
                  <Search size='large' loading={fetching} placeholder="Add a user by searching for username" allowClear onSearch={debounceFetcher} />
                </div>
              </AutoComplete>
            </Form.Item>
            {(loading) && <LoadingSpinner />}
            <div className='followList'>
              {
                selectedUser && <div className='follow-item create_group_item' key={selectedUser?.id}>
                  <div className='follow-left'>
                    <div className='follower-img'>
                      <img src={profilePictureHelper(selectedUser?.pic)} alt="" onError={placeHolderProfileHelper} />
                    </div>
                    <div className='follower-name'>
                      <p>{selectedUser?.username}
                        {selectedUser?.admin && <ModBadge />}
                        {
                          selectedUser?.host ? (
                            <ProfileBadges user={selectedUser} />
                          ) : null
                        }
                      </p>
                    </div>
                  </div>
                  <div className='follower-right'>
                    <Button type='link' className='Link_btn' onClick={() => {
                      onRemoveSubscriber()
                    }}>
                      <Cancel />
                    </Button>

                  </div>
                </div>
                // ))
              }
            </div>

            {/* {props.isSettingsModal && (
                            <div className='modle-btn'>
                                <Button type='ghost' onClick={leaveGroup} >Leave Group</Button>
                                <Button type='primary' disabled={formValid || !subscribersList.length} htmlType='submit'>Save</Button>
                            </div>
                        )} */}

          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default CreateChatModal
import React from 'react'
import {images} from '../../../Assets/ImageConfig/images'
import '../ChatScreen.scss'

type Props = {}

const NoMessage = (props: Props) => {
  return (
    <div className='NoMessageStyle'>
      <img src={images.NoMessageImg} alt="" />
      <div className='NoMessageText'>No Message yet. Please make the first Move.</div>
    </div>
  )
}

export default NoMessage
import React from 'react'
import { Form, Input, Button } from 'antd'
import './Helpemail.scss'
import { emailRegex } from '../../../../Utils/constants';
const { TextArea } = Input;

function Helpemail() {
  const [form] = Form.useForm();
  const onFinish = (value: any) => {
    window.location.href = `mailto:support@letsgavel.com?subject=Query - ${value.name} - ${value.email}&body=${value.message}`
  }
  return (
    <div className="EditProfile-details ">
      <h3 >Email us</h3>
      <Form form={form}
        name="basic"
        className='accountsForm'
        layout={'vertical'}
        onFinish={onFinish}
      >
        <Form.Item
          label="Full Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter name'
            },
          ]} >
          <Input placeholder="Enter you name" />
        </Form.Item>
        <Form.Item
          label="Email address"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please enter email'
            },
            {
              pattern: emailRegex,
              message: 'Please input a valid email!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: 'Please enter message'
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <div className='submitBtn'>
          <Form.Item >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>

  )
}

export default Helpemail

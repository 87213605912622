import React from "react";

function Leftbannershape() {
  return (
    <svg width="261" height="377" viewBox="0 0 261 377" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.57759 212.407C21.2603 300.239 94.1879 367.841 185.185 376.135C231.574 380.359 270.47 368.256 258.653 310.264C232.392 181.402 126.234 211.518 172.222 77.2009C218.209 -57.1161 11.0095 0.909928 1.42429 130.519C-0.796384 160.531 1.18868 187.968 5.56015 212.397L5.57759 212.407Z" fill="#B2A8EE"/>
    </svg>

  );
}

export default Leftbannershape;
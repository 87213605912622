export const GUEST_USERS = 'GUEST_USERS';
export const SET_LOADER = 'SET_LOADER';
export const OPEN_LOGIN_SIGNUP_MODAL = 'OPEN_LOGIN_SIGNUP_MODAL';
export const OPEN_DOWNLOAD_MODAL = 'OPEN_DOWNLOAD_MODAL'
export const IMPLICITE_TOKEN = 'IMPLICITE_TOKEN';
export const CHECK_USERS_EXISTANCE = 'CHECK_USERS_EXISTANCE';
export const SIGN_UP_PHONE_USER = 'SIGN_UP_PHONE_USER';
export const CHECK_USERNAME_EXIST = 'CHECK_USERNAME_EXIST';
export const IS_USERNAME_CHECKING = 'IS_USERNAME_CHECKING';
export const SET_UP_USERNAME = 'SET_UP_USERNAME';
export const SET_FOLLOWING = 'SET_FOLLOWING';
export const SET_BOOKMARK = 'SET_BOOKMARK';
export const SET_USER = 'SET_USER';
export const SET_HOST_USER_DETAILS = 'SET_HOST_USER_DETAILS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE';
export const UPDATE_USER_COVER_IMAGE = 'UPDATE_USER_COVER_IMAGE';
export const UPDATE_USER_TOPICS = 'UPDATE_USER_TOPICS';
export const UPDATE_USER_WATCHLIST = 'UPDATE_USER_WATCHLIST';
export const UPDATE_WATCH_LIST = 'UPDATE_WATCH_LIST'
export const UPDATE_HOST_USER_SHOWS = 'UPDATE_HOST_USER_SHOWS';
export const UPDATE_USER_CURRENT_SHOW = 'UPDATE_USER_CURRENT_SHOW';
export const RESET = 'RESET'
export const SET_RESET_PASSWORD_TOKEN = 'SET_RESET_PASSWORD_TOKEN'
export const LIST_FOLLOWER_USERS = 'LIST_FOLLOWER_USERS'
export const LIST_FOLLOWING_USERS = 'LIST_FOLLOWING_USERS'
export const RESET_FOLLOWER_FOLLOWING_USERS = 'RESET_FOLLOWER_FOLLOWING_USERS'
export const SOFT_DELETE_USER = 'SOFT_DELETE_USER'
export const BLOCK_USER = 'BLOCK_USER'
export const REPORT_USER = 'REPORT_USER'
export const REMOVE_FOLLOWER = 'REMOVE_FOLLOWER'
export const FETCHED_USER = 'FETCHED_USER'
export const FETCH_SHIPPING_ADDRESSES = 'FETCH_SHIPPING_ADDRESSES'
export const VALIDATE_SHIPPING_ADDRESS = 'VALIDATE_SHIPPING_ADDRESS'
export const ADD_SHIPPING_ADDRESS = 'ADD_SHIPPING_ADDRESS'
export const UPDATE_CARD_LIST = 'UPDATE_CARD_LIST'
export const SEARCH_USER = 'SEARCH_USER'
export const FETCH_TRANSACTION_HISTORY = 'FETCH_TRANSACTION_HISTORY'
export const FETCH_ORDER_HISTORY = 'FETCH_ORDER_HISTORY'
export const UPDATE_FAILED_ITEMS = 'UPDATE_FAILED_ITEMS'

import React, { useState } from 'react'
import { Drawer, Button } from 'antd'

import Navmenu from '../NavMenu'
import './Responsiveheader.scss'

const Responsiveheader = () => {
  const [visible, setVisible] = useState(false)
  const handleDrawer = (e:any) => {
    e?.preventDefault()
    setVisible(!visible)
  }

  return (
    <div className='responsiveDrawer'>
      <Button
        type="link"
        onClick={handleDrawer}
        className="navbar-toggler-icon"
      >
        <span></span>
      </Button>
      <Drawer
        placement="right"
        closable={false}
        onClose={handleDrawer}
        visible={visible}
      >
        <div className="closebutton" onClick={handleDrawer}>

        </div>
        <Navmenu
          handleDrawer={handleDrawer}
          isDrawer={true}

        />
      </Drawer>
    </div>
  )
}

export default Responsiveheader

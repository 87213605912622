import React from 'react';
import { images } from '../../../Assets/ImageConfig/images'
import './Emptymsg.scss'


function Emptymsg() {



  return (

    <div className='empty-msg'>
      <div className='empty-msg-content'>
        <img src={images.Emaptyimg} alt="" />
        <div className='img-des'>
          <p>Select message</p>
          <span>To see your existing conversation</span>
        </div>
      </div>
    </div>

  )
}

export default Emptymsg

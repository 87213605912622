import React from 'react'
import {Button, Modal} from 'antd'

import {Close} from '../../../Icons'
import {images} from '../../../../Assets/ImageConfig/images'
import {placeHolderProfileHelper} from '../../../../Utils/helper'
import Lock from '../../../Icons/Lock/Lock'

const BlockUnblockModal = (props: any) => {
    return (
        <Modal width={'496px'} title="" className='themeModal orderModal' visible={props.isModalVisible}
            onOk={props.handleOk} onCancel={props.handleCancel} footer={false} centered closeIcon={<Close />} maskClosable={false} closable={props.blockModalType === 'block'} >
            <div className='modal-body'>
                <div className='blockUserModal'>
                    <div className='blockUserImg'>
                        <img src={props.user?.pic ?? images.profiledummy} onError={placeHolderProfileHelper} alt='Profile' />
                    </div>
                    <div className='deleteuserContent'>
                        {props.blockModalType === 'block' && (
                            <>
                                <h5>{props.user?.username}</h5>
                                <p>Are you sure you want to block {props.user?.username}?</p>
                                <div className='blockUserBtns'>
                                    <Button type='ghost' className='large' onClick={props.handleCancel} >Cancel</Button>
                                    <Button type='primary' className='large' onClick={props.onBlockUser} >Block</Button>
                                </div>
                            </>
                        )}

                        {
                            props.blockModalType === 'unblock' && (
                                <>
                                    <h5>{props.user?.username}</h5>
                                    {props.user?.host && <p>Host</p>}
                                    <div className='blockUserBtns'>
                                        <Button type='ghost' className='large' onClick={props.onUnblockUser} ><Lock />{` `} Unblock</Button>
                                    </div>
                                </>
                            )
                        }

                        {
                            props.blockModalType === 'blockedBy' && (
                                <>
                                    <h5>{props.user?.username}</h5>
                                    {props.user?.host && <p>Host</p>}
                                   <div className='blockUserBtns'>
                                        <Button type='ghost' className='large'><Lock />{` `} You are blocked by {props.user?.username}</Button>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BlockUnblockModal
import { Form, Input, Button, Spin, DatePicker, Upload, message } from 'antd'
import React, { Suspense, useEffect, useState } from 'react'
import './Settingform.scss'
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { images } from '../../../../Assets/ImageConfig/images';
import { Close, Edit } from '../../../Icons/index'
import { emailRegex, messageStrings, passwordRegex } from '../../../../Utils/constants';
import { updateUser, checkUserNameExistance, updateUserProfileImage } from '../../../../store/Actions/users';
import Loader from '../../../CommonComponents/Loader'
import moment from 'moment';
import { Modal } from 'antd';
import LoadingSpinner from '../../../CommonComponents/LoadingSpinner';
import ImageComponent from '../../../Profile/Components/ImageComponent';
import {displayErrorToast, placeHolderProfileHelper} from '../../../../Utils/helper';

const dateFormat = 'YYYY-MM-DD';

function Settingform(props: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [resetModal, setResetModal] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  useEffect(() => {
    if (users.user.username) {
      form.setFieldsValue({ username: users.user.username });
      form.setFieldsValue({firstname: users.user.firstname});
      form.setFieldsValue({lastname: users.user.lastname});
      form.setFieldsValue({ email: users.user.email });
      form.setFieldsValue({birthdate: users.user?.birthdate ? moment(users.user?.birthdate) : null});
    }
  }, [users.user])
  const onSaveClick = async (value: any) => {

    if (!users.userNameChecking.isUserName && !users.userNameChecking.isuserNameChecking) {
      try{
        await dispatch(updateUser({
          birthdate: value.birthdate ? moment(value.birthdate).format(dateFormat) : null,
          username: value.username,
          email: value.email,
          firstname: value.firstname,
          lastname: value.lastname
        }))
      }
      catch(e: any){
        console.log(e);
        if (e.response?.data?.message)
          displayErrorToast(e.response?.data?.message)

        if(e.response?.data?.error)
          e.response?.data?.error.forEach((error: any) => {
            displayErrorToast(error.message)

        })
      }
    }
  }

  const checkUserName = (event: any) => {
    if (event.target.value.length >= 4) {
      dispatch(checkUserNameExistance(event.target.value))
    }
  }

  const handleProfileUpload = async (options: any) => {
    const { file } = options
    const fmData = new FormData()
    await fmData.append("file", file)
    try{
      await dispatch(updateUserProfileImage(fmData))
    }
    catch(e: any){
      if(e.response?.data?.error)
        e.response?.data?.error.forEach((error: any) => {
          displayErrorToast(error.message)
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handlePassword = () => { }
  return (
    <>
    <div className='EditProfile-details'>
      {users.loader && <Loader />}
      <h3>Account & Personal Info</h3>
        <Form form={form} className='accountsForm' name="basic" layout={'vertical'} initialValues={{username: users.user.username, email: users.user.email}} onFinish={onSaveClick}
        onFieldsChange={() => setFormValid(form.getFieldsError().filter(({ errors }) => errors.length).length !== 0) }>
          <div className='EditProfile'>
                <div className='userpicWrapper'>
                  <div className='userpic'>
                    <Suspense fallback={<LoadingSpinner />}>
                      <ImageComponent imgSrc={users.user?.pic ?? images.profiledummy} placeholderImg={images.profiledummy} altText='Profile' errorHandler={placeHolderProfileHelper} />
                    </Suspense>
                    </div>
                    <Upload
                      accept='image/*'
                      showUploadList={false}
                      customRequest={handleProfileUpload}
                    >
                      <div className='EditIcon'>
                          <Edit/>
                      </div>
                    </Upload>
                </div>
          </div>
        <Form.Item label="Email Address" name="email" rules={[
          {
            required: true,
            message: 'Please input your email!'
          },
          {
            pattern: emailRegex,
            message: 'Please input valid email!'
          }]}>
          <Input placeholder='Email' allowClear disabled={ users.user?.source === 'google' || users.user?.source === 'apple' }/>
        </Form.Item>
        <Form.Item label="First Name" name="firstname" rules={ [
          {
            required: true,
            message: 'Please enter first name'
          }]}>
            <Input autoComplete='off' placeholder='Firstname' allowClear />
          </Form.Item>
        <Form.Item label="Last Name" name="lastname" rules={ [
          {
            required: true,
            message: 'Please enter last name'
          }]}>
          <Input autoComplete='off' placeholder='Lastname' allowClear />
        </Form.Item>
        <div className='usernameLoader'>
          <Form.Item label="User Name" name="username" rules={[
            {
              required: true,
              message: 'Please enter username!'
            },
            {
              min: 4,
              message: 'Username length must be at least 4 characters long.'
            }
          ]} >
            <Input onChange={checkUserName} placeholder="Username" allowClear maxLength={20} />
          </Form.Item>
          {users.userNameChecking.isuserNameChecking ? <Spin className='userloader' /> : null}
          {users.userNameChecking.isUserName ? <span style={{ color: "red", }}>Username Exist</span> : null}
        </div>
        <Form.Item label="BIRTHDATE" name="birthdate" >
          <DatePicker disabledDate={(current: any) =>  current && current > moment().subtract(14, "years")} format={dateFormat} placeholder="Birthdate" allowClear={false} inputReadOnly />
        </Form.Item>
        <div className='submitBtn'>
          <Form.Item >
            <Button type="primary" className='filledbtn'  htmlType="submit" disabled={formValid || users.userNameChecking.isUserName}>
              Save
            </Button>
          </Form.Item>
          {/* <a onClick={() => setResetModal(true)} className='forgetLink'>Change Password?</a> */}
        </div>
      </Form>
    </div>
    <Modal title="Reset password" className='themeModal' width={448} visible={resetModal} onCancel={() => setResetModal(false)} footer={false} centered closeIcon={<Close />}>
      {users.loader && <Loader />}
      <div className='modal-body'>
        <p>Enter your password</p>
        <Form
            name="register"
            onFinish={handlePassword}
            form={form} layout={'vertical'}
        >
            <>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter new password!'
                        },
                        {
                            pattern: passwordRegex,
                            message: messageStrings.errorMessage.emailNotValid,
                        }
                    ]}>
                    <div className='numberInput'>
                        <Input type='password' autoComplete="new-password" />
                    </div>
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}

                >
                    <div className='numberInput'>
                        <Input  autoComplete="new-password"  type='password' />
                    </div>
                </Form.Item>
            </>
            <div id="recaptcha-container"></div>
            <div className='modalbtns'>
                <Button type="primary" className='modalbtn filledbtn' htmlType="submit">Continue</Button>
            </div>
        </Form>
    </div>
    </Modal>
  </>

  )
}

export default Settingform

import React from "react";

function Edit() {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.7282 1.54678C8.26181 0.909043 9.22105 0.815529 9.87202 1.33829C9.90801 1.36665 11.0644 2.265 11.0644 2.265C11.7795 2.69732 12.0017 3.61636 11.5597 4.31772C11.5362 4.35528 4.99835 12.5332 4.99835 12.5332C4.78084 12.8045 4.45066 12.9647 4.09779 12.9686L1.59407 13L1.02995 10.6123C0.950923 10.2766 1.02995 9.92399 1.24746 9.65264L7.7282 1.54678Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.51782 3.06445L10.2687 5.945" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


  );
}

export default Edit;
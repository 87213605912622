import {Route, Routes} from "react-router-dom";
import Show from "../Pages/Show";
import AllShows from "../Pages/AllShows"
import ShowDashBoard from "../Pages/ShowDashboard"
import HostPage from "../Pages/HostPage"
import MyProfilePage from '../Pages/MyProfilePage'
import EditProfilePage from '../Pages/EditProfilePage'
import ChatPage from '../Pages/ChatPage'
import AccountSettings from '../Pages/AccountSettings'
import PrivateAuthentication from '../Components/Auth/Auth';
import PublicAuthentication from '../Components/Auth/Public';
// import HomePageEn from '../Pages/Website/HomePageEn';
// import HomePageGe from '../Pages/Website/HomePageGe';
import DeepLink from '../Pages/DeepLink/DeepLink';
import ShowScreenSharing from '../Pages/ShowSharing'
import DownloadPage from '../Pages/DownloadPage/index'
import HomePageEn from '../Pages/Home'
import Seller from '../Pages/Seller'
import HomePage from '../Pages/Home'
import HomePageDe from '../Pages/HomeDE'

let isDe: any;
let prefix: any;

if (process.env.NODE_ENV === "production") {
  prefix = ''
} else {
  prefix = ''
}

if (/^de\b/.test(navigator.language)) {
  isDe = true;
} else {
  isDe = false
}

const CustomRoutes = () => {
  return (
    <Routes>

      <Route path="/" element={<HomePage />} />
      <Route path="/en" element={<HomePage />} />
      <Route path="/de" element={<HomePage />} />
      <Route path={`/shows`} element={<PublicAuthentication><ShowDashBoard /></PublicAuthentication>} />
      {/* Feature shows section remove for now ref of ticket ENT-103  */}
      {/* <Route path={`/all-shows/feature-show`} element={<PublicAuthentication><AllShows path="feature-show" /></PublicAuthentication>} /> */}
      <Route path={`/all-shows/upcoming-show`} element={<PublicAuthentication><AllShows path="upcoming-show" /></PublicAuthentication>} />
      <Route path={`/all-shows/live-show`} element={<PublicAuthentication><AllShows path="live-show" /></PublicAuthentication>} />
      <Route path={`/all-shows/suggested-show`} element={<PublicAuthentication><AllShows path="suggested-show" /></PublicAuthentication>} />
      <Route path={`/all-shows/game-show`} element={<PublicAuthentication><AllShows path="game-show" /></PublicAuthentication>} />
      <Route path={`${prefix}/show/:id`} element={<PublicAuthentication><Show /></PublicAuthentication>} />
      <Route path={`${prefix}/show/:id/sharing`} element={<ShowScreenSharing />} />
      <Route path={`${prefix}/my-profile`} element={<PrivateAuthentication><MyProfilePage /></PrivateAuthentication>} />
      <Route path={`${prefix}/edit-profile`} element={<PrivateAuthentication><EditProfilePage /></PrivateAuthentication>} />
      <Route path={`${prefix}/account-settings/:tab`} element={<PrivateAuthentication><AccountSettings /></PrivateAuthentication>} />
      <Route path={`${prefix}/account-settings`} element={<PrivateAuthentication><AccountSettings /></PrivateAuthentication>} />
      <Route path={`${prefix}/chat`} element={<PublicAuthentication><ChatPage /></PublicAuthentication>} />
      <Route path="/android" element={<DeepLink />} />
      <Route path="/ios" element={<DeepLink />} />
      <Route path="/collectibles/android" element={<DeepLink />} />
      <Route path="/collectibles/ios" element={<DeepLink />} />
      <Route path="/culture" element={<DeepLink />} />
      <Route path="/jobs" element={<DeepLink />} />
      <Route path="/imprint" element={<DeepLink />} />
      <Route path="/impressum" element={<DeepLink />} />
      <Route path="/terms" element={<DeepLink />} />
      <Route path="/privacy" element={<DeepLink />} />
      <Route path="/faq" element={<DeepLink />} />
      <Route path="/discord" element={<DeepLink />} />
      <Route path="/facebook" element={<DeepLink />} />
      <Route path="/dashboard" element={<DeepLink />} />
      <Route path="/orders" element={<DeepLink />} />
      <Route path="/shipping/:user" element={<DeepLink />} />
      <Route path="/shipping" element={<DeepLink />} />
      <Route path="/waitlist/:user" element={<DeepLink />} />
      <Route path="/waitlist" element={<DeepLink />} />
      <Route path="/download" element={<DownloadPage />} />
      <Route path="/app" element={<DownloadPage />} />
      <Route path={`/welovegavel/version`} element={<div>1.0.1</div>}/>
      <Route path="/:hostName" element={<PublicAuthentication><HostPage /></PublicAuthentication>} />
      <Route path="/@:hostName" element={<PublicAuthentication><HostPage /></PublicAuthentication>} />
      <Route path="*" element={<DeepLink />} />
      <Route path="/seller" element={<Seller />} />
    </Routes>
  );
};

export default CustomRoutes;

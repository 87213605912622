import {Button, Form, Input, message, Modal, Select, } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';

import isEmpty from 'lodash/isEmpty'

import {RootState} from '../../../../store';
import {getReportReasons} from '../../../../store/Actions/showDashboard';
import {Close} from '../../../Icons'


const {Option} = Select

const ReportUserModal = (props: any) => {

  const [form] = Form.useForm();

  const dispatch = useDispatch()

  const {reportReasonsList} = useSelector(
    (state: RootState) => state?.showDashboard
  );
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const [loading, setLoading] = useState(false)
  const [formValid, setFormValid] = useState(false)

  const onFinish = (values: any) => {

    props.onSubmit(props?.isShowPage ? users.currentShow?.user_id : users?.hostUser?.id, values?.reason, values?.description)
    props.handleCancel()

  };

  useEffect(() => {
      if(!loading && isEmpty(reportReasonsList))
        fetchReportReasonsList()
  }, [])

  const fetchReportReasonsList = async () => {
    setLoading(true)
    try {
      await dispatch(getReportReasons())
    } catch (e) {
      console.log(e);
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Modal width={'640px'} title={`${props?.isShowPage ? 'Report this show' : 'Report this user'}`} className='themeModal' visible={props.isModalVisible} onOk={props.handleOk} onCancel={props.handleCancel} footer={false} centered closeIcon={<Close />}>
      <div className='modal-body'>
        <Form
          name="register"
          onFinish={onFinish}
          form={form}
          layout='vertical'
          onFieldsChange={() => setFormValid(form.getFieldsError().filter(({errors}) => errors.length).length !== 0)}
          initialValues={{reason: null, description: ''}}

        >
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: 'Please select your reason!'
              },
            ]}
          >
            <Select placeholder="Select Reason"
              showSearch optionFilterProp="children" className='selectDropdown'
            >
              {
                reportReasonsList.map((item: any, index: number): any => (
                  <Option key={index} value={`${item.id}`}>
                    {item?.reason}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item name="description" rules={[
            {
              required: true,
              message: 'Please provide description'
            }
          ]} >
            <TextArea rows={4} placeholder='Description' />
          </Form.Item>
          <div className='deleteuserBtns'>
            <Form.Item >
              <Button type='ghost' onClick={props.handleCancel} htmlType="button" >Cancel</Button>
              <Button type='primary' className='filled'  htmlType='submit' disabled={formValid} >Report</Button>
            </Form.Item>
          </div>

        </Form>
      </div>
    </Modal>
  );
}

export default ReportUserModal
import {images} from '../../../../Assets/ImageConfig/images';

const EmptyFollowerFollowing = (props: any) => {
    return (
        <div className='nofollowersBox'>
            <div className='followInner'>
                <div className='NofollowImg'>
                    <img src={props.isFollower ? images.NoFollowing : images.NoFollowers} alt='logo' />
                </div>
                <div className='nofollowText'>
                    {
                        props.isFollower ? (
                             <>
                                <h4>No followers yet</h4>
                                <p>{props.isMyProfile ? 'You\'ll see all of the people who follow you here' : 'You\'ll see all of the people who follow the user here'} </p>
                            </>

                        ) : (
                           <>
                                <h4>No friends to show</h4>
                                <p>{props.isMyProfile ? 'You are not following anyone yet.' : 'The user is not following anyone yet.' }</p>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default EmptyFollowerFollowing
import React from "react";

function Profile() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10.001" r="7" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M14.3768 14.8423C14.2463 14.6802 14.1066 14.5246 13.9581 14.3761C12.8641 13.2821 11.3804 12.6675 9.83332 12.6675C8.28623 12.6675 6.80249 13.2821 5.70853 14.3761C5.51215 14.5724 5.33121 14.7814 5.1665 15.001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.001C10.7956 12.001 11.5587 11.6849 12.1213 11.1223C12.6839 10.5597 13 9.79666 13 9.00101C13 8.20536 12.6839 7.4423 12.1213 6.87969C11.5587 6.31708 10.7956 6.00101 10 6.00101C9.20435 6.00101 8.44129 6.31708 7.87868 6.87969C7.31607 7.4423 7 8.20536 7 9.00101C7 9.79666 7.31607 10.5597 7.87868 11.1223C8.44129 11.6849 9.20435 12.001 10 12.001V12.001Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
}

export default Profile;
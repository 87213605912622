/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
import {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
// import { createUser } from "../../store/session/Actions/index";
import AgoraRTC, {CameraVideoTrackInitConfig} from "agora-rtc-sdk-ng";
import {Button, Col, Row, Tooltip} from "antd";
import {Eye, Failed, Flag, Mute, Unmute, Wallet} from '../../Components/Icons/index';
import '../../Style/buttons.scss';
import '../../Style/common.scss';
import API from "../../Utils/api";
import AppConfig from "../../Utils/config";
import Loader from "../CommonComponents/Loader";
import Showended from './Components/Showended/Showended';
import Shownotstarted from './Components/Shownotstarted/Shownotstarted';
import './show.scss';

import {loadStripe} from '@stripe/stripe-js';
import _ from "lodash";
import {useNavigate} from 'react-router';
import {useParams, useSearchParams} from "react-router-dom";
import {images} from "../../Assets/ImageConfig/images";
import DeepLinkCheck from "../../Components/DeepLinkCheck/DeepLinkCheck";
import {SocketContext} from '../../Context/socket';
import {getShowById, openLoginSignUpModal, reportUser, setCurrentShow, updateUser} from "../../store/Actions/users";
import Infoicon from "../Icons/Infoicon/Infoicon";
import {followUser} from './../../store/Actions/users';
import Chat from "./Chat";

import {
  Elements,
  ElementsConsumer
} from '@stripe/react-stripe-js';
import CreditCard from "./Components/CreditCard";
import GiveAway from "./Components/GiveAway";
import ShippingAddress from "./Components/ShippingAddress";
import TopUpModal from "./Components/Topup";

import {LoadingOutlined} from '@ant-design/icons';
import {displayErrorToast, displayInfoToast, getCardIcon, openInNewTab} from "../../Utils/helper";
import {bid_clicked, bid_failed, show_entered, show_left, show_watched, user_follow, user_unfollow} from "../../Utils/mParticle";
import LoadingSpinner from "../CommonComponents/LoadingSpinner";
import ProfileBadges from "../CommonComponents/ProfileBadges";
import ReportUserModal from "../Profile/Components/ProfileView/ReportUserModal";
import ShippingFeeDescModal from "./Components/ShippingDescPopup/ShippingFeesDescriptionPopup";
// import ShippingSizeCostModal from "./Components/ShippingSizeCostPopup/ShippingSizePopup"
import {ShippingUpgradeData} from "../ShowScreen/Chat/types";


// mode: rtc & live. rtc:- 1 to 1 or Group call, live:- Live streaming
// codec vp8, h264
interface CounDown {
  count: number;
  isCount?: boolean;
}

let toastDisplayed: boolean = false;

const ShowWithPayment = () => {

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  let stripePromise
  if (users.user.publishableKey) {
    stripePromise = loadStripe(users.user.publishableKey);
  }

  return (<>
    {stripePromise ? <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Inter&display=swap',
          },
        ],
      }}
    >
      <ElementsConsumer>
        {({stripe, elements}) => (
          <div >
            <Show stripe={stripe} elements={elements} />
          </div>

        )}
      </ElementsConsumer>
    </Elements > : <Show />
    }
  </>
  )
}
function Show(props: any) {
  const [role, setRole] = useState<string>('audience'); // As of now it's audience, will change with permission
  const [userCount, setUserCount] = useState<number>(0)
  const [giveawayUser, setGiveawayUser] = useState<any>([])
  // const [user, setUser] = useState<IAgoraRTCRemoteUser>();
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<CounDown>({count: 10, isCount: false})
  const [isShowOn, setIsShowOn] = useState(true);
  const [isConnecting, setIsConnecting] = useState(true)
  const [isShowEnded, setIsShowEnded] = useState(false)
  const [isShowGiveawayModal, setIsShowGiveawayModal] = useState(false)
  // const [userCount, setUserCount] = useState<boolean>(false);
  const [tracks, setTracks] = useState<any>(null);
  const [downloadModal, setDownloadModal] = useState(false)

  const [isAutoPlayFailed, setAutoPlayFailed] = useState(false)
  const [userAudioTrack, setUserAudioTrack] = useState<any>(null)
  const [isAudioPlaying, setAudioPlaying] = useState(false)
  const [isAutoPlayReset, setAutoPlayReset] = useState(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [followersCount, setFollowersCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [is_biddable, isBiddable] = useState<boolean>(false);
  const [item, setItem] = useState<any>(null);
  const [itemType, setItemType] = useState<string>('')
  const [amount, setAmount] = useState(0)
  const [saved_card_count, setSavedCardCount] = useState<number>(0);
  const [is_bidding, isBidding] = useState<boolean>(false);
  const [is_waiting, isWaiting] = useState<boolean>(false);
  const [is_instant, isInstant] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(-1);
  const [wallet_amount, setWalletAmount] = useState<number>();
  const [shippingAddressCount, setShippingAddressCount] = useState<number>(0);
  const [hasShippingAddress, setHasShippingAddress] = useState<string>('unknown')
  const [hasPaymentMethod, setHasPaymentMethod] = useState<string>('unknown')
  const [shipping_amount, setShippingAmount] = useState<number>(0);
  const [isToppedUpBefore, setIsToppedUpBefore] = useState<boolean>(false);
  const [is_winning, isWinning] = useState<boolean>(false);
  const [last_payment_status, setLastPaymentStatus] = useState<string>('');
  const [isShippingAddress, setIsShippingAddress] = useState<any>(false);
  const [isTopUp, setIsTopUp] = useState<any>(false);
  const [isCreditCard, setIsCreditCard] = useState<any>(false);
  const [winner, setWinner] = useState<string>();
  const [winners, setWinners] = useState<any>([]);
  const [cardBrand, setCardBrand] = useState<string>('');
  const childRef = useRef();
  const {stripe, elements} = props;
  const [shippingFeeDescription, setShippingFeeDescription] = useState<string>('');
  const [shippingFeeModalVisible, setshippingFeeModalVisible] = useState<boolean>(false)
  const [shippingFee, setShippingFee] = useState<number>(0)
  const [shippingUpgradeData, setUpgradedShippingData] = useState<ShippingUpgradeData>()
  const [upgradeShippingPaymentStatus, setUpgradeShippingPaymentStatus] = useState<string>()

  const [reportShowModal, setReportShowModal] = useState<boolean>(false)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const io = useContext(SocketContext)

  const params = useParams();
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const showDashboard = useSelector(
    (state: RootState) => state?.showDashboard
  );
  const dispatch = useDispatch()

  const videoConfig: CameraVideoTrackInitConfig = {
    encoderConfig: {width: 600, height: 600},
  };

  // const context = new AudioContext()

  useEffect(() => {

    if (!toastDisplayed && searchParams.has('redirect_status')) {
      const redirectStatus = searchParams.get('redirect_status')

      if (redirectStatus === 'succeeded') {
        displayInfoToast(`Your balance top-up was successful!`)
        navigate({
          search: ''
        }, {replace: true})
      }
      else if (redirectStatus === 'failed') {
        displayErrorToast(`We couldn't add money to your pre-paid balance. Try once again! We are unable to authenticate your payment method. Please choose a different payment method and try again..`)
        navigate({
          search: ''
        }, {replace: true})
      }
      toastDisplayed = true;
    }

  }, [searchParams, toastDisplayed]);

  useEffect(() => {
    AgoraRTC.onAutoplayFailed = () => {
      console.log('result from onAutoplayFailed', userAudioTrack);
      if (isAutoPlayFailed) return;
      userAudioTrack && userAudioTrack.stop()
      setAutoPlayFailed(true)
    }
  }, [userAudioTrack]);
  useEffect(() => {
    if (users.user?.id) {
      if (users.currentShow?.followed_by_me && users.user?.id)
        setIsFollowing(true)
      else
        setIsFollowing(false)
    }
    setFollowersCount(users.currentShow?.followed_by_ids?.length)
  }, [users.currentShow])


  useEffect(() => {
    return () => {
      destroyAgora()
      toastDisplayed = false
    }
  }, []);

  useEffect(() => {
    const shows = [...showDashboard.shows?.featured?.shows ?? null, ...showDashboard.shows?.ready?.shows ?? null, ...showDashboard.shows?.live?.shows ?? null]
    const show: any = shows.find((e: any) => e.id === Number(params.id))
    if (_.isEmpty(show) && _.isEmpty(users.currentShow) || users.isFetchedUser) {
      if (_.isEmpty(users.currentShow) || users.isFetchedUser)
        dispatch(getShowById(Number(params.id), users.user?.id))
    }
    else if (!_.isEmpty(show))
      dispatch(setCurrentShow(show))
  }, [params.id, showDashboard.shows, users.isFetchedUser])

  const init = async () => {
    let cameraTrack;
    let audioTrack;
    if (isInitializing || isInitialized) {
    } else {
      AppConfig.rtc.client = AgoraRTC.createClient({
        mode: "live",
        codec: "vp8",
      });
      setIsInitializing(true);
      if (role === "host") {
        await AppConfig.rtc.client.setClientRole("host");
        cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
        await AppConfig.rtc.client.setLowStreamParameter({height: 600});

        audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "high_quality_stereo",
        });

        await setTracks([audioTrack, cameraTrack]);
      } else {
        // tracks && (await tracks[0]?.setEnabled(false));
        await AppConfig.rtc.client.setClientRole("audience");
      }
      if (role === "audience") {
        AppConfig.rtc.client.on(
          "user-published",
          async (user: any, mediaType: any) => {
            // Subscribe to a remote user.
            await AppConfig.rtc.client.subscribe(user, mediaType);
            console.log("subscribe success");
            // If the subscribed track is video.
            if (mediaType === "video") {
              // Get `RemoteVideoTrack` in the `user` object.
              const remoteVideoTrack = user.videoTrack;
              // Dynamically create a container in the form of a DIV element for playing the remote video track.
              const remotePlayerContainerAudience =
                document.getElementById("audience");
              if (remotePlayerContainerAudience) {
                const remotePlayerContainer = document.createElement("div");
                // Specify the ID of the DIV container. You can use the `uid` of the remote user.
                remotePlayerContainer.id = user.uid.toString();
                // remotePlayerContainer.textContent =
                //   "Remote user " + user.uid.toString();
                // remotePlayerContainer.style.width = "640px";
                // remotePlayerContainer.style.height = "650px";
                remotePlayerContainerAudience.append(remotePlayerContainer);

                // Play the remote video track.
                // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
                remoteVideoTrack.play(remotePlayerContainer);

                // Or just pass the ID of the DIV container.
                // remoteVideoTrack.play(playerContainer.id);
              }
            }

            // If the subscribed track is audio.
            if (mediaType === "audio") {
              // Get `RemoteAudioTrack` in the `user` object.
              const remoteAudioTrack = user.audioTrack;
              setUserAudioTrack(remoteAudioTrack)
              // Play the audio track. No need to pass any DOM element.
              remoteAudioTrack.play();
              // remoteAudioTrack.stop()
            }
          }
        );

        AppConfig.rtc.client.on("user-unpublished", (user: any) => {
          // Get the dynamically created DIV container.
          const remotePlayerContainer = document.getElementById(user.uid);
          // Destroy the container.
          setUserAudioTrack(null)
          setAutoPlayFailed(false)
          remotePlayerContainer && remotePlayerContainer.remove();
        });
      }

      const res = await API.getDataWithOutToken('/api/agora', {'id': params.id, 'link': "", 'user_id': users.user.id || AppConfig.visitor, 'seller': ""});
      const token = res.data?.agora?.token;
      const channelFromRes = res.data?.agora?.channel;
      // client.setEncryptionConfig("sm4-128-ecb", "123456789");
      AppConfig.rtc.client.on("user-joined", onHostJoined);
      await AppConfig.rtc.client.join(
        AppConfig.appId,
        channelFromRes,
        token,
        null
      );
      if (role === "host") {
        cameraTrack?.play("local-player");
        audioTrack &&
          cameraTrack &&
          (await AppConfig.rtc.client.publish([audioTrack, cameraTrack]));
      }
      setIsInitialized(true);
      //   setStart(true);
    }
  };

  const onHostJoined = () => {

  }

  const handleFollow = async () => {
    setButtonLoading(true)
    try {
      if (users.user?.id) {
        if (users.user?.id === users.currentShow?.user_id)
         displayInfoToast("It's you!")
        else {
          setButtonLoading(true)
          await dispatch(followUser(isFollowing, users.currentShow?.user_id, true))
          // if(_.isEmpty(showDashboard.shows.featured.shows))
          if (isFollowing){
            setFollowersCount(followersCount - 1)
            user_unfollow(users.user, users.currentShow?.user_id, 'Show_Screen.follow_button', 'Show', 'Show');
          }
          else{
            setFollowersCount(followersCount + 1)
            user_follow(users.user, users.currentShow?.user_id, 'Show_Screen.follow_button', 'Show', 'Show');
          }
          setTimeout(() => {
            setButtonLoading(false)
            setTimeout(() => {
              setIsFollowing(!isFollowing)
            }, 200);
          }, 500);
        }
      }
      else {
        dispatch(openLoginSignUpModal(true))
        setButtonLoading(false)
      }
    } catch (error: any) {
      console.log(error);
      setButtonLoading(false)
    }
  }

  const destroyAgora = () => {
    AppConfig.rtc.client.leave()
  }
  const openDownloadModal = () => {
    setDownloadModal(!downloadModal)
  }

  // useEffect(() => {
  //   // if (users.isGuestUser || users.user.username) {
  //   init();
  //   // }

  // }, [tracks, tracks?.cameraTrack, tracks?.cameraTrack]);

  // useEffect(() => {
  //   if ((userAudioTrack && userAudioTrack?.isPlaying) || !isAutoPlayFailed) {
  //     setAudioPlaying(true)
  //   }
  //   else if (!userAudioTrack?.isPlaying && isAutoPlayFailed) {
  //     setAudioPlaying(false)
  //   }
  //   else {
  //     userAudioTrack?.isPlaying && setAudioPlaying(userAudioTrack?.isPlaying)
  //   }
  // }, [userAudioTrack, isAutoPlayFailed])

  const toggleAudio = () => {
    if (userAudioTrack?.isPlaying && isAutoPlayFailed) {
      // userAudioTrack.stop()
      userAudioTrack.play()
      setAudioPlaying(true)
      setAutoPlayFailed(false)
      setAutoPlayReset(true)
    }
    else if (userAudioTrack?.isPlaying) {
      userAudioTrack.stop()
      setAudioPlaying(userAudioTrack?.isPlaying || false)
    }
    else {
      userAudioTrack.play()
      setAudioPlaying(userAudioTrack?.isPlaying || false)
    }
  }

  useEffect(() => {
    if (users.currentShow?.id && (users?.isFetchedUser || (!users?.loader && !Boolean(localStorage.getItem('access-token'))))) {
      console.log('dispatching show_entered', users.currentShow?.id, users.user?.id);
      show_entered(users.user, users.currentShow)

      if (users.user?.firstShowEnter) {
        dispatch(updateUser({
          firstShowEnter: false
        }));
        users.user.firstShowEnter = false;
      }
    }
  }, [users.currentShow?.id, users.isFetchedUser]);

  useEffect(()=>{
    let entered    = new Date();
    return ()=>{
      const duration = (new Date().getTime() - entered.getTime()) / 1000;
      //if the user spent more than 3 minutes
      if (duration > 180) {
        show_watched(users.user, users.currentShow, duration)
        if (users.user?.firstWatch) {
          dispatch(updateUser({
            firstWatch: false
          }));
          users.user.firstWatch = false
        }
      }
      show_left(users?.user, users.currentShow, duration);
    }
  },[users.user?.id])

  useEffect(() => {
    if (!isAutoPlayFailed) {
      // if autoplay is fixed then do nothing
      if (isAutoPlayReset) return
      setAudioPlaying(true)
    }
    else  if ((userAudioTrack && userAudioTrack?.isPlaying) || !isAutoPlayFailed) {
      setAudioPlaying(false)
    }
    else if (!userAudioTrack?.isPlaying && isAutoPlayFailed) {

      setAudioPlaying(false)
    }
    else {
      userAudioTrack?.isPlaying && setAudioPlaying(userAudioTrack.isPlaying)
    }
  }, [userAudioTrack, isAutoPlayFailed, isAutoPlayReset])

  const onBidClick = () => {
    // return
    bid_clicked(users.user, users?.currentShow, item, price);
    if (!users.isGuestUser && !users.user.username) {
      bid_failed( users.user, users?.currentShow, item, price, 'BUSINESS', 'due to the guest user', 'MISSING_DATA');
      dispatch(openLoginSignUpModal(true))
    } else {
      if (users.isGuestUser) {
        bid_failed( users.user, users?.currentShow, item, price, 'BUSINESS', 'due to the guest user', 'MISSING_DATA');
        dispatch(openLoginSignUpModal(true))
      } else {
        const actual_price = price + (is_instant ? 0 : is_bidding ? 1 : 0);
        let final_amount = Number(actual_price) + Number(shipping_amount);
        if (upgradeShippingPaymentStatus === 'unpaid') {
          displayInfoToast('Please pay your upgraded shipping costs to proceed.');
          navigate('/account-settings/payment-method')
        }
        else if (
          (saved_card_count ?? 0) > 0 &&
          (last_payment_status === 'failed' || last_payment_status === 'unpaid' || upgradeShippingPaymentStatus === 'unpaid')
        ) {
          displayInfoToast('Please add a new card to proceed or retry payment for old unpaid transaction');
          bid_failed( users.user, users?.currentShow, item, price, 'BUSINESS', 'Last payment failed or unpaid', 'MISSING_DATA');
          navigate('/account-settings/payment-method')

        } else if (
          wallet_amount === 0 &&
          (saved_card_count ?? 0) === 0 &&
          !isToppedUpBefore
        ) {
          // RED: Setup wallet
          setIsTopUp(true)
          displayInfoToast('Please setup your wallet to proceed');
          bid_failed( users.user, users?.currentShow, item, price, 'BUSINESS', 'Empty wallet or no credit card', 'MISSING_DATA');
        } else if (
          wallet_amount === 0 &&
          (saved_card_count ?? 0) === 0 &&
          isToppedUpBefore
        ) {
          // RED: €0
          setIsTopUp(true)
          displayInfoToast('Please add a wallet amount or card to proceed');
          bid_failed( users.user, users?.currentShow, item, price, 'BUSINESS', 'Empty wallet', 'MISSING_DATA');
        } else if (shippingAddressCount === 0) {
          // RED: Setup wallet
          setIsShippingAddress(true)
          displayInfoToast('Please add a shipping address to proceed');
          bid_failed( users.user, users?.currentShow, item, price, 'BUSINESS', 'Missing address', 'MISSING_DATA');
        } else if (
          wallet_amount &&
          wallet_amount !== 0 &&
          wallet_amount < Number(final_amount) &&
          (saved_card_count ?? 0) === 0
        ) {
          // YELLOW: €5
          setIsTopUp(true)
          displayInfoToast('Wallet amount is less than the final amount');
          bid_failed( users.user, users?.currentShow, item, price, 'BUSINESS', 'Wallet amount is less than the final amount', 'MISSING_DATA');
        } else {
          is_winning && !is_instant
            ? displayInfoToast('You are currently the winner with the highest bid.')
            //@ts-ignore
            : childRef?.current?.emitBid1(is_instant ? 0 : is_bidding ? 1 : 0);
        }
      }
    }
  }

  const getWalletBadgeBackground = () => {
    if (wallet_amount === 0 && saved_card_count === 0 && isToppedUpBefore) {
      return 'red'
    }
    else if (upgradeShippingPaymentStatus === 'unpaid') {
      return 'red'
    }
    else if (wallet_amount !== 0 && ((wallet_amount ?? 0) < 15 || (wallet_amount ?? 0) < price) && saved_card_count === 0) {
      return '#FF8D00'
    }
    else {
      return '#2ABC01'
    }
  }

  const onShippingFeeClick = () => {
    setshippingFeeModalVisible(true);
  }

  const handleCancel = () => {
    setshippingFeeModalVisible(false)
  }

  const onReportShowBtn = () => {
    if (users.user?.id) {
      setReportShowModal(true)
    }
    else {
      dispatch(openLoginSignUpModal(true))
    }
  }

  const onReportShow = async (userId: number, reasonId: number, description: string) => {
    try {
      await dispatch(reportUser(userId, reasonId, description, true))
    }
    catch (e: any) {
      if (e.response?.data?.message)
       displayErrorToast(e.response?.data?.message)
    }
  }

  const getWalletBadge = () => {

    const lastFailed = last_payment_status === 'failed' || last_payment_status === 'unpaid'
    const unpaidUpgradedShipping = upgradeShippingPaymentStatus === 'unpaid'

    if (hasShippingAddress === 'unknown' || hasPaymentMethod === 'unknown') {
      return (
        <h3
        style={{padding: '16px 4px', backgroundColor: "transparent", fontSize: "14px"}}
        >
        <LoadingSpinner />
          </h3>
      )
    }

    else if (saved_card_count > 0 && (lastFailed || unpaidUpgradedShipping)) {
      // RED: Setup wallet
      return (
        <h3
          style={{padding: '16px 4px', backgroundColor: "#EC000F", fontSize: "14px"}} className="paymentFailed"
          onClick={() => navigate('/account-settings/payment-method')}
        >
          <Failed /> {unpaidUpgradedShipping ? "Unpaid Upgraded Shipping Costs" : "Payment Failed"}
        </h3>
      );
    }
    else if (wallet_amount === 0 && saved_card_count === 0 && !isToppedUpBefore) {
      // RED: Setup wallet
      return (
        <h3
          style={{padding: '17px 4px', backgroundColor: "#EC000F", fontSize: "14px"}} className="paymentFailed"
          onClick={() => {
            if (!users.user?.id) {
              dispatch(openLoginSignUpModal(true))
              return
            }
            setIsCreditCard(true)
          }
          }
        >
          <Wallet />Setup Wallet
        </h3>
      );
    }
    else if (wallet_amount === 0 && saved_card_count === 0 && isToppedUpBefore) {
      // RED: €0
      return (
        <h3 onClick={() => {
          if (!users.user?.id) {
            dispatch(openLoginSignUpModal(true))
            return
          }
          setIsTopUp(true)
        }}>
          <><Wallet />€{wallet_amount}</>
        </h3>
      );
    }
    else if (shippingAddressCount === 0) {
      // RED: Add shipping address
      return (
        <h3
          style={{padding: '17px 4px', backgroundColor: "#EC000F", fontSize: "14px"}} className="paymentFailed"
          onClick={() => {
            if (!users.user?.id) {
              dispatch(openLoginSignUpModal(true))
              return
            }
            setIsShippingAddress(true)
          }}
        >
          Add shipping address
        </h3>
      );
    }
    else if (wallet_amount !== 0 && ((wallet_amount ?? 0) < 15 || (wallet_amount ?? 0) < price) && saved_card_count === 0) {
       // YELLOW: €5
      return (<h3 onClick={() => {
        if (!users.user?.id) {
          dispatch(openLoginSignUpModal(true))
          return
        }
        setIsTopUp(true)
      }} style={{
        backgroundColor: getWalletBadgeBackground()
      }}>
        <><Wallet />€{wallet_amount}</>
      </h3>
      );
    }
    else if (wallet_amount !== 0 && (wallet_amount ?? 0) < 15 && saved_card_count > 0) {
      // GREEN: €5 VISA/MasterCard Logo
      return (
        <h3 onClick={() => {
          if (!users.user?.id) {
            dispatch(openLoginSignUpModal(true))
            return
          }
          setIsTopUp(true)
        }}
          style={{
            backgroundColor: getWalletBadgeBackground()
          }}
        >
          {saved_card_count > 0 && (<span className="cardIcon"><img src={getCardIcon(cardBrand)} alt="cardIcon" className={`${cardBrand === 'mastercard' ? 'mastercard' : 'CardImg'}`} /> {`  |  `} </span>)}
          <span className={'ml-2 walltetIcon'}><Wallet />€{wallet_amount}</span>
        </h3>
      );
    }
    else if ((wallet_amount ?? 0) >= 15 && saved_card_count > 0) {
      //GREEN : €15  + VISA/MasterCard Logo
      return (
        <h3 onClick={() => {
          if (!users.user?.id) {
            dispatch(openLoginSignUpModal(true))
            return
          }
          setIsTopUp(true)
        }}
          style={{
            backgroundColor: getWalletBadgeBackground()
          }}
        >
          {saved_card_count > 0 && (<span className="cardIcon"><img src={getCardIcon(cardBrand)} alt="cardIcon" className={`${cardBrand === 'mastercard' ? 'mastercard' : 'CardImg'}`} /> {`  |  `} </span>)}
          <span className={'ml-2 walltetIcon'}><Wallet />€{wallet_amount}</span>
        </h3>
      );
    }
    else if ((wallet_amount ?? 0) >= 15 && saved_card_count === 0) {
      // GREEN : show wallet balance
      return (
        <h3 onClick={() => {
          if (!users.user?.id) {
            dispatch(openLoginSignUpModal(true))
            return
          }
          setIsTopUp(true)
        }}
          style={{
            backgroundColor: getWalletBadgeBackground()
          }}
        >
          <Wallet />€{wallet_amount}
        </h3>
      );
    }
    else if (wallet_amount === 0 && saved_card_count > 0) {
      //GREEN: show visa logo
      return (
        <h3 onClick={() => {
          if (!users.user?.id) {
            dispatch(openLoginSignUpModal(true))
            return
          }
          setIsTopUp(true)
        }}  style={{padding: '17px 4px', backgroundColor: getWalletBadgeBackground(), fontSize: "14px"}}

        >
          <img src={getCardIcon(cardBrand)} alt="cardIcon" className={`${cardBrand === 'mastercard' ? 'mastercard' : 'CardImg'} mr-8`} />{` `} Saved
        </h3>
      );
    }
    else {
      //GREEN: Show Wallet
      return (
        <h3 onClick={() => {
          if (!users.user?.id) {
              dispatch(openLoginSignUpModal(true))
              return
            }
          setIsTopUp(true)
        }}
          style={{
            backgroundColor: getWalletBadgeBackground()
          }}
        >
          <Wallet />€{wallet_amount}
        </h3>
      );
    }
  }

  return (
    <>
      <div className="body-wrapper">
      <ShippingFeeDescModal modalOpen={shippingFeeModalVisible} onCancel={handleCancel} shippingInfo={shippingFeeDescription} />
        {console.log("stripe", stripe)}
        {/* <BreadcrumbTop showDetails={users.currentShow}/> */}
        {/* <div className='Breadcrumpdiv'>
          <div className='show-container'>
            <Breadcrumb separator={<RightArrow />}>
              <Breadcrumb.Item>
                <a href="/shows">Show</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item> {users.currentShow?.title}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div> */}
        <div className="show-container">
          <div className="livevodeo-block">
            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 30}}>
              <Col className="gutter-row left_bidSection" sm={24} md={24} lg={8} >
                <div className="cardsPopup">
                  {stripe && elements && <>
                    {isShippingAddress && <ShippingAddress stripe={stripe} elements={elements} setIsShippingAddress={setIsShippingAddress} />}
                    {isTopUp && <TopUpModal stripe={stripe} amount={wallet_amount} elements={elements} isShowPage setIsTopUp={setIsTopUp} setIsCreditCard={setIsCreditCard} />}
                    {isCreditCard && <CreditCard stripe={stripe} elements={elements} isShowPage setIsTopUp={setIsTopUp} setIsCreditCard={setIsCreditCard} />}
                  </>}
                </div>
                <div className="leftTop">
                  {!isShippingAddress && !isTopUp && !isCreditCard &&<div className="showHeader"> <h6>
                    {users.currentShow?.title}</h6>
                   <div className="showBtns">
                   <Tooltip placement="top" title={(<span>{users.currentShow?.description}</span>)}>
                      <Button className="info_btn" icon={<Infoicon />}>Info</Button>
                    </Tooltip>
                    {users.user?.id !== users.currentShow?.user_id && <button className="reportBtn" onClick={onReportShowBtn}>
                      <Flag/>  Report
                    </button>}
                    </div>
                    </div>
                  }

                  <div className="bid-box">
                    <Row gutter={{xs: 8, sm: 16, md: 24, lg: 30}}>
                      {!isShippingAddress && !isTopUp && !isCreditCard && <Col sm={24} md={24} lg={24} >
                        <div className="bid-left">
                          {getWalletBadge()}

                          {/* NOTE: Commented until implemented */}
                          {/* <div className="wallet-icon">
                            <Button className="info_btn wallet_btn">
                              <Voucher />
                            </Button>
                          </div> */}
                        </div>
                      </Col>}
                      {!isShippingAddress && !isTopUp && !isCreditCard && <Col sm={24} md={24} lg={24} >
                        {item &&
                          (
                          <div className="bid-right">

                            {!is_biddable && (winner || !!winners?.length) && <div  className="bid-btn congratsBtn" >
                              {!is_instant ? `Congrats to ${winner} 🎉` : `Congratulations to all ${winners?.length} buyers🎉`}
                            </div>}
                            {is_biddable && <>
                              {/* disabled={is_winning} */}
                              <button type="button" className="bid-btn" onClick={onBidClick} >
                                {(is_winning && !is_instant) || is_waiting ?
                                  (<LoadingSpinner />) : (<>
                                    {is_instant ? `BUY FOR` : `BID FOR`}
                                    <span>€{price + (is_instant ? 0 : is_bidding ? 1 : 0)}</span>
                                </>)}
                              </button>
                              </>
                            }
                            {is_instant ?
                              (<div className="bid-fixed">
                                <p className="bid-fixed-span">{itemType === 'game' ? 'Game' : 'Fixed'}</p>
                                <span>{amount}</span>
                                <p>{amount > 1 ? 'items' : 'item'} left</p>
                              </div>) : (
                              <div className="bid-fixed">
                                <p className="bid-fixed-span">Auction</p>
                                <span>€{price}</span>
                                <p>{winner ? 'highest bid' : 'start price'}</p>
                              </div>
                              )}
                            {users.user?.id ? (
                            <div onClick={onShippingFeeClick} className="bid-shipping">
                              <div className="bid-shopping-inner">
                                <span className="infoIcon-text">
                                  <Infoicon /> Shipping fee{shippingAddressCount > 0 ? ':' : null}</span>
                                { shippingAddressCount > 0 ? <span className="show-price">€{shippingFee} per show</span> : null }
                                </div>
                            </div>
                            ) : null}
                          </div>
                          )
                        }
                      </Col>}
                      {!isShippingAddress && !isTopUp && !isCreditCard && <Col sm={24} md={24} lg={24} >
                        <div className="userfollow-list">
                          <div className="userfollow-list--left">
                            <div className="userfollow-list-profile" role="button" >
                              <img src={users?.currentShow?.profile_img_url||images.profiledummy}  alt=""  onClick={()=>{openInNewTab(`/${users.currentShow?.host}`)}}/>
                            </div>
                            <div className="userInfo">
                              <h4 role="button" onClick={()=>{openInNewTab(`/${users.currentShow?.host}`)}}> {users.currentShow?.host}
                                {users.currentShow?.host && <ProfileBadges user={users.currentShow} />}
                              </h4>
                              <p>{users.currentShow?.followed_by_ids?.length} followers</p>
                            </div>
                          </div>
                          <div className="userfollow-list--right">
                            {users.user?.id !== users.currentShow?.user_id && (buttonLoading ? <LoadingSpinner/> :<Button type="ghost" className="Follow" onClick={handleFollow}>{isFollowing ? `Following` : `+ Follow`}</Button>)}
                          </div>
                        </div>
                      </Col>}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row w100" sm={24} md={12} lg={8} >
                <div className="Livestreamingbox">
                  <div className="showBackground" style={{backgroundImage: 'url(' + users.currentShow?.picture_url + '), url(' + images.showPlaceholder + ')'}} />
                  {isShowOn && !isShowEnded ?
                    <Shownotstarted showDetails={users.currentShow} isConnecting={isConnecting} /> :
                    <Showended />
                  }
                  {isShowEnded ? <Showended /> : null}
                  {countDown.isCount && countDown.count > 0 ? <div className="counter">
                    {countDown.count}
                  </div> : null}
                  <div className="">
                    <div className="Livestreamingbox--top">
                      <div className="Livestreamingbox--top__left">
                        {/* <div className="layers">
                          <Layer></Layer>
                          <span>54/100</span>
                        </div> */}
                        <div className="layers">
                          <Eye />
                          <span>{userCount}</span>
                        </div>
                        {/* {
                          (userAudioTrack) && (<Button onClick={toggleAudio}>{ isAudioPlaying && userAudioTrack?.isPlaying ? 'Playing': 'Muted' }</Button>)
                        } */}
                      </div>
                      <div className="Livestreamingbox--top__right">
                        {
                          userAudioTrack && (
                            <button className={`mutebtn ${isAudioPlaying ? '' : 'unmute'}`} onClick={toggleAudio}>{isAudioPlaying ? <Unmute /> : <Mute />}</button>
                          )
                        }
                        {/* <div className="layers filled">
                          <Live />
                          <span>Live</span>
                        </div>
                      </div>
                    </div>

                    <div className="Livestreamingbox-bottom">
                      {/* <div className="Livestreamingbox-bottom__left">
                        <div className="avtar-profile">
                          <img src={images.Avtar} alt="" title="" />
                          <span className="avtar-profile--text">Levi77 <span>won!</span></span>
                        </div>
                      </div>
                      <div className="Livestreamingbox-bottom__right">
                        <div className="celebrate-icon">
                          <img src={images.Celebrate} alt="" title="" />
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  {role === "host" ? <div id="local-player" className="player"></div> : null}
                  {role === "audience" ? <div id="audience" className="audience-player"></div> : null}
                </div>
              </Col>
              <Col className="gutter-row order-three" sm={24} md={12} lg={8} >
                <Chat
                  setUserCount={setUserCount}
                  show={{currancy: "€"}}
                  navigate={navigate}
                  channel={params.id}
                  setCountDown={setCountDown}
                  setIsShowOn={setIsShowOn}
                  showDetails={{...users.currentShow}}
                  setIsConnecting={setIsConnecting}
                  isConnecting={isConnecting}
                  openDownloadModal={openDownloadModal}
                  setIsShowGiveawayModal={setIsShowGiveawayModal}
                  setLiveUsers={setGiveawayUser}
                  setIsShowEnded={setIsShowEnded}
                  destroyAgora={destroyAgora}
                  init={init}
                  isBiddable={isBiddable}
                  setItem={setItem}
                  setType={setItemType}
                  setAmount={setAmount}
                  setSavedCardCount={setSavedCardCount}
                  isBidding={isBidding}
                  isInstant={isInstant}
                  setPrice={setPrice}
                  setWalletAmount={setWalletAmount}
                  setShippingAddressCount={setShippingAddressCount}
                  setHasShippingAddress={setHasShippingAddress}
                  setHasPaymentMethod={setHasPaymentMethod}
                  setShippingAmount={setShippingAmount}
                  setIsToppedUpBefore={setIsToppedUpBefore}
                  isWinning={isWinning}
                  setLastPaymentStatus={setLastPaymentStatus}
                  ref={childRef}
                  setWinner={setWinner}
                  setWinners={setWinners}
                  isWaiting={isWaiting}
                  setCardBrand={setCardBrand}
                  setShippingFeeDescription={setShippingFeeDescription}
                  setShippingFee={setShippingFee}
                  setUpgradedShippingData={setUpgradedShippingData}
                  setUpgradeShippingPaymentStatus={setUpgradeShippingPaymentStatus}

                />
              </Col>
              {/* <Col className="gutter-row order-two" sm={2} md={2} lg={2} >

                <div className="sideBtns">
                  <button className="iconBtn"><Swap /></button>
                  <button className="iconBtn"><Upload /></button>
                  <button className="iconBtn"><Volume /></button>
                  <button className="iconBtn"><Discount /></button>
                </div>

              </Col> */}
            </Row>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {isShowGiveawayModal&&<GiveAway
        users={giveawayUser}
          io={io}
          // emit={this.emit}
          visible={isShowGiveawayModal}
          showModal={setIsShowGiveawayModal}
          // toggleModal={this.toggleGiveaway}
          show={users.currentShow}
          user={users.user}
          setUsers={setGiveawayUser}
          // isHost={this.isHost}
          />}
        {
          reportShowModal && <ReportUserModal isModalVisible={reportShowModal} isShowPage handleCancel={() => setReportShowModal(false)} onSubmit={onReportShow} />
        }
      <DeepLinkCheck visible={downloadModal} onOk={openDownloadModal} onCancel={openDownloadModal} />
    </>
  );
}

export default ShowWithPayment;

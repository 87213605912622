import React from "react";

function Note() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.27312 10.3384H4.1748" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.27312 7.38237H4.1748" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.12021 4.43315H4.1748" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.58523 1.00098C9.58523 1.00098 4.1642 1.0038 4.15573 1.0038C2.20679 1.01581 1 2.29815 1 4.25415V10.7478C1 12.7137 2.21597 14.001 4.18186 14.001C4.18186 14.001 9.60217 13.9989 9.61135 13.9989C11.5603 13.9869 12.7678 12.7038 12.7678 10.7478V4.25415C12.7678 2.28827 11.5511 1.00098 9.58523 1.00098Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
}

export default Note;
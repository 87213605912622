import React, {useEffect, useState} from 'react';
import {Button, Modal, Select, Menu, Dropdown} from 'antd'
import {images} from '../../../Assets/ImageConfig/images'
import {Close, Email, Phone} from '../../Icons'
import Navmenu from './NavMenu'
import PhoneModal from "../../Auth/UserAuth/Phone"
import EmailModal from "../../Auth/UserAuth/Email"
import GmailModal from "../../Auth/UserAuth/Gmail"
import AppleModal from "../../Auth/UserAuth/Apple"

import {useDispatch, useSelector} from "react-redux";
import Responsiveheader from './Responsiveheader'
// import { images } from '../../config/images'
import {guestUserSet, openLoginSignUpModal, createImplicitToken, fetchUserDetails, logOutUser, openDownloadAppModal} from "../../../store/Actions/users"
import {useNavigate} from "react-router-dom";
import {RootState} from "../../../store";
import './Header.scss';
import {notificationPermission} from '../../../Utils/brazePushNotification'
import DeepLinkCheck from '../../DeepLinkCheck/DeepLinkCheck';
import {matchRoutes, useLocation} from 'react-router';
import {auth_method_selected} from '../../../Utils/mParticle';
import {strings} from '../../../Utils/constants';
const {Option} = Select;
function Header(props: any) {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoginPopModal, setIsLoginPopModal] = useState(false);
  const [isRegisterPopModal, setIsRegisterPopModal] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [continueWithPhone, setContinueWithPhone] = useState(false);
  const [continueWithEmail, setContinueWithEmail] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false)
  const [userName, setUserName] = useState("");
  const [flow, setFlow] = useState("");
  const navigate = useNavigate();
  const location = useLocation()
  const routes = matchRoutes([{path: '/edit-profile'}, {path: '/my-profile'}, {path: '/account-settings'}, {path: '/account-settings/:tab'}], location.pathname)
  const isShowPage = matchRoutes([{path: '/show/:id'}, {path: '/chat'}], location.pathname);
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  useEffect(() => {
    // notificationPermission("guest")
    dispatch(createImplicitToken())
    dispatch(fetchUserDetails())
  }, [])

  useEffect(() => {
    if (users.loginSignUpModal) {
      showModal()
      // setDownloadModal(true)

    }
  }, [users.loginSignUpModal])

  useEffect(() => {
    if (users.downloadAppModal) {
      setDownloadModal(true)
      // dispatch((openLoginSignUpModal(false)))
    }
  }, [users.downloadAppModal])


  useEffect(() => {
    if (users.user.username) {
      notificationPermission(users.user.id)
      setUserName(users.user.username)
      if (!users?.user?.phone && localStorage.getItem('loggedInFrom') === 'Gmail') {
        showModal()
      }
    } else {
      setUserName("")
    }
  }, [users.user])
  const dispatch = useDispatch()
  const showModal = () => {
    setIsModalVisible(true);
    dispatch((openLoginSignUpModal(false)))
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsLoginPopModal(false);
    setContinueWithPhone(false);
    setContinueWithEmail(false);
    dispatch((openLoginSignUpModal(false)))
    setDownloadModal(false)
    // NOTE: if logged in with gmail and phone is not saved then do not remove
    if (localStorage.getItem('loggedInFrom') === 'Gmail' && !users.user?.phone) {
      return
    }
    localStorage.removeItem('loggedInFrom')
  };

  const handleCancel = () => {
    setIsRegisterPopModal(false)
    setIsModalVisible(false);
    setIsLoginPopModal(false);
    setContinueWithPhone(false)
    setContinueWithEmail(false)
    setIsRegister(false)
    dispatch((openLoginSignUpModal(false)))
    dispatch(openDownloadAppModal(false))
    setDownloadModal(false)
  };
  const onLoginClick = () => {
    setIsModalVisible(false)
    setIsLoginPopModal(true)
    setFlow("Login")
  }
  const onContinueWithPhone = () => {
    setIsModalVisible(false)
    setContinueWithPhone(true)
    setIsLoginPopModal(false)
    setIsRegisterPopModal(false)
    auth_method_selected(strings.trackTypePhone);
    localStorage.setItem('loggedInFrom', 'Phone')
  }
  const onContinueWithEmail = (signUp = false) => {
    if (signUp) {
      setIsRegisterPopModal(false)
      setIsRegister(true)
    }
    else {
      setContinueWithEmail(true)
    }
    auth_method_selected(strings.trackTypeEmail);
    setIsModalVisible(false)
    setIsLoginPopModal(false)
  }

  const onRegisterClick = () => {
    setIsLoginPopModal(false)
    setIsModalVisible(false)
    setIsRegisterPopModal(false)
    setIsRegisterPopModal(true)
    setFlow("Register")
  }

  const onLogoutClick = async () => {
    setUserName("")
    dispatch(guestUserSet(false))
    await dispatch(logOutUser())
    if (routes?.length) {
      navigate('/shows', {replace: true})
    }
    else {
      if (isShowPage?.length) {
        window.location.reload()
      }
    }
  }

  const menu = (
    <Menu>
      {users.user.username
        ? (
          <>
            <Menu.Item key="profile" onClick={() => {
              navigate(`/my-profile`);
            }}>
              My Profile
            </Menu.Item>
            <Menu.Item key="account-settings" onClick={() => {
              navigate(`/account-settings`);
            }}>
              Settings
            </Menu.Item>
            <Menu.Item key="logout" onClick={onLogoutClick}>
              Log Out
            </Menu.Item>
          </>
        )
        : <Menu.Item key="login" onClick={showModal}>Login</Menu.Item>
      }
    </Menu>
  );
  return (
    <div className="HeaderStyles">
      <div className="logo">
        <Button type="link" className='logo-link' onClick={() => {
          navigate('/shows')
        }}>
          <img src={images.Blogo} alt='logo' style={{width: 100}} />
        </Button>
        <div className="beta-badge">
          Beta
        </div>
      </div>
      <Navmenu />
      <div className='loginBtn'>
        {/* <a href='' className='notificationBell'>
          <Badge count={5} size="small" color='#45B26B'>
            <Bell />
          </Badge>
        </a> */}

        {
          users.isGuestUser || userName ? <Dropdown overlay={menu} trigger={['click']}>
            <Button type='primary' style={{borderRadius: "90px"}}>{userName || "Guest User"}</Button>
          </Dropdown> :
            <Button type='primary' onClick={showModal} style={{borderRadius:"90px"}}>Login</Button>
        }
        {/* <div className='headerProfile'>
            <di className="headerProfileImg">
              <img src={images.Avtar} />
            </di>
            <div className='username'>
              <span>Adam 007</span>
            </div>
          </div> */}

      </div>
      <Responsiveheader />
      {/* guest user modal */}
      <Modal title="Welcome to Gavel" className='themeModal loginModal' width={448} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} centered closeIcon={<Close />}>
        <div className='modal-body'>
          {/* <p>Set up your profile to join the GAVEL community! Or discover as a guest.</p> */}
          <div className='popuplogin-icon'>
            <img src={images.Blogo} alt="" />
          </div>
          <p className='text-center logintext'>Set up your very own profile! Be part of the GAVEL community and show your passion for collections.</p>
          <div className='modalbtns'>

            <GmailModal handleCancel={handleCancel} logout={onLogoutClick} continueWithPhone={continueWithPhone} handleOk={handleOk} flow={flow} isRegisterPopModal={isRegisterPopModal} setUserName={setUserName} />
            <AppleModal handleCancel={handleCancel} />
            {/* <button type='button' className='modalbtn filledbtn' onClick={() => onContinueWithEmail(false)}><Email /> Continue with Email</button> */}
            <button type='button' className='modalbtn whitebtn' onClick={onContinueWithPhone}><Phone /> Continue with Phone</button>

            {/* <button type='button' className='modalbtn transparentbtn' onClick={onLoginClick}>Login</button>
            <button type='button' className='modalbtn filledbtn' onClick={onRegisterClick}>Register</button> */}
            {/* <button type='button' className='modalbtn whitebtn' onClick={() => {
              dispatch(guestUserSet(true))
              dispatch((openLoginSignUpModal(false)))
              handleCancel()
            }}>Continue As Guest</button> */}
          </div>
        </div>
      </Modal>
      {/* login popup */}
      <Modal title="Welcome Gavel" className='themeModal' width={448} visible={isLoginPopModal} onOk={handleOk} onCancel={handleCancel} footer={false} centered closeIcon={<Close />}>
        <div className='modal-body'>
          <p>Please set up your profile</p>
          <div className='modalbtns'>

            <GmailModal handleCancel={handleCancel} />
            <AppleModal handleCancel={handleCancel} />
            {/* <button type='button' className='modalbtn filledbtn' onClick={() => onContinueWithEmail(false)}><Email /> Continue with Email</button> */}
            <button type='button' className='modalbtn whitebtn' onClick={onContinueWithPhone}><Phone /> Continue with Phone</button>
          </div>
        </div>
      </Modal>
      <DeepLinkCheck visible={downloadModal} onOk={handleOk} onCancel={handleCancel} />
      <Modal title="Welcome Gavel" className='themeModal' width={448} visible={isRegisterPopModal} onOk={handleOk} onCancel={handleCancel} footer={false} centered closeIcon={<Close />}>
        <div className='modal-body'>
          <p>Please set up your profile.</p>
          <div className='modalbtns'>

            <GmailModal handleCancel={handleCancel} />
            <AppleModal handleCancel={handleCancel} />
            {/* <button type='button' className='modalbtn filledbtn' onClick={() => onContinueWithEmail(true)}><Email /> Sign Up With Email</button> */}
            <button type='button' className='modalbtn whitebtn' onClick={onContinueWithPhone}><Phone /> Sign Up With Phone</button>
          </div>
        </div>
      </Modal>
      {continueWithPhone && <PhoneModal isFromGmail={false} logout={onLogoutClick} continueWithPhone={continueWithPhone} handleOk={handleOk} flow={flow} isRegisterPopModal={isRegisterPopModal} handleCancel={handleCancel} setUserName={setUserName} />}
      <EmailModal continueWithEmail={continueWithEmail} signUp={isRegister} handleOk={handleOk} handleCancel={handleCancel} />
    </div>
  )
}

export default Header

import React from "react";

function Phone() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.53174 9.99119C13.5208 13.9792 14.4258 9.36552 16.9656 11.9036C19.4143 14.3516 20.8216 14.842 17.7192 17.9435C17.3306 18.2558 14.8616 22.0131 6.1846 13.3385C-2.49348 4.6628 1.26158 2.19124 1.57397 1.80275C4.68387 -1.30736 5.16586 0.108182 7.61449 2.55613C10.1544 5.09529 5.54266 6.00321 9.53174 9.99119Z" fill="currentColor"/>
    </svg>
    

  );
}

export default Phone;
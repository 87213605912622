import React from "react";

function Show() {
  return (
    <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.63188 0.25H12.3893C15.215 0.25 17.1878 2.19721 17.1878 4.98773V13.0123C17.1878 15.8028 15.215 17.75 12.3893 17.75H5.63188C2.80619 17.75 0.833336 15.8028 0.833336 13.0123V4.98773C0.833336 2.19721 2.80619 0.25 5.63188 0.25ZM21.7843 3.02548C22.2965 2.76491 22.8973 2.79215 23.3862 3.1001C23.875 3.40687 24.1667 3.93986 24.1667 4.52261V13.4781C24.1667 14.062 23.875 14.5938 23.3862 14.9006C23.119 15.0676 22.8203 15.1529 22.5193 15.1529C22.2685 15.1529 22.0176 15.0937 21.7831 14.974L20.0553 14.1023C19.416 13.7778 19.0193 13.1263 19.0193 12.4026V5.59689C19.0193 4.87201 19.416 4.22058 20.0553 3.89841L21.7843 3.02548Z" fill="currentColor"/>
    </svg>
    


  );
}

export default Show;
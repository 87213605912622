export const images = {
    Avtar: require('../Images/Shows-img/avatar.png'),
    Celebrate: require('../Images/Shows-img/celebrate.png'),
    Logo: require('../Images/Shows-img/logo.png'),
    Appstore: require('../Images/Shows-img/appstore.png'),
    Playstore: require('../Images/Shows-img/playstore.png'),
    F1: require('../Images/Shows-img/f1.png'),
    Shownot: require('../Images/Shows-img/shownot-started.png'),
    ShowEnd: require('../Images/Shows-img/ShowEnd.png'),
    CoverImage: require('../Images/Shows-img/coverImage.png'),

    Chikorita: require('../Images/pokemon-animated/running-chikorita.gif'),

    Emaptyimg: require('../Images/Chat-img/empty-img.png'),
    Blockimg: require('../Images/Chat-img/blockimg.png'),
    Paymentimg: require('../Images/Chat-img/paymentimg.png'),

    DeleteIcon: require('../Images/Chat-img/DeleteIcon.png'),
    userPlus: require('../Images/Chat-img/userPlus.png'),

    Img1: require('../Images/Chat-img/img1.png'),
    Img2: require('../Images/Chat-img/img2.png'),
    Img3: require('../Images/Chat-img/img3.png'),
    Img4: require('../Images/Chat-img/img4.png'),

    gavellogo: require('../Images/Shows-img/gavel-logo-w.png'),
    appios: require('../Images/Shows-img/app-ios.png'),
    appandroid: require('../Images/Shows-img/app-android.png'),
    appgavel: require('../Images/Shows-img/app-gavel.png'),
    gavelsignet: require('../Images/Shows-img/gavel-signet-w.png'),
    bgcollectibles: require('../Images/Shows-img/bg-collectibles.jpeg'),

    profiledummy: require('../Images/Shows-img/dummyuser.png'),
    startchat: require('../Images/Shows-img/startchat.png'),


    aap1: require('../Images/appstoreheaser.png'),
    aap2: require('../Images/playstoreheader.png'),


    // birthday page images
    Blogo: require('../Images/bdayImg/b-logo-hq.png'),
    Bcelebration: require('../Images/bdayImg/celebration-img.png'),
    Bcake: require('../Images/bdayImg/cake.png'),
    watchcollection: require('../Images/bdayImg/watchcollection.png'),
    streamsell: require('../Images/bdayImg/streamsell.png'),
    featuredshow: require('../Images/bdayImg/featuredshow.png'),

    bservice1: require('../Images/bdayImg/b-service1.png'),
    bservice2: require('../Images/bdayImg/b-service2.png'),
    bservice3: require('../Images/bdayImg/b-service3.png'),

    botttomVector: require('../Images/bdayImg/botttomVector-hq.png'),
    Vector: require('../Images/bdayImg/Vector.png'),
    floating: require('../Images/bdayImg/floating-gavel-with-tcgs-hq.png'),
    alogo: require('../Images/bdayImg/appstore.png'),
    plogo: require('../Images/bdayImg/playstore.png'),


    userWatchList: require('../Images/Shows-img/user-watchlist.png'),
    userCreateShow: require('../Images/Shows-img/user-create-show.png'),

    Hammer: require('../Images/Seller/hammer2x.png'),
    bannerRight: require('../Images/Seller/bannerRight2x.png'),
    bannerLeftShape: require('../Images/Seller/bannerLeftShape2x.png'),

    Shap1: require('../Images/Seller/contentShape.png'),
    shap1B: require('../Images/Seller/shap1B.png'),
    calander: require('../Images/Seller/calander.png'),

    shap2: require('../Images/Seller/shap2.png'),
    shap2B: require('../Images/Seller/contentShape.png'),
    shap3B: require('../Images/Seller/contentShape.png'),
    shap4B: require('../Images/Seller/contentShape.png'),

    centershape2: require('../Images/Seller/centershape2.png'),
    centershape1: require('../Images/Seller/centershape1.png'),
    Amount: require('../Images/Seller/amount.png'),
    shap1M: require('../Images/Seller/mobileShape.png'),
    bannerBottom: require('../Images/Seller/bannerBottom.png'),
    bannerBottomMobile: require('../Images/Seller/mshape.png'),
    NoFollowers: require('../Images/Shows-img/nofollower.png'),
    NoFollowing: require('../Images/Shows-img/noFollowing.png'),
    NoMessageImg: require('../Images/Chat-img/NoMessageImg.png'),

    showPlaceholder: require('../Images/Shows-img/default_show.png'),
    Deleteuser: require('../Images/Shows-img/deleteuse.png'),

    LoginIocn: require('../Images/Shows-img/loginicon.png'),
    sellerLogo: require('../Images/Seller/Logo.png'),

    // payment img
    mastercard: require('../Images/Payment-img/mastercard.png'),
    visacard: require('../Images/Payment-img/visacard.png'),
    secureicon: require('../Images/Payment-img/secureicon.png'),
    ShieldDone: require('../Images/Payment-img/Shield Done.png'),
    otherpayment1: require('../Images/Payment-img/1.png'),
    otherpayment2: require('../Images/Payment-img/2.png'),
    otherpayment3: require('../Images/Payment-img/3.png'),
    otherpayment4: require('../Images/Payment-img/4.png'),
    otherpayment5: require('../Images/Payment-img/5.png'),
    giropaymodal: require('../Images/Payment-img/giropaymodal.png'),
    GpayTopup: require('../Images/Payment-img/GpayTopup.png'),
    giropayTopup: require('../Images/Payment-img/giropayTopup.png'),
    epsTopup: require('../Images/Payment-img/epsTopup.png'),
    paypalTopup: require('../Images/Payment-img/paypalTopup.png'),
    visa: require('../Images/Payment-img/Visa.png'),
    RightClick: require('../Images/Payment-img/RightClick.png'),
    infoicon: require('../Images/Payment-img/infoicon.png'),
    Unsuccesssfulpayment: require('../Images/Payment-img/Unsuccesssfulpayment.png'),
    Deletemodalimg: require('../Images/Payment-img/DeleteModalImg.png'),
    Noordersyet: require('../Images/Payment-img/Noordersyet.png'),
    Notransactionsyet: require('../Images/Payment-img/Notransactionsyet.png'),
    placeholderimg: require('../Images/Payment-img/placeholderimg.png'),
}

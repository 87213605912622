
import {Button, Modal} from 'antd';
import Close from '../../../Icons/Close/Close';
import './ShippingSizePopupStyle.scss';

const ShippingSizeCostModal = (props: any) => {
    console.log('ShippingSizeCostModal ', props)

    return (
        <Modal title={props?.shippingUpgradedData?.title} className='themeModal topicsModal shippingFeeModal' width={600} visible={props.modalOpen} onOk={props.onOk} onCancel={props.onCancel} footer={false} centered closeIcon={<Close />}>
            <div>
                <div className="ship-size-main">
                    <span>{props?.shippingUpgradedData?.subTitle}</span>
                </div>
                <Button className="ant-btn ant-btn-primary btn-full large" onClick={props?.shippingUpgradePayment}>Pay €{props?.shippingUpgradedData?.price}</Button>
            </div>
        </Modal>
    )
}

export default ShippingSizeCostModal;

import React from "react";

function Cancelpayment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#EC000F"
        fillRule="evenodd"
        d="M7 0a7 7 0 110 14A7 7 0 017 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M7 8.307a.655.655 0 01-.655-.655V4.14a.655.655 0 011.31 0v3.512A.655.655 0 017 8.307zM6.978 10.515a.633.633 0 110-1.267h.043a.633.633 0 110 1.267h-.043z"
      ></path>
    </svg>
  );
}

export default Cancelpayment;
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, message, Modal} from 'antd';
import { useParams, useNavigate } from "react-router-dom";
import {RootState} from '../../../../store';
import { Cart, Chat, Close, Envelope, Faq, Note, Payment,PrivacyIcon, Profile, Shiporder, Watch } from '../../../Icons'
import {images} from '../../../../Assets/ImageConfig/images';

import packageJson from '../../../../../package.json'

import './Settingsmenu.scss'

import Loader from '../../../CommonComponents/Loader';
import Settingform from '../SettingForm';
import PaymentPage from '../Payment';
import Helpemail from '../HelpEmail';
import Termsconditions from '../TermsConditions';
import Transactionhistory from '../TransactionHistory';
import Orderhistory from '../OrderHistory';
import Privacy from '../Privacy'
import {gavelRetool} from '../../../../Utils/constants';

function Settingsmenu(props: any) {


  const [loading, setLoading] = useState(false)


  const { tab } = useParams();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const {user} = users

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCancelClick = () => {
    handleCancel()
  }

  const onOkClick = () => {
    handleOk()
    props.onDeleteUser()
  }

  return (
    <>
      {/* {loading && <Loader />} */}
      <div className="Settingsmenu">
        <div className='menuListing'>
          <h5>Profile</h5>
          <ul>
            <li className={tab === 'setting' || !tab ? 'm-active' : ''}>
              <a onClick={() => {navigate('/account-settings/setting')}} className={tab === 'setting' || !tab ? 'active' : ''}><Profile />  Account & Personal Info</a>
              <div className='mobile-settings'>
                <Settingform/>
              </div>
            </li>
            <li className={tab === 'payment-method' ? 'm-active' : ''}>
              <a onClick={() => {navigate('/account-settings/payment-method')}} className={tab === 'payment-method' ? 'active' : ''}><Payment /> Wallet & Addresses</a>
              <div className='mobile-settings'>
                <PaymentPage/>
              </div>
            </li>
            <li className={tab === 'transaction-history' ? 'm-active' : ''}>
              <a onClick={() => {navigate('/account-settings/transaction-history')}} className={tab === 'transaction-history' ? 'active' : ''}><Watch /> Transaction History</a>
              <div className='mobile-settings'>
                <Transactionhistory/>
              </div>
            </li>
            <li className={tab === 'order-history' ? 'm-active' : ''}>
              <a onClick={() => {navigate('/account-settings/order-history')}} className={tab === 'order-history' ? 'active' : ''}><Cart /> Order History</a>
              <div className='mobile-settings'>
                <Orderhistory/>
              </div>
            </li>
            {users?.user?.host&&<li className={tab === 'list-ship-orders' ? 'm-active' : ''}>
              <a href={`${gavelRetool}phone=${users?.user?.phone||''}&user_id=${users?.user?.id}&username=${users?.user?.username}&uuid=${users?.user?.uuid}`} target="_blank" className={tab === 'list-ship-orders' ? 'active' : ''}><Shiporder /> List & Ship Orders</a>
            </li>}
          </ul>
        </div>
        <div className='menuListing'>
          <h5>Help</h5>
          <ul>
            <li className={tab === 'email' ? 'm-active' : ''}> <a onClick={() => {navigate('/account-settings/email')}} className={tab === 'email' ? 'active' : ''}><Envelope /> Email</a>
              <div className='mobile-settings'> <Helpemail/></div>
            </li>
            <li> <a onClick={props?.onStartSupportChat} className={tab === 'support-chat' ? 'active' : ''}><Chat /> Start Support Chat</a> </li>
          </ul>
        </div>
        <div className='menuListing'>
          <h5>Legal</h5>
          <ul>
            <li className={tab === 'privacy-policy' ? 'm-active' : ''}>
              <a onClick={() => {navigate('/account-settings/privacy-policy')}} className={tab === 'privacy-policy' ? 'active' : ''}><PrivacyIcon /> Privacy Policy</a>
               <div className='mobile-settings'>
                <Privacy/>
               </div>
            </li>
            <li className={tab === 'terms-condition' ? 'm-active' : ''}> <a onClick={() => {navigate('/account-settings/terms-condition')}} className={tab === 'terms-condition' ? 'active' : ''} ><Note /> Terms & Conditions

            </a>
              <div className='mobile-settings'>
                  <Termsconditions/>
               </div>
            </li>
            {/* <li> <a onClick={() => {navigate('/account-settings/faqs')}} className={tab === 'faqs' ? 'active' : ''}><Faq /> FAQs</a> </li> */}
          </ul>
        </div>
        <div className='deleteUser'>
          <p><span>User ID : </span> {user?.id}</p>
          <p><span>{user?.phone ? 'Phone' : 'Email'} : </span> {user?.phone ? user?.phone : user?.email}</p>
          <p><span>Web Version : </span> {packageJson.version}</p>
          <Button type="link" onClick={showModal} className='deleteUser'>Delete Profile?</Button>

        </div>
      </div>

      {/* delete user modal */}
      <Modal width={'638px'} title="" className='themeModal' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} centered closeIcon={<Close />}>
        <div className='modal-body'>
          <div className='deleteUserModal'>
            <div className='delteuserImg'>
              <img src={images.Deleteuser} alt="" />
            </div>
            <div className='deleteuserContent'>
              <h5>Delete Profile</h5>
              <p>Are you sure to delete your profile?</p>
              <div className='deleteuserBtns'>
                <Button type='ghost' onClick={onCancelClick} >No</Button>
                <Button type='ghost' onClick={onOkClick} >Yes</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Settingsmenu

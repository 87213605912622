import React from "react";

function PrivacyIcon() {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.33249 14.001C6.33249 14.001 11.6218 12.3994 11.6218 7.98427C11.6218 3.56843 11.8135 3.22372 11.3895 2.79902C10.9648 2.37433 7.02606 1.00098 6.33249 1.00098C5.63891 1.00098 1.70017 2.37433 1.27616 2.79902C0.851472 3.22372 1.04314 3.56843 1.04314 7.98427C1.04314 12.3994 6.33249 14.001 6.33249 14.001Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.54102 7.2915L5.84543 8.59798L8.53285 5.90918" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


  );
}

export default PrivacyIcon;
import React from "react";

function Ribbons() {
  return (
    <span className="verificationIcon">
      <svg width="15" height="17" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 3.51642C1 3.33484 1.09845 3.16753 1.25718 3.07935L4.75718 1.1349C4.90819 1.05101 5.09181 1.05101 5.24282 1.1349L8.74282 3.07935C8.90155 3.16753 9 3.33484 9 3.51642V5.83576C9 7.6516 8.01554 9.3247 6.42821 10.2065L5.24282 10.8651C5.09181 10.949 4.90819 10.949 4.75718 10.8651L3.57179 10.2065C1.98446 9.3247 1 7.6516 1 5.83576V3.51642Z" stroke="#0CFDE4" stroke-width="0.75" />
        <path d="M3.75 6L4.75 7L6.75 5" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
  );
}

export default Ribbons;
/* eslint-disable react/jsx-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table, message, Button } from 'antd';
import './Orderhistory.scss'
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '../../../../store';
import {fetchOrderHistory, orderHistoryAction} from '../../../../store/Actions/users';
import moment from 'moment';
import OrderDetailsModal from './OrderDetailsModal';
import LoadingSpinner from '../../../CommonComponents/LoadingSpinner';
import Loader from '../../../CommonComponents/Loader';
import EmptyOrderHistory from './EmptyOrderHistory';
import {images} from '../../../../Assets/ImageConfig/images';
import {displayErrorToast} from '../../../../Utils/helper';



function Orderhistory() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const users = useSelector(
        (state: RootState) => state?.usersReducer
    );

    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(1);
    const [ordersList, setOrdersList] = useState<any>([])

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<any>(null)

    const observer = useRef<any>()

    const fetchMore = useCallback(() => {
        setPage(page + 1);
    }, [page]);

    const lastCardElementRef = useCallback(
        (node) => {

        if (loading) return;

        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
            setTimeout(() => {

                fetchMore()
            }, 2000);
            }
        });
        if (node) observer.current.observe(node);
        },
        [loading]
    );

    async function getOrderHistory() {
        try {
            if (page <= 1 || (page > 1 && users.orderHistory?.totalCount > ordersList.length)) {
                setLoading(true)

                await dispatch(fetchOrderHistory(page))
            }
            } catch (error: any) {
                console.log(error);
                if (error?.response?.data?.message) displayErrorToast(error.response?.data?.message)
            } finally {
                setLoading(false)
        }
    }

    useEffect(() => {
        setPage(0)
    }, [users.user?.id])


    useEffect(() => {
        getOrderHistory()
    }, [page])

    useEffect(() => {
        setOrdersList([...users.orderHistory?.orders ?? []])
    }, [users.orderHistory])

    useEffect(() => {
      return () => {
        dispatch(orderHistoryAction([], 'RESET'))
      }
    }, [])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setSelectedOrder(null)
        setIsModalVisible(false);
    };

    const onRetryPayment = () => {
        navigate('/account-settings/payment-method')
    }

    const data = ordersList?.map((order: any, index: number) => {
        return {
            key: order?.id,
            Orders: (
                <div className='orderProfile' ref={ordersList.length - 1 === index ? lastCardElementRef : null}>
                    <div className='orderImg'>
                        <img src={order?.showCover ?? images.placeholderimg} alt=""
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null
                                currentTarget.src = images.placeholderimg
                            }}
                        />
                    </div>
                    <div className='ordername'>
                        <p>{order?.showTitle}</p>
                        <span>@{order?.hostName}</span>
                        {order?.paymentStatus === 'failed' ? (
                            <span className='Payment_failed'>Payment failed</span>) : (
                            <span className='Payment_success'>{order?.paymentStatus}</span>
                        )}
                    </div>
                </div>
            ),
            Items: `${order?.sellsCount} ${order?.sellsCount > 1 ? 'Items': 'Item'}`,
            Date: `${moment(order?.createdAt).locale('en').format('DD-MMM-YY')}`,
            Amount: (
                <div className='Retry_payment_Wrapper'>
                    <span className='amountSpan white'>€{order?.totalAmount}</span>
                    {order?.paymentStatus === 'failed' && <Button type='primary' className='w100 Retry_payment' onClick={onRetryPayment}>Retry  payment</Button>}
                </div>
            ),
        }
    });

    const displayOrderDetailsModal = (rowIndex: any) => {
        setSelectedOrder(ordersList[rowIndex])
        showModal()
    };

    const columns = [
        {
            title: 'Orders',
            dataIndex: 'Orders',
            onCell: (record: any, rowIndex: any) => {
                return {
                    onClick: () => {
                        displayOrderDetailsModal(rowIndex)
                    }
                }
            }
        },
        {
            title: 'Items',
            dataIndex: 'Items',
            onCell: (record: any, rowIndex: any) => {
                return {
                    onClick: () => {
                        displayOrderDetailsModal(rowIndex)
                    }
                }
            }
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            onCell: (record: any, rowIndex: any) => {
                return {
                    onClick: () => {
                        displayOrderDetailsModal(rowIndex)
                    }
                }
            }
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
        },
    ];

    return (
        <>
            {data?.length > 0 ? (
            <div className='OrderhistoryStyle'>
                <h3>Order History</h3>
                <Table columns={columns} dataSource={data} pagination={false} />

                {loading && <LoadingSpinner />}


            </div>
            ): (
                <>
                {loading && <Loader />}
                <EmptyOrderHistory />
            </>
        )}
        {/* Order History modal */}
        {isModalVisible && <OrderDetailsModal order={selectedOrder} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />}

        </>
    );
}

export default Orderhistory

export const termsSections = [
    {
      title: `SECTION 1 - USAGE TERMS`,
      text: [
        `By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.`,
  
        `You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).`,
  
        `You must not transmit any worms or viruses or any code of a destructive nature.`,
        `A breach or violation of any of the Terms will result in an immediate termination of your Services and may cause demage for which you have to pay.`
      ]
    },
    {
      title: `SECTION 2 - GENERAL CONDITIONS`,
      text: [
        `We reserve the right to refuse service to anyone for any reason at any time.`,
  
        `You understand that your content (not including payment information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.`,
  
        `You agree not to reproduce, duplicate, copy, or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.`,
  
        `The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.`
      ]
    },
    {
      title: `SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION`,
      text: [
        `We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.`,
  
        `This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.`
      ]
    },
    {
      title: `SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES`,
      text: [
        `Prices for our products are highly dynamic and subject to change without notice.`,
  
        `We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.`,
        
        `We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.`
      ]
    },
    {
      title: `SECTION 5 - PRODUCTS OR SERVICES`,
      text: [
        `Certain products or services may be available exclusively through the website or app. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.`,
  
        `We and our partners have made every effort to display as accurately as possible the colors, conditions of the products that appear at the app. We cannot guarantee that your computer monitor's display of any color will be accurate or that your internet connection is good enough to have a clear view of every detail.`,
        
        `We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.`,
        
        `We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.`
      ]
    },
    {
      title: `SECTION 6 - ACCURACY OF PAYMENT AND ACCOUNT INFORMATION`,
      text: [
        `We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities or types purchased or able to purchase per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same email address, or the same payment details, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers or distributors.`,
  
        `You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.`,
        
        `For more detail, please review our Returns Policy.`
      ]
    },
    {
      title: `SECTION 7 - MARKETPLACE, AUCTION SHOWS, AND TRANSACTIONS`,
      text: [
        `Sellers can host a live-streaming auction on the Gavel platform (a "Show") and adversise it together with Gavel, to motivate users to join the Show at its timeframe. When a user enters a Show for the first time, the Seller welcomes the user and explains all functionalities verbally. If there are unclarities or ambiguities, it is the user's obligation to ask questions by using the chat or push-to-talk functionalities, before buying or bidding. Most users quickly understand the process of buying and bidding for Items. However, you are not allowed to buy or bid, in case you did not understand the consequences fully.`,
  
        `When you place your binding offer by clicking the confirmation button saying "Buy" or "Bid" (a "Offer"), a countdown visualizes a certain amount of time (e.g. 10 seconds) available for other users to place their Offers. If there is no higher Offer by another user within this timespan, your Offer wins, which is visualized by the word "Sold" and a message in the Chat mentioning the Item's title, the highest price from the winning Offer and the user's name, who placed the winning Offer.`,
        
        `A Transaction occurs when a Listing Price and an Offer align and the Offer wins, as it has the highest price for the certain Item. Upon a Transaction, Sellers are responsible to ship the Item in accordance with Gavel's shipping instructions. Should, for any reason, the cost to ship an Item exceed the paid shipping price, Gavel shall be responsible for any additional payment necessary to ship the Item. Failure to abide by shipping instructions may, at the sole discretion of Gavel, result in penalties.`,
        
        `The Listing Price is shown in real-time on all users' devices simultaneously, assuming that the users' internet connection is stable and capable. We are not responsible for unwanted Transactions due to connection lags. We want to highlight, that the video livestreaming and the bidding/buying functionality are decoupled; and that bidding logic is exclusively managed on the server (single-source-of-truth) even an user's device gets disconnected or face connection lags; and that the price for an Offer is defined by the device and not the server, so you can only place an Offers for a price, you see on your device, and if the Listing Price is already higher on the server-side, your Offer will not be accepted and you see a chat message describing why.`,
        
        `When you place an Offer, you are responsible for the completion of payment of the Transaction amount, including all additional fees and taxes. Upon a Transaction, you authorizes Gavel to charge your Payment Account in case you submitted it before. You are responsible for updating Payment Account and Billing address information as necessary to ensure sufficient funds and accurate information that will allow the satisfaction of payment obligations arising from a Transaction.`,
        
        `Sellers are responsible for the accuracy of an Item's description and presentation during the Show. Sellers are required to ensure that the Item matches exactly the what is displayed on the App, and that the described condition of the Item is a true and accurate description of the condition of the Item. Any inaccuracy, whether it be the description, condition or authenticity of an Item, may, at the sole discretion of Gavel, result in penalties.`
      ]
    },
    {
      title: `SECTION 8 - PAYMENTS`,
      text: [
        `You shall pay within 7 days for Items you bought.`,
  
        `Your unpaid Items/Transactions are listed in the app by clicking the cart icon in the main navigation.`,
        
        `You can pay by credit card (Stripe), Paypal or bank transfer. If you have troubles with the payment functionality within the app, we can send you payment details by email. Please send us a request to [accounting@gavel.com](mailto:accounting@letsgavel.com).`,
        
       `The following shall apply if you enter payment information for automated payment ("Payment Account"):`,
        
        `(a) You authorize Gavel and/or any other company that bills for products or services, or acts as billing agent for Gavel ("Billing Agent") to charge and/or place a hold on your Account Balance for any charges or fees related to your use of the App.(b) You authorize Gavel and/or Billing Agent to charge and/or place a hold on your Payment Account for any charges related to your use of the App.(c) You authorize the party that issued your Payment Account to pay any amounts described herein without requiring a signed receipt, and you agree that your acceptance of these Terms is authorization to the issuer to pay all such amounts.(d) You authorize Gavel and/or its Billing Agent to continue to attempt to charge your Payment Account all amounts described herein, or any portion thereof, until such amounts are paid in full.`,
        
        `If the Payment Account on file is declined or otherwise rejected for any fee or charge, the User shall have 7 days to provide updated information and payment. Failure to cure the within the 7-day grace period may, at Gavel's sole discretion, result in termination of your Account without a refund. If a User wishes to reactivate their Account after deactivation from failure to pay a fee, they will be required to satisfy any debts owed to another User or to Gavel before a new Account can be created.`
      ]
    },
    {
      title: `SECTION 9 – AFTER BUYING AN ITEM`,
      text: [
        `By pressing the confirmation button saying "Buy" or "Bid" you place a binding Offer and if you are the highest bidder after the countdown finishes, you bought the Item at the price shown above or on the button, when you clicked on it. According to § 433 Abs. 2 BGB (in Germany) we and the Seller have the right to get paid and the obligation to ship your products as soon as the payment is confirmed.`,
  
        `If the Sellers is a private person (Privatverkauf) then there is no right of withdrawal.`,
        
        `If you decided to unseal the Item (Entsiegelung) by telling the Seller to open the package (commonly called as "card breaks" by the community), there is no right of withdrawal and return (§ 312g Abs. 2 BGB in Germany). Sealed card packages have a highly volatile price and value due to emotional dynamics, curiosity, and tensions during a Show, which is significantly different after unsealing or breaking or opening the card package. Every user who attends a "card breaks" shall consider this effect.`,
        
        `If a return is applicable, you have to pay the costs of the return.`,
        
        `You can get a full refund on an item at no cost to you if:`,
        
        `(a) The item was damaged in shipping(b) The item was clearly sent with damage that does not match listing photos(c) The incorrect item was sent to you(d) If the item does not match the condition described(e) The item is a counterfeit in some way`,
        
        `If you like to get a refund please contact us at [help@letsgavel.com](mailto:help@letsgavel.com) within 2 days of receiving the item. We may also ask that you return the item to us.`,
        
        `If you like to withdraw or cancel your Transaction (Kaufvertrag) please contact us at at [help@letsgavel.com](mailto:help@letsgavel.com) within 7 days of after the Transaction occurs.`
      ]
    },
    {
      title: `SECTION 9 - THIRD-PARTY LINKS`,
      text: [
        `Certain content, products and services available via our Service may include materials from third-parties.`,
  
        `Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.`,
        
        `We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.`
      ]
    },
    {
      title: `SECTION 10 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS`,
      text: [
        `If, at our request, you send certain specific submissions or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments' or 'chat'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.`,
  
        `We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.`,
        
        `You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.`
      ]
    },
    {
      title: `SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS`,
      text: [
        `Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).`,
  
        `We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.`
      ]
    },
    {
      title: `SECTION 12 - PROHIBITED USES`,
      text: [
        `In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or app or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.`
      ]
    },
    {
      title: `SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY`,
      text: [
        `We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.`,
  
        `We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.`,
        
        `You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.`,
        
        `You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.`,
        
        `In no case shall Gavel, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.`
      ]
    },
    {
      title: `SECTION 14 - INDEMNIFICATION`,
      text: [
        `You agree to indemnify, defend and hold harmless Gavel and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.`
      ]
    },
    {
      title: `SECTION 15 - SEVERABILITY`,
      text: [
        `In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.`
      ]
    },
    {
      title: `SECTION 16 - TERMINATION`,
      text: [
        `The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.`,
  
        `These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.`,
        
        `If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).`
      ]
    },
    {
      title: `SECTION 17 - ENTIRE AGREEMENT`,
      text: [
        `The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.`,
  
        `These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).`,
        
        `Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.`
      ]
    },
    {
      title: `SECTION 18 - GOVERNING LAW`,
      text: [
        `These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of the Republic of Austria and shall excluding private international law.`
      ]
    },
    {
      title: `SECTION 19 - CHANGES TO TERMS OF SERVICE`,
      text: [
        `You can review the most current version of the Terms of Service at any time at this page.`,
  
        `We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.`
      ]
    },
  ]

  
export const tearms = [
    `This website and the Gavel app is operated by Gavel. Throughout the site, the terms “we”, “us” and “our” refer to Gavel. Gavel offers this website and the Gavel app, including all information, tools and services available to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.`,
  
  `We will refer to a user who creates an account on the Gavel website or app as a "User". In some instances when describing interactions between Users we will differentiate "selling" Users who provide items in the app to other users, often by hosting a live-streaming show (a "Seller").`,
  
  `By visiting our site or app and/or purchasing something from us or one of our users or Sellers, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including our privacy policy, available on this web site. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.`,
  
  `Gavel is a platform accessible via website and app (mobile applications) that connects users with users and users and Sellers of certain products (an "Item"), that can result in a purchase of an Item (a "Transaction"), which is a contract of purchase / Kaufvertrag, which all its obligations and rights (§ 433 BGB in Germany).`,
  
  `Please read these Terms of Service carefully before accessing or using our website or our app. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.`,
  
  `Any new features or offers which are added to the current website and app shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.`
  ]
  
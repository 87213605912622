import React from 'react';
import Accountsettings from "../../Components/AccountSettings"
import {Helmet} from "react-helmet";

const AccountSettings = () => {


    return (
        <>
            <Helmet>
                <title>GAVEL - Setings</title>
                <meta property="og:title" content="GAVEL - Live shows for trading cards and much more" />
                <meta property="title" content="GAVEL - Live shows for trading cards and much more" />
                <meta property="og:description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
                <meta property="description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
                <meta property="og:url" content="https://letsgavel.com" />
                <meta property="og:image" content="https://i.ibb.co/1KqZpJ7/Meta-Image-EN.png" />
                <link rel="canonical" href="https://letsgavel.com" />
            </Helmet>
            <Accountsettings />
        </>
    );
}

export default AccountSettings;
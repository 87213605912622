import React from 'react';
import Hostpublic from "../../Components/Profile/HostProfile"

const HostPage = () => {


    return (
       <>
       <Hostpublic />
       </>
    );
}

export default HostPage;
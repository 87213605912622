import { Button, Modal } from 'antd'
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTopics } from '../../../../store/Actions/showDashboard';
import { RootState } from '../../../../store/index';
import { updateUserTopics } from '../../../../store/Actions/users';
import Loader from '../../../CommonComponents/Loader';
import Close from '../../../Icons/Close/Close';

const TopicsModal = (props:any) => {

    const dispatch = useDispatch()

    const [userTopics, setuserTopics] = useState<any>([])
    const [pageNum, setPageNum] = useState(1);
    const [loading, setLoading] = useState(false)
    const [isLanguageSelected, setLanguageSelected] = useState(false)
    const [isInterestSelected, setInterestSelected] = useState(false)

    const observer = useRef<any>()

    const {topics} = useSelector(
      (state: RootState) => state?.showDashboard
    );
    const {user} = useSelector(
      (state: RootState) => state?.usersReducer
    );

    const lastCardElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting  && topics?.categoryTopics.length < topics.totalCount) {
          setPageNum((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

    useEffect(() => {
      if(!loading && _.isEmpty(topics?.categoryTopics))
        fetchTopics(pageNum)
    },[])

    useEffect(() => {
      if(!loading && pageNum >1)
        fetchTopics(pageNum)
    },[pageNum])

    const fetchTopics = async (pageNum: number) => {
      setLoading(true)
        try{
          await dispatch(getTopics(pageNum))
        }catch(e){
          console.log(e);
        }
        finally{
          setLoading(false)
        }
    }

    useEffect(() => {
      if (user?.userTopics?.length)
        setuserTopics([...user?.userTopics?.map((topic: any) => {
          updateCategorySelection(topic.categoryId, true)
          return {topicId: topic?.topicId, categoryId: topic?.categoryId}
        })] ?? null);

    }, [user])

  const updateCategorySelection = (categoryId: number, selection: boolean) => {
    switch (categoryId) {
      case 1:
        setLanguageSelected(selection)
        break;

      case 2:
      case 3:
      case 4:
        setInterestSelected(selection)
        break;

      default:
        break;
    }
  };

  const hadleTopicClick = async (topic: any) => {

    if (userTopics?.find((tempTopic: any) => tempTopic?.topicId === topic?.id)) {
      const updatedTopics = userTopics.filter((tempTopic: any) => tempTopic?.topicId !== topic.id)

      if (topic?.categoryId === 1) {
        const getLanguageCount = updatedTopics?.filter((temp: any) => temp?.categoryId === 1)?.length
        updateCategorySelection(topic.categoryId, getLanguageCount > 0)
      }
      else {
        const otherInterestCount = updatedTopics?.filter((temp: any) => temp?.categoryId !== 1)?.length
        updateCategorySelection(topic.categoryId, otherInterestCount > 0)
      }

      setuserTopics([...updatedTopics])
    }
    else {
      updateCategorySelection(topic.categoryId, true)
      setuserTopics([...userTopics, {topicId: topic?.id, categoryId: topic?.categoryId}])
    }
  }

    const handleTopicsUpdate = async () => {

      setLoading(true)
      try{
        await dispatch(updateUserTopics(userTopics?.map((topic: any) => topic?.topicId)))
      }catch(e){
        console.log(e);
      }
      finally{
        setLoading(false)
        props.onOk()
      }
    }

  const isTopicSelected = (topic: any) => {
    return userTopics?.findIndex((tempTopic: any) => tempTopic?.topicId === topic?.id) !== -1
  }

  return (
    <Modal title="Select Interests" className='themeModal topicsModal' width={550} visible={props.visible} onOk={props.onOk} onCancel={props.onCancel} footer={false} centered closeIcon={<Close />} >
    <div className='modal-body'>
      {loading && <Loader />}
        {topics?.categoryTopics?.map((category: any, i: number) => {
          const isLastElement = topics.categoryTopics.length === i + 1;
          return (
          <>
          {isLastElement ? (
          <div className='topics-box' ref={lastCardElementRef}>
              <p className='bold white'>{category?.name}</p>
              <div className='checbox-group'>
              {category?.topics?.map((topic: any, i:number) => {
                return (
                  <label className="topicsList" key={i}>
                      <input checked={isTopicSelected(topic)} onChange={() => hadleTopicClick(topic)} type="checkbox" name="options"  />
                      <div className='topic-btn'>{topic?.name}</div>
                    </label>
              )})}
              </div>
            </div>
          ):(
            <div className='topics-box'>
              <p className='bold white'>{category?.name}</p>
              <div className='checbox-group'>
              {category?.topics?.map((topic: any, i:number) => {
                return (
                    <label className="topicsList" key={i}>
                      <input checked={isTopicSelected(topic)} onChange={() => hadleTopicClick(topic)} type="checkbox" name="options"  />
                      <div className='topic-btn'>{topic?.name}</div>
                    </label>
              )})}
              </div>
            </div>
            )}
            </>
            )
        })}
        {!isLanguageSelected || !isInterestSelected ? (<p className='bold white'>Please pick one language and one interest*</p>) : null}
      <Button type='primary' onClick={handleTopicsUpdate} disabled={!isLanguageSelected || !isInterestSelected} className='btn-full large'>Save and Continue</Button>
    </div>
  </Modal>
  )
}

export default TopicsModal
import React from "react";

function Payment() {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4444 1.00098H2.55556C1.69645 1.00098 1 1.71732 1 2.60098V10.601C1 11.4846 1.69645 12.201 2.55556 12.201H13.4444C14.3036 12.201 15 11.4846 15 10.601V2.60098C15 1.71732 14.3036 1.00098 13.4444 1.00098Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 5.80078H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


  );
}

export default Payment;
import React from 'react'
import './ProfileBadge.scss'

const ProfileBadge = () => {
    return (
        <span className='verificationIcon'>
            <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55795 10.6064C4.71862 10.4458 4.71862 10.1853 4.55795 10.0246C4.39727 9.8639 4.13677 9.8639 3.97609 10.0246L4.55795 10.6064ZM2.42447 11.5762C2.2638 11.7369 2.2638 11.9974 2.42447 12.1581C2.58515 12.3187 2.84565 12.3187 3.00633 12.1581L2.42447 11.5762ZM6.82719 11.9447L7.23449 11.8865L7.23449 11.8865L6.82719 11.9447ZM9.6589 10.044L10.0662 9.98578L10.0662 9.98578L9.6589 10.044ZM2.63781 7.75534L2.69599 7.34804L2.69599 7.34804L2.63781 7.75534ZM4.53854 4.92364L4.48036 5.33094L4.48036 5.33094L4.53854 4.92364ZM3.97609 10.0246L2.42447 11.5762L3.00633 12.1581L4.55795 10.6064L3.97609 10.0246ZM9.21281 10.2185L7.46723 11.9641L8.04909 12.546L9.79466 10.8004L9.21281 10.2185ZM7.23449 11.8865L7.00175 10.2573L6.18715 10.3737L6.41989 12.0029L7.23449 11.8865ZM9.09644 9.01602L9.2516 10.1022L10.0662 9.98578L9.91104 8.89965L9.09644 9.01602ZM7.46723 11.9641C7.38732 12.044 7.25047 11.9984 7.23449 11.8865L6.41989 12.0029C6.53177 12.786 7.4897 13.1053 8.04909 12.546L7.46723 11.9641ZM9.79466 10.8004C10.0083 10.5867 10.1089 10.2849 10.0662 9.98578L9.2516 10.1022C9.2577 10.1449 9.24333 10.188 9.21281 10.2185L9.79466 10.8004ZM3.78213 4.78787L2.03655 6.53344L2.61841 7.1153L4.36398 5.36973L3.78213 4.78787ZM2.57962 8.16264L4.20882 8.39539L4.32519 7.58079L2.69599 7.34804L2.57962 8.16264ZM5.68286 4.6715L4.59673 4.51634L4.48036 5.33094L5.56649 5.4861L5.68286 4.6715ZM2.03655 6.53344C1.47716 7.09283 1.79648 8.05077 2.57962 8.16264L2.69599 7.34804C2.58411 7.33206 2.5385 7.19521 2.61841 7.1153L2.03655 6.53344ZM4.36398 5.36973C4.39451 5.3392 4.43762 5.32483 4.48036 5.33094L4.59673 4.51634C4.29759 4.4736 3.99579 4.5742 3.78213 4.78787L4.36398 5.36973Z" fill="#0CFDE4" />
                <path d="M3.3942 9.44278C3.55488 9.28211 3.55488 9.0216 3.3942 8.86093C3.23352 8.70025 2.97302 8.70025 2.81234 8.86093L3.3942 9.44278ZM2.03653 9.63674C1.87586 9.79741 1.87586 10.0579 2.03653 10.2186C2.19721 10.3793 2.45771 10.3793 2.61839 10.2186L2.03653 9.63674ZM5.72163 11.7702C5.8823 11.6095 5.8823 11.349 5.72163 11.1884C5.56095 11.0277 5.30045 11.0277 5.13977 11.1884L5.72163 11.7702ZM4.36396 11.9642C4.20329 12.1248 4.20329 12.3853 4.36396 12.546C4.52464 12.7067 4.78514 12.7067 4.94582 12.546L4.36396 11.9642ZM2.81234 8.86093L2.03653 9.63674L2.61839 10.2186L3.3942 9.44278L2.81234 8.86093ZM5.13977 11.1884L4.36396 11.9642L4.94582 12.546L5.72163 11.7702L5.13977 11.1884Z" fill="white" />
                <path d="M5.52775 5.95175C5.36707 5.79107 5.10656 5.79107 4.94589 5.95175C4.78521 6.11242 4.78521 6.37293 4.94589 6.5336L5.52775 5.95175ZM8.04913 9.63684C8.2098 9.79752 8.47031 9.79752 8.63098 9.63684C8.79166 9.47617 8.79166 9.21566 8.63098 9.05499L8.04913 9.63684ZM4.94589 6.5336L8.04913 9.63684L8.63098 9.05499L5.52775 5.95175L4.94589 6.5336Z" fill="white" />
                <path d="M8.24308 4.20607C7.65393 4.79522 7.65393 5.75041 8.24308 6.33955L8.82493 5.75769C8.55714 5.4899 8.55714 5.05572 8.82493 4.78793L8.24308 4.20607ZM8.24308 6.33955C8.83222 6.92869 9.78741 6.92869 10.3766 6.33955L9.7947 5.75769C9.5269 6.02548 9.09273 6.02548 8.82493 5.75769L8.24308 6.33955ZM10.3766 6.33955C10.9657 5.75041 10.9657 4.79522 10.3766 4.20607L9.7947 4.78793C10.0625 5.05572 10.0625 5.4899 9.7947 5.75769L10.3766 6.33955ZM10.3766 4.20607C9.78741 3.61693 8.83222 3.61693 8.24308 4.20607L8.82493 4.78793C9.09273 4.52014 9.5269 4.52014 9.7947 4.78793L10.3766 4.20607Z" fill="white" />
                <path d="M11.0553 7.40644L10.7537 7.12665L11.0553 7.40644ZM7.17627 3.52739L7.45605 3.82905L7.17627 3.52739ZM12.8971 2.72344L13.3051 2.77679L12.8971 2.72344ZM11.8593 1.6856L11.9126 2.09356L11.8593 1.6856ZM4.57704 7.3196L4.94647 7.50071L4.57704 7.3196ZM7.26311 10.0057L7.08199 9.63624L7.26311 10.0057ZM4.55793 10.6066C4.71861 10.446 4.71861 10.1854 4.55793 10.0248C4.39726 9.8641 4.13675 9.8641 3.97607 10.0248L4.55793 10.6066ZM2.42445 11.5764C2.26378 11.7371 2.26378 11.9976 2.42445 12.1582C2.58513 12.3189 2.84564 12.3189 3.00631 12.1582L2.42445 11.5764ZM6.36315 9.5026L5.0801 8.21955L4.49824 8.80141L5.78129 10.0845L6.36315 9.5026ZM10.7537 7.12665C9.71484 8.24671 8.02813 9.17239 7.08199 9.63624L7.44422 10.3751C8.39887 9.90708 10.2068 8.92635 11.357 7.68622L10.7537 7.12665ZM4.94647 7.50071C5.41031 6.55457 6.336 4.86786 7.45605 3.82905L6.89649 3.22572C5.65636 4.3759 4.67563 6.18384 4.20761 7.13849L4.94647 7.50071ZM12.4891 2.67008C12.3225 3.94462 11.8796 5.91269 10.7537 7.12665L11.357 7.68622C12.6711 6.26932 13.1358 4.07089 13.3051 2.77679L12.4891 2.67008ZM7.45605 3.82905C8.67002 2.70314 10.6381 2.26025 11.9126 2.09356L11.8059 1.27764C10.5118 1.44689 8.31339 1.9116 6.89649 3.22572L7.45605 3.82905ZM13.3051 2.77679C13.4209 1.89145 12.6913 1.16185 11.8059 1.27764L11.9126 2.09356C12.262 2.04786 12.5348 2.32066 12.4891 2.67008L13.3051 2.77679ZM5.0801 8.21955C4.88087 8.02032 4.83219 7.73382 4.94647 7.50071L4.20761 7.13849C3.92883 7.70715 4.06608 8.36924 4.49824 8.80141L5.0801 8.21955ZM5.78129 10.0845C6.21346 10.5166 6.87556 10.6539 7.44422 10.3751L7.08199 9.63624C6.84889 9.75052 6.56238 9.70184 6.36315 9.5026L5.78129 10.0845ZM3.97607 10.0248L2.42445 11.5764L3.00631 12.1582L4.55793 10.6066L3.97607 10.0248Z" fill="#0CFDE4" />
            </svg>
        </span>
    );
}

export default ProfileBadge
import React from "react";

function FollowIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 8.00022C13.5523 8.00022 14 7.55251 14 7.00022C14 6.44794 13.5523 6.00022 13 6.00022V8.00022ZM1 6.00022C0.447715 6.00022 0 6.44794 0 7.00022C0 7.55251 0.447715 8.00022 1 8.00022V6.00022ZM5.99821 12.9999C5.99815 13.5522 6.44582 13.9999 6.99811 14C7.55039 14.0001 7.99815 13.5524 7.99821 13.0001L5.99821 12.9999ZM7.99946 1.0001C7.99952 0.44782 7.55185 5.76377e-05 6.99957 0C6.44728 -5.75781e-05 5.99952 0.447611 5.99946 0.999896L7.99946 1.0001ZM13 6.00022H1V8.00022H13V6.00022ZM7.99821 13.0001L7.99946 1.0001L5.99946 0.999896L5.99821 12.9999L7.99821 13.0001Z" fill="currentColor"/>
    </svg>

  );
}

export default FollowIcon;
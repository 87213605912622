import React from "react";

function Block() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 4.00101C14.0894 4.00101 17 6.9109 17 10.501C17 14.0904 14.0894 17.001 10.5 17.001C6.90989 17.001 4 14.0904 4 10.501C4 6.9116 6.91059 4.00101 10.5 4.00101Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5 6.00101L6 15.001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
      

  );
}

export default Block;
import React from "react";

function UnfollowIcon() {
  return (
    <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 2C13.5523 2 14 1.55228 14 1C14 0.447715 13.5523 0 13 0V2ZM1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM13 0H1V2H13V0Z" fill="currentColor"/>
    </svg>



  );
}

export default UnfollowIcon;
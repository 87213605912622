import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";

import './LoadingSpinner.scss'

const LoadingSpinner = () => {
  return (
   <div className='LoadingSpinner' >
      <ClipLoader size={50} color={"#0CFDE4"} />
    </div>
  )
}

export default LoadingSpinner
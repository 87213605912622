/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import _ from "lodash";

import {RootState} from "../../store";
// import { createUser } from "../../store/session/Actions/index";
import AppConfig from "../../Utils/config";
import API from "../../Utils/api";
import AgoraRTC, {CameraVideoTrackInitConfig} from "agora-rtc-sdk-ng";
import './sharing.scss';
import '../../Style/common.scss';
import {getShowById, setCurrentShow} from "../../store/Actions/users";
// mode: rtc & live. rtc:- 1 to 1 or Group call, live:- Live streaming
// codec vp8, h264

const TopLeftComponent = ({hostName}: {hostName:string}) => (<p
  style={{
    position:'absolute',
    top: 0,
    left: 0,
    zIndex: 5,
  }}
>{hostName}</p>)

const BottomRight = ({hostName}: {hostName:string}) => (<p
  style={{
    position:'absolute',
    bottom: 0,
    right: 0,
    zIndex: 5,
  }}
>{hostName}</p>)

function Show() {
  const [role, setRole] = useState<string>('audience'); // As of now it's audience, will change with permission
  // const [user, setUser] = useState<IAgoraRTCRemoteUser>();
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [usernameLocation, setUsernameLocation] =useState<boolean>(false);
  const [hostName, setHostName] = useState<string>('')

  // const [userCount, setUserCount] = useState<boolean>(false);
  const [tracks, setTracks] = useState<any>(null);
  const params = useParams();
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const showDashboard = useSelector(
    (state: RootState) => state?.showDashboard
  );

  const dispatch = useDispatch()

  const videoConfig: CameraVideoTrackInitConfig = {
    encoderConfig: {width: 1920, height: 1080},
  };

  const fetchHostName = async (id: any) => {
    const res = await API.getDataWithOutToken(`/api/show/${id}`)
    console.log('res: ', res.data.data.room.host);
    if(res.data.success){
      return res.data.data.room.host
    }else{
      return '';
    }
  }

  useEffect(() => {
    const shows = [...showDashboard.shows?.featured?.shows ?? null, ...showDashboard.shows?.ready?.shows ?? null, ...showDashboard.shows?.live?.shows ?? null]
    const show: any = shows.find((e: any) => e.id === Number(params.id))
    console.log('show: ', shows);
    if (_.isEmpty(show) && _.isEmpty(users.currentShow)) {
      if (_.isEmpty(users.currentShow))
        dispatch(getShowById(Number(params.id), users.user?.id))
    }
    else if (!_.isEmpty(show))
      dispatch(setCurrentShow(show))
  }, [params.id, showDashboard.shows])


  const init = async () => {
    let cameraTrack;
    let audioTrack;
    if (isInitializing || isInitialized) {
    } else {
      AppConfig.rtc.client = AgoraRTC.createClient({
        mode: "live",
        codec: "vp8",
      });
      setIsInitializing(true);
      if (role === "host") {
        await AppConfig.rtc.client.setClientRole("host");
        cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
        audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "high_quality_stereo",
        });

        await setTracks([audioTrack, cameraTrack]);
      } else {
        // tracks && (await tracks[0]?.setEnabled(false));
        await AppConfig.rtc.client.setClientRole("audience");
      }
      if (role === "audience") {
        AppConfig.rtc.client.on(
          "user-published",
          async (user: any, mediaType: any) => {
            // Subscribe to a remote user.
            await AppConfig.rtc.client.subscribe(user, mediaType);
            console.log("subscribe success");
            // If the subscribed track is video.
            if (mediaType === "video") {
              // Get `RemoteVideoTrack` in the `user` object.
              const remoteVideoTrack = user.videoTrack;
              // Dynamically create a container in the form of a DIV element for playing the remote video track.
              const remotePlayerContainerAudience =
                document.getElementById("audience");
              if (remotePlayerContainerAudience) {
                const remotePlayerContainer = document.createElement("div");
                // Specify the ID of the DIV container. You can use the `uid` of the remote user.
                remotePlayerContainer.id = user.uid.toString();
                // remotePlayerContainer.textContent =
                //   "Remote user " + user.uid.toString();
                // remotePlayerContainer.style.width = "640px";
                // remotePlayerContainer.style.height = "650px";
                remotePlayerContainerAudience.append(remotePlayerContainer);

                // Play the remote video track.
                // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
                remoteVideoTrack.play(remotePlayerContainer);

                // Or just pass the ID of the DIV container.
                // remoteVideoTrack.play(playerContainer.id);
              }
            }

            // If the subscribed track is audio.
            if (mediaType === "audio") {
              // Get `RemoteAudioTrack` in the `user` object.
              const remoteAudioTrack = user.audioTrack;
              // Play the audio track. No need to pass any DOM element.
              remoteAudioTrack.play();
            }
          }
        );

        AppConfig.rtc.client.on("user-unpublished", (user: any) => {
          // Get the dynamically created DIV container.
          const remotePlayerContainer = document.getElementById(user.uid);
          // Destroy the container.
          remotePlayerContainer && remotePlayerContainer.remove();
        });
      }

      const res = await API.getDataWithOutToken('/api/agora', {'id': params.id, 'seller': "", 'type': 'sharing'}, {bypass: true});
      const token = res.data?.agora?.token;
      const channelFromRes = res.data?.agora?.channel;
      // client.setEncryptionConfig("sm4-128-ecb", "123456789");
      AppConfig.rtc.client.on("user-joined", onHostJoined);
      await AppConfig.rtc.client.join(
        AppConfig.appId,
        channelFromRes,
        token,
        role === 'host' ? 1 : 2
      );
      if (role === "host") {
        cameraTrack?.play("local-player");
        audioTrack &&
          cameraTrack &&
          (await AppConfig.rtc.client.publish([audioTrack, cameraTrack]));
      }
      setIsInitialized(true);
      //   setStart(true);
    }
    console.log('inside useEffect: ')
    const hostName = await fetchHostName(params.id);
    console.log('inside useEffect: ', hostName)
    setHostName(hostName)
  };

  const onHostJoined = (uid: number, elapsed: number) => {

  }

  useEffect(() => {
    // if (users.isGuestUser || users.user.username) {
    init();
    // }
    const intervalId = setInterval(()=> {
      setUsernameLocation(prevLocation => !prevLocation)
    }, 10000)
    return ()=> clearInterval(intervalId);

  }, [tracks, tracks?.cameraTrack, tracks?.cameraTrack]);

  return (
    <>
      <div>
        {
          usernameLocation ? <TopLeftComponent hostName={hostName} /> : <BottomRight hostName={hostName} />
        }
      </div>
      {role === "host" ? <div id="local-player" className="player"></div> : null}
      {role === "audience" ? <div id="audience" className="sharing-audience-player"></div> : null}

    </>
  );
}

export default Show;

import React from 'react';
import lottie from "lottie-web";
import animationData from '../../Assets/demo.json'
// import animationData from "./lottie.json";
function LottieText() {
    React.useEffect(() => {
        var animDuration = 5000;
        const anim = lottie.loadAnimation({
            container: lottieRef.current!,
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData,
            initialSegment: [-100,900],
        });

        // document.addEventListener('mouseenter', (e) => {
        //     anim.play();
        // });



        function animatebodymovin(duration: number) {
            const scrollPosition = window.scrollY;
            const maxFrames = anim.totalFrames;
            const frame = (maxFrames / 100) * (scrollPosition / (duration / 100));
            anim.goToAndStop(frame, true);
        }

        const onScroll = () => {
            console.log("Scrolling");
            animatebodymovin(animDuration);
        };
        document.addEventListener("scroll", onScroll);
        return () => {
            anim.destroy();
            document.removeEventListener("scroll", onScroll);
        };
    }, []);
    const lottieRef = React.useRef<HTMLDivElement>(null);
    return (
        <div className='mobileanimation' ref={lottieRef}></div>
    )
}
export default LottieText
import React from "react";

function Twitch() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.81149 4C5.39806 4 5.02725 4.25442 4.87848 4.64015L4.06699 6.7442C4.02271 6.85901 4 6.981 4 7.10405V17.8431C4 18.3954 4.44772 18.8431 5 18.8431H7.00007C7.55235 18.8431 8.00007 19.2908 8.00007 19.8431V20C8.00007 20.5523 8.44778 21 9.00007 21H9.83147C10.0993 21 10.3559 20.8926 10.5438 20.7018L12.0814 19.1413C12.2693 18.9505 12.5259 18.8431 12.7937 18.8431H15.2066C15.4744 18.8431 15.731 18.7357 15.9189 18.545L19.7123 14.6949C19.8967 14.5078 20 14.2557 20 13.9931V5C20 4.44772 19.5523 4 19 4H5.81149ZM18.5001 13.2321C18.5001 13.4949 18.3967 13.7471 18.2123 13.9342L16.2934 15.8809C16.1055 16.0716 15.8489 16.1789 15.5812 16.1789H12.4184C12.1506 16.1789 11.894 16.2863 11.7061 16.4771L10.9484 17.246C10.5548 17.6456 9.87474 17.3668 9.87474 16.806C9.87474 16.4596 9.594 16.1789 9.24769 16.1789H7.49991C6.94763 16.1789 6.49991 15.7312 6.49991 15.1789V6.52203C6.49991 5.96975 6.94763 5.52203 7.49991 5.52203H17.4996C18.0519 5.52203 18.4996 5.96971 18.4996 6.52197L18.5001 13.2321ZM15.2499 8.44019C15.664 8.44019 15.9997 8.77589 15.9997 9.19V12.1306C15.9997 12.5447 15.664 12.8804 15.2499 12.8804C14.8357 12.8804 14.5 12.5447 14.5 12.1306V9.19C14.5 8.77589 14.8357 8.44019 15.2499 8.44019ZM11.2498 8.44019C11.6639 8.44019 11.9996 8.77589 11.9996 9.19V12.1306C11.9996 12.5447 11.6639 12.8804 11.2498 12.8804C10.8357 12.8804 10.5 12.5447 10.5 12.1306V9.19C10.5 8.77589 10.8357 8.44019 11.2498 8.44019Z" fill="currentColor"/>
    </svg>

    

  );
}

export default Twitch;
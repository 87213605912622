import {Button, message, Modal, Steps} from 'antd'
import moment from 'moment';
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import {images} from '../../../../../Assets/ImageConfig/images';
import {displayErrorToast, fetchOrderDetails} from '../../../../../Utils/helper';
import LoadingSpinner from '../../../../CommonComponents/LoadingSpinner';

import {Close} from '../../../../Icons';

type Props = {
    isModalVisible: boolean;
    handleOk: any;
    handleCancel: any;
    order: any
}

const {Step} = Steps;

const OrderDetailsModal = (props: Props) => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [orderDetails, setOrderDetails] = useState<any>(null)

    async function getOrderDetails() {
        try {
            setLoading(true)
            const response = await fetchOrderDetails(props.order?.id);
            setOrderDetails(response)
        } catch (error: any) {
            console.log(error);
            if (error?.response?.data?.message) displayErrorToast(error.response?.data?.message)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
      getOrderDetails()
    }, [props.order?.id])

    const getLastUpdatedAt = (status: any) => {
        const createdAt = orderDetails?.shippingHistory?.find((shipping: any) => shipping?.status?.toLowerCase() === status?.toLowerCase())?.createdAt ?? ''
        return createdAt ? moment(createdAt).locale('en').fromNow() : ''
    }

    const getActiveStatus = () => {
        const lastStatus = orderDetails?.shippingHistory?.[orderDetails?.shippingHistory?.length - 1]?.status;
        switch (lastStatus) {
            case 'Ordered':
            case 'ordered':
                return 1;

            case 'In Delivery':
                return 2;

            case 'Delivered':
                return 3;

            default:
                return 0
        }
    }

    return (
        <Modal width={'683px'} title="Order Details" className='themeModal orderModal' visible={props.isModalVisible} onOk={props.handleOk} onCancel={props.handleCancel} footer={false} centered closeIcon={<Close />}>
            <div className='modal-body'>

                <div className='orderProfile'>
                    <div className='orderProfile-left'>
                        <div className='orderProfile-img'>
                            <img src={props.order?.showCover ?? images.placeholderimg} alt=""
                                onError={({currentTarget}) => {
                                    currentTarget.onerror = null
                                    currentTarget.src = images.placeholderimg
                                }}
                            />
                        </div>
                        <div className='orderProfile-details'>
                            <p>{props.order?.showTitle}</p>
                            <span>@{props.order?.hostName}</span>
                            <div className='item-date'>
                                <span className='itemdate'>{moment(props.order?.createdAt).locale('en').format('DD-MMM-YY')}</span>
                            </div>
                        </div>
                    </div>

                    {props.order?.paymentStatus === 'failed' && <div className='orderProfile-right'>
                        <Button type='primary' className='w100' onClick={()=>navigate('/account-settings/payment-method')}>Retry  payment</Button>
                    </div>}
                </div>

                <div className='order-summarybox'>
                    <div className='ordersummary'>
                        <p>Order Summary ( {props.order?.sellsCount} {props.order?.sellsCount > 1 ? 'items' : 'item'})</p>
                        {loading && <LoadingSpinner />}
                        {
                            !!orderDetails?.sellData?.length && (
                                <div className='summary-table'>
                                    {orderDetails?.sellData?.map((sellItem: any, index: number) => (

                                        <div className='summaryrow' key={`${sellItem?.id}-${index}`}>
                                            <div className='summary-cell-right'>
                                                {sellItem?.itemName}
                                                <span>{sellItem?.paymentMethod}  |  {moment(sellItem?.paymentDate).locale('en').format('DD-MMM-YY')}  |  {moment(sellItem?.paymentDate).locale('en').format('hh:mm a')}</span>
                                            </div>
                                            <span className='summary-cell-left'>€{sellItem?.sellAmount}</span>
                                        </div>
                                    ))}
                                    <div className='summaryrow totla'>
                                        <span className='summary-cell '>Total</span>
                                        <span className='summary-cell'>€{orderDetails?.totalAmount}</span>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
                <div className='order-summarybox'>
                    {
                        !!orderDetails?.shippingHistory?.length && (
                            <>
                                {/* <div className='ordersummary'>
                                    <p>
                                        Order Summary ( {props.order?.sellsCount} items)
                                        <span>Wallet | Credit Card XX2612 | 20-Feb-22</span>
                                    </p>
                                </div> */}
                                <div className='ShippingStatusWrapper'>
                                    <p>Shipping Status</p>
                                    <div>
                                        <Steps progressDot current={getActiveStatus()} direction="vertical">
                                            <Step title="Ordered" description={getLastUpdatedAt('Ordered') ? `Last update: ${getLastUpdatedAt('Ordered')}` : ' '} />
                                            <Step title="Out for Delivery" description={getLastUpdatedAt('In Delivery') ? `Last update: ${getLastUpdatedAt('In Delivery')}` : ' '} />
                                            <Step title="Delivered" description={getLastUpdatedAt('Delivered') ? `Last update: ${getLastUpdatedAt('Delivered')}` : ' '} />
                                        </Steps>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        orderDetails?.shippingAddress && Object.keys(orderDetails?.shippingAddress).length > 0 && (
                            <div className='ShippingStatusWrapper'>
                                <p>Shipping Address</p>
                                <div className='AddressStyle'>
                                    <span>{orderDetails?.shippingAddress?.fullName}</span>
                                    <p>{orderDetails?.shippingAddress?.addressLine1} {orderDetails?.shippingAddress?.houseNumber}, {orderDetails?.shippingAddress?.postalCode}, {orderDetails?.shippingAddress?.addressLine2} {orderDetails?.shippingAddress?.city} {orderDetails?.shippingAddress?.country}</p>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        </Modal>
    );
};

export default OrderDetailsModal
import React from "react";

function Plus() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#0CFDE4" stroke-opacity="0.5" />
      <path d="M12.0002 6.72546L12.0002 17.2746M6.72566 12L17.2748 12" stroke="#B2A8EE" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  );
}

export default Plus;

import AllShow from "../../Components/AllShow"
import Pagefooter from "../../Components/CommonComponents/PageFooter";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import {goToTop} from "../../Utils/helper";
const AllShows = (props: any) => {
    const [title, setTitle] = useState('');
    useEffect(() => {
        switch (props.path) {
            case "feature-show":
                setTitle("Featured shows");
                break;
            case "upcoming-show":
                setTitle("Upcoming shows");
                break;

            case "live-show":
                setTitle("Live shows");
                break;
            case "game-show":
                setTitle("Game shows");
                break;

            case "suggested-show":
                setTitle("Suggested shows");
                break;
        }
    });

    useEffect(() => {
        goToTop()
    }, []);

    return <>
        {title && <Helmet>
            <title>GAVEL - {title}</title>
            <meta property="og:title" content="GAVEL - Live shows for trading cards and much more" />
            <meta property="title" content={`GAVEL - ${title}`} />
            <meta property="og:description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
            <meta property="description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
            <meta property="og:url" content="https://letsgavel.com" />
            <meta property="og:image" content="https://i.ibb.co/1KqZpJ7/Meta-Image-EN.png" />
            <link rel="canonical" href="https://letsgavel.com" />
        </Helmet>}
        <AllShow path={props.path} />
        <Pagefooter />
    </>
}

export default AllShows
import React from "react";

function Infoicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.256 5a4.744 4.744 0 119.488 0A4.744 4.744 0 01.256 5zM5 1.958a.67.67 0 100 1.34.67.67 0 000-1.34zm-.722 1.87a.45.45 0 100 .9h.375v2.194H3.97a.45.45 0 100 .9h2.268a.45.45 0 000-.9h-.684V4.278a.45.45 0 00-.45-.45h-.825z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Infoicon;
import React from "react";

function Cart() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.43311 1.50098L2.90983 1.75656L3.59352 9.90197C3.64819 10.5672 4.20409 11.0777 4.87146 11.0755H12.6164C13.2533 11.077 13.7936 10.6084 13.8837 9.97794L14.5575 5.322C14.6327 4.8016 14.2714 4.31882 13.7517 4.24356C13.7062 4.23717 3.14696 4.23362 3.14696 4.23362" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.50879 6.85791H11.4775" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.00006 13.0967C5.45722 13.0967 5.82628 13.4673 5.82628 13.9229C5.82628 14.3801 5.45722 14.7507 5.00006 14.7507C4.5429 14.7507 4.17383 14.3801 4.17383 13.9229C4.17383 13.4673 4.5429 13.0967 5.00006 13.0967Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.1234 13.0918C12.5829 13.0918 12.9553 13.4643 12.9553 13.9222C12.9553 14.3817 12.5829 14.7542 12.1234 14.7542C11.6639 14.7542 11.293 14.3817 11.293 13.9222C11.293 13.4643 11.6639 13.0918 12.1234 13.0918Z" fill="currentColor"/>
    </svg>
    
    

  );
}

export default Cart;
import { Col, Form, Input, message, Row } from 'antd'
import { SendArrow } from '../../Icons/index'
import { images } from '../../../Assets/ImageConfig/images'
import './Pagefooter.scss'
import { useForm } from 'antd/lib/form/Form'
import { useDispatch, useSelector } from 'react-redux';
import { subscribeEmail } from '../../../store/Actions/users';
import {Fade} from "react-awesome-reveal";
import Appstore from '../AppStore'
import Playstore from '../Playstore/Playstore'
import {displayInfoToast} from '../../../Utils/helper'
import {RootState} from '../../../store'
import {discord_button_clicked} from '../../../Utils/mParticle'

function Pagefooter() {

  const [form] = useForm()
  const dispatch = useDispatch()

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );


  const handleEmailSubscribe = async (values:any) => {
    try{
      await dispatch(subscribeEmail(values.email))
      displayInfoToast('email subscribed.')

    }
    catch(err){
      console.log(err);
    }
  }

  return (

      <div className="pagefooter" >
        <div className='container'>
            {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 44 }}> */}
              {/* <Col className="gutter-row" xs={24} sm={12} lg={8}>
                  <div className='footerColumn'>
                    <div className='footerLogo'> */}
                      {/* <img src={images.Logo} alt='logo' /> */}
                      {/* <img src={images.Blogo} alt='logo' style={{width: 80}} />
                    </div>
                    <h5>Join the community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection.</h5>
                    <div className='appdownload-link'> */}
                      {/* <a href='https://apps.apple.com/at/app/gavel-live-collectibles-sale/id1570409915'>
                        <img src={images.Appstore} alt='appstore' />
                      </a>
                      <a href='https://play.google.com/store/apps/details?id=com.gavel.collectibles'>
                      <img src={images.Playstore} alt='Playstore' />
                      </a> */}
                      {/* <Appstore width={150} />
                      <Playstore width={150} />
                    </div>
                  </div>
              </Col> */}
              {/* <Col className="gutter-row" xs={24} sm={12} lg={4}>
                  <div className='footerColumn'>
                      <p>About</p>
                      <ul className='footerLink'>
                        <li><a href='https://letsgavel.com'>Discover</a></li>
                        <li><a href='https://angel.co/company/letsgavel'>AngelList</a></li>
                        <li><a href='mailto:founders@letsgavel.com'>Investors</a></li>
                        <li><a href='https://letsgavel.com/jobs'>Jobs</a></li>
                      </ul>
                  </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} lg={4}>
                  <div className='footerColumn'>
                      <p>Policy</p>
                      <ul className='footerLink'>
                        <li><a href='https://letsgavel.com/privacy'>Privacy Policy</a></li>
                        <li><a href='https://letsgavel.com/terms'>Terms of Use</a></li>
                        <li><a href='https://letsgavel.com/imprint'>Imprint</a></li>
                      </ul>
                  </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={12}  lg={8}>
                  <div className='footerColumn'>
                      <p>Join Newsletter</p>
                      <div className='subscribeform'>
                        <span>Subscribe our newsletter</span>
                        <Form form={form} onFinish={handleEmailSubscribe}>
                          <div className='subscribe-div'>
                            <Form.Item
                              className='emailInputWrapper'
                              name="email"
                            >
                            <Input type='email' required name='email' className='emailinput' placeholder='Enter your email' />
                            </Form.Item>
                            <button type='submit' className='sendbtn' ><SendArrow/></button>
                          </div>
                        </Form>
                      </div>
                  </div>
              </Col>

            </Row> */}

        </div>
        <div className='bottomfooter'>
                <Fade>
                    <div className='container'>
                        <div className='finner'>
                            <div className='footerlogo-container'>
                                <a href="" className='footerlogo'>
                                    <img src={images.Blogo} alt="logo" />
                                </a>
                                <div className="footerbeta-badge">
                                    Beta
                                </div>
                            </div>
                            <div className='footerright'>
                                <div className='emailblock'>
                                    <Form layout="vertical"  >
                                        <Form.Item label="Talk to us" >
                                            <a type='button' className='ant-input' href='mailto:hello@letsgavel.com'>hello@letsgavel.com</a>

                                        </Form.Item>
                                    </Form>
                                    <div className='footersociallinks'>
                                        <a href="/discord" onClick={()=>{discord_button_clicked('footer_icon', users?.user);}}  target="_blank">
                                            <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.3296 13.7172C16.571 13.3154 17.6495 12.7518 18.4482 11.7187C18.2804 11.8178 18.1125 11.917 17.9446 12.0161C15.9198 13.2162 13.7474 13.8424 11.392 13.7276C9.77416 13.6493 8.2174 13.2684 6.70135 12.7101C5.99928 12.4544 5.32265 12.1309 4.63585 11.8439C4.60023 11.8283 4.56462 11.8178 4.52392 11.8231C5.3023 12.8301 6.3554 13.3989 7.53569 13.7954C7.1643 14.2755 6.80818 14.7399 6.4368 15.1938C6.3961 15.246 6.29944 15.2669 6.22821 15.2669C4.79864 15.2408 3.46573 14.8442 2.29054 14.0041C1.28831 13.2841 0.87114 12.2457 0.988151 10.9986C1.18147 8.91669 1.62408 6.88692 2.38211 4.94585C3.38942 2.37343 5.25651 0.875886 7.93251 0.458453C8.11565 0.432363 8.30389 0.411491 8.48195 0.427145C8.58879 0.432363 8.69562 0.510632 8.79228 0.588901C7.45938 0.959372 6.19769 1.43942 5.04284 2.1908C4.77321 2.36821 4.51883 2.56649 4.26446 2.7752C9.02122 0.61499 13.7627 0.635862 18.494 2.84826C17.2273 1.82555 15.7723 1.20461 14.2308 0.724566C14.3325 0.515849 14.475 0.427145 14.7039 0.448017C17.5579 0.724566 19.5573 2.21167 20.6511 4.91977C21.4142 6.80865 21.8568 8.78624 22.0552 10.8212C22.1824 12.1414 21.694 13.2006 20.6155 13.9311C19.4301 14.7347 18.1125 15.1312 16.6931 15.1782C16.5405 15.1782 16.4387 15.1417 16.3471 15.0164C16.0215 14.5938 15.6858 14.1763 15.3246 13.7224L15.3296 13.7172ZM16.632 8.8906C16.632 7.82092 15.7723 6.90779 14.7802 6.91822C13.7881 6.92344 12.9385 7.83136 12.9385 8.88016C12.9385 9.94461 13.7932 10.863 14.7853 10.863C15.7875 10.863 16.6422 9.95505 16.6371 8.8906H16.632ZM6.31979 8.86972C6.31979 9.93939 7.15413 10.8525 8.15127 10.863C9.13823 10.8734 10.0184 9.94461 10.0184 8.89581C10.0184 7.83658 9.15349 6.91301 8.16653 6.91822C7.17448 6.91822 6.32488 7.82092 6.31979 8.86972Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://facebook.com/lets.gavel" target="_blank">
                                            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.003 14.9561C3.003 14.8935 2.99791 14.8309 2.99791 14.7683C2.99791 12.6446 2.99791 10.5209 2.99791 8.39721V8.19371H0.779785V5.55345H2.99791C2.99791 5.4804 2.99791 5.423 2.99791 5.3656C2.99791 4.67684 2.98265 3.98286 3.01317 3.29409C3.04878 2.46444 3.28789 1.70263 3.85769 1.0817C4.29521 0.612088 4.83448 0.340758 5.45006 0.21031C5.94863 0.100734 6.45228 0.116387 6.95594 0.13204C7.36293 0.147694 7.77502 0.18422 8.18201 0.21031C8.21254 0.21031 8.24815 0.220744 8.28376 0.23118V2.58446C8.23289 2.58446 8.1871 2.58446 8.13622 2.58446C7.63257 2.58967 7.12891 2.58446 6.62525 2.61055C6.03002 2.64185 5.69425 2.98102 5.66882 3.59151C5.64338 4.2281 5.65864 4.86469 5.65864 5.50127C5.65864 5.51171 5.66373 5.51692 5.6739 5.54301H8.19727L7.86659 8.18328H5.66882C5.66882 8.24067 5.65864 8.28241 5.65864 8.32416C5.65864 10.5 5.65864 12.6811 5.65864 14.857C5.65864 14.8883 5.65864 14.9248 5.66373 14.9561H3.00808H3.003Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://instagram.com/lets_gavel" target="_blank">
                                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.1159 15.7602H3.94772C3.64247 15.6976 3.32705 15.6611 3.03198 15.5619C1.33786 14.988 0.254239 13.4643 0.249151 11.6433C0.238976 9.40481 0.249151 7.16633 0.249151 4.93306C0.249151 4.22342 0.401774 3.55031 0.74772 2.9346C1.54136 1.54664 2.73182 0.826565 4.30384 0.816129C6.45583 0.800475 8.60781 0.810911 10.7598 0.816129C11.0294 0.816129 11.3042 0.831783 11.5636 0.894398C13.1967 1.26487 14.2447 2.28758 14.6924 3.93644C14.7534 4.15559 14.7738 4.38518 14.8145 4.60955V11.9668C14.7891 12.1181 14.7738 12.2747 14.7433 12.426C14.3719 14.1009 13.3849 15.1758 11.7722 15.6402C11.5585 15.7028 11.3347 15.7237 11.121 15.7654L11.1159 15.7602ZM7.52928 4.19212C5.32642 4.19212 3.5509 6.01838 3.53564 8.28296C3.52546 10.5371 5.3315 12.3895 7.52928 12.3842C9.73214 12.3842 11.5127 10.558 11.5229 8.29339C11.5331 6.03926 9.72705 4.1869 7.52928 4.19212ZM12.9779 3.94687C12.9779 3.26333 12.4336 2.70501 11.7671 2.70501C11.1057 2.70501 10.5512 3.26855 10.5512 3.94687C10.5512 4.61999 11.1057 5.18874 11.762 5.19395C12.4183 5.19395 12.9728 4.63042 12.9728 3.94687H12.9779Z" fill="currentColor" />
                                                <path d="M12.113 3.93648C12.113 3.7382 11.9502 3.57645 11.7568 3.58167C11.5737 3.58167 11.416 3.74864 11.4109 3.93648C11.4109 4.12955 11.5737 4.30174 11.7619 4.30174C11.9502 4.30174 12.113 4.13476 12.113 3.93648Z" fill="currentColor" />
                                                <path d="M7.52918 5.07397C9.25891 5.07397 10.6681 6.51412 10.663 8.29342C10.663 10.0623 9.25891 11.5024 7.53427 11.5024C5.80454 11.5024 4.39532 10.0623 4.4004 8.28299C4.4004 6.51411 5.80454 5.07397 7.52918 5.07397Z" fill="currentColor" />
                                                <path d="M12.1131 3.93636C12.1131 4.13464 11.9503 4.30161 11.762 4.30161C11.5738 4.30161 11.4059 4.12942 11.411 3.93636C11.411 3.74852 11.5738 3.58676 11.7569 3.58154C11.9503 3.58154 12.1131 3.73808 12.1131 3.93636Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://twitter.com/lets_gavel" target="_blank">
                                            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.30814 5.86419C1.8118 6.12508 2.32563 6.29728 2.93612 6.31293C2.0153 5.62938 1.49129 4.74755 1.35393 3.65179C1.25218 2.83258 1.40989 2.0499 1.82706 1.31417C3.80608 3.70919 6.3091 5.0241 9.3463 5.23803C9.33104 4.85713 9.30051 4.50231 9.31069 4.14749C9.35139 2.61343 10.5062 1.14198 11.9562 0.761074C13.3145 0.406256 14.5101 0.724547 15.5326 1.70551C15.5835 1.75248 15.6853 1.78378 15.7565 1.76813C16.4687 1.62203 17.1403 1.36113 17.7711 0.995879C17.8169 0.969789 17.8627 0.948918 17.9441 0.907175C17.6643 1.78378 17.1453 2.42559 16.4382 2.93694C17.1199 2.86911 17.766 2.67083 18.3969 2.40471L18.4376 2.45167C18.1425 2.80649 17.8627 3.17174 17.5473 3.51091C17.298 3.77702 17.0029 3.99617 16.7383 4.24663C16.6773 4.30403 16.6264 4.40317 16.6264 4.48144C16.6264 8.35313 15.1358 11.4682 12.063 13.7328C10.7657 14.6877 9.28525 15.2095 7.70306 15.4182C6.20735 15.6217 4.73199 15.5225 3.28207 15.0947C2.36633 14.8233 1.50655 14.4268 0.697649 13.905C0.6773 13.8945 0.667125 13.8789 0.621338 13.8424C2.60544 14.025 4.37587 13.5293 5.98859 12.2874C4.89479 12.2196 4.01466 11.797 3.31768 10.9934C2.99209 10.6177 2.74789 10.1846 2.58509 9.67848C3.12436 9.74631 3.64328 9.75675 4.17746 9.60543C2.17301 9.08886 1.25218 7.2939 1.30305 5.87984L1.30814 5.86419Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className='linkssec'>
                                    <div className='f-downloadlogo'>
                                        <Appstore width={120} /><br />
                                        <Playstore width={120} />
                                    </div>
                                    <div className='footerlinks'>
                                        <h4>About</h4>
                                        <a href="https://angel.co/company/letsgavel" target="_blank">AngelList</a>
                                        <a href="https://linkedin.com/company/letsgavel" target="_blank">LinkedIn</a>
                                        <a href="mailto:invest@letsgavel.com" target="_blank">Investors Relations</a>
                                        <a href="https://gavel.notion.site/GAVEL-Careers-Mission-Culture-fa339dc6bb3740b2b9a63958fd0ca39e" target="_blank">Careers</a>
                                    </div>
                                    <div className='footerlinks'>
                                        <h4>Legal</h4>
                                        <a href="/terms#privacy" target="_blank">Data Protection & Privacy</a>
                                        <a href="/terms#terms" target="_blank">Terms of Use</a>
                                        <a href="/imprint" target="_blank">Imprint & Contact</a>
                                    </div>
                                    {/* <div className='socials'>
                                        <a href="/discord" onClick={()=>{discord_button_clicked('footer_icon', users?.user);}}  target="_blank">
                                            <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.3296 13.7172C16.571 13.3154 17.6495 12.7518 18.4482 11.7187C18.2804 11.8178 18.1125 11.917 17.9446 12.0161C15.9198 13.2162 13.7474 13.8424 11.392 13.7276C9.77416 13.6493 8.2174 13.2684 6.70135 12.7101C5.99928 12.4544 5.32265 12.1309 4.63585 11.8439C4.60023 11.8283 4.56462 11.8178 4.52392 11.8231C5.3023 12.8301 6.3554 13.3989 7.53569 13.7954C7.1643 14.2755 6.80818 14.7399 6.4368 15.1938C6.3961 15.246 6.29944 15.2669 6.22821 15.2669C4.79864 15.2408 3.46573 14.8442 2.29054 14.0041C1.28831 13.2841 0.87114 12.2457 0.988151 10.9986C1.18147 8.91669 1.62408 6.88692 2.38211 4.94585C3.38942 2.37343 5.25651 0.875886 7.93251 0.458453C8.11565 0.432363 8.30389 0.411491 8.48195 0.427145C8.58879 0.432363 8.69562 0.510632 8.79228 0.588901C7.45938 0.959372 6.19769 1.43942 5.04284 2.1908C4.77321 2.36821 4.51883 2.56649 4.26446 2.7752C9.02122 0.61499 13.7627 0.635862 18.494 2.84826C17.2273 1.82555 15.7723 1.20461 14.2308 0.724566C14.3325 0.515849 14.475 0.427145 14.7039 0.448017C17.5579 0.724566 19.5573 2.21167 20.6511 4.91977C21.4142 6.80865 21.8568 8.78624 22.0552 10.8212C22.1824 12.1414 21.694 13.2006 20.6155 13.9311C19.4301 14.7347 18.1125 15.1312 16.6931 15.1782C16.5405 15.1782 16.4387 15.1417 16.3471 15.0164C16.0215 14.5938 15.6858 14.1763 15.3246 13.7224L15.3296 13.7172ZM16.632 8.8906C16.632 7.82092 15.7723 6.90779 14.7802 6.91822C13.7881 6.92344 12.9385 7.83136 12.9385 8.88016C12.9385 9.94461 13.7932 10.863 14.7853 10.863C15.7875 10.863 16.6422 9.95505 16.6371 8.8906H16.632ZM6.31979 8.86972C6.31979 9.93939 7.15413 10.8525 8.15127 10.863C9.13823 10.8734 10.0184 9.94461 10.0184 8.89581C10.0184 7.83658 9.15349 6.91301 8.16653 6.91822C7.17448 6.91822 6.32488 7.82092 6.31979 8.86972Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://facebook.com/lets.gavel" target="_blank">
                                            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.003 14.9561C3.003 14.8935 2.99791 14.8309 2.99791 14.7683C2.99791 12.6446 2.99791 10.5209 2.99791 8.39721V8.19371H0.779785V5.55345H2.99791C2.99791 5.4804 2.99791 5.423 2.99791 5.3656C2.99791 4.67684 2.98265 3.98286 3.01317 3.29409C3.04878 2.46444 3.28789 1.70263 3.85769 1.0817C4.29521 0.612088 4.83448 0.340758 5.45006 0.21031C5.94863 0.100734 6.45228 0.116387 6.95594 0.13204C7.36293 0.147694 7.77502 0.18422 8.18201 0.21031C8.21254 0.21031 8.24815 0.220744 8.28376 0.23118V2.58446C8.23289 2.58446 8.1871 2.58446 8.13622 2.58446C7.63257 2.58967 7.12891 2.58446 6.62525 2.61055C6.03002 2.64185 5.69425 2.98102 5.66882 3.59151C5.64338 4.2281 5.65864 4.86469 5.65864 5.50127C5.65864 5.51171 5.66373 5.51692 5.6739 5.54301H8.19727L7.86659 8.18328H5.66882C5.66882 8.24067 5.65864 8.28241 5.65864 8.32416C5.65864 10.5 5.65864 12.6811 5.65864 14.857C5.65864 14.8883 5.65864 14.9248 5.66373 14.9561H3.00808H3.003Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://instagram.com/lets_gavel" target="_blank">
                                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.1159 15.7602H3.94772C3.64247 15.6976 3.32705 15.6611 3.03198 15.5619C1.33786 14.988 0.254239 13.4643 0.249151 11.6433C0.238976 9.40481 0.249151 7.16633 0.249151 4.93306C0.249151 4.22342 0.401774 3.55031 0.74772 2.9346C1.54136 1.54664 2.73182 0.826565 4.30384 0.816129C6.45583 0.800475 8.60781 0.810911 10.7598 0.816129C11.0294 0.816129 11.3042 0.831783 11.5636 0.894398C13.1967 1.26487 14.2447 2.28758 14.6924 3.93644C14.7534 4.15559 14.7738 4.38518 14.8145 4.60955V11.9668C14.7891 12.1181 14.7738 12.2747 14.7433 12.426C14.3719 14.1009 13.3849 15.1758 11.7722 15.6402C11.5585 15.7028 11.3347 15.7237 11.121 15.7654L11.1159 15.7602ZM7.52928 4.19212C5.32642 4.19212 3.5509 6.01838 3.53564 8.28296C3.52546 10.5371 5.3315 12.3895 7.52928 12.3842C9.73214 12.3842 11.5127 10.558 11.5229 8.29339C11.5331 6.03926 9.72705 4.1869 7.52928 4.19212ZM12.9779 3.94687C12.9779 3.26333 12.4336 2.70501 11.7671 2.70501C11.1057 2.70501 10.5512 3.26855 10.5512 3.94687C10.5512 4.61999 11.1057 5.18874 11.762 5.19395C12.4183 5.19395 12.9728 4.63042 12.9728 3.94687H12.9779Z" fill="currentColor" />
                                                <path d="M12.113 3.93648C12.113 3.7382 11.9502 3.57645 11.7568 3.58167C11.5737 3.58167 11.416 3.74864 11.4109 3.93648C11.4109 4.12955 11.5737 4.30174 11.7619 4.30174C11.9502 4.30174 12.113 4.13476 12.113 3.93648Z" fill="currentColor" />
                                                <path d="M7.52918 5.07397C9.25891 5.07397 10.6681 6.51412 10.663 8.29342C10.663 10.0623 9.25891 11.5024 7.53427 11.5024C5.80454 11.5024 4.39532 10.0623 4.4004 8.28299C4.4004 6.51411 5.80454 5.07397 7.52918 5.07397Z" fill="currentColor" />
                                                <path d="M12.1131 3.93636C12.1131 4.13464 11.9503 4.30161 11.762 4.30161C11.5738 4.30161 11.4059 4.12942 11.411 3.93636C11.411 3.74852 11.5738 3.58676 11.7569 3.58154C11.9503 3.58154 12.1131 3.73808 12.1131 3.93636Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        <a href="https://twitter.com/lets_gavel" target="_blank">
                                            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.30814 5.86419C1.8118 6.12508 2.32563 6.29728 2.93612 6.31293C2.0153 5.62938 1.49129 4.74755 1.35393 3.65179C1.25218 2.83258 1.40989 2.0499 1.82706 1.31417C3.80608 3.70919 6.3091 5.0241 9.3463 5.23803C9.33104 4.85713 9.30051 4.50231 9.31069 4.14749C9.35139 2.61343 10.5062 1.14198 11.9562 0.761074C13.3145 0.406256 14.5101 0.724547 15.5326 1.70551C15.5835 1.75248 15.6853 1.78378 15.7565 1.76813C16.4687 1.62203 17.1403 1.36113 17.7711 0.995879C17.8169 0.969789 17.8627 0.948918 17.9441 0.907175C17.6643 1.78378 17.1453 2.42559 16.4382 2.93694C17.1199 2.86911 17.766 2.67083 18.3969 2.40471L18.4376 2.45167C18.1425 2.80649 17.8627 3.17174 17.5473 3.51091C17.298 3.77702 17.0029 3.99617 16.7383 4.24663C16.6773 4.30403 16.6264 4.40317 16.6264 4.48144C16.6264 8.35313 15.1358 11.4682 12.063 13.7328C10.7657 14.6877 9.28525 15.2095 7.70306 15.4182C6.20735 15.6217 4.73199 15.5225 3.28207 15.0947C2.36633 14.8233 1.50655 14.4268 0.697649 13.905C0.6773 13.8945 0.667125 13.8789 0.621338 13.8424C2.60544 14.025 4.37587 13.5293 5.98859 12.2874C4.89479 12.2196 4.01466 11.797 3.31768 10.9934C2.99209 10.6177 2.74789 10.1846 2.58509 9.67848C3.12436 9.74631 3.64328 9.75675 4.17746 9.60543C2.17301 9.08886 1.25218 7.2939 1.30305 5.87984L1.30814 5.86419Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='copyright'>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 44 }}>
                              <Col className="gutter-row" sm={12} lg={12}>
                                  <div className='copyrighttext '>
                                  Copyright © 2022  GAVEL. All rights reserved
                                  </div>
                              </Col>
                              <Col className="gutter-row" sm={12} lg={12}>
                                  <div className='contentright'>
                                    {/* <p>We use cookies for better service. <a href=''>Accept</a></p> */}
                                  </div>
                              </Col>
                            </Row>
                          </div>
                    </div>
                </Fade>
            </div>
      </div>

  )
}

export default Pagefooter

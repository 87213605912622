import {GrowthBook} from "@growthbook/growthbook-react";
import {message} from "antd";
import axios, {Axios} from "axios";
import { images } from "../Assets/ImageConfig/images";
import api from "./api";
import Config from "./config";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { DateTime } = require('luxon');
export function capitalize(str:string){
    return str && (str.slice(0,1).toUpperCase()+str.slice(1) || '');
  }


export function localDateTime(date:any){
    // log('localDateTime '+date+' ('+(typeof date)+')');
    if(typeof date === 'undefined') date = DateTime.now();
    else if(typeof date === 'number') date = DateTime.fromJSDate(new Date(date));
    else if(typeof date === 'string') date = DateTime.fromISO(date);
    else if(typeof date === 'object') date = DateTime.fromJSDate(date);

    const today = DateTime.now().set({hours: 0, minutes: 1});
    let day = date.diff(today, 'day').toObject().days;

    // if(day>=-1 && day<=2) day = capitalize(date.toRelativeCalendar({unit: 'days'}));
    // else if(day>2 && day<7) day = date.setLocale('en-gb').toLocaleString({ weekday: 'long' });
    // else day = date.toLocaleString({ day: 'numeric', month: 'numeric' });

    if(day>=-1 && day<=1) day = capitalize(date.toRelativeCalendar({unit: 'days'}));
    else day = date.toLocaleString({ day: 'numeric', month: 'numeric' });

    const time = date.toLocaleString(DateTime.TIME_24_SIMPLE);

    return day+', '+time+'';
  }

export const setUser = (userData: any) => {
  localStorage.setItem('user', JSON.stringify({
    accessToken: userData.accessToken,
    refreshToken: userData.refreshToken,
  }))
}

export const getUser = () => {

  const value = localStorage.getItem('user')

  return JSON.parse(value || '{}')
}

export const profilePictureHelper = (pic: any): any => {
  if (pic && pic?.startsWith(`https://`)) {
    return pic
  }
  else if (pic && pic !== '') {
    return `${Config.baseUrl}/assets/images/avatars/${pic}`
  }
  return images.profiledummy
};

export const getChannelURL = (channelName: string) => {
  return `https://ui-avatars.com/api/?name=${channelName}/?length=1/`
};

export const placeHolderProfileHelper = (e: any): any => {
  e.currentTarget.onerror = null
  e.currentTarget.src = images.profiledummy
}

export const capitalizeFirstLetter = (text: string) => {
  if(!text) return

  return text?.charAt(0)?.toUpperCase() + text?.slice(1).toLowerCase()
}

export const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const goToTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, 0);
};

export const getUserById = async (userId: any) => {
  try {
    let response
    if (!localStorage.getItem('access-token')) {
        response = await api.getDataWithOutToken(`/api/user/${userId}`, {bypass: true})
      }
      else {
        response = await api.getDataWithToken(`/api/user/${userId}`)
    }

    return response.data?.data;

  } catch (error) {
    throw error;
  }
}

export const fetchOrderDetails = async (orderId: any) => {
  try {
    const response = await api.getDataWithToken(`user/order/${orderId}`)
    return response.data?.data;

  } catch (error) {
    throw error;
  }
}

export const checkChannels = async (userId: any, channelId: any) => {
  try {
    const response = await api.getDataWithToken(`api/checkChannels/${userId}/${channelId}`)
    return response.data?.data;

  } catch (error) {
    throw error;
  }
}

export const retryPaymentAPI = async () => {
  try {
    const response = await api.getDataWithToken(`payments/retry`)
    return response.data?.data;

  } catch (error) {
    throw error;
  }
}

export const dateFormatConversion = (date: any) => {
  return new Date(date).toLocaleDateString('en-GB', {day: 'numeric', month: 'long', year: 'numeric'})
}

export const timeFormatConversion = (date: any) => {
  return new Date(date).toLocaleTimeString('en-US', {hour: 'numeric',minute:'2-digit'})
}

export const formatTime = (timestamp: Date) => {
  let currentTime: Date = new Date();
  const fulldays: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const months: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const date = timestamp.getDate();
  const month = months[timestamp.getMonth()];
  const year = timestamp.getFullYear();
  const diffDays = currentTime.getDate() - date;
  const diffMonths = currentTime.getMonth() - timestamp.getMonth();
  const diffYears = currentTime.getFullYear() - year;

  if (diffYears === 0 && diffMonths === 0 && diffDays === 0) {
    return timeFormatConversion(timestamp)
  } else if (diffYears === 0 && diffDays === 1) {
    return 'Yesterday';
  } else if (diffYears === 0 && diffDays > 1 && diffDays < 7) {
    return fulldays[timestamp.getDay()];
  } else {
    return `${date}/${month}/${year}`;
  }
};

export const sliceArr = (arr: any[], val: any) => {
  const newArr = [...arr];
  const index = newArr?.indexOf(val);
  if (index > -1) {
    newArr.splice(index, 1);
  }
  return newArr;
};

export const getCardIcon = (cardType: String) => {
  if (cardType === 'visa') {
    return images.visa
  }
  else if (cardType === 'mastercard') {
    return images.mastercard
  }
}

export const getBrowser = () => {
  let sBrowser = 'unknown';
  const sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Chrome";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }
  return sBrowser
};

export const displayInfoToast = (messageStr: string) => {
  message.info({icon: '👉', content: messageStr})
}

export const displayErrorToast = (messageStr: string) => {
  message.error({icon: '💥', content: messageStr})
}
export const getRefreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh-token');
    const response = await api.postDataWithToken('auth/revive', {token: refreshToken}, {
      'grant-type': 'refresh_token',
      'Authorization': `Bearer ${refreshToken}`
    });

    localStorage.setItem("access-token", response.data?.data?.[0]?.accessToken)
    localStorage.setItem("refresh-token", response.data?.data?.[0]?.refreshToken)
    return response.data?.data?.[0]?.accessToken;
  } catch (error) {
    throw error
  }
};

export const checkFeatureFlag = async () => {
  const start = new Date();
  axios.get(Config.growthBookURL)
    .then((res) => {
      const end = new Date();
      console.log(
        'checkFeatureflags done after ' + (end.getTime() - start.getTime()) + 'ms',
        res.data.features
      );
      growthbook.setFeatures(res.data.features);
    })
    .catch((err) => {
      console.log('checkFeatureflags failed', err);
      growthbook.setFeatures({
        payment_giropay: { defaultValue: true },
        payment_applepay: { defaultValue: true },
        payment_paypal: { defaultValue: true },
        payment_eps: { defaultValue: true },
        payment_stripe_paypal: { defaultValue: true },
        messages: { defaultValue: true }
      });
    });
}

export const growthbook = new GrowthBook({
  // enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    console.log('growthbook experiment', {
      experimentId: experiment.key,
      variationId: result.variationId
    });
  }
});

export const getErrorMessage = (err: any) => {
  return err.response?.data?.error?.[0]?.message || err.response?.data?.message;
};

export const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}
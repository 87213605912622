import React from "react";

function Tiktok() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4ZM6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6Z" fill="currentColor"/>
    <path d="M14 7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7V14.5C12 15.3284 11.3284 16 10.5 16C9.67157 16 9 15.3284 9 14.5C9 13.8125 9.56207 13.1095 10.1989 12.9802C10.7402 12.8703 11.0899 12.3425 10.98 11.8013C10.8701 11.26 10.3423 10.9103 9.80107 11.0202C8.1731 11.3506 7 12.9227 7 14.5C7 16.433 8.567 18 10.5 18C12.4319 18 13.9982 16.4347 14 14.5032V14.5V10.4649C14.5883 10.8052 15.2714 11 16 11C16.5523 11 17 10.5523 17 10C17 9.44771 16.5523 9 16 9C14.8954 9 14 8.10457 14 7Z" fill="currentColor"/>
    </svg>
    

  );
}

export default Tiktok;
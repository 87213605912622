import React, { useEffect, useState } from 'react'
import {  Modal } from 'antd'
import { images } from '../../Assets/ImageConfig/images'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DeepLinkCheck({ id, visible, onOk, onCancel }: any) {

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(visible)
  },[visible])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    onOk()
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    onCancel()
    setIsModalVisible(false);
  };
  //detect client OS and redirect to its deeplink url and if app is not installed in client device then it'll redirect to web page detail page
  useEffect(() => {

    let deepLink: any = `com.gavel.collectibles`
    const userOS = detectClientOS()
    if (userOS === 'iOS') {
      //if App is not installed then we don't need to fire deeplink in case of iOS only
      deepLink = `letsgavel.com://show/0`
    }
    if (userOS === 'Android') {
      deepLink = `letsgavel.com://show/0`
    }
    // @ts-ignore

    if (userOS !== 'unknown') {
      if (userOS === 'iOS') {
        // @ts-ignore
        document?.getElementById("clickCheck").click();
      }
      window.location.href = deepLink
    // eslint-disable-next-line no-empty
    } else {}

  }, [])

  //detect scanned device OS
  const detectClientOS = () => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
    }
    if (/android/i.test(userAgent)) {
      return 'Android'
    }
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    }
    return 'unknown'
  }
  return (
    <div>
      <a href="letsgavel.com://show/0" id="clickCheck"></a>
        {/* Breakpoint modal */}
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
        <Modal className='themeModal breakpointModal' zIndex={1000} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={380}  footer={false} centered >
          <p>Please download the Gavel mobile app for the best live shopping experience</p>
          <div className='downloadbtn'>
            <a href="https://play.google.com/store/apps/details?id=com.gavel.collectibles" className="androidbtn">
            <img src={images.Playstore} alt='Google Play' />
            </a>
            <a href="https://apps.apple.com/at/app/gavel-live-collectibles-sale/id1570409915" className="iosbtn">
            <img src={images.Appstore} alt='Apple Appstore' />
            </a>
          </div>
        </Modal>
       {/* Breakpoint modal */}
    </div>
  )
}

export default DeepLinkCheck
/* eslint-disable complexity */
import mParticle, {IdentityApiData, MPConfiguration} from "@mparticle/web-sdk";

import { strings } from './constants'
import { getOS } from './helper';

import packageJson from '../../package.json';

declare const window: Window &
   typeof globalThis & {
    mParticle: any
}

// const mParticle = window.mParticle
// var mParticle: any;
var locale = window.navigator.language;
const platformOs = getOS()

const LANGUAGE: number = 1;
const TCGS: number = 2;
const CATEGORY: number = 3;

export const init_mParticle = () => {

  const getLogLevel = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development')
      return 'verbose'
    return 'none'
  }

  const getIsDevelopmentMode = () => {
    return process.env.REACT_APP_ENVIRONMENT === 'stage' || process.env.REACT_APP_ENVIRONMENT === 'development'
  };

  // const isLoggedIn = localStorage.getItem('access-token')

  let mParticleConfig: MPConfiguration = {
    isDevelopmentMode: getIsDevelopmentMode(),
    logLevel: getLogLevel(),
    appVersion: packageJson.version,
    // identifyRequest: !isLoggedIn ? {
    //   userIdentities: {
    //     other: 'guest-1111'
    //   }
    // } : undefined,
    // identifyRequest: {
    //   userIdentities: {
    //     email: "email@example.com",
    //     customerid: "123456"
    //   }
    // },
    identityCallback: (result: any) => {
      console.log({ result });
    }
  };
  mParticle.init(`${process.env.REACT_APP_MPARTICLE_API_KEY}`, mParticleConfig);
};

export const show_entered = (user: any | undefined, show: any) => {
  if (user?.id !== show?.user_id) {

    mParticle.logEvent(strings.showEntered, mParticle.EventType.Other, {
      screen_group: strings.shows,
      screen_name: strings.showScreen,
      show_id: show?.id,
      show_live: show?.status === strings.live,
      show_interest: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == CATEGORY)
        .map((element:any) => element?.slug)
        .join(','),
      show_interest_category: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == TCGS)
        .map((element:any) => element?.slug)
        .join(','),
      host_id: show?.user_id,
      user_host_status: user?.host,
      show_language: (show?.topicData || []).map((element:any) => element?.slug).join(','),
      show_shipping_country: '', //TODO add show shipping country
      user_ltv: user?.paid || 0,
      user_language: locale,
      user_country: user?.country,
      platform: platformOs
    });
    if (user?.firstShowEnter) {
      mParticle.logEvent(strings.firstShowEntered, mParticle.EventType.Other, {
        screen_group: strings.shows,
        screen_name: strings.showScreen,
        show_id: show?.id,
        show_live: show?.status === strings.live,
        show_interest: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == CATEGORY)
          .map((element:any) => element?.slug)
          .join(','),
        show_interest_category: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == TCGS)
          .map((element:any) => element?.slug)
          .join(','),
        host_id: show?.user_id,
        user_host_status: user?.host,
        show_language: (show?.topicData || []).map((element:any) => element?.slug).join(','),
        show_shipping_country: '', //TODO add show shipping country
        user_ltv: user?.paid || 0,
        user_language: locale,
        user_country: user?.country,
        platform: platformOs
      });
    }
  }
};

export const show_list_screen_viewed = (
  user: any | undefined,
  liveShowsData: any
) => {
  let liveInterest: string[] = [];
  let liveLanguages: Set<string> = new Set<string>();
  for (const show of liveShowsData?.shows) {
    liveInterest = liveInterest.concat((show.topicData || []).map((showTopic:any) => showTopic.slug));
    (show.languagesData || []).map((showTopic:any) => liveLanguages.add(showTopic.slug));
  }

  mParticle.logEvent(strings.showListScreenViewed, mParticle.EventType.Other, {
    screen_group: strings.shows,
    screen_name: strings.shows,
    user_host_status: user?.host || false,
    user_ltv: user?.paid_value || 0,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    live_shows: liveShowsData?.totalCount,
    live_interests: liveInterest.join(','),
    user_language: locale,
    user_country: user?.country,
    platform: platformOs,
    show_language: Array.from(liveLanguages).join(',')
  });
};

export const show_watched = async(user: any | undefined, show: any, duration: number) => {
  if (user?.id !== show?.user_id) {
    mParticle.logEvent(strings.showWatched, mParticle.EventType.Other, {
      screen_group: strings.shows,
      screen_name: strings.showScreen,
      show_id: show?.id,
      show_interest: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == CATEGORY)
        .map((element:any) => element?.slug)
        .join(','),
      show_interest_category: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == TCGS)
        .map((element:any) => element?.slug)
        .join(','),
      show_live: show?.status === strings.live,
      host_id: show?.user_id,
      user_host_status: user?.host,
      show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
      show_shipping_country: '', //TODO add show shipping country
      user_ltv: user?.paid || 0,
      user_language: locale,
      user_country: user?.country,
      platform: platformOs,
      watch_time: duration
    });
    if (user?.firstWatch) {
      await mParticle.logEvent(strings.firstShowWatched, mParticle.EventType.Other, {
        screen_group: strings.shows,
        screen_name: strings.showScreen,
        show_id: show?.id,
        show_interest: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == CATEGORY)
          .map((element:any) => element?.slug)
          .join(','),
        show_interest_category: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == TCGS)
          .map((element:any) => element?.slug)
          .join(','),
        show_live: show?.status === strings.live,
        host_id: show?.user_id,
        user_host_status: user?.host,
        show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
        show_shipping_country: '', //TODO add show shipping country
        user_ltv: user?.paid || 0,
        user_language: locale,
        user_country: user?.country,
        platform: platformOs,
        watch_time: duration
      });
      return true
    }
  }
};

export const show_left = (user: any | undefined, show: any, duration: number) => {
  if (user?.id !== show?.user_id)
    mParticle.logEvent(strings.showLeft, mParticle.EventType.Other, {
      screen_group: strings.shows,
      screen_name: strings.showScreen,
      show_id: show?.id,
      show_interest: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == CATEGORY)
        .map((element:any) => element?.slug)
        .join(','),
      show_interest_category: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == TCGS)
        .map((element:any) => element?.slug)
        .join(','),
      show_live: show?.status === strings.live,
      host_id: show?.user_id,
      user_host_status: user?.host,
      show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
      show_shipping_country: '', //TODO add show shipping country
      user_ltv: user?.paid || 0,
      user_language: locale,
      user_country: user?.country,
      platform: platformOs,
      watch_time: duration
    });
};

export const item_viewed = (user: any | undefined, show: any, item: any) => {
  mParticle.logEvent(strings.itemViewed, mParticle.EventType.Other, {
    screen_group: strings.shows,
    screen_name: strings.showScreen,
    show_id: show?.id,
    show_interest: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == CATEGORY)
      .map((element:any) => element?.slug)
      .join(','),
    show_interest_category: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == TCGS)
      .map((element:any) => element?.slug)
      .join(','),
    show_language: (show?.languagesData || [])
      .filter((element:any) => element?.categoryOrder == LANGUAGE)
      .map((element:any) => element?.slug)
      .join(','),
    show_shipping_country: '', //TODO- check delivery countries
    currency: show?.currency === strings.currencyEuro ? strings.euroText : strings.poundText,
    item_product_category: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == TCGS)
      .map((element:any) => element?.slug)
      .join(','),
    user_host_status: user?.host,
    host_id: show?.user_id,
    platform: platformOs,
    sale_type: item?.instant ? strings.fixedPrice : strings.auction,
    start_price: item?.start_price,
    user_language: locale,
    user_country: user?.country,
    user_ltv: user?.paid || 0
  });
};

export const bid_placed = async (
  show: any,
  item: any,
  start_price: number,
  bid_amount: number,
  user:any
) => {
  if (user?.id !== show?.user_id) {
    mParticle.logEvent(strings.bidPlaced, mParticle.EventType.Other, {
      screen_group: strings.shows,
      screen_name: strings.showScreen,
      show_id: show?.id,
      show_interest: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == CATEGORY)
        .map((element:any) => element?.slug)
        .join(','),
      show_interest_category: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == TCGS)
        .map((element:any) => element?.slug)
        .join(','),
      show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
      show_shipping_country: '',
      currency: show?.currency === strings.currencyEuro ? strings.euroText : strings.poundText,
      host_id: show?.user_id,
      sale_type: item?.instant ? strings.fixedPrice : strings.auction,
      sell_id: `${show?.id}_${new Date().getTime()}`,
      item_product_category: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == TCGS)
        .map((element:any) => element?.slug)
        .join(','),
      platform: platformOs,
      user_host_status: user?.host,
      start_price,
      user_language: locale,
      user_country: user?.country,
      bid_amount,
      user_type: user?.isGuest
        ? strings.guest
        : (user?.paid || 0) > 0
        ? strings.existing
        : strings.newUser,
      user_limit: 0,
      prepaid_balance: user?.walletAmount
    });
    if (user?.firstBid) {
      mParticle.logEvent(strings.firstBidPlaced, mParticle.EventType.Other, {
        screen_group: strings.shows,
        screen_name: strings.showScreen,
        show_id: show?.id,
        show_interest: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == CATEGORY)
          .map((element:any) => element?.slug)
          .join(','),
        show_interest_category: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == TCGS)
          .map((element:any) => element?.slug)
          .join(','),
        show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
        show_shipping_country: '',
        currency: show?.currency === strings.currencyEuro ? strings.euroText : strings.poundText,
        host_id: show?.user_id,
        sale_type: item?.instant ? strings.fixedPrice : strings.auction,
        sell_id: `${show?.id}_${new Date().getTime()}`,
        item_product_category: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == TCGS)
          .map((element:any) => element?.slug)
          .join(','),
        platform: platformOs,
        user_host_status: user?.host,
        start_price,
        user_language: locale,
        user_country: user?.country,
        bid_amount,
        user_type: user?.isGuest
          ? strings.guest
          : (user?.paid || 0) > 0
          ? strings.existing
          : strings.newUser,
        user_limit: 0,
        prepaid_balance: user?.walletAmount
      });
    }
  }
};

export const purchase = async (
  show: any,
  item: any,
  start_price: number,
  sellId: number,
  bid_amount: number,
  user:any
) => {
  console.log('inside purchase',{user});

  const product = mParticle.eCommerce.createProduct(item?.title, item?.instant ? 'Fixed' : 'Auction', bid_amount)
  // const product = new mParticle.Product(item?.title, item?.instant ? 'Fixed' : 'Auction', bid_amount);
  // const transactionAttributes = new mParticle.TransactionAttributes(sellId);
  const transactionAttributes = {
    Id: sellId
  }
  // const event = mParticle.CommerceEvent.createProductActionEvent(
  //   mParticle.ProductActionType.Purchase,
  //   [product],
  //   transactionAttributes
  // );
  const customAttributes = {
    show_id: show?.id,
    show_interest: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == CATEGORY)
      .map((element:any) => element?.slug)
      .join(','),
    show_interest_category: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == TCGS)
      .map((element:any) => element?.slug)
      .join(','),
    show_language: (show?.languagesData || [])
      .filter((element:any) => element?.categoryOrder == LANGUAGE)
      .map((element:any) => element?.slug)
      .join(','),
    show_shipping_country: '',
    item_product_category: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == TCGS)
      .map((element:any) => element?.slug)
      .join(','),
    currency: show?.currency === strings.currencyEuro ? strings.euroText : strings.poundText,
    host_id: show?.user_id,
    sale_type: item?.type,
    sell_id: `${show?.id}_${new Date().getTime()}`,
    user_host_status: user?.host,
    user_language: locale,
    user_country: user?.country,
    start_price: item?.instant ? bid_amount : start_price
  }
  // const event =
    mParticle.eCommerce.logProductAction(mParticle.ProductActionType.Purchase, [product], customAttributes, {}, transactionAttributes);
  // event.setCustomAttributes({
  //   show_id: show?.id,
  //   show_interest: (show?.topicData || [])
  //     .filter((element:any) => element?.categoryOrder == CATEGORY)
  //     .map((element:any) => element?.slug)
  //     .join(','),
  //   show_interest_category: (show?.topicData || [])
  //     .filter((element:any) => element?.categoryOrder == TCGS)
  //     .map((element:any) => element?.slug)
  //     .join(','),
  //   show_language: (show?.languagesData || [])
  //     .filter((element:any) => element?.categoryOrder == LANGUAGE)
  //     .map((element:any) => element?.slug)
  //     .join(','),
  //   show_shipping_country: '',
  //   item_product_category: (show?.topicData || [])
  //     .filter((element:any) => element?.categoryOrder == TCGS)
  //     .map((element:any) => element?.slug)
  //     .join(','),
  //   currency: show?.currency === strings.currencyEuro ? strings.euroText : strings.poundText,
  //   host_id: show?.user_id,
  //   sale_type: item?.type,
  //   sell_id: `${show?.id}_${new Date().getTime()}`,
  //   user_host_status: user?.host,
  //   user_language: locale,
  //   user_country: user?.country,
  //   start_price: item?.instant ? bid_amount : start_price
  // });
  // mParticle.logCommerceEvent(event);
  // // TODO - check this with the new payment workflow.
  if (user?.firstPurchase) {
    mParticle.logEvent(strings.firstPurchase, mParticle.EventType.Other, {
      screen_group: strings.shows,
      screen_name: strings.showScreen,
      show_id: show?.id,
      show_interest: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == CATEGORY)
        .map((element:any) => element?.slug)
        .join(','),
      show_interest_category: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == TCGS)
        .map((element:any) => element?.slug)
        .join(','),
      show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
      show_shipping_country: '',
      item_product_category: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == TCGS)
        .map((element:any) => element?.slug)
        .join(','),
      currency: show?.currency === strings.currencyEuro ? strings.euroText : strings.poundText,
      host_id: show?.user_id,
      platform: platformOs,
      sale_type: item?.type,
      sell_id: `${show?.id}_${new Date().getTime()}`,
      user_host_status: user?.host,
      user_language: locale,
      user_country: user?.country,
      start_price: item?.instant ? bid_amount : start_price,
      bid_amount
    });
  }
};

export const user_follow = (
  user: any | undefined,
  following_id: number | undefined,
  button_id: string,
  screen_group: string,
  screen_name: string
) => {
  mParticle.logEvent(strings.userFollow, mParticle.EventType.Other, {
    screen_group,
    screen_name,
    user_host_status: user?.host,
    user_interest: user?.userTopics?.map((topic: any) => topic?.topicName).join(','),
    button_id,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    followed_user_id: following_id
  });
};

export const user_unfollow = (
  user: any,
  following_id: number | undefined,
  button_id: string,
  screen_group: string,
  screen_name: string
) => {
  mParticle.logEvent(strings.userUnfollow, mParticle.EventType.Other, {
    screen_group,
    screen_name,
    user_host_status: user?.host,
    user_interest: user?.userTopics?.map((topic: any) => topic?.topicName).join(','),
    button_id,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    followed_user_id: following_id
  });
};

export const discord_button_clicked = (button_id?: string, user?: any) => {
  mParticle.logEvent(strings.discordButtonClicked, mParticle.EventType.Other, {
    screen_group: strings.home,
    screen_name: strings.home,
    user_host_status: user?.host,
    button_id,
    user_language: locale,
    user_country: user?.country,
    platform: platformOs
  });
};

export const host_application_button_clicked = (
  user: any,
  screen_group: string,
  screen_name: string,
  button_id: string
) => {
  mParticle.logEvent(strings.hostApplicationButtonClicked, mParticle.EventType.Other, {
    screen_group,
    screen_name,
    user_host_status: user?.host,
    button_id,
    user_language: locale,
    user_country: user?.country,
    platform: platformOs
  });
};

export const sign_in_success = (login_type: string, user: any | undefined) => {
    mParticle.logEvent(strings.signInSuccess, mParticle.EventType.Other, {
        screen_group: strings.authScreen,
        screen_name: strings.signInScreen,
        login_type,
        user_host_status: user?.host,
        user_type: user?.isGuest
            ? strings.guest
            : (user?.paid || 0) > 0
                ? strings.existing
                : strings.newUser,
        user_language: locale,
        user_country: user?.country,
        platform: platformOs
    });
    if (user && user?.id && typeof user?.id === 'number') {
        identify_user(user);
    }
};

export const sign_in_fail = (
    login_type: string,
    error_category: string,
    error_message: string,
    error_code?: string
  ) => {
    mParticle.logEvent(strings.authFail, mParticle.EventType.Other, {
      screen_group: strings.authScreen,
      screen_name: strings.signInScreen,
      login_type,
      user_language: locale,
      user_country: '',
      platform: platformOs,
      error_category,
      error_message,
      error_type: strings.unexpectedError,
      error_code
    });
  };

  export const registration_success = (
    login_type: string,
    topics: any[],
    user: any | undefined
  ) => {
    if (!user?.isGuest)
      mParticle.logEvent(strings.registrationSuccess, mParticle.EventType.Other, {
        screen_category: strings.authScreen,
        screen_name: strings.signUpScreen,
        login_type: login_type || '',
        user_language: locale,
        user_interest: topics.map((topic) => topic?.name).join(','),
        user_country: user?.country,
        platform: platformOs
      });
    identify_user(user);
  };

  export const registration_fail = (
    login_type: string,
    error_category: string,
    error_message: string,
    error_code?: string
  ) => {
    mParticle.logEvent(strings.registrationFail, mParticle.EventType.Other, {
      screen_group: strings.authScreen,
      screen_name: strings.signUpScreen,
      login_type,
      user_language: locale,
      user_country: '',
      platform: platformOs,
      error_type: strings.unexpectedError,
      error_category,
      error_message,
      error_code
    });
  };

  export const profile_screen_view = (user: any | undefined) => {
    mParticle.logEvent(strings.profileScreenView, mParticle.EventType.Other, {
      screen_group: strings.profileScreen,
      screen_name: strings.profileScreen,
      user_language: locale,
      user_country: user?.country,
      platform: platformOs
    });
  };

  export const give_away_started = (
    user: any | undefined,
    show: any,
    screen_group: string,
    screen_name: string
  ) => {
    if (user?.id !== show?.user_id)
      mParticle.logEvent(strings.giveAwayStarted, mParticle.EventType.Other, {
        screen_group,
        screen_name,
        show_id: show?.id,
        show_interest: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == CATEGORY)
          .map((element:any) => element?.slug)
          .join(','),
        show_interest_category: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == TCGS)
          .map((element:any) => element?.slug)
          .join(','),
        show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
        show_shipping_country: '',
        item_product_category: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == TCGS)
          .map((element:any) => element?.slug)
          .join(','),
        user_language: locale,
        user_country: user?.country,
        user_ltv: user?.paid_value || 0,
        platform: platformOs
      });
  };

  export const give_away_won = (
    user:any | undefined,
    show: any,
    screen_group: string,
    screen_name: string
  ) => {
    if (user?.id !== show?.user_id)
      mParticle.logEvent(strings.giveAwayWon, mParticle.EventType.Other, {
        screen_group,
        screen_name,
        show_id: show?.id,
        show_interest: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == CATEGORY)
          .map((element:any) => element?.slug)
          .join(','),
        show_interest_category: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == TCGS)
          .map((element:any) => element?.slug)
          .join(','),
        show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
        show_shipping_country: '',
        item_product_category: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == TCGS)
          .map((element:any) => element?.slug)
          .join(','),
        user_language: locale,
        user_country: user?.country,
        user_ltv: user?.paid || 0,
        platform: platformOs
      });
  };

  export const payment_event = async (
    event_name: string,
    screen_group: string,
    screen_name: string,
    currency: string,
    delivery_fee: number,
    revenue: number,
    payment_type?: string,
    user?:any
  ) => {
    // const user = await User.get(true);
    mParticle.logEvent(event_name, mParticle.EventType.Other, {
      screen_group,
      screen_name,
      currency: currency === strings.currencyEuro ? strings.euroText : strings.poundText,
      delivery_fee,
      platform: platformOs,
      user_country: user?.country,
      user_language: locale,
      revenue,
      payment_type
    });

    if (user?.firstPayment) {
      first_payment_success(
        strings.showScreen,
        strings.show,
        currency,
        delivery_fee,
        revenue,
        user,
        payment_type,
        ''
      );
    }
  };

  export const chat_message_sent = (show: any, user: any | undefined) => {
    if (user?.id !== show?.user_id)
      mParticle.logEvent(strings.chatMessageSent, mParticle.EventType.Other, {
        screen_group: 'Show',
        screen_name: strings.showScreen,
        show_id: show?.id,
        show_interest: (show?.topicData || [])
          .filter((element:any) => element?.categoryOrder == CATEGORY)
          .map((element:any) => element?.slug)
          .join(','),
        host_id: show?.user_id,
        platform: platformOs,
        user_language: locale,
        user_country: user?.country
      });
  };

  export const screen_viewed = (
    event_name: string,
    screen_group: string,
    screen_name: string,
    user?: any
  ) => {
    mParticle.logEvent(event_name, mParticle.EventType.Other, {
      screen_group,
      screen_name,
      platform: platformOs,
      user_language: locale,
      user_country: user?.country
    });
  };


export const auth_method_selected = (login_type: string) => {
  mParticle.logEvent(strings.authMethodSelected, mParticle.EventType.Other, {
    screen_group: strings.authScreen,
    screen_name: strings.signInScreen,
    login_type,
    user_language: locale,
    user_country: '',
    platform: platformOs
  });
};

export const show_reminder_event = (
  activate: boolean,
  screen_group: string,
  screen_name: string,
  user: any,
  show:any,
  button_id?: string
) => {
  mParticle.logEvent(
    activate ? strings.showReminderActivated : strings.showReminderDeactivated,
    mParticle.EventType.Other,
    {
      screen_group,
      screen_name,
      button_id,
      user_host_status: user?.host,
      host_interest: '',
      user_language: locale,
      user_country: user?.country,
      show_interest: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == CATEGORY)
        .map((element:any) => element?.slug)
        .join(','),
      show_interest_category: (show?.topicData || [])
        .filter((element:any) => element?.categoryOrder == TCGS)
        .map((element:any) => element?.slug)
        .join(','),
      show_id: show?.id,
      host_id: show?.user_id,
      platform: platformOs,
      show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
      show_shipping_country: '' // TODO - misisng country
    }
  );
};

export const account_created_success = (user: any | undefined, login_type: string) => {
  mParticle.logEvent(strings.accountCreatedSuccess, mParticle.EventType.Other, {
    screen_category: strings.authScreen,
    screen_name: strings.signUpScreen,
    login_type,
    user_language: locale,
    user_country: user?.country,
    platform:  platformOs
  });
};

export const identify_user = (user?: any) => {
  console.log('inside identify_user', {user});

  if (user && user?.id && typeof user?.id === 'number' && !user?.isGuest) {
    const identityRequest: IdentityApiData = {
      userIdentities: {
        customerid: String(user?.id) || ""
      }
    };

    if (user?.email) {
      identityRequest.userIdentities.email = user?.email
    }

    if (user?.phone) {
      identityRequest.userIdentities.mobile_number = user?.phone
    }

    const identityCallback = function (result: any) {
      console.log('before identity:', {result, userFromResult: result.getUser()});

      // if (result.getUser()) {
        //     //proceed with login
        // Copy attributes from previous user to current user
        result.getUser().setUserAttributes(result.getPreviousUser().getAllUserAttributes());
        // Create and send the alias request
        const aliasRequest = mParticle.Identity.createAliasRequest(result.getPreviousUser(), result.getUser());
        console.log('identify::', {aliasRequest});

        mParticle.Identity.aliasUsers(aliasRequest, console.log);
      // }
    };
    mParticle.Identity.login(identityRequest, identityCallback);
  }
};

export const logout_user = () => {
  const identityCallback = (result: any) => {
    console.log('logout:::',{result});

    if (result.getUser()) {
      // proceed with logout
    }
  }
  mParticle.Identity.logout({}, identityCallback)
}

export const wallet_screen_viewed = (
  user: any | undefined,
  source_button_name: string,
  source_button_id: string
) => {
  mParticle.logEvent(strings.walletScreenViewed, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.paymentWallets,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    prepaid_balance: user?.walletAmount || 0,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    source_button_name,
    source_button_id
  });
};

export const address_screen_viewed = (
  user: any | undefined,
  number_of_addresses: number,
  source_button_name: string,
  source_button_id: string
) => {
  mParticle.logEvent(strings.addressScreenViewed, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.addAddress,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    number_of_addresses,
    source_button_name,
    source_button_id
  });
};

export const address_added_success = (
  user: any | undefined,
  number_of_addresses: number,
  address_country: string
) => {
  mParticle.logEvent(strings.addressAddedSuccess, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.addAddress,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    number_of_addresses,
    address_country
  });
};

export const address_added_fail = (
  user: any | undefined,
  number_of_addresses: number,
  address_country: string,
  error_category: string,
  error_message: string,
  error_type: string
) => {
  mParticle.logEvent(strings.addressAddedFail, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.addAddress,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    number_of_addresses,
    address_country,
    error_category,
    error_message,
    error_type
  });
};


export const wallet_topup_success = (
  user: any | undefined,
  topup_method: string|null,
  topup_amount: number
) => {
  mParticle.logEvent(strings.walletTopupSuccess, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.topUpBalance,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    prepaid_balance: user?.walletAmount,
    topup_method,
    topup_amount
  });
  payment_event(
    strings.paymentSuccessEvent,
    strings.showScreen,
    strings.show,
    strings.euroText,
    0,
    topup_amount,
    'card',
    user
  );
};

export const wallet_topup_fail = (
  user: any | undefined,
  topup_method: string|null,
  topup_amount: number,
  error_category: string,
  error_message: string,
  error_type: string
) => {
  mParticle.logEvent(strings.walletTopupFail, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.topUpBalance,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    prepaid_balance: user?.walletAmount,
    topup_method,
    topup_amount,
    error_category,
    error_message,
    error_type
  });
};

export const wallet_card_added = (user: any | undefined, card_type: string) => {
  mParticle.logEvent(strings.walletCardAdded, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.addCreditCardScreen,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    prepaid_balance: user?.walletAmount,
    card_type
  });
};

export const add_topup_button_clicked = (user: any | undefined) => {
  mParticle.logEvent(strings.addTopupButtonClicked, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.paymentWalletAndAddress,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    prepaid_balance: user?.walletAmount,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country
  });
};

export const add_card_button_clicked = (user: any | undefined) => {
  mParticle.logEvent(strings.addCardButtonClicked, mParticle.EventType.Other, {
    screen_group: strings.paymentWallets,
    screen_name: strings.paymentWalletAndAddress,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    prepaid_balance: user?.walletAmount,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country
  });
};

export const bid_clicked = (
  user: any | undefined,
  show: any,
  item: any,
  bid_amount: number
) => {
  mParticle.logEvent(strings.bidClicked, mParticle.EventType.Other, {
    screen_group: strings.showScreen,
    screen_name: strings.show,
    show_id: show.id,
    show_interest: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == CATEGORY)
      .map((element:any) => element?.slug)
      .join(','),
    show_interest_category: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == TCGS)
      .map((element:any) => element?.slug)
      .join(','),

    show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
    show_shipping_country: '',
    currency: show?.currency === strings.currencyEuro ? strings.euroText : strings.poundText,
    host_id: show?.user_id,
    sale_type: item?.instant ? strings.fixedPrice : strings.auction,
    sell_id: `${show?.id}_${new Date().getTime()}`,
    platform:platformOs,
    user_host_status: user?.host,
    start_price: item?.start_price,
    user_language: locale,
    user_country: user?.country,
    bid_amount,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    user_limit: 0, //TODO- change this
    prepaid_balance: user?.walletAmount
  });
};

export const bid_failed = (
  user: any | undefined,
  show: any,
  item: any,
  bid_amount: number,
  error_category: string,
  error_message: string,
  error_type: string
) => {
  mParticle.logEvent(strings.bidFailed, mParticle.EventType.Other, {
    screen_group: strings.showScreen,
    screen_name: strings.show,
    show_id: show.id,
    show_interest: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == CATEGORY)
      .map((element:any) => element?.slug)
      .join(','),
    show_interest_category: (show?.topicData || [])
      .filter((element:any) => element?.categoryOrder == TCGS)
      .map((element:any) => element?.slug)
      .join(','),

    show_language: (show?.languagesData || []).map((element:any) => element?.slug).join(','),
    show_shipping_country: '',
    currency: show?.currency === strings.currencyEuro ? strings.euroText : strings.poundText,
    host_id: show?.user_id,
    sale_type: item?.instant ? strings.fixedPrice : strings.auction,
    //sell_id: sell
    platform:platformOs,
    user_host_status: user?.host,
    start_price: item?.start_price,
    user_language: locale,
    user_country: user?.country,
    bid_amount,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    user_limit: 0, //TODO- change this
    prepaid_balance: user?.walletAmount,
    error_category,
    error_message,
    error_type
  });
};

export const wallet_badge_clicked = (
  user: any | undefined,
  button_id: string,
  button_text: string
) => {
  mParticle.logEvent(strings.walletBadgeClicked, mParticle.EventType.Other, {
    screen_group: strings.showScreen,
    screen_name: strings.show,
    user_host_status: user?.host,
    user_type: user?.isGuest
      ? strings.guest
      : (user?.paid_value || 0) > 0
      ? strings.existing
      : strings.newUser,
    prepaid_balance: user?.walletAmount,
    platform: platformOs,
    user_language: locale,
    user_country: user?.country,
    button_id,
    button_text
  });
};

export const payment_fail = (
  user: any | undefined,
  currency: string,
  delivery_fee: number,
  revenue: number,
  sell_id: string,
  payment_type: string,
  error_category: string,
  error_message: string,
  error_type: string
) => {
  mParticle.logEvent(strings.paymentFail, mParticle.EventType.Other, {
    screen_group: strings.showScreen,
    screen_name: strings.show,
    currency: currency === strings.currencyEuro ? strings.euroText : strings.poundText,
    delivery_fee,
    platform: platformOs,
    user_country: user?.country,
    user_language: locale,
    revenue,
    payment_type,
    sell_id,
    error_category,
    error_message,
    error_type
  });
};

export const first_payment_success = (
  screen_group: string,
  screen_name: string,
  currency: string,
  delivery_fee: number,
  revenue: number,
  user: any | undefined,
  payment_type: string | undefined,
  transaction_id: string
) => {
  mParticle.logEvent(strings.firstPaymentSuccess, mParticle.EventType.Other, {
    screen_group,
    screen_name,
    currency: currency === strings.currencyEuro ? strings.euroText : strings.poundText,
    delivery_fee,
    platform: platformOs,
    user_country: user?.country,
    user_language: locale,
    revenue,
    payment_type,
    transaction_id
  });
};
import React from "react";

function Bookmark() {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 16.3494C0.5 7.51281 7.66344 0.349365 16.5 0.349365C25.3366 0.349365 32.5 7.51281 32.5 16.3494C32.5 25.1859 25.3366 32.3494 16.5 32.3494C7.66344 32.3494 0.5 25.1859 0.5 16.3494Z" fill="#B2A8EE"/>
      <path d="M13.5 9.34937H19.5C20.0304 9.34937 20.5391 9.53374 20.9142 9.86193C21.2893 10.1901 21.5 10.6352 21.5 11.0994V20.0405C21.5 21.5195 21.5 22.259 21.0173 22.5507C20.5346 22.8425 19.8798 22.4988 18.5703 21.8113L17.4297 21.2124C16.9743 20.9733 16.7465 20.8538 16.5 20.8538C16.2535 20.8538 16.0257 20.9733 15.5703 21.2124L14.4297 21.8113C13.1202 22.4988 12.4654 22.8425 11.9827 22.5507C11.5 22.259 11.5 21.5195 11.5 20.0405V11.0994C11.5 10.6352 11.7107 10.1901 12.0858 9.86193C12.4609 9.53374 12.9696 9.34937 13.5 9.34937Z" stroke="#202C47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.5 14.8494L16 16.3494L18.5 13.3494" stroke="#202C47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default Bookmark;


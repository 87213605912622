import React from "react";

function Lock() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.167 9.16675H5.83366C4.91318 9.16675 4.16699 9.91294 4.16699 10.8334V15.8334C4.16699 16.7539 4.91318 17.5001 5.83366 17.5001H14.167C15.0875 17.5001 15.8337 16.7539 15.8337 15.8334V10.8334C15.8337 9.91294 15.0875 9.16675 14.167 9.16675Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.0003 14.1667C10.4606 14.1667 10.8337 13.7936 10.8337 13.3333C10.8337 12.8731 10.4606 12.5 10.0003 12.5C9.54009 12.5 9.16699 12.8731 9.16699 13.3333C9.16699 13.7936 9.54009 14.1667 10.0003 14.1667Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.66699 9.16667V5.83333C6.66699 4.94928 7.01818 4.10143 7.6433 3.47631C8.26842 2.85119 9.11627 2.5 10.0003 2.5C10.8844 2.5 11.7322 2.85119 12.3573 3.47631C12.9825 4.10143 13.3337 4.94928 13.3337 5.83333V9.16667" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default Lock;
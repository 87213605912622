import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import './CardEmpty.scss';

function CardEmpty(props: any) {
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  return (

      <div className='CardEmpty'>
        <p id='noMatchText'>{props.titleText}</p>
        <hr />
       <p id='pickInterestText' onClick={() => {
          props?.onPressSubtitle();
        }}>{props.subtitleText}</p>

        {props.subtitle2Text && <p id='pickInterestText' className='pt-20'>{props.subtitle2Text}</p>}
      </div>

  )
}

export default CardEmpty

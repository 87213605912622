import { NOTIFY_ME } from './../Types/showDashboard';
import { notifyMeSuccessAction } from './../Actions/showDashboard';
import _ from "lodash";
import { ShowDashboardAction } from "../Actions/showDashboard";
import { FETCHING_ROOMS, FETCHED_ROOMS, GET_TOPICS, FOLLOW_ACTION, BOOKMARK_ACTION, RESET_FETCHED_ROOMS, GET_REPORT_REASONS } from "../Types/showDashboard"
// States' definition
export interface ShowDashboard {
  isFetching: boolean;
  shows?: any;
  topics?: any;
  reportReasonsList?: any;
  notifyMeSuccess?:boolean;
}

const initialState = {
  isFetching: true,
  shows:{
    live: {
      totalCount: 0,
      shows: []
    },
    game_show: {
      totalCount: 0,
      shows: []
    },
    featured: {
      totalCount: 0,
      shows: []
    },
    ready: {
      totalCount: 0,
      shows: []
    },
    suggested: {
      totalCount: 0,
      shows: []
    }
  },
  topics: {
    categoryTopics: [],
    totalCount: 0
  },
  reportReasonsList: [],
  notifyMeSuccess:false
}

const showDashboard = (
  state: ShowDashboard = initialState,
  action: ShowDashboardAction
): ShowDashboard => {
  switch (action.type) {
    case FETCHING_ROOMS:
      return { ...state, isFetching: action.isFetching };
    case FETCHED_ROOMS:
      switch(action.status){
        case 'live':
          return {
            ...state,
            shows: {
              ...state.shows,
              live: {
                totalCount: action.shows?.totalCount ?? 0,
                shows: (_.uniqBy([...state.shows.live.shows, ...action.shows?.shows ?? []], 'id'))
              }
            }
          }
        case 'game_show':
          return {
            ...state,
            shows: {
              ...state.shows,
              game_show: {
                totalCount: action.shows?.totalCount ?? 0,
                shows: (_.uniqBy([...state.shows.game_show.shows, ...action.shows?.shows ?? []], 'id'))
              }
            }
          }
        case 'featured':
          return {
            ...state,
            shows: {
              ...state.shows,
              featured: {
                totalCount: action.shows?.totalCount ?? 0,
                shows: (_.uniqBy([...state.shows.featured.shows, ...action.shows?.shows ?? []], 'id'))
              }
            },
          }
        case 'ready':
          return {
            ...state,
            shows: {
              ...state.shows,
              ready: {
                totalCount: action.shows?.totalCount ?? 0,
                shows: (_.uniqBy([...state.shows.ready.shows, ...action.shows?.shows ?? []], 'id'))
              }
            }
          }
        case "suggested":
          return {
            ...state,
            shows: {
              ...state.shows,
              suggested: {
                totalCount: action.shows?.totalCount ?? 0,
                shows: (_.uniqBy([...state.shows.suggested.shows, ...action.shows?.shows ?? []], 'id'))
              }
            }
          }

        default:
          return state;
      }

    case RESET_FETCHED_ROOMS: {
      switch (action.status) {
        case 'live':
          return {
            ...state,
            shows: {
              ...state.shows,
              live: {
                totalCount: 0,
                shows: []
              }
            }
          }

        case 'game_show':
          return {
            ...state,
            shows: {
              ...state.shows,
              game_show: {
                totalCount: 0,
                shows: []
              }
            }
          }

        case 'featured':
          return {
            ...state,
            shows: {
              ...state.shows,
              featured: {
                totalCount: 0,
                shows: []
              }
            }
          }

        case 'ready':
          return {
            ...state,
            shows: {
              ...state.shows,
              ready: {
                totalCount: 0,
                shows: []
              }
            }
          }

        case 'suggested':
          return {
            ...state,
            shows: {
              ...state.shows,
              suggested: {
                totalCount: 0,
                shows: []
              }
            }
          }

        default:
          return state
      }
    }

    case GET_TOPICS:
      return {...state, topics: {totalCount: action.topics.totalCount, categoryTopics: [...state.topics?.categoryTopics ?? null, ...action.topics?.categoryTopics ?? null]}};

    case GET_REPORT_REASONS:
      return {...state, reportReasonsList: action.reasons ?? []}

    case FOLLOW_ACTION: {
      return {
        ...state,
        shows: {
          ...state.shows,
          live: {
            ...state.shows.live,
            shows: [...state.shows.live.shows.map((show: any) => show.user_id === action.id ?
                {
                  ...show,
                  followed_by_ids: show.followed_by_me ? show.followed_by_ids.filter((id: number) => id !== action.id) : [...show.followed_by_ids, action.id],
                  followed_by_me: !action.following,
                } : show
              )]
          },
          game_show: {
            ...state.shows.game_show,
            shows: [...state.shows.game_show.shows.map((show: any) => show.user_id === action.id ?
                {
                  ...show,
                  followed_by_ids: show.followed_by_me ? show.followed_by_ids.filter((id: number) => id !== action.id) : [...show.followed_by_ids, action.id],
                  followed_by_me: !action.following,
                } : show
              )]
          },
          featured: {
            ...state.shows.featured,
            shows:[...state.shows.featured.shows.map((show: any) => show.user_id === action.id ?
                {
                  ...show,
                  followed_by_ids: show.followed_by_me ? show.followed_by_ids.filter((id: number) => id !== action.id) : [...show.followed_by_ids, action.id],
                  followed_by_me: !action.following,
                } : show
              )]
          },
          ready: {
            ...state.shows.ready,
            shows:[...state.shows.ready.shows.map((show: any) => show.user_id === action.id ?
                {
                  ...show,
                  followed_by_ids: show.followed_by_me ? show.followed_by_ids.filter((id: number) => id !== action.id) : [...show.followed_by_ids, action.id],
                  followed_by_me: !action.following,
                } : show
              )]
          },
          suggested: {
            ...state.shows.suggested,
            shows:[...state.shows.suggested.shows.map((show: any) => show.user_id === action.id ?
                {
                  ...show,
                  followed_by_ids: show.followed_by_me ? show.followed_by_ids.filter((id: number) => id !== action.id) : [...show.followed_by_ids, action.id],
                  followed_by_me: !action.following,
                } : show
              )]
          },
        }
      }
    }
    case BOOKMARK_ACTION:
      return {
        ...state,
        shows: {
          ...state.shows,
          live: {
            ...state.shows.live,
            shows:[...state.shows.live.shows.map((show: any) => ({
              ...show,
              isWatchlisted: show.id === action.id ? !show.isWatchlisted : show.isWatchlisted
            }))]
          },
          game_show: {
            ...state.shows.game_show,
            shows:[...state.shows.game_show.shows.map((show: any) => ({
              ...show,
              isWatchlisted: show.id === action.id ? !show.isWatchlisted : show.isWatchlisted
            }))]
          },
          featured: {
            ...state.shows.featured,
            shows:[...state.shows.featured.shows.map((show: any) => ({
              ...show,
              isWatchlisted: show.id === action.id ? !show.isWatchlisted : show.isWatchlisted
            }))]
          },
          ready: {
            ...state.shows.ready,
            shows:[...state.shows.ready.shows.map((show: any) => ({
              ...show,
              isWatchlisted: show.id === action.id ? !show.isWatchlisted : show.isWatchlisted
            }))]
          },
          suggested: {
            ...state.shows.suggested,
            shows:[...state.shows.suggested.shows.map((show: any) => ({
              ...show,
              isWatchlisted: show.id === action.id ? !show.isWatchlisted : show.isWatchlisted
            }))]
          },
        }
      }
    case NOTIFY_ME:
      return {
        ...state,
        notifyMeSuccess: action.notifyMeSuccess
      }
    default:
      return state;
  }
};
export default showDashboard;

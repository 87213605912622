import React, {useEffect, useState} from 'react'
import {images} from '../../Assets/ImageConfig/images';

import {Fade} from "react-awesome-reveal";
import './HomePage.scss'
import {getAllShows} from '../../store/Actions/showDashboard';
import {RootState} from "../../store"
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import DE from './HomePageDe'
import EN from './HomePage'
import {screen_viewed} from '../../Utils/mParticle';
import {fetchUserDetails} from '../../store/Actions/users';

let isDe: any;

if (/^de\b/.test(navigator.language) || window.location.pathname === '/de') {
  isDe = true;
} else {
  isDe = false
}
function HomePageLayOut() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isGerman, setIsGerman] = useState(isDe)
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  useEffect(() => {
    dispatch(fetchUserDetails())
    dispatch(getAllShows('ready', 1, users.user?.id, [], '0', 3))
    screen_viewed('home_screen_view', 'Home', 'Home_Page');
  }, [])
  return (
    <>
      <div className="BirthdayhomeStyles">
        <div className="b-header">
          <div className='b-container'>
            <Fade>
              <nav className='b-nav'>
                <a className='b-logo'>
                  <img src={images.Blogo} alt='logo' />
                </a>
                <div className='lan-switch'>
                  <label className="switch">
                    <input type="checkbox" id="toggle_vat" checked={!isGerman} className="toggle_vat" onChange={()=>{
                      setIsGerman(!isGerman)
                      navigate(isGerman?'/en':'/de')
                    }}/>
                    <div className="slider round">
                      <span className="slider_text"><span className="off">DE</span><span className="on">EN</span></span>
                    </div>
                  </label>
                </div>
              </nav>
            </Fade>
          </div>
        </div>
        {isGerman ? <DE /> : <EN />}
      </div>
    </>
  )
}
export default HomePageLayOut
import React from "react";

function Watch() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15 8.00098C15 11.8672 11.8663 15.001 8 15.001C4.13373 15.001 1 11.8672 1 8.00098C1 4.13471 4.13373 1.00098 8 1.00098C11.8663 1.00098 15 4.13471 15 8.00098Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.5971 10.2274L7.74414 8.52542V4.85742" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
      
  );
}

export default Watch;
import React, { useEffect, useState } from 'react'
import {  Modal } from 'antd'
import { Close } from '../../../Icons/index'
import {give_away_won} from '../../../../Utils/mParticle';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function GiveAway({users,   io, visible, showModal, show, user}: any) {
  const [modalTitle, setModalTitle] = useState<string>('Giveaway Time 🎁');
  const [activeId, setActiveId] = useState<any>(null);
  const [running, setRunning] = useState<boolean>(false);
  const [winnerId, setWinnerId] = useState<any>(null);
  const [users_freezed, setUsers] = useState<any>(users);
  const [mounted, alreadyMounted] = useState<boolean>(false);

  let rolling = false;
  let rollingTimeout: any;

  const handleOk = () => {
    showModal(false);
  };

  const handleCancel = () => {
    showModal(false);
    clearTimeout(rollingTimeout);
  };

  const bindIO = () => {
    io.off('giveaway_id', updateId);
    io.off('giveaway_animation', runAnimation);
    io.off('giveaway_winner', updateWinner);

    io.on('giveaway_id', updateId);
    io.on('giveaway_animation', runAnimation);
    io.on('giveaway_winner', updateWinner);
  }

  const updateId = (data: any) => {
    setActiveId(data.id)
  }

  const runAnimation = (data: any) => {
    rolling = true;
    setActiveId(null);
    setWinnerId(null);
    setRunning(true);
    setUsers(users);
    rollit(300, data.user_ids[0], data.user_ids.slice(1), () => {
    });
  }

  function rollit(speed: number, user_id: any, rest: any, end: any) {
    clearTimeout(rollingTimeout);
    if (rolling) { // rolling instead of running because it is faster than redraw
      setActiveId(user_id);
      rollingTimeout = setTimeout(() => {
        if (rest.length) rollit(speed, rest[0], rest.slice(1), end);
        else end();
      }, speed);
    }
  }

  const updateWinner = (data: any) => {
    rolling = false;
    setActiveId(data.id);
    setRunning(false);
    setModalTitle('Congrats 🎉GG')
    let winners = users.filter((u: any) => u.id == data.id);
    let winner = winners && winners[0];
    if (data?.id == users?.user?.id && !users?.user?.host) {
      give_away_won(users?.user, show, 'Shows', 'Show Screen');
    }
    if (winner) {
      setTimeout(() => {
        setWinnerId(data.id);
        setUsers([winner]);
      }, 3000);
    }
  }

  useEffect(() => {
    if (show?.id) {
      if (mounted) console.log('IO-GiveawayModal mounted again');
      else {
        alreadyMounted(true);
        bindIO();
      }
    }
    return () => {
      setActiveId(null)
      setWinnerId(null)
    };
  }, [show?.id, user?.id]);

  const giveawayUsers = () => {
    return users_freezed.map((user: any) => {
      return <div className={`giveaway-user ${activeId === user.id && running ? 'user-running' :  activeId === user.id && 'user-active'}`}>
        <div className='giveaway-userImg'>
          <img src={user?.pic} alt="" />
        </div>
        <div className='giveaway-userName'>
          <p>{user?.username}</p>
        </div>
      </div>
    })
  }

  return (
    <div>
        <Modal title={ modalTitle} className={`themeModal ${winnerId&&'fixedPricemodal'}`} width={600}  zIndex={100} visible={visible} onOk={handleOk} onCancel={handleCancel}  footer={false} centered closeIcon={<Close />}  >
            <div className='giveawayModal'>
                <div className='profileWraper'>
                  {giveawayUsers()}
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default GiveAway

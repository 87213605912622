/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'
import { Close } from '../../../Icons'
import { emailRegex, messageStrings, passwordRegex } from '../../../../Utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserExistance, resetPassword, signInWithEmail, verifyEmailOtp } from '../../../../store/Actions/users';
import { RootState } from './../../../../store';
import UserName from './../UserName';
import { signUpWithEmail, sendEmailOtp, resetUserExistance } from './../../../../store/Actions/users';
import Loader from '../../../CommonComponents/Loader';
import OTPInput from './../OTPInput/index';
import _ from 'lodash'
import {displayErrorToast, displayInfoToast} from '../../../../Utils/helper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const base64 = require('base-64');

const EmailModal = (props: any) => {
    const [form] = Form.useForm();
    const [final, setfinal] = useState<any>('');
    const [passwordModal, setPasswordModal] = useState(false)
    const [email, setEmail] = useState('')
    const [userName, setUserName] = useState(false)
    const [loading, setLoading] = useState(false)
    const [OTPSent, setOTPSent] = useState(false)
    const [otp, setOtp] = useState('')
    const [modalState, setModalState] = useState('GET_EMAIL')
    const [forget, setForget] = useState(false)
    const [userExist, setUserExist] = useState(true)
    const users = useSelector(
        (state: RootState) => state?.usersReducer
    );

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetUserExistance())
        setModalState('GET_EMAIL')
    },[])

    const onFinish = async (values: any) => {
        setEmail(values.email)
        setLoading(true)
        try {
            if(modalState === 'GET_EMAIL')
            await dispatch(checkUserExistance({
                email: values.email
            }))
            if(modalState === 'SET_PASSWORD'){
                if(forget){
                    dispatch(resetPassword({
                        token: users?.resetToken,
                        password: values.password
                    }))
                }
                else await dispatch(signUpWithEmail(
                    {
                        "email": email,
                        "password": values.password,
                        "app": "collectibles",
                        "source": "app",
                        "guest": false
                    }
                ))
                setLoading(false)
                if(!forget)
                    setModalState('SET_USERNAME')
                    else {
                        displayInfoToast('Your password has been reset. You can now use the new password to login in to your account.')
                        setModalState('GET_EMAIL')
                }
                props.handleCancel()
                form.resetFields()
                dispatch(resetUserExistance())
            }
            if(modalState === 'GET_PASSWORD'){
                await dispatch(signInWithEmail(base64.encode(`${email}:${values.password}`)))
                props.handleCancel()
                form.resetFields()
                dispatch(resetUserExistance())
            }
        } catch (error: any) {
            console.log(error?.response?.data?.code === 401);
            displayErrorToast(error?.response?.data?.message)

            if(error?.response?.data?.code === 401)
                displayErrorToast("Invalid User Email or Password")
        }
        setLoading(false)
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if(!_.isEmpty(users.userExistance)){
            if (users.userExistance?.isUserExist && !_.isEmpty(users.userExistance)){
                setModalState('GET_PASSWORD')
            }
            else {
                dispatch(sendEmailOtp(email, "SIGNUP"))
                if(modalState === 'GET_EMAIL')
                    setModalState('SET_OTP')
            }
        }
    }, [users.userExistance])


    const reset = () => {
        setPasswordModal(false)
        setUserName(false)
        setModalState('GET_EMAIL')
        setLoading(false)
        form.resetFields()
        props.handleCancel()
    }

    const ValidateOtp = async () => {
        if (otp.length < 4 || final === null) {
           displayInfoToast('Please enter valid otp')
            return;
        }
        try {
            if(forget)
                await dispatch(verifyEmailOtp(email, otp, "RESET_PASSWORD"))
            else await dispatch(verifyEmailOtp(email, otp, "SIGNUP"))
            displayErrorToast('Otp verified successfully')
            setModalState('SET_PASSWORD')
            setOTPSent(false)
        } catch (error: any) {
            displayErrorToast(error.response?.data?.message)
        }
    }

    const handleForgetPassword = async () => {
        setForget(true)
        setLoading(true)
        try{
           await dispatch(sendEmailOtp(email, "RESET_PASSWORD"))
        } catch (error: any) {
            message.error({icon:'💥', content:error.response?.data?.message})
            console.log(error.response?.data);
        }
        setLoading(false)
        setModalState('SET_OTP')
    }

    const showModal = () => {
        switch (modalState) {
            case 'GET_EMAIL':
                return (
                    <div className='modal-body'>
                            <p>Signup with Gavel</p>
                            <Form
                                name="register"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                form={form}
                                layout='vertical'
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter email!'
                                        },
                                        {
                                            pattern: emailRegex,
                                            message: 'Please input valid email!'
                                        }]}>
                                    <div className='numberInput'>
                                        <Input autoComplete="off" />
                                    </div>
                                </Form.Item>
                                <div id="recaptcha-container"></div>
                                <div className='modalbtns'>
                                    <Button type="primary" className='modalbtn filledbtn' htmlType="submit">Continue</Button>
                                </div>
                            </Form>
                        </div>
                )
                case 'GET_PASSWORD':
                    return (
                        <div className='modal-body'>
                                <p>Account already exists. Please login with Gavel</p>
                                <Form
                                    name="register"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    form={form}
                                    layout='vertical'

                                >
                                    <>
                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter new password!'
                                                },
                                                {
                                                    pattern: passwordRegex,
                                                    message: messageStrings.errorMessage.emailNotValid,
                                                }
                                            ]}>
                                            <div className='numberInput'>
                                                <Input type='password' autoComplete="new-password" />
                                            </div>
                                        </Form.Item>
                                    </>
                                    <div id="recaptcha-container"></div>
                                    <p>Don't remember? <a onClick={handleForgetPassword} >reset password</a></p>
                                    <div className='modalbtns'>
                                        <Button type="primary" className='modalbtn filledbtn' htmlType="submit">Continue</Button>
                                    </div>
                                </Form>
                            </div>
                    )
                case 'SET_PASSWORD':
                    return(
                        <div className='modal-body'>
                            <p>Enter your password</p>
                            <Form
                                name="register"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                form={form}
                                layout='vertical'

                            >
                                <>
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter new password!'
                                            },
                                            {
                                                pattern: passwordRegex,
                                                message: messageStrings.errorMessage.emailNotValid,
                                            }
                                        ]}>
                                        <div className='numberInput'>
                                            <Input type='password' autoComplete="new-password" />
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name="confirm"
                                        label="Confirm Password"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                },
                                            }),
                                        ]}

                                    >
                                        <div className='numberInput'>
                                            <Input  autoComplete="new-password"  type='password' />
                                        </div>
                                    </Form.Item>
                                </>
                                <div id="recaptcha-container"></div>
                                <div className='modalbtns'>
                                    <Button type="primary" className='modalbtn filledbtn' htmlType="submit">Continue</Button>
                                </div>
                            </Form>
                        </div>
                        )
            case 'SET_USERNAME':
                return (
                    <>
                        <div className='modal-body'>
                            <p>Thanks for the verification. Now choose your unique username.</p>
                            <div className='popupform usernameform'>
                                <UserName reset={reset} setLoader={setLoading} loader={loading} />
                            </div>
                        </div>
                    </>
                )
            case 'SET_OTP':
                return (
                    <div className='modal-body'>
                    <p>Check your email and enter the code here:</p>
                        <div className='popupform otpform'>
                            <Form
                                form={form}
                                name="register"
                                onFinish={ValidateOtp}
                                layout='vertical'

                            >
                                <Form.Item name="otp" rules={[{ required: true, message: 'Please enter OTP!' }]}>
                                    <OTPInput length={4}
                                        onChangeOTP={(otp) => setOtp(otp)}
                                        className="otpContainer"
                                        autoFocus
                                        inputClassName="otpInput"
                                    />
                                </Form.Item>
                                <div className='modalbtns'>
                                    <button type='submit' className='modalbtn filledbtn'>Continue </button>
                                </div>
                            </Form>

                        </div>
                    </div>
                )
            default:
                break;
        }
    }

    return (
        <Modal title="Login with Email" className='themeModal' width={448} visible={props.continueWithEmail} onOk={props.handleOk} onCancel={reset} footer={false} centered closeIcon={<Close />}>
            {loading && <Loader />}
            {showModal()}
        </Modal>
    )
}

export default EmailModal;
/* eslint-disable react/no-unescaped-entities */
import React, { Suspense, useEffect, useState } from 'react'
import { Row, Col, Button } from 'antd';
import Pagefooter from '../../CommonComponents/PageFooter'
import Profileaside from '../Components/ProfileView'
import Showlist from '../../ShowList'
import './Hostpublic.scss'
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHostUserDetails, openDownloadAppModal, openLoginSignUpModal, updateHostUserShowsAction } from '../../../store/Actions/users';
import { RootState } from "../../../store"
import Filtertitle from '../Components/FilterTitle';
import { getHostUserShows } from '../../../store/Actions/users';
import Loader from '../../CommonComponents/Loader';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router';
import { images } from '../../../Assets/ImageConfig/images';
import _ from 'lodash';
import ImageComponent from '../Components/ImageComponent';
import LoadingSpinner from '../../CommonComponents/LoadingSpinner';
import {getChannelURL} from '../../../Utils/helper';
import {profile_screen_view} from '../../../Utils/mParticle';

function Hostpublic() {

  const { hostName } = useParams()
  const dispatch = useDispatch()
  const [shows, setShows] = useState<any>([])
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const href = useLocation()

  useEffect(() => {
    if(!href.pathname.includes('@')){
      navigate(`/@${hostName}`, {replace: true})
    }
    else{
      const getdetails = async () => {
        if (hostName) {
        try {
          setLoading(true)
          await dispatch(fetchHostUserDetails(hostName, users.user?.id))
          profile_screen_view(users.user)
        }
        catch (e: any) {
          if (e.response.data.key === "NotFoundException") {
            navigate('/')
          }
        }
      }
      setLoading((false))
    }
    if(users.isFetchedUser) getdetails()
  }
  }, [hostName, users.user.id, href.pathname, users.isFetchedUser])

  useEffect(() => {
    setShows([...users?.hostUserShows?.live?.shows ?? null, ...users?.hostUserShows?.ready?.shows ?? null])
  }, [users.hostUserShows])

  useEffect(() => {
    if (users.hostUser?.id) {

      const fetchAndResetShows = async () => {
        setLoading(true)
        await dispatch(updateHostUserShowsAction({}, "RESET"))
        try {
          await Promise.all([
            dispatch(getHostUserShows(users.hostUser.id, users.user?.id, 'ready', 1, searchTerm)),
            dispatch(getHostUserShows(users.hostUser.id, users.user?.id, 'live', 1, searchTerm))
          ])
        } catch (e) {
          console.log(e);
        }
        setLoading(false)
      }

      fetchAndResetShows()

    }
  }, [users.hostUser.id, searchTerm, users.user?.id]);

  const fetchMore = async () => {
    setLoading(true)

    if (users?.hostUserShows?.live?.totalCount > users?.hostUserShows?.live?.shows.length)
     await dispatch(getHostUserShows(users.hostUser.id, users.user?.id, 'live', page + 1, searchTerm))

    if (users?.hostUserShows?.ready?.totalCount > users?.hostUserShows?.ready?.shows.length)
      await dispatch(getHostUserShows(users.hostUser.id, users.user?.id, 'ready', page + 1, searchTerm))

    setPage(page + 1)
    setLoading(false)
  }

  const onStartChat = () => {
    if (!users.user?.id) {
      dispatch(openLoginSignUpModal(true))
    }
    else {
      const subscribers = [users.user.id, users.hostUser.id].sort((a, b) => a - b)
      const username = users.user?.username;
      const title = [username + '', users.hostUser?.username].sort((a, b) => (a[0] < b[0] ? 1 : -1)).join(', ')

      const profile_pics = {
        [users.user.id]: users.user?.pic ?? getChannelURL(username),
        [users.hostUser.id]: users.hostUser?.pic ?? getChannelURL(users.hostUser?.username)
      }

      const usernames = {
        [users.user.id]: String(username),
        [users.hostUser.id]: String(users.hostUser?.username)
      }

      const displayName = usernames[users.hostUser.id];
      const channel = {
        id: `${subscribers[0]}:${subscribers[1]}`,
        type: 'private',
        title,
        subscribers,
        profile_pics: JSON.parse(JSON.stringify(profile_pics)),
        usernames: JSON.parse(JSON.stringify(usernames)),
        username: displayName,
        message: {
          time: new Date()
        },
      }
      navigate('/chat', {
        state: {
          channel: {
            ...channel,
            admin: users.hostUser?.admin ?? false,
            host: users.hostUser?.host ?? false,
            badgeFrequentHost: users.hostUser?.badgeFrequentHost ?? false,
            badgeTopSeller: users.hostUser?.badgeTopSeller ?? false
          }
        }
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>{hostName} at GAVEL - Live stream auctions for trading cards and much more</title>
        <meta property="og:title" content= {`${hostName} at GAVEL - Live stream auctions for trading cards and much more`} />
        <meta property="title" content={`${hostName} at GAVEL - Live stream auctions for trading cards and much more`} />
        <meta property="og:description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
        <meta property="description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
        <meta property="og:url" content={`https://letsgavel.com/@${hostName}`} />
        <meta property="og:image" content="https://i.ibb.co/1KqZpJ7/Meta-Image-EN.png" />
        <link rel="canonical" href={`https://letsgavel.com/@${hostName}`} />
      </Helmet>
      <div className="Hostpublic">
        {loading && <Loader />}
        <div className='Hostpublic-block'>
          <div className='Hostpublic-block__banner'>
            <div className='cover-banner'>
            <Suspense fallback={<LoadingSpinner />}>
              <ImageComponent imgSrc={users.hostUser?.cover ?? images.CoverImage} placeholderImg={images.CoverImage} altText='Profile' />
            </Suspense>
          </div>
          </div>
          <div className='Hostpublick-grid'>
            <div className='container'>

              <div className='profile-wrapper'>
                  <div className='profile-wrapper-left'>
                    <Profileaside show={shows[0]} onStartChat={onStartChat} />
                  </div>
                  <div className='profile-wrapper-right'>
                      {
                        users.hostUser?.host && (!users?.hostUserShows?.live?.totalCount && !users?.hostUserShows?.ready?.totalCount) && !searchTerm?.length &&
                          <div className='startchatbox'>
                            <div className='statrtchatcontent'>
                              <div className='startchat-img'>
                                <img src={images.startchat} />
                              </div>
                              <div className='noHost'>
                                <h4>This verified GAVEL host takes a break and has no shows upcoming.</h4>
                                <p> Let's follow and activate push notifications</p>
                              </div>

                              <Button type='primary'  onClick={() => {onStartChat()}}>Start chat</Button>
                            </div>
                          </div>
                      }
                      {
                        !users.hostUser?.host &&
                          <div className='startchatbox'>
                            <div className='statrtchatcontent'>
                              <div className='startchat-img'>
                                <img src={images.startchat} />
                              </div>
                             <div className='noHost'>
                                <h4>This user is not a verified GAVEL host.</h4>
                                <p> Feel free to follow or send a direct message.</p>
                              </div>
                              <Button type='primary' onClick={() => {onStartChat()}} >Start chat</Button>
                            </div>
                          </div>
                      }
                      {
                        (!_.isEmpty(shows) || searchTerm?.length) ? <Filtertitle searchTerm={searchTerm} setSearchTerm={setSearchTerm} /> : null
                      }
                      {
                        (_.isEmpty(shows) && searchTerm?.length) ? (
                          <div>
                            <p>No result found!</p>
                          </div>
                        ) : null
                      }
                        <Showlist fetchMore={fetchMore} shows={shows} />
                  </div>
              </div>

            </div>
          </div>
        </div>
        <Pagefooter />
      </div>
    </>

  )
}

export default Hostpublic

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Modal, Input, Form, message} from 'antd';
import Pagefooter from '../CommonComponents/PageFooter'
import {images} from '../../Assets/ImageConfig/images'
import './ChatScreen.scss'
import {Cancel} from '../Icons';
import Chatlisting from './Chatlisting/Chatlisting';
import Msgarea from './Msgarea/Msgarea';
import {SocketContext} from '../../Context/socket';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {RootState} from "../../store"
import Loader from '../CommonComponents/Loader'
import {channelsData, MessagesData} from './types';
import {AnalyticsContext} from '../../Context/analytics';
import MessageInput from './MessageInput/MessageInput';
import Emptymsg from './Emptymsg/Emptymsg';
import CreateGroupModal from './CreateGroupModal/CreateGroupModal';
import {checkChannels, displayErrorToast, displayInfoToast, getChannelURL, sliceArr} from '../../Utils/helper';
import {openLoginSignUpModal} from '../../store/Actions/users';
import NoMessage from './NoMessage/NoMessage';
import appConfig from '../../Utils/config';
import CreateChatModal from './CreateChatModal/CreateChatModal';

type LocationState = {
  channel? : any,
  message?: string
}

// @ts-ignore

function ChatScreen(props: any) {
  const io = useContext(SocketContext)
  const dispatch = useDispatch();

  const location = useLocation()
  const navigate = useNavigate()

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const analytics = useContext(AnalyticsContext)

  const lastMessageRef = useRef<any>(null);

  // When channel clicked get channel Id
  const [selectedChannel, setSelectedChannel] = useState<any>(null)
  const [isChannelSelected, setIsChannelSelected] = useState<boolean>(false)
  const [selectedChannelData, setSelectedChannelData] = useState<any>(null)
  const [preDefinedMessage, setPreDefinedMessage] = useState('')

  // Currently not properly used
  const [loading, setLoading] = useState(false)

  const [loadingChannels, setLoadingChannels] = useState(false)
  const [loadingMessages, setLoadingMessages] = useState(false)

  // socket.io connection
  const [ioConnected, setIoConnected] = useState<boolean>(false)
  const [initializing, setInitializing] = useState<boolean>(false)

  // Set all channels in channelsList
  const [channelsList, setchannelsList] = useState<channelsData[]>([]);

  // Store all messages
  const [messageList, setMessageList] = useState<any[]>([]);


  const bindedIO = () => {
    if (io.connected) {
      emitInit();

      console.log('bindedIO ', io.connected)
    }
  }

  const connectIO = async () => {
    if (ioConnected) {
      return;
    } else {
      await io.connect();
      setIoConnected(true);
      setInitializing(true);
    }
  };

  // If socket.io disconnects reconnect
  useEffect(() => {
    connectIO();
    console.log('finished connecting to socket.io?', io.connected)
  }, [!ioConnected])

  useEffect(() => {
    console.log('====================================');
    console.log('channel updated from location');
    console.log('====================================');
    console.log("location state", location.state);

    const routeChannelData = (location.state as LocationState)?.channel;
    if (routeChannelData && ioConnected) {
      // selectChannel(routeChannelData)
      setSelectedChannel(routeChannelData?.id)
      setIsChannelSelected(true)
      setSelectedChannelData(routeChannelData)
      setPreDefinedMessage((location.state as LocationState)?.message ?? '')
      navigate(".", {replace: true})
    }
  }, [location.state, ioConnected])

  useEffect(() => {
    console.log('first useEffect running', (users.isGuestUser || users.user.username) && initializing && ioConnected);

    if ((users.isGuestUser || users.user.username) && initializing && ioConnected) {
      setLoading(true)

      io.on('connect', onConnect);
      io.on('connect_error', onConnectError);
      io.on('disconnect', onDisconnect);
      io.on('error', onError);

      // io.off('chatChannels', onChannelsLoaded);
      // io.off('chatNewNotification', onNewNotification);
      // io.off('chatNewChannel', onNewChannel);
      // io.off('deleteChannel', onChannelDelete);

      io.on('chatChannels', onChannelsLoaded);
      io.on('chatNewNotification', onNewNotification);
      io.on('chatNewChannel', onNewChannel);
      io.on('deleteChannel', onChannelDelete);
      // io.on('chatGetGroupChannel', chatGetGroupChannel);

      bindedIO();
      // loadMessages();
      io.emit('chatGetChannels', {user_id: users.user?.id});

      setLoading(false)

      if (!users?.user?.username) {
        disconnectIO()
      }
      console.log('get messages useEffect', io.connected)
    }

    // return () => {
    //   io.off('connect');
    //   io.off('connect_error');
    //   io.off('disconnect');
    //   io.off('error');

    //   io.off('chatChannels');
    //   io.off('chatNewNotification');
    //   // io.off('chatNewChannel');
    //   io.off('deleteChannel');
    // }
  }, [io.connected, users.isGuestUser, users.user?.id])

  const chatGetGroupChannel = (data: any) => {
    console.log('updating channel data', data);
    setSelectedChannelData(data)
  }

  // Get all previous messages
  useEffect(() => {
    // if (isChannelSelected) {
      console.log('get old chat messages useEffect run', {isChannelSelected})

      // io.off('chatGetOldMessages', chatGetOldMessages);
      // io.off('chatNewMessage', chatNewMessage);
      // io.off('chatChannels', onChannelsLoaded);
      // io.off('chatGetGroupChannel', chatGetGroupChannel);

      // io.off('chatGetGroupChannel', chatGetGroupChannel);
      // io.on('chatGetGroupChannel', chatGetGroupChannel);
      // io.off('chatChannels')
      io.on('chatChannels', onChannelsLoaded);
      // io.on('chatNewMessage', chatNewMessage);

      // io.off('chatGetOldMessages', chatGetOldMessages);
      // io.on('chatGetOldMessages', chatGetOldMessages);

      loadMessages();
    // }

    return () => {
      io.off('chatChannels')
    }

  }, [!isChannelSelected, ioConnected, selectedChannel, users.user?.id]);

  useEffect(() => {
    (async() => {
      if (users.user?.id && selectedChannel) {

        const exists = await checkChannels(users.user?.id, selectedChannel);
        if (!exists) {
          io.emit('chatNewChannel', selectedChannelData)
        }
      }
    })()
  }, [users.user?.id, selectedChannel])



  useEffect(() => {

    io.on('chatNewChannel', onNewChannel);

    // return () => {
    //   io.off('chatGetOldMessages', chatGetOldMessages);
    //   io.off('chatNewMessage', chatNewMessage);
    //   io.off('chatGetGroupChannel', chatGetGroupChannel);
    //   io.off('chatChannels', onChannelsLoaded);
    //   io.off('chatNewNotification', onNewNotification);
    //   io.off('chatNewChannel', onNewChannel);
    //   io.off('deleteChannel', onChannelDelete);
    // }
  }, []);

  // NOTE: commented for purpose as latest user_id was not considered

  // const loadMessages = useCallback(
  //   () => {
  //     console.log('loadMessages',);
  //     console.log('before emitting userId', users.user?.id);

  //     setLoadingChannels(true)
  //     setLoadingMessages(true)
  //     io.emit('chatOnline', {user_id: users.user?.id});
  //     io.emit('chatGetChannels', {user_id: users.user?.id});
  //     // io.emit('chatGetAllMessages', {user_id: users.user?.id});
  //     // io.emit('chatGetOldMessages', {user_id: users.user.id, channel_id: selectedChannel});
  //     // io.emit('chatGetGroupChannel', {user_id: users.user.id, channel_id: selectedChannel});
  //     console.log('loadmessages func run')
  //   },
  //   [users.user?.id],
  // );

  const loadMessages = () => {
    console.log('loadMessages', 'channel id: ', {selectedChannel});
    console.log('before emitting userId', users.user?.id);

    if(!users.user?.id) return

    setLoadingChannels(true)
    setLoadingMessages(true)
    io.emit('chatOnline', {user_id: users.user?.id});
    io.emit('chatGetChannels', {user_id: users.user?.id});
    // io.emit('chatGetAllMessages', {user_id: users.user?.id}); // NOTE: commented as this event is not being used
    // selectedChannel && io.emit('chatGetOldMessages', {user_id: users.user.id, channel_id: selectedChannel});
    // io.emit('chatGetGroupChannel', {user_id: users.user.id, channel_id: selectedChannel});
    console.log('loadmessages func run')
  };


  const emitInit = () => {
    const guestId = 121314;
    const userDetails = {
      "user": {
        "id": users.user.id || guestId,
        "email": users.user.email || "",
        "username": users.user.username || "guest",
        "name": users.user.name || users.user.username,
        "phone": users.user.phone || "",
        "pic": users.user.pic || "dogs/Dog_12.png"
      },
      "invisible": false,
    }
    // now init is manually triggered
    io.emit('init', userDetails)
    console.log("emitInit", userDetails)
  };

  const scrollToLastMessage = () => {
    if(lastMessageRef && lastMessageRef.current ) {
      lastMessageRef.current.scrollIntoView({behaviour: 'smooth', block: 'nearest'})
    }
  }

  const sendMessage = (messageObj: any) => {
    const updatedMessages: any[] = [...messageList];
    messageObj.sending = true;
    updatedMessages.unshift(messageObj);
    setMessageList(updatedMessages);
    console.log('message list in sendMessage:', updatedMessages)
    scrollToLastMessage()
  }

  // Send Messages
  const emitMessage = (message: string) => {
    console.log('Chat emitMessage (' + message + ') sent to channel #' + selectedChannel);

    if (!users.user?.id) {
      dispatch(openLoginSignUpModal(true))
      return
    }
    // Get all selected channel data -
    const allSelectedChannelData: any[] = []
    channelsList.map((elm: any, i: number) => {
      if (elm.id === selectedChannel) {
        allSelectedChannelData.push(elm)
      }
    })

    const id = String(new Date().getTime());

    const messageObj: MessagesData = {
      id,
      channel_id: selectedChannel,
      user_id: users?.user.id,
      pic_uri: users?.user.pic,
      message,
      time: new Date(),
      from: users?.user?.username,
      title: allSelectedChannelData[0].title
    };

    if (allSelectedChannelData.length > 0) {
      if (selectedChannel != undefined) {
        if (allSelectedChannelData[0]?.type === 'general') {

          console.log('all selected data', allSelectedChannelData)

          io.emit('chatNewMessage', {
            'id': selectedChannel,
            'user_id': users.user?.id ? users.user?.id.toString() : '',
            'title': allSelectedChannelData[0].title,
            'subscribers': allSelectedChannelData[0].subscribers,
            'message': messageObj,
            'pic_url': allSelectedChannelData[0].pic_url,
            'usernames': allSelectedChannelData[0].usernames || "guest",
            'type': 'general',
            'admins': allSelectedChannelData[0]?.admins,
          });
        } else {
          io.emit('chatNewMessage', {
            'id': selectedChannel,
            'user_id': users.user?.id ? users.user?.id.toString() : '',
            'title': allSelectedChannelData[0].title,
            'subscribers': allSelectedChannelData[0].subscribers,
            'message': messageObj,
            'pic_url': allSelectedChannelData[0].pic_url,
            'profile_pics': allSelectedChannelData[0].profile_pics,
            'usernames': allSelectedChannelData[0].usernames || "guest",
            'type': 'private'
          });
        }
      }
    }

    sendMessage(messageObj)
    // renderMessage(messageObj)
    console.log('emitMessage:', message)
    console.log('emitMessage:', messageObj)
    console.log('emit message user obj:', users.user)
    console.log('emitmessage:', selectedChannel)

  }


  const onConnect = async () => {
    if (!ioConnected) {
      await io.connect()
      setIoConnected(true)

    }

  };

  const onConnectError = (err: any) => {
    console.log("onConnectError", err)
    console.log('IO connection error (' + io.id + ' ' + err?.message + ')');
    // log('-------------------');
    // this.setState({ io_reconnecting: true });
    // useage of err parameter https://socket.io/docs/v4/server-api/#namespaceusefn
  };

  const onDisconnect = (reason: string) => {
    console.log("onDisconnect", reason)    // log('IO disconnected due to ' + reason + ' (' + this.io.id + ')');
    // this.setState({ io_reconnecting: true });
    // if (reason === 'io server disconnect') {
    //   log('IO disconnected > reconnecting');
    //   this.io.connect();
    // }
  };

  const disconnectIO = () => {

    if (initializing || ioConnected) {
      io.disconnect();
      // initializing = false;
      setIoConnected(false);
      // ioConnected = false;
      setIoConnected(true)
      // setMessages([]);
      //   log('IO disconnected');
      //   log('---------------');
      //   Bugsnag.leaveBreadcrumb('Disconnect IO');
    } else {
      //   log('IO already disconnected');
    }
    console.log('disconenctIO', ioConnected)
  };

  const onError = (data: any) => {
    // error('Show-IO error from server: ' + data.message);
    // this.showError(data.message, data);
    console.log('onError', data)
  }

  // Check function names and what they're doing - this one check old messages
  const chatNewMessage = (data: any) => {

    io.emit('chatGetOldMessages', {user_id: users?.user.id, channel_id: selectedChannel});

    // REMOVE?
    // if (data?.channel_id === selectedChannel) {
    // console.log('chat new messages: ', data);

    // console.log('channel_id: ', selectedChannel);

    // const newMessage: any[] = [...messageList];
    // newMessage.unshift(data);
    // setMessageList(newMessage);
    // console.log('message list in chatNewMessage:', newMessage)

    // }
  }

  // Pass this data to msgarea also

  const chatGetOldMessages = (data: any) => {

    if (data.messages) {
      if (data?.channel_id === selectedChannel) {
        setMessageList(data.messages)
        console.log(data.messages)
        setIsChannelSelected(true)
      }
    }
    else {
      console.log('Channel does not exist yet');
      setMessageList([])
    }
    setLoadingMessages(false)
    // setLoadingChat(false);
    console.log('chat get old messages')
    console.log('chatGetOldMessages', data?.messages?.length);
    console.log('chatGetOldMessages', data)

  }


  // Profile pics (of both users) are getting cut during this function
  const sortChannels = (channels: any) => {
    // if (data.channels.length > 0) {
    const sortChannels = [...channels]

    return sortChannels.sort((x: any, y: any) => {

      // let xd:any = x.id
      // let yd:any = y.id

      // let xd:any = x.last_message == null? x.id : new Date(x.last_message.time);
      // let yd:any = y.last_message == null? y.id : new Date(y.last_message.time);

      if (x?.last_message && y?.last_message) {
        // console.log('sortChannels 1:', sortChannels)
        return (new Date(y.last_message.time)).getTime() - (new Date(x.last_message.time)).getTime();
      } else {
        // console.log('sortChannels 2 :', sortChannels)
        return y.id - x.id;
      }
    })
    // }
  }

  // console.log('channels list:', channelsList)
  const onChannelsLoaded = async (data: any) => {
      console.log('onChannelsLoaded', {data});

      const sortedChannels: any = sortChannels(data.channels);
      setchannelsList([...sortedChannels]);
      setLoadingChannels(false)
  }


  const onNewGroupChannel = (data: any) => {
    console.log('on chatGroupChannel:', {data})
    loadMessages()
  }

  // NOTE: code commented as it was causing flickering in chat listing
  useEffect(() => {
    // console.log('All conversations screen mounted');

    // io.off('chatChannels', onChannelsLoaded);
    // io.off('chatNewNotification', onNewNotification);
    // io.off('chatNewChannel', onNewChannel);
    // io.off('deleteChannel', onChannelDelete);
    // io.off('chatNewMessage', chatNewMessage);

    // io.on('chatNewMessage', chatNewMessage);
    // io.on('chatChannels', onChannelsLoaded);
    // io.on('chatNewNotification', onNewNotification);
    // io.on('chatNewChannel', onNewChannel);
    // io.on('deleteChannel', onChannelDelete);

    io.on('chatGroupChannel', onNewGroupChannel);

    return () => {
      io.off('chatGroupChannel')
    }

  }, [io.connected, users.user?.id]);

  const onNewNotification = (data: any) => {
    console.log('notification received, for: ', users.user.id, data)
    loadMessages();
  }

  const onNewChannel = (data: any) => {
    console.log('new Channel: ', data);
    // dispatch(updateChannels(data))
    console.log(io.listeners('chatNewChannel'));
    loadMessages()
  }

  const onChannelDelete = (data: any) => {
    console.log('channel deleted: ', data)
    io.emit('chatGetChannels', {user_id: users.user.id});
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreateChatModal, setCreateChatModal] = useState(false)

  const showModal = async () => {
    try {
      if (users.user?.id) {
        setIsModalVisible(true);
      }
      else {
        dispatch(openLoginSignUpModal(true))
      }
    } catch (error: any) {
      displayErrorToast(error.response?.data?.message)
    }
  };

  const showChatModal = async () => {
    try {
      if (users.user?.id) {
        setCreateChatModal(true);
      }
      else {
        dispatch(openLoginSignUpModal(true))
      }
    } catch (error: any) {
      displayErrorToast(error.response?.data?.message)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOkChatModal = () => {
    setCreateChatModal(false)
  }

  const handleCancelChatModal = () => {
    setCreateChatModal(false)
  }

  const resetChannel = () => {
    setIsChannelSelected(false)
    setSelectedChannel(null)
    setSelectedChannelData(null)
    setPreDefinedMessage('')
  }

  const selectChannel = async (channel: any) => {
    console.log('selectChannel:', channel)
    setSelectedChannel(channel.id)
    setSelectedChannelData(channel)
    setIsChannelSelected(true)
    setPreDefinedMessage('')
  }

  const onCreateChat = (selectedUser: any) => {
    const subscribers = [users.user.id, selectedUser.id].sort((a, b) => a - b)
    const username = users.user?.username;
    const title = [username + '', selectedUser?.username].sort((a, b) => (a[0] < b[0] ? 1 : -1)).join(', ')

    const profile_pics = {
      [users.user.id]: users.user?.pic ?? getChannelURL(username),
      [selectedUser.id]: selectedUser?.pic ?? getChannelURL(selectedUser?.username)
    }

    const usernames = {
      [users.user.id]: String(username),
      [selectedUser.id]: String(selectedUser?.username)
    }

    const displayName = usernames[selectedUser.id];
    const channel = {
      id: `${subscribers[0]}:${subscribers[1]}`,
      type: 'private',
      title,
      subscribers,
      profile_pics,
      usernames,
      username: displayName,
      message: {
        time: new Date()
      },
    }
    io.emit('chatNewChannel', channel)
    selectChannel({
      ...channel,
      admin: selectedUser?.admin ?? false,
      host: selectedUser?.host ?? false,
      badgeFrequentHost: selectedUser?.badgeFrequentHost ?? false,
      badgeTopSeller: selectedUser?.badgeTopSeller ?? false
    });
    handleCancelChatModal()
  };

  const createGroupChat = (name: string, usersList: object[]) => {
    const id = String(new Date().getTime());
    let subscribers: string[] = usersList?.length ? usersList.map((userObj: any) => String(userObj?.id)) : []
    let admins: string[] = users.user?.id ? [String(users.user?.id)] : []

    if (!subscribers?.includes(String(users.user?.id))) {
      users.user?.id && subscribers.push(String(users.user?.id))
    }

    const channel = {
      id,
      title: name,
      time: new Date(),
      subscribers,
      type: 'general',
      last_message: {
        time: new Date()
      },
      admins,
      pic_url: getChannelURL(name)
    }

    io.emit('chatGroupChannel', channel);
    console.log('chatGroupChannel',{channel});

    handleCancel()
  }

  const saveGroup = (name: string, usersList: object[], admins: [], removed?: []) => {
    const id = String(selectedChannel);
    let subscribers: string[] = usersList?.length ? usersList.map((userObj: any) => String(userObj?.id)) : []

    if (!subscribers?.includes(String(users.user?.id))) {
      users.user?.id && subscribers.push(String(users.user?.id))
    }

    const channel = {
      id,
      title: name,
      time: selectedChannelData.time,
      subscribers,
      type: 'general',
      last_message: selectedChannelData?.last_message,
      admins,
      pic_url: getChannelURL(name)
    }

    if (removed?.length) {
      io.emit('chatRemoveUser', {...channel, removedUsers: removed});
    }
    else {
      io.emit('chatGroupChannel', channel)
    }
  }

  const leaveGroup = (usersList: object[]) => {
    const id = String(selectedChannel);
    let subscribers: string[] = usersList?.length ?
      usersList.map((userObj: any) => String(userObj?.id))
      .filter(subscriber => subscriber !== String(users.user?.id)) : []

    const channel = {
      id,
      title: selectedChannelData?.title,
      time: selectedChannelData?.time,
      subscribers,
      type: 'general',
      last_message: selectedChannelData?.last_message,
      admins: selectedChannelData?.admins,
      pic_url: selectedChannelData?.pic_url,
      user_id: users.user?.id,
    }

    io.emit('leaveGroupChannel', channel);
    io.emit('chatGetChannels', {user_id: users.user.id});
    resetChannel()
  }

  const handleDeleteChannel = (id: string) => {
    if (selectedChannel === id) {
      resetChannel()
    }
    io.emit('deleteChannel', { user_id: users.user?.id, channel_id: id });
    displayInfoToast('Channel deleted successfully!')
  }

  const onSupportChat = () => {
    if (ioConnected) {
      if (users?.user?.id) {
        console.log('start support');
        const supportObj = {
          id: appConfig.support,
          name: 'Support',
          pic: getChannelURL('support')
        }

        const subscribers = [supportObj.id, users.user?.id]

        const profile_pics = {
          [users.user?.id]: users.user?.pic,
          [supportObj.id]: supportObj.pic
        }

        const usernames = {
          [users.user?.id]: String(users.user?.username),
          [supportObj.id]: 'Support'
        }

        const receiverId = sliceArr(subscribers, users.user?.id.toString())[0];
        const displayName = usernames[receiverId];

        const channel = {
          id: `${subscribers[0]}:${subscribers[1]}`,
          time: new Date(),
          title: 'Support',
          type: 'private',
          subscribers,
          profile_pics,
          usernames,
          username: displayName,
          message: {
            time: new Date()
          },
        }
        // io.emit('chatNewChannel', channel)
        selectChannel({...channel, admin: true})
      }
      else {
        dispatch(openLoginSignUpModal(true))
      }
    }
    else {
      message.error(`Please send us an email to support@letsgavel.com`)
    }
  }

  return (

    <div className="Hostpublic">
      {loading && <Loader />}
      <div className="BreadCrumbs">
        <div className='container'>
          <div className='pagetopheader'>
            <h3>Chat</h3>
            <div className='btnRight'>
              <Button type='primary' onClick={showChatModal}>+ Create Chat</Button>
              <Button type='ghost' onClick={onSupportChat}>Support</Button>
              <Button type='primary' onClick={showModal}>+ Create New Group</Button>
            </div>
          </div>
        </div>
      </div>

      {/* {channelsList.length > 0 && messageList.length > 0 ? ( */}



      <div className='chat-section'>
        <div className='container'>
          <div className='chatmainWrapper'>
            <Chatlisting channels={channelsList} loading={loadingChannels} selectedChannel={selectedChannel} selectChannel={selectChannel} handleDeleteChannel={handleDeleteChannel} />

            <div className='chat-right'>
              {selectedChannel ? (
                <Msgarea channels={channelsList} loading={loadingMessages} selectChannel={selectedChannel} selectedChannelData={selectedChannelData} resetChannel={resetChannel} messages={messageList} currentUser={users.user}
                  onSaveGroup={saveGroup} onLeaveGroup={leaveGroup} lastMessageRef={lastMessageRef} preDefinedMessage={preDefinedMessage} scrollToLastMessage={scrollToLastMessage} /* Pass messages via props */ />
              ) : null}
              {/* {message={message}} */}
              {!isChannelSelected && <Emptymsg />}   {/* Emptymsg component shown when starting new chat */}
              {/* <Blockuser/> */} {/* Blockuser component shown when user is blocked */}



              {/* <div className='msginputarea'> */}
              {/* {selectedChannel && <MessageInput emitMessage={emitMessage}  />} */}
              {/* <input
                  type='text'
                  className="msgInput"
                  value={isSendable}
                  onChange={setIsSendable}
                  cleanOnEnter
                  onEnter={onSend}
                  placeholder="Type a message"/> */}

              {/* <InputEmoji
                  value={isSendable}
                  className="msgInput"
                  onChange={setIsSendable}
                  cleanOnEnter
                  onEnter={emitMessage}
                  placeholder="Type a message"
                />
                <div className='sharebtn-right' >
                  <button className='sharebtn' type='button'onClick={emitMessage}><Send /></button>
                </div> */}
              {/* </div> */}

            </div>
          </div>
        </div>
      </div>





      <Pagefooter />

      {/* Create New Group modal */}
      {
        isModalVisible && <CreateGroupModal isModalVisible={isModalVisible} handleOk={handleOk}
          handleCancel={handleCancel} createGroupChat={createGroupChat} />
      }

      {
        isCreateChatModal && <CreateChatModal isModalVisible={isCreateChatModal} handleOk={handleOkChatModal} handleCancel={handleCancelChatModal} createChat={onCreateChat}  />
      }


    </div>

  )
}

export default ChatScreen

import uniqBy from 'lodash/uniqBy'

import {UsersAction} from "../Actions/users";
import {
  GUEST_USERS,
  OPEN_LOGIN_SIGNUP_MODAL,
  IMPLICITE_TOKEN,
  CHECK_USERS_EXISTANCE,
  SIGN_UP_PHONE_USER,
  CHECK_USERNAME_EXIST,
  SET_UP_USERNAME,
  SET_FOLLOWING,
  SET_HOST_USER_DETAILS,
  RESET,
  UPDATE_USER,
  SET_RESET_PASSWORD_TOKEN,
  UPDATE_USER_IMAGE,
  UPDATE_USER_TOPICS,
  UPDATE_USER_COVER_IMAGE,
  UPDATE_USER_WATCHLIST,
  SET_LOADER,
  UPDATE_HOST_USER_SHOWS,
  SET_BOOKMARK,
  UPDATE_USER_CURRENT_SHOW,
  LIST_FOLLOWER_USERS,
  LIST_FOLLOWING_USERS,
  RESET_FOLLOWER_FOLLOWING_USERS,
  OPEN_DOWNLOAD_MODAL,
  UPDATE_WATCH_LIST,
  SOFT_DELETE_USER,
  BLOCK_USER,
  REMOVE_FOLLOWER,
  FETCHED_USER,
  FETCH_SHIPPING_ADDRESSES,
  VALIDATE_SHIPPING_ADDRESS,
  ADD_SHIPPING_ADDRESS,
  SEARCH_USER,
  FETCH_TRANSACTION_HISTORY,
  FETCH_ORDER_HISTORY,
  UPDATE_FAILED_ITEMS
} from "../Types/users"
// States' definition
export interface Users {
  isGuestUser: boolean;
  loginSignUpModal: boolean;
  downloadAppModal: boolean;
  impliciteToken: string;
  userExistance: any;
  user: any;
  userNameChecking: any;
  userName: any;
  hostUser: any;
  hostUserShows: any;
  loader: boolean;
  resetToken: string;
  watchlistShows: any;
  currentShow: any;
  usersList: any;
  isFetchedUser: boolean;
  addressesList: any;
  validatingAddress: any;
  allUsersList: any;
  transactionHistory: any;
  orderHistory: any;
  failedItemsList: any;
}
const initialState: Users = {
  isGuestUser: false,
  loginSignUpModal: false,
  downloadAppModal: false,
  impliciteToken: '',
  resetToken: '',
  userExistance: {},
  user: {
    followed_by_ids: []
  },
  userNameChecking: {
    isUserName: false,
    isuserNameChecking: false
  },
  userName: {
    loader: false,
    userName: {}
  },
  hostUser: {},
  hostUserShows: {
    live: {
      totalCount: 0,
      shows: []
    },
    ready: {
      totalCount: 0,
      shows: []
    }
  },
  loader: false,
  watchlistShows: [],
  currentShow: {},
  usersList: {},
  addressesList: [],
  isFetchedUser: false,
  validatingAddress: {
    isValidating: false,
    deliverability: '',
    lobAddressResponse: null
  },
  allUsersList: [],
  transactionHistory: {
    totalCount: 0,
    transactions: []
  },
  orderHistory: {
    totalCount: 0,
    orders: []
  },
  failedItemsList: {
    totalCount: 0,
    failedItems: [],
    shippingUpgradeData: {}
  }
}
const usersReducer = (
  state: Users = {
    isGuestUser: false,
    loginSignUpModal: false,
    downloadAppModal: false,

    isFetchedUser: false,
    impliciteToken: '',
    resetToken: '',
    userExistance: {},
    user: {
      followed_by_ids: [],
      cardList:[]
    },
    userNameChecking: {
      isUserName: false,
      isuserNameChecking: false
    },
    userName: {
      loader: false,
      userName: {}
    },
    hostUser: {
      followed_by_ids: []
    },
    hostUserShows: {
      live: {
        totalCount: 0,
        shows: []
      },
      ready: {
        totalCount: 0,
        shows: []
      }
    },
    loader: false,
    watchlistShows: [],
    currentShow: {},
    usersList: {
      followers: {
        totalCount: 0,
        users: []
      },
      followings: {
        totalCount: 0,
        users: []
      }
    },
    addressesList: [],
    validatingAddress: {
      isValidating: false,
      deliverability: '',
      lobAddressResponse: null,
    },
    allUsersList: [],
    transactionHistory: {
      totalCount: 0,
      transactions: []
    },
    orderHistory: {
      totalCount: 0,
      orders: []
    },
    failedItemsList: {
      totalCount: 0,
      failedItems: [],
      shippingUpgradeData: {}
    }
  },
  action: UsersAction
): Users => {
  switch (action.type) {
    case GUEST_USERS:
      return {...state, isGuestUser: action.isGuestUser};
    case OPEN_LOGIN_SIGNUP_MODAL:
      return {...state, loginSignUpModal: action.loginSignUpModal};
    case OPEN_DOWNLOAD_MODAL:
      return {...state, downloadAppModal: action.downloadAppModal};
    case IMPLICITE_TOKEN:
      return {...state, impliciteToken: action.impliciteToken};
    case CHECK_USERS_EXISTANCE:
      return {...state, userExistance: action.userExistance};
    case SIGN_UP_PHONE_USER:
      return {...state, user: action.user};
    case CHECK_USERNAME_EXIST:
      return {...state, userNameChecking: action.userNameChecking};
    case SET_UP_USERNAME:
      return {...state, userName: action.userName};
    case SET_HOST_USER_DETAILS:
      return {...state, hostUser: action.hostUser};
    case UPDATE_USER_CURRENT_SHOW:
      return {...state, currentShow: action.show};
    case UPDATE_USER:
      return {
        ...state, user: {
          ...state.user,
          ...action.user.user,
        },
        loader: action.loader
      };
    case SET_FOLLOWING: {

      // if need to update host along with user
      if (action.shouldUpdateHost) {

        const readyUpdated = state.hostUserShows.ready.shows.map((show: any) => {
          return {
            ...show,
            followed_by_me: !show.followed_by_me,
            followed_by_ids: show.followed_by_me ? show.followed_by_ids.filter((id: number) => id !== state.user.id) : [...show.followed_by_ids, state.user.id]
          }
        })
        const liveUpdated = state.hostUserShows.live.shows.map((show: any) => {
          return {
            ...show,
            followed_by_me: !show.followed_by_me,
            followed_by_ids: show.followed_by_me ? show.followed_by_ids.filter((id: number) => id !== state.user.id) : [...show.followed_by_ids, state.user.id]
          }
        })
        if (action.following.following) {
          return {
            ...state,
            user: {
              ...state.user,
              // followed_by_ids: [...state.user.followed_by_ids.filter((id: number) => id !== +action.following.id)],
              followedByMeUserIds: [...state.user.followedByMeUserIds.filter((id: number) => id !== +action.following.id)]
            },
            hostUser: {
              ...state.hostUser,
              followed_by_ids: [...state.hostUser.followed_by_ids.filter((id: number) => id !== state.user.id)]
            },
            hostUserShows: {
              live: {
                ...state.hostUserShows.live,
                shows: liveUpdated
              },
              ready: {
                ...state.hostUserShows.ready,
                shows: readyUpdated
              }
            }
          }
        }
        else {
          return {
            ...state,
            user: {
              ...state.user,
              // followed_by_ids: [...state.user.followed_by_ids, +action.following.id],
              followedByMeUserIds: [...state.user.followedByMeUserIds, +action.following.id]

            },
            hostUser: {
              ...state.hostUser,
              followed_by_ids: [...state.hostUser.followed_by_ids, state.user.id]
            },
            hostUserShows: {
              live: {
                ...state.hostUserShows.live,
                shows: liveUpdated
              },
              ready: {
                ...state.hostUserShows.ready,
                shows: readyUpdated
              }
            }
          }
        }
      }
      else {
        if (action.following.following) {
          return {
            ...state,
            user: {
              ...state.user,
              followedByMeUserIds: [...state.user.followedByMeUserIds.filter((id: number) => id !== +action.following.id)]
            }
          }
        }
        else {
          return {
            ...state,
            user: {
              ...state.user,
              followedByMeUserIds: [...state.user.followedByMeUserIds, +action.following.id]
            }
          }
        }
      }

    }
    case REMOVE_FOLLOWER:
      return {
        ...state,
        user: {
          ...state.user,
          followed_by_ids: [...state.user.followed_by_ids.filter((id: number) => id !== +action.id)]
        },
        usersList: {
          ...state.usersList,
          followers: {
            totalCount: state.usersList.followers?.totalCount - 1,
            users: [...state.usersList?.followers?.users.filter((user: any) => user.followerId !== action.id)],
          }
        }
      }

    case BLOCK_USER: {
      return {
        ...state,
        hostUser: {
          ...state.hostUser, isBlockedByMe: action.shouldBlock
        }
      }
    }

    case SOFT_DELETE_USER:
    case RESET:
      return {
        ...initialState,
        hostUser: state.hostUser,
        currentShow: state.currentShow,
        hostUserShows: state.hostUserShows,
        impliciteToken: state.impliciteToken
      }

    case UPDATE_USER_IMAGE: {
      return {...state, user: {...state.user, pic: action.url}, loader: action.loader};
    }
    case UPDATE_USER_COVER_IMAGE: {
      return {...state, user: {...state.user, cover: action.url}, loader: action.loader};
    }
    case UPDATE_USER_TOPICS: {
      return {...state, user: {...state.user, userTopics: action.topics}, loader: action.loader};
    }
    case SET_RESET_PASSWORD_TOKEN: {
      return {...state, resetToken: action.resetToken};
    }
    case UPDATE_USER_WATCHLIST: {
      return {...state, watchlistShows: action.watchlist};
    }
    case UPDATE_WATCH_LIST: {
      return {
        ...state,
        watchlistShows: {
          ...state.watchlistShows,
          shows: [...state.watchlistShows?.shows.map((show: any) => show.user_id === action.id ?
            {
              ...show,
              followed_by_ids: show.followed_by_me ? show.followed_by_ids.filter((id: number) => id !== action.id) : [...show.followed_by_ids, action.id],
              followed_by_me: !action.following,
            } : show
          )]
        }
      }
    }
    case SET_LOADER: {
      return {...state, loader: action.loader};
    }
    case UPDATE_HOST_USER_SHOWS: {

      switch (action.showType) {
        case 'ready':

          return {
            ...state, hostUserShows: {
              ...state.hostUserShows,
              ready: {
                totalCount: action.shows?.totalCount,
                shows: [...state.hostUserShows.ready.shows, ...action.shows?.shows ?? null]
              }
            }
          }
        case 'live':
          return {
            ...state, hostUserShows: {
              ...state.hostUserShows,
              live: {
                totalCount: action.shows?.totalCount,
                shows: [...state.hostUserShows.live.shows, ...action.shows?.shows ?? null]
              }
            }
          }
        case 'RESET':
          return {
            ...state, hostUserShows: {
              live: {
                totalCount: 0,
                shows: []
              },
              ready: {
                totalCount: 0,
                shows: []
              },
            }
          }
        default:
          return {...state, };
      }
    }
    case SET_BOOKMARK: {

      const readyUpdated = state.hostUserShows.ready.shows.map((show: any) => {
        if (show.id === action.id)
          return {
            ...show,
            isWatchlisted: !show.isWatchlisted
          }
        else return show
      })
      const liveUpdated = state.hostUserShows.live.shows.map((show: any) => {
        if (show.id === action.id)
          return {
            ...show,
            isWatchlisted: !show.isWatchlisted
          }
        else return show
      })

      if (action.isWatchList) {
        const watchListUpdated = [...state.watchlistShows?.shows.filter((show: any) => show.id !== action.id)]
        return {
          ...state,
          hostUserShows: {
            live: {
              ...state.hostUserShows.live,
              shows: liveUpdated
            },
            ready: {
              ...state.hostUserShows.ready,
              shows: readyUpdated
            }
          },
          watchlistShows: {
            ...state.watchlistShows,
            shows: watchListUpdated
          }
        }
      }
      return {
        ...state,
        hostUserShows: {
          live: {
            ...state.hostUserShows.live,
            shows: liveUpdated
          },
          ready: {
            ...state.hostUserShows.ready,
            shows: readyUpdated
          }
        }
      }
    }
    case LIST_FOLLOWER_USERS: {

      return {
        ...state,
        usersList: {
          ...state.usersList,
          followers: {
            totalCount: action.followersData?.totalCount ?? 0,
            // users: [...action?.followersData?.result ?? null]
            users: (uniqBy([...state.usersList?.followers?.users, ...action?.followersData?.result ?? [],], 'followerId'))
          }
        }
      }

    }

    case LIST_FOLLOWING_USERS: {
      return {
        ...state,
        usersList: {
          ...state.usersList,
          followings: {
            totalCount: action.followingData?.totalCount ?? 0,
            // users: [...action?.followingData?.result ?? null ]
            users: (uniqBy([...state.usersList?.followings?.users, ...action?.followingData?.result ?? []], 'userId'))
          }
        }
      }
    }

    case RESET_FOLLOWER_FOLLOWING_USERS: {
      return {
        ...state,
        usersList: {
          followers: {
            totalCount: 0,
            users: []
          },
          followings: {
            totalCount: 0,
            users: []
          }
        }
      }
    }

    case FETCHED_USER: {
      return {
        ...state,
        isFetchedUser: action.isFetchedUser
      }
    }

    case FETCH_SHIPPING_ADDRESSES: {
      return {
        ...state,
        addressesList: [...action.addressData]
      }
    }

    case VALIDATE_SHIPPING_ADDRESS: {
      return {
        ...state,
        validatingAddress: action.validatingAddress
      }
    }

    case ADD_SHIPPING_ADDRESS: {

      switch (action.operationType) {
        case 'ADD':
          return {
            ...state,
            addressesList: [...state.addressesList, action.address]
          }

        case 'UPDATE':{
          const updatedAddressList = state.addressesList?.map((address: any) =>
            address?.id === action.address?.id ? {...action.address} : address
          )
          return {
            ...state,
            addressesList: [...updatedAddressList]
          }
        }

        case 'DELETE':
          return {
            ...state,
            addressesList: [...state.addressesList.filter((address: any) => address?.id !== action.address?.id), action.address]
          }

        default:
          return {...state, };
      }
    }

    case SEARCH_USER: {
      switch (action.actionType) {
        case 'RESET':
          return {
            ...state, allUsersList: []
          }

        default:
          return {
            ...state, allUsersList: [...action.usersData ?? []]
          }
      }
    }

    case FETCH_TRANSACTION_HISTORY: {

      switch (action.actionType) {
        case 'RESET':
          return {
            ...state,
            transactionHistory: {
              totalCount: 0,
              transactions: []
            }
          }

        default: {
          return {
            ...state,
            transactionHistory: {
              totalCount: action.transactionData?.totalCount ?? 0,
              transactions: uniqBy([...state.transactionHistory.transactions , ...action.transactionData?.transactionHistory], 'id')
            }
          }
        }
      }
    }

    case FETCH_ORDER_HISTORY: {

      switch (action.actionType) {
        case 'RESET':
          return {
            ...state,
            orderHistory: {
              totalCount: 0,
              orders: []
            }
          }

        default:
          return {
            ...state,
            orderHistory: {
              totalCount: action.orderHistoryData?.totalCount ?? 0,
              orders: uniqBy([...state.orderHistory?.orders , ...action.orderHistoryData?.orderHistory], 'id')
            }
          }
      }
    }

    case UPDATE_FAILED_ITEMS: {

      switch (action.actionType) {
        case 'RESET':
          return {
            ...state,
            failedItemsList: {
              totalCount: 0,
              failedItems: [],
              shippingUpgradeData: {}
            },
          }

        default:
          return {
            ...state,
            failedItemsList: {
                totalCount: action?.failedItemsData?.totalCount ?? 0,
                failedItems: [...action.failedItemsData?.items],
                shippingUpgradeData: action?.failedItemsData?.shippingUpgradeData
              }
          }
      }
    }

    default:
      return state;
  }
};
export default usersReducer;
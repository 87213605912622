import React from "react";

function Chat() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.07677 15C11.9428 15 15.0768 11.866 15.0768 8C15.0768 4.13401 11.9428 1 8.07677 1C4.21077 1 1.07677 4.13401 1.07677 8C1.07677 9.27501 1.41765 10.4704 2.01324 11.5C2.33261 12.0521 0.624165 14.5565 1.07673 15C1.54005 15.454 4.16726 13.8441 4.742 14.1561C5.73341 14.6943 6.86939 15 8.07677 15Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.07666 8H5.08499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0767 8H12.085" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.57666 8H8.58499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>



  );
}

export default Chat;
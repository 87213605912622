import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Menu, Modal} from 'antd';
import './Chatlisting.scss'
import {Block, Close, Delete, Dotmenu} from '../../Icons';
import {formatTime, getChannelURL, profilePictureHelper, sliceArr} from '../../../Utils/helper';
import {images} from '../../../Assets/ImageConfig/images';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import LoadingSpinner from '../../CommonComponents/LoadingSpinner';
import ProfileBadges from '../../CommonComponents/ProfileBadges';
import ModBadge from '../../CommonComponents/ModBadge';




function Chatlisting(props: any) {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null)

  const [chatList, setChatList] = useState<any>([])

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  useEffect(() => {
    setChatList([...props.channels ?? []])
  }, [props.channels])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCancelClick = () => {
    handleCancel()
    setSelectedChannel(null)
  }

  const onOkClick = () => {
    handleOk()
    props.handleDeleteChannel(selectedChannel)
    setSelectedChannel(null)
  }

  const menu = (channel: any) => (
    <Menu key={channel?.id}>
      {/* NOTE: will be uncommented when functionality is implemented */}
      {/* {channel?.type === 'private' && (
        <Menu.Item key='blockMenu'>
          <Button type="link" className='Link_btn' target="_blank" rel="noopener noreferrer"  >
            <Block /> Block
          </Button>
        </Menu.Item>
      )} */}
      <Menu.Item key='deleteMenu'>
        <Button type="link" className='Link_btn' onClick={() => {
          setSelectedChannel(channel.id)
          showModal()
        }} >
          <Delete /> Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  const getChatProfileImg = (channel: any, channelType: string) => {
    let receiverId;
    if (channel.subscribers) {
      receiverId = sliceArr(channel.subscribers, users.user?.id?.toString())[0]
    }
    else {
      receiverId = users.user?.id?.toString()
    }

    if (channelType === 'general') {
      const group_pic = channel?.type === 'general' ? channel?.pic_url : channel?.profile_pics[receiverId]
      return profilePictureHelper(group_pic)
    }
    else {
      const pic_url = channel?.profile_pics && channel?.profile_pics[receiverId]
      return profilePictureHelper(pic_url)
    }
  }

  const getChannelTitle = (channel: any) => {
    let receiverId;
    if (channel.subscribers) {
      receiverId = sliceArr(channel.subscribers, users.user?.id?.toString())[0]
    }
    else {
      receiverId = users.user?.id?.toString()
    }

    let username;
    if (channel?.usernames) {
      username = channel?.usernames && channel?.usernames[receiverId];
    }
    return username
  }


  return (
    <>
      <div className='chat-left'>
        {/* {props?.loading && <LoadingSpinner />} */}
        {chatList.map((channel: any) => (
          <div className={`chat-list ${props.selectedChannel === channel?.id ? 'active' : ''}`}
            key={channel?.id}
          >
            <div className='chatbox-left' onClick={() => {(String(props.selectChannel(channel)))}}>
              <div className='chat-profile'>
                <img src={getChatProfileImg(channel, channel?.type)} alt="profile" onError={({currentTarget}) => {
                  currentTarget.onerror = null
                  if (channel?.type === 'general') {
                    currentTarget.src = getChannelURL(String(channel?.title) ?? '')
                  }
                  else {
                    currentTarget.src = images.profiledummy
                  }
                }} />
              </div>
              <div className='username'>
                <p>
                  {channel?.type === 'general' ? String(channel?.title) : (getChannelTitle(channel) ?? String(channel?.title))}
                  {channel?.admin ? <ModBadge /> : null}
                  {channel?.type === 'private' ? channel?.host && <ProfileBadges user={channel} /> : null}
                </p>
                <span>{String(channel?.last_message?.message ?? '')}</span>
              </div>
            </div>

            <div className='chatbox-right'>
              <span className='time'> {channel?.last_message?.time ? formatTime(new Date(channel?.last_message?.time)) : ''} </span>
              <Dropdown overlay={() => menu(channel)} className="chatDropdown">
                <Button type='link' className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <Dotmenu />
                </Button>
              </Dropdown>
            </div>
          </div>
        ))}
      </div>
      {/* Delete Chat Pop-up */}
      <Modal
        width={'496px'} title="" className='themeModal' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} centered closeIcon={<Close />}>
        <div className='modal-body'>
          <div className='deleteUserModal'>
            <div className='delteuserImg'>
              <img src={images.Deleteuser} alt="" />
            </div>
            <div className='deleteuserContent'>
              <h5>Delete Message?</h5>
              <p>This message would be deleted from your inbox. The other person will still be able to see it.</p>
              <div className='deleteuserBtns'>
                <Button type='ghost' onClick={onCancelClick} >No</Button>
                <Button type='primary' onClick={onOkClick} >Yes</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Chatlisting

import React from "react";

function Twitter() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.18075 13.9998C1.52703 14.0045 0.459513 15.8884 1.47831 17.3157C2.93733 19.3596 5.58308 21 10.0001 21C16.8452 21 22.3398 15.4217 21.5706 8.8311L22.6975 6.5773C23.479 5.01428 22.121 3.23622 20.4074 3.57894L18.9186 3.87669C18.5239 3.66843 18.1154 3.51127 17.7663 3.39741C17.0859 3.17552 16.2624 3 15.5001 3C14.1308 3 12.949 3.35131 12.0098 4.05593C11.0813 4.75264 10.5583 5.66825 10.2713 6.50905C10.139 6.89661 10.0509 7.28534 9.99437 7.65843C9.4631 7.49123 8.92139 7.26626 8.39105 6.99155C7.18785 6.36832 6.2149 5.57822 5.66605 4.88551C4.74522 3.7233 2.79378 3.80947 2.109 5.32021C1.14386 7.44947 1.40861 9.97346 2.27615 12.0129C2.56693 12.6964 2.94518 13.3721 3.40729 13.9983C3.32818 13.9992 3.2525 13.9996 3.18075 13.9998ZM10 19C6.13666 19 4.13899 17.6007 3.10604 16.1537C3.05963 16.0887 3.10643 16 3.1863 15.9998C4.23714 15.9968 6.39414 15.9471 7.81674 15.1145C7.89031 15.0714 7.87412 14.9629 7.79332 14.9358C4.47715 13.8208 2.59212 9.09859 3.9305 6.1459C3.96119 6.0782 4.05219 6.06927 4.09836 6.12754C5.62723 8.05718 8.97003 9.9472 11.8784 9.99891C11.9415 10 11.9892 9.9427 11.9796 9.88033C11.8624 9.12053 11.4149 5 15.5 5C16.4755 5 17.9269 5.47583 18.4615 5.9633C18.4862 5.98583 18.5197 5.99606 18.5525 5.9895L20.7995 5.5401C20.8811 5.52378 20.9458 5.60845 20.9086 5.68288L19.5149 8.47019C19.5052 8.48954 19.5022 8.51175 19.5059 8.53306C20.482 14.02 15.988 19 10 19Z" fill="currentColor"/>
    </svg>
    

  );
}

export default Twitter;
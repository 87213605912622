import React, {useCallback, useState} from 'react'
import {Input } from 'antd';
  import './Filtertitle.scss'
import {debounce} from 'lodash';
  const { Search } = Input;

function Filtertitle(props: any) {
  const [value, setValue] = useState(props.searchTerm)

  const onSearch = (searchValue: string) => {
        props.setSearchTerm(searchValue?.trim())
    }

    const debounceFn = useCallback(debounce(onSearch, 1000), [])

    const onSearchChange = (e: any) => {
        setValue(e.target.value)
        debounceFn(e.target.value)
    }

  return (

    <div className='feature-title'>
      <div className='feature-ttitletext'>
        All Shows
      </div>
      <div className='title-btn'>
        <Search placeholder="Search shows" allowClear value={value} onChange={onSearchChange}  />
      </div>
    </div>

  )
}

export default Filtertitle

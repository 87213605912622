import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Form, Input, message, Modal, Radio, Row, Select} from 'antd'
import {images} from '../../../../Assets/ImageConfig/images'
import {Cancel, Close, Delete, Edit, Tick} from '../../../Icons'
import type {RadioChangeEvent} from 'antd';
import {addShippingAddress, updateShippingAddress, validateAddressAction, validateShippingAddress} from '../../../../store/Actions/users';
import {RootState} from '../../../../store';
import CountryList from '../../../../Utils/ContryService/CountryList';
import {capitalizeFirstLetter, displayErrorToast} from '../../../../Utils/helper';
import LoadingSpinner from '../../../CommonComponents/LoadingSpinner';
import {address_added_fail, address_added_success, address_screen_viewed} from '../../../../Utils/mParticle';
import {strings} from '../../../../Utils/constants';
// import './Payment.scss';

type Props = {
  isShippingAddress: boolean;
  address?: any
  handleOk: any;
  handleCancel: any;
  getShippingAddresses: any;
}

const ShippingAddModal = (props: Props) => {

  const dispatch = useDispatch()
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const [value, setValue] = useState(1);
  const [isAddressDeliverable, setAddressDeliverable] = useState(false)
  const [validationCounter, setValidationCounter] = useState(0)
  const [validationText, setValidationText] = useState('')
  const [isFormSubmitted, setisFormSubmitted] = useState(false)
  const [isEditAddress, setEditAddress] = useState(false)

  const [formValid, setFormValid] = useState(false)

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  const {Option} = Select;
  const [form] = Form.useForm();

  const verifyAddress = async (values: any) => {
    console.log({values});
    setisFormSubmitted(true)
    try {

      if (!values?.fullName?.trim()?.length ||
        !values?.addressLine1?.trim()?.length ||
        !values?.houseNumber?.trim()?.length ||
        !values?.city?.trim()?.length ||
        !values?.postalCode?.trim()?.length ||
        !values?.countryCode?.trim()?.length
      )
      {
        address_added_fail(
          users.user, (users?.addressesList.length || 0) + 1, (users?.addressesList.length || 0) + 1, 'INPUT', 'Please ensure fields are filled', 'invalid_input'
        );
        return;
      }

      const houseNumber = values?.houseNumber?.replace(/\D/g, '');

      const countryCode = values?.country === 'INT' ? '' : values?.countryCode;

      const country = CountryList.find((country: any) => country.code === values?.countryCode)?.name;
      form.setFieldsValue({country});

      const lobData = {
        primary_line: `${values?.addressLine1} ${houseNumber}`,
        city: values?.city,
        state: values?.state,
        postal_code: values?.postalCode,
        country: countryCode
      }

      console.log({lobData});


      await dispatch(validateShippingAddress(lobData))
      setValidationCounter((prevCounter) => prevCounter + 1)
    } catch (error: any) {
      console.log({error});
      if (error.response?.data?.message)
        displayErrorToast(error.response?.data?.message)
      if (error.response?.data?.error)
        error.response?.data?.error.forEach((error: any) => {
          displayErrorToast(error.message)
        })
    }
  }

  const onSaveAddress = async () => {
    const formData = form.getFieldsValue(true);
    try {

      console.log({formData});
      if (isEditAddress) {
        await dispatch(updateShippingAddress(formData));
        props?.getShippingAddresses()
      }
      else {
        await dispatch(addShippingAddress(formData));
        address_added_success( users.user,(users?.addressesList.length || 0) + 1, formData.countryCode || 'int' );
        props?.getShippingAddresses()
      }
      props?.handleCancel()
    } catch (error: any) {
      console.log({error});
      !isEditAddress && address_added_fail(users.user, (users?.addressesList.length || 0) + 1, formData.countryCode || 'int', 'API', '', 'unexpected_error');
      if (error.response?.data?.message)
       displayErrorToast(error.response?.data?.message)
      if (error.response?.data?.error)
        error.response?.data?.error.forEach((error: any) => {
          displayErrorToast(error.message)
        })
    }
  }

  useEffect(() => {
    address_screen_viewed(users.user,props?.address?.length || 0, 'Add_Shipping_Address', '');
    if (props?.address && Object.keys(props?.address).length) {
      const {uuid, ...newAddress} = props?.address;
      setEditAddress(true)
      form.setFieldsValue({
        ...newAddress
      })
    }
    else {
      setEditAddress(false)
    }
  }, [props?.address])


  useEffect(() => {
    console.log({response: users.validatingAddress, });
    if (users.validatingAddress?.lobAddressResponse && Object.keys(users.validatingAddress?.lobAddressResponse).length) {

      const logAddressFailEvent = (errorStr: string) => {
        if (!users.validatingAddress?.isValidating && !isEditAddress) {
          address_added_fail(
            users.user, (users?.addressesList.length || 0) + 1, (users?.addressesList.length || 0) + 1, 'INPUT', errorStr, 'invalid_input'
          );
        }
      };

      const lobData = users.validatingAddress?.lobAddressResponse
      form.setFieldsValue({addressLine1: capitalizeFirstLetter(lobData?.street_name)})

      const houseNumber = form.getFieldValue('houseNumber');

      const isValidHouseNumber = houseNumber?.includes(lobData?.primary_number)
      if (isValidHouseNumber) {
        form.setFieldsValue({houseNumber})
      }

      form.setFieldsValue({houseNumber});
      form.setFieldsValue({city: capitalizeFirstLetter(lobData?.city)})
      form.setFieldsValue({postalCode: lobData?.postal_code})
      switch (users.validatingAddress?.deliverability) {
        case 'deliverable':
          setAddressDeliverable(true)
          setValidationText('We checked your address - it is valid, we just improved some details. Please check again if all is correct.')
          form.setFieldsValue({isValid: true})
          break;

        case 'deliverable_missing_info':
          setAddressDeliverable(false)
          setValidationText(strings.partialValidAddress)
          form.setFieldsValue({isValid: false})
          logAddressFailEvent(strings.partialValidAddress)
          break;

        case 'undeliverable':
        case 'no_match':
          setAddressDeliverable(false)
          setValidationText(strings.invalidAddress)
          form.setFieldsValue({isValid: false})
          logAddressFailEvent(strings.invalidAddress)
          break;

        default:
          setAddressDeliverable(false)
          setValidationText(strings.invalidAddress)
          form.setFieldsValue({isValid: false})
          logAddressFailEvent(strings.partialValidAddress)
          break;
      }

    }
    else {
      setValidationText('')
    }
  }, [users.validatingAddress])

  useEffect(() => {
    return () => {
      dispatch(validateAddressAction({
        isValidating: false,
        deliverability: '',
        lobAddressResponse: null
      }))
    }
  }, []);

  return (
    <>

      <Modal title="Shipping Addresses" className='themeModal AddNewCard' centered closeIcon={<Close />} width={507} visible={props.isShippingAddress} onOk={props.handleOk} footer={false} onCancel={props.handleCancel}>
        <div className='ShippingAddressModalStyle'>
          <Form form={form} name="basic" layout={'vertical'} onFinish={verifyAddress}
            onFieldsChange={() => {
              if (((isFormSubmitted && isAddressDeliverable) || (isAddressDeliverable && isEditAddress))) {
                setValidationCounter(0)
                setAddressDeliverable(false)
                setValidationText('Please verify your address again')
              }
              setFormValid(form.getFieldsError().filter(({errors}) => errors.length).length !== 0)
            }}>
            <Form.Item label="Full Name" name="fullName" rules={[
              {
                required: true,
                message: 'Name cannot be empty'
              }
            ]}>
              <Input placeholder='Full Name' allowClear />
            </Form.Item>
            <Row gutter={15}>
              <Col xs={24} sm={14} lg={14}>
                <Form.Item label="Street" name="addressLine1" rules={[{
                  required: true,
                  message: 'Street cannot be empty'
                }]}>
                  <Input placeholder="Street" allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={10} lg={10}>
                <Form.Item label="Number" name="houseNumber" rules={[{
                  required: true,
                  message: 'Number required'
                }]}>
                  <Input placeholder="Number" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="(Other address information)" name="addressLine2" >
              <Input placeholder="(Other address information)" allowClear />
            </Form.Item>
            <Row gutter={15}>
              <Col xs={24} sm={14} lg={14}>
                <Form.Item label="Postcode" name="postalCode" rules={[{
                  required: true,
                  message: 'Postal code cannot be empty'
                }]}>
                  <Input placeholder="Postcode" allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={10} lg={10}>
                <Form.Item label="City" name="city" rules={[{
                  required: true,
                  message: 'City cannot be empty'
                }]}>
                  <Input placeholder="City" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Country" name="countryCode" rules={[{
              required: true,
              message: 'Country cannot be empty'
            }]}>
              <Select showSearch optionFilterProp="children" notFoundContent={null}>
                {
                  CountryList.map((country: any, index: number) => (
                    <Option value={country.code} key={index}>{country.name}</Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item name='isDefault' valuePropName='checked'>
              <Radio>Set as primary shipping address</Radio>
            </Form.Item>
            {
              users.validatingAddress?.isValidating ? <LoadingSpinner /> :
                (isFormSubmitted && validationText) &&
                (

                  <div className='ShippingAddNote'>
                    {
                      isAddressDeliverable ? (
                        <span className='icon green'><img src={images.RightClick} alt="RightClick" /></span>
                      ) : (
                        <span className='icon red'><img src={images.infoicon} alt="infoicon" /></span>
                      )
                    }
                    <p>{validationText}</p>
                    {/* <span className='icon red'><Cancel /></span> */}
                  </div>
                )
            }
            {((isAddressDeliverable) || validationCounter >= 2) && (
              <Form.Item>
                <Button type='primary' className='w100' htmlType='button' disabled={formValid || users.validatingAddress.isValidating} onClick={onSaveAddress}>Save</Button>
              </Form.Item>
            )}
            {/* {!isAddressDeliverable && ( */}
            <Form.Item>
              <Button type='ghost' className='w100' htmlType='submit' disabled={formValid || users.validatingAddress.isValidating}>Verify Address</Button>
            </Form.Item>
            {/* )} */}

          </Form>
        </div>
      </Modal>

    </>
  )
}

export default ShippingAddModal
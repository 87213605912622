import React from "react";

function Live() {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2681 0.23011C11.9701 -0.0679041 11.4734 -0.0679041 11.1613 0.23011C10.8632 0.528124 10.8632 1.02479 11.1613 1.33697C12.1404 2.31612 12.6797 3.62171 12.6797 4.99813C12.6797 6.37456 12.1404 7.68027 11.1613 8.6593C10.8632 8.95731 10.8632 9.45398 11.1613 9.76616C11.3174 9.9223 11.516 9.99324 11.7147 9.99324C11.9133 9.99324 12.1121 9.9223 12.2681 9.76616C13.5453 8.48901 14.2407 6.80028 14.2407 5.01216C14.2407 3.19575 13.5453 1.50711 12.2681 0.22999V0.23011Z"
        fill="white"
      />
      <path
        d="M2.32029 4.9982C2.32029 3.60751 2.85955 2.31607 3.83869 1.33704C4.13671 1.03902 4.13671 0.542358 3.83869 0.230179C3.54068 -0.0678355 3.04401 -0.0678355 2.73183 0.230179C1.45468 1.50733 0.759277 3.19606 0.759277 4.99826C0.759277 6.78635 1.483 8.51769 2.74601 9.76634C2.90215 9.92249 3.1008 9.99342 3.29944 9.99342C3.49808 9.99342 3.69684 9.92249 3.85287 9.76634C4.15088 9.45417 4.15088 8.97166 3.8387 8.65948C2.87373 7.70856 2.3203 6.37451 2.3203 4.99802L2.32029 4.9982Z"
        fill="white"
      />
      <path
        d="M10.3098 2.20272C10.0118 1.89055 9.51515 1.89055 9.20298 2.18856C8.8908 2.48658 8.8908 2.98324 9.18881 3.29542C9.64297 3.74958 9.89839 4.35979 9.89839 4.99829C9.89839 5.63691 9.64299 6.24713 9.18881 6.70116C8.8908 7.01334 8.8908 7.49584 9.20298 7.80802C9.35912 7.96417 9.55777 8.0351 9.75641 8.0351C9.95504 8.0351 10.1538 7.96417 10.3098 7.80802C11.062 7.05607 11.4736 6.06266 11.4736 4.99829C11.4736 3.94822 11.062 2.9548 10.3098 2.20273V2.20272Z"
        fill="white"
      />
      <path
        d="M5.7972 2.18835C5.49919 1.89033 5.00252 1.89033 4.69034 2.18835C3.93816 2.94054 3.52661 3.94812 3.52661 4.99819C3.52661 6.06256 3.93816 7.05585 4.69023 7.80804C4.84637 7.96419 5.04501 8.03512 5.24365 8.03512C5.44229 8.03512 5.64105 7.96419 5.79708 7.80804C6.0951 7.51003 6.0951 7.01336 5.79708 6.70118C5.34293 6.24703 5.08751 5.63682 5.08751 4.99831C5.08751 4.35969 5.34291 3.74948 5.79708 3.29545C6.10938 2.99731 6.10938 2.50067 5.7972 2.18838L5.7972 2.18835Z"
        fill="white"
      />
      <path
        d="M8.69221 4.99819C8.69221 5.65662 8.15855 6.19024 7.50016 6.19024C6.84185 6.19024 6.30811 5.65659 6.30811 4.99819C6.30811 4.33988 6.84185 3.80614 7.50016 3.80614C8.15858 3.80614 8.69221 4.33988 8.69221 4.99819Z"
        fill="white"
      />
    </svg>
  );
}

export default Live;

import React, {useState, useEffect, useContext} from 'react';
import {Button, Modal, Form, Select, Input} from 'antd';
import {images} from '../../../Assets/ImageConfig/images'
import './Msgarea.scss'
import {Cancel, Settings} from '../../Icons';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import CreateGroupModal from '../CreateGroupModal/CreateGroupModal';
import {checkChannels, dateFormatConversion, getChannelURL, sliceArr, timeFormatConversion} from '../../../Utils/helper';
import LoadingSpinner from '../../CommonComponents/LoadingSpinner';
import NoMessage from '../NoMessage/NoMessage';
import {SocketContext} from '../../../Context/socket';
import MessageInput from '../MessageInput/MessageInput';
import {openLoginSignUpModal} from '../../../store/Actions/users';
import {MessagesData} from '../types';
import ProfileBadges from '../../CommonComponents/ProfileBadges';
import ModBadge from '../../CommonComponents/ModBadge';

const {Option} = Select;

function Msgarea(props: any) {
  // console.log('msg area props:', props)

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const dispatch = useDispatch()

  const [oldMessages, setOldMessages] = useState<any>([])
  const [userId, setUserId] = useState<string>(props.currentUser.id)
  const [activeChannel, setActiveChannel] = useState<any>(null)

  const [isAdminUser, setIsAdminUser] = useState(false)
  const [groupMessages, setGroupMessages] = useState<any>({})

  const [messageList, setMessageList] = useState<any[]>([]);
  const [chatTitle, setChatTitle] = useState('')
  const [loading, setLoading] = useState(false)

  // const isAdmin = channel?.admins && channel?.admins.includes(String(user?.id));

  const io = useContext(SocketContext)


  const chatNewMessage = (data: any) => {
    console.log('before emitting chatGetOldMessages', props.selectChannel);

    io.emit('chatGetOldMessages', {user_id: users?.user.id, channel_id: props.selectChannel});

    // REMOVE?
    // if (data?.channel_id === selectedChannel) {
    // console.log('chat new messages: ', data);

    // console.log('channel_id: ', selectedChannel);

    // const newMessage: any[] = [...messageList];
    // newMessage.unshift(data);
    // setMessageList(newMessage);
    // console.log('message list in chatNewMessage:', newMessage)

    // }
  }

  const chatGetOldMessages = (data: any) => {

    if (data.messages) {
      if (data?.channel_id === props.selectChannel) {
        console.log('chatGetOldMessages message assign');

        setMessageList(data.messages)
        console.log(data.messages)
        // setIsChannelSelected(true)
      }
    }
    else {
      console.log('Channel does not exist yet');
      setMessageList([])
    }
    // setLoading(false)
    // setLoadingChat(false);
    console.log('chat get old messages')
    console.log('chatGetOldMessages', data?.messages?.length);
    console.log('chatGetOldMessages', data)

  }

  const chatGetGroupChannel = (data: any) => {
    console.log('chatGetGroupChannel', {data});
    if (data?.id === props?.selectChannel) {
      setActiveChannel(data);
    }
  }

  const sendMessage = (messageObj: any) => {
    const updatedMessages: any[] = [...messageList];
    messageObj.sending = true;
    updatedMessages.unshift(messageObj);
    setMessageList([...updatedMessages]);
    console.log('message list in sendMessage:', updatedMessages)
    // props.scrollToLastMessage()
  }

  const emitMessage = (message: string) => {
    console.log('Chat emitMessage (' + message + ') sent to channel #' + props.selectChannel);

    if (!users.user?.id) {
      dispatch(openLoginSignUpModal(true))
      return
    }
    // Get all selected channel data -
    const allSelectedChannelData: any[] = []
    props.channels.map((elm: any, i: number) => {
      if (elm.id === props.selectChannel) {
        allSelectedChannelData.push(elm)
      }
    })

    const id = String(new Date().getTime());

    const messageObj: MessagesData = {
      id,
      channel_id: props.selectChannel,
      user_id: users?.user.id,
      pic_uri: users?.user.pic,
      message,
      time: new Date(),
      from: users?.user?.username,
      title: allSelectedChannelData[0]?.title
    };

    if (allSelectedChannelData.length > 0) {
      if (props.selectChannel != undefined) {
        if (allSelectedChannelData[0]?.type === 'general') {

          console.log('all selected data', allSelectedChannelData)

          io.emit('chatNewMessage', {
            'id': props.selectChannel,
            'user_id': users.user?.id ? users.user?.id.toString() : '',
            'title': allSelectedChannelData[0].title,
            'subscribers': allSelectedChannelData[0].subscribers,
            'message': messageObj,
            'pic_url': allSelectedChannelData[0].pic_url,
            'usernames': allSelectedChannelData[0].usernames || "guest",
            'type': 'general',
            'admins': allSelectedChannelData[0]?.admins,
          });
        } else {
          io.emit('chatNewMessage', {
            'id': props.selectChannel,
            'user_id': users.user?.id ? users.user?.id.toString() : '',
            'title': allSelectedChannelData[0].title,
            'subscribers': allSelectedChannelData[0].subscribers,
            'message': messageObj,
            'pic_url': allSelectedChannelData[0].pic_url,
            'profile_pics': allSelectedChannelData[0].profile_pics,
            'usernames': allSelectedChannelData[0].usernames || "guest",
            'type': 'private'
          });
        }
      }
    }

    sendMessage(messageObj)
    // renderMessage(messageObj)
    console.log('emitMessage:', message)
    console.log('emitMessage:', messageObj)
    console.log('emit message user obj:', users.user)
    console.log('emitmessage:', props.selectChannel)

  }

  const loadChatMessages = () => {
    setLoading(true)
    console.log('before emitting from loadMessagesChat', props.selectChannel);

    io.emit('chatGetOldMessages', {user_id: users.user.id, channel_id: props.selectChannel});
    io.emit('chatGetGroupChannel', {user_id: users.user.id, channel_id: props.selectChannel});
  }

  const onGroupUpdate = async (data: any) => {
    const exists = await checkChannels(users.user?.id, props.selectChannel);

    if (exists) {
      io.emit('chatGetGroupChannel', { user_id: users.user?.id, channel_id: props.selectChannel });
    } else {
      props?.resetChannel()
    }
  }

  useEffect(() => {
    console.log('selectChannel:::', props.selectChannel);

    setMessageList([])
    loadChatMessages()

    // return () => {
    //   io.off('chatNewMessage');
    //   io.off('chatGetOldMessages');
    //   io.off('chatGetGroupChannel');
    //   io.off('chatGroupChannel')
    // }
  }, [props.selectChannel]);


  useEffect(() => {
    console.log('Msgarea:: socket io connected', io.connected);

    io.off('chatNewMessage')
    io.on('chatNewMessage', chatNewMessage);

    io.off('chatGetOldMessages');
    io.on('chatGetOldMessages', chatGetOldMessages);

    io.off('chatGetGroupChannel');
    io.on('chatGetGroupChannel', chatGetGroupChannel);

    io.off('chatGroupChannel', onGroupUpdate);
    io.on('chatGroupChannel', onGroupUpdate);

  }, [props.selectChannel])
  // io.connected, props.selectChannel



  // useEffect(() => {
  //   if (props.messages === oldMessages) return;
  //   setOldMessages(props.messages)
  //   console.log('old messages update', oldMessages)
  // }, [props.messages, props.user])

  useEffect(() => {
    if (props.user_id === userId) return;
    setUserId(props.id)
    console.log('user id update', userId)
  }, [props.currentUser, props.messages])

  // useEffect(() => {
  //   if (props.selectChannel && props.channels?.length) {
  //     setActiveChannel(props.channels?.find((channel: any) => channel?.id === props.selectChannel))
  //   }
  // }, [props.selectChannel, props.channels])

  useEffect(() => {
    if (activeChannel && activeChannel?.admins?.length) {
      setIsAdminUser(activeChannel?.admins?.includes(String(users?.user?.id)))
    }
    props?.scrollToLastMessage()
    if (activeChannel)
      setChatTitle(activeChannel?.type === 'general' ? activeChannel?.title : (getChannelTitle(activeChannel) ?? ''))
    else
      setChatTitle('')
  }, [activeChannel])

  // const groupMessages: any = {}
  useEffect(() => {
    console.log('running messages hook', {len: messageList.length});
    const tempGroupMessages: any = {}
    messageList.map((message: any) => {

      const time = new Date(message.time)
      const groupKey = `${time.getFullYear()}-${('0' + (time.getMonth() + 1)).slice(-2)}-${('0' + time.getDate()).slice(-2)}`;
      if (tempGroupMessages[groupKey]) {
        tempGroupMessages[groupKey] = [message, ...tempGroupMessages[groupKey]];
      }
      else {
        tempGroupMessages[groupKey] = [message]
      }

      // console.log('message pic', message.pic_uri)
    })
    setGroupMessages({...tempGroupMessages})
    // props?.scrollToLastMessage()
    setLoading(false)
  }, [messageList]);

  useEffect(() => {
    if (groupMessages && Object.keys(groupMessages).length) {
      props?.scrollToLastMessage()
    }
  }, [groupMessages])


  // console.log('oldMessages:', oldMessages)

  // const groupMessages: any = {}


  // props.messages.map((message: any) => {

  //   const time = new Date(message.time)
  //   const groupKey = `${time.getFullYear()}-${('0' + (time.getMonth() + 1)).slice(-2)}-${('0' + time.getDate()).slice(-2)}`;
  //   if (groupMessages[groupKey]) {
  //     groupMessages[groupKey] = [message, ...groupMessages[groupKey]];
  //   }
  //   else {
  //     groupMessages[groupKey] = [message]
  //   }

  //   // console.log('message pic', message.pic_uri)
  // })


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const saveGroup = (name: string, usersList: object[], admins: [], removed?: []) => {

    const id = String(props?.selectChannel);
    let subscribers: string[] = usersList?.length ? usersList.map((userObj: any) => String(userObj?.id)) : []

    if (!subscribers?.includes(String(users.user?.id))) {
      users.user?.id && subscribers.push(String(users.user?.id))
    }

    const channel = {
      id,
      title: name,
      time: activeChannel.time,
      subscribers,
      type: 'general',
      last_message: activeChannel?.last_message,
      admins,
      pic_url: getChannelURL(name)
    }

    if (removed?.length) {
      io.emit('chatRemoveUser', {...channel, removedUsers: removed});
    }
    else {
      io.emit('chatGroupChannel', channel)
    }
    handleCancel()
  };

  const leaveGroup = (users: []) => {
    props.onLeaveGroup(users)
    handleCancel()
  }

  const getChannelTitle = (channel: any) => {
    let receiverId;
    if (channel?.subscribers) {
      receiverId = sliceArr(channel.subscribers?.map(String), users.user?.id?.toString())[0]
    }
    else {
      receiverId = users.user?.id?.toString()
    }

    let username;
    if (channel?.usernames) {
      username = channel?.usernames && channel?.usernames[receiverId];
    }
    return username
  }

  return (
    <>
    <div className='msgarea'>
      <div className='namingArea'>
        <p>
            {chatTitle}
            {activeChannel?.id === props?.selectedChannelData?.id && (
              <>
              {props?.selectedChannelData?.admin && <ModBadge />}
              {activeChannel?.type === 'private' ? props?.selectedChannelData?.host && <ProfileBadges user={props?.selectedChannelData} /> : null}
              </>
            )}
        </p>
        {activeChannel?.type === 'general' && (
          <span className='chat-settings' onClick={showModal}>
            <Settings />
          </span>
        )}
      </div>
      {loading && <LoadingSpinner />}
      {!!Object.keys(groupMessages).length ? Object.keys(groupMessages).sort().map((groupDate: any, dateIndex: number) => (
          <div className="msger-chat" key={groupDate}>
            <div className='msg-center'>
              <div className='msg-status'>
                {dateFormatConversion(groupDate)}
              </div>
            </div>
            {
              groupMessages[groupDate]?.map((message: any, index: number) => (
                <div
                  className={message.user_id === props.currentUser.id ? "msg right-msg" : "msg left-msg"}
                  key={message?.id}
                  ref={Object.keys(groupMessages).length - 1 === dateIndex && groupMessages[groupDate].length - 1 === index ? props.lastMessageRef : null}
                  id={Object.keys(groupMessages).length - 1 === dateIndex && groupMessages[groupDate].length - 1 === index ? 'last one': 'not last'}
                >
                  <div className="msg-img" >
                    <img src={message.pic_uri} alt="" />
                  </div>

                  <div className="msg-pop">
                    <div className="msg-bubble">
                      {message.message}

                    </div>
                    <div className="msg-info-time">
                      {timeFormatConversion(message.time)}
                    </div>
                  </div>
                </div>
              ))
            }

          </div>
      )) : (
          <NoMessage />
        )
      }


      {/* Settings modal */}
      {isModalVisible && <CreateGroupModal isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} isSettingsModal
        admins={activeChannel?.admins} groupName={activeChannel?.title} isAdmin={isAdminUser} subsList={activeChannel?.subscribers}
        onSaveGroup={saveGroup} onLeaveGroup={leaveGroup}
        />}
    </div>
        <MessageInput selectChannel={props?.selectChannel} emitMessage={emitMessage} preDefinedMessage={props.preDefinedMessage} />
    </>
  );
}

export default Msgarea

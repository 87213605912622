import React from "react";

function Envelope() {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8333 1.16797H3.16667C2.24619 1.16797 1.5 1.91416 1.5 2.83464V11.168C1.5 12.0884 2.24619 12.8346 3.16667 12.8346H14.8333C15.7538 12.8346 16.5 12.0884 16.5 11.168V2.83464C16.5 1.91416 15.7538 1.16797 14.8333 1.16797Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.5 2.83496L9 7.83496L16.5 2.83496" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}

export default Envelope;
import {Modal} from 'antd'
import React from 'react';
import {Close} from '../../../Icons'

import CreditCard from '../../../ShowScreen/Components/CreditCard';
// const CARD_OPTIONS = {
//   style: {
//     base: {
//       fontFamily: `"Inter", sans-serif`,
//       letterSpacing: '0.8px',
//       color: '#959798',
//       '::placeholder': {
//         color: '#959798',
//       },
//     },
//   },
//   hidePostalCode: true
// };

// import './Payment.scss';
const NewcardModal = (props: any) => {


  const {stripe, elements} = props;

  return (
    <>
      {/* Add a new card */}
      <Modal title="Credit card" className='themeModal AddNewCard' centered closeIcon={<Close />} width={507} visible={props.isAddnewcard} onOk={props.handleOk} footer={false} onCancel={props.handleCancel}>
        <CreditCard stripe={stripe} elements={elements} isShowPage={props?.isShowPage} handleOk={props.handleOk} handleCancel={props.handleCancel} openTopUpModal={props.openTopUpModal} />
      </Modal>

    </>
  )
}

export default NewcardModal
import React, {useEffect, useState, useContext, forwardRef, useImperativeHandle, useRef, useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {openLoginSignUpModal, updateUser} from "../../../store/Actions/users";
import "../show.scss"
import {ProfileBadge, Send} from '../../../Components/Icons/index'
import {Button, message as antdMessage} from "antd";
import _, {cloneDeep} from "lodash"
import {SocketContext} from '../../../Context/socket';
import {AnalyticsContext} from '../../../Context/analytics';
import {displayErrorToast, displayInfoToast, openInNewTab, placeHolderProfileHelper} from "../../../Utils/helper";
import appConfig from "../../../Utils/config";
// @ts-ignore
import InputEmoji from 'react-input-emoji';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import Arrowdown from "../../Icons/Arrowdown/Arrowdown";
import Video from "../../Icons/Video/Video";
import GameShowDisclaimer from "../Components/GameShowDisclaimer";
import ChatSend from "../../Icons/ChatSend/ChatSend";
import ProfileBadges from "../../CommonComponents/ProfileBadges";
import ModBadge from "../../CommonComponents/ModBadge";
import {bid_placed, chat_message_sent, give_away_started, item_viewed, payment_event, payment_fail, purchase} from "../../../Utils/mParticle";
import {strings} from "../../../Utils/constants";
// import ShowScreen from "../index"
import ShippingSizeCostModal from '../Components/ShippingSizeCostPopup/ShippingSizePopup'
import {useNavigate} from "react-router-dom";
import {ShippingUpgradeData} from "./types"

let init_messages: [] = [];
const message_ids: number[] = [];
let initializing = false;
let ioConnected = false;
const Chat = forwardRef((props: any, ref: any) => {
  const observer = useRef<any>()
  const io = useContext(SocketContext)
  const analytics = useContext(AnalyticsContext)
  const [messages, setMessages] = useState<any>([])
  // const [ioConnected, setIoConnected] = useState<boolean>(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [followersCount, setFollowersCount] = useState(0)
  const [message, setMessage] = useState<string>('');
  const [amount, setAmount] = useState<number>(1);
  const [price, setPrice] = useState<number>(-1);
  const [is_bidding, isBidding] = useState<boolean>(false);
  const [is_waiting, isWaiting] = useState<boolean>(false);
  const [is_instant, isInstant] = useState<boolean>(false);
  const [item, setItem] = useState<any>(null);
  const [loading, setLoading] = useState(false)
  const [sendable, setSendable] = useState("")
  const [is_biddable, isBiddable] = useState<boolean>(false);
  const [gameShowVisible, setGameShowVisible] = useState(false)


  const dispatch = useDispatch()
  let liveShow = {}
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const showDashboard = useSelector(
    (state: RootState) => state?.showDashboard
  );

  const [wallet_amount, setWalletAmount] = useState<number>();
  const [last_payment_status, setLastPaymentStatus] = useState<string>('');
  const [saved_card_count, setSavedCardCount] = useState<number>(0);
  const [disable_bid, setBidDisabled] = useState<boolean>(false);
  const [cardBrand, setCardBrand] = useState<string>();
  const [isToppedUpBefore, setIsToppedUpBefore] = useState<boolean>(false);
  const [shippingAddressCount, setShippingAddressCount] = useState<number>(0);
  const [hasShippingAddress, setHasShippingAddress] = useState<string>('unknown')
  const [hasPaymentMethod, setHasPaymentMethod] = useState<string>('unknown')
  const [shipping_amount, setShippingAmount] = useState<number>(0);
  const [type, setType] = useState<string>('');
  const [shipping_on_winner, setShippingWinner] = useState<boolean>(false);
  const [is_winning, isWinning] = useState<boolean>(false);
  const [winner, setWinner] = useState<string>();
  const [winners, setWinners] = useState<any>([]);
  const [shippingFeeDescription, setShippingFeeDescription] = useState<string>('')
  const [shippingFee, setShippingFee] = useState<number>(0)
  const [isChatScrollable, setChatScrollable] = useState(false)
  const [isLastVisible, setLastVisible] = useState(false)
  const [shippingCostModalVisible, setshippingCostModalVisible] = useState<boolean>(false)
  const [shippingUpgradeData, setUpgradedShippingData] = useState<ShippingUpgradeData>({price: '', subTitle: '', title: ''})
  const [upgradeShippingPaymentStatus, setUpgradeShippingPaymentStatus] = useState<string>('')

  const navigate = useNavigate()

  const bindedIO = () => {
    setTimeout(() => {
      props.setIsConnecting(false)
    }, 4000);

    console.log(users.currentShow)
    if (io.connected) {
      emitInit();
    }
  }
  const connectIO = async () => {

    // eslint-disable-next-line no-empty
    if (ioConnected || initializing) {

    } else {
      initializing = true;

      await io.connect();
      console.log('connected?', io.connected)
      // setIoConnected(true)
      // ioConnected = true;
      return

    }
  };
  useImperativeHandle(ref, () => ({
    emitBid1(flag: any) {
      emitBid(flag)
    }

  }));
  // useEffect(() => {
  //   props.isBiddable(is_biddable)
  //   props.setItem(item)
  //   props.setSavedCardCount(saved_card_count)
  //   props.isBidding(is_bidding)
  //   props.isInstant(is_instant)
  //   props.setPrice(price)
  //   props.setWalletAmount(wallet_amount)
  //   props.setShippingAddressCount(shippingAddressCount)
  //   props.setShippingAmount(shipping_amount)
  //   props.setIsToppedUpBefore(isToppedUpBefore)
  //   props.isWinning(is_winning)
  //   props.isWaiting(is_waiting)
  //   props.setLastPaymentStatus(last_payment_status)
  //   props.setWinner(winner)
  //   props.setWinners(winners)
  // }, [is_biddable,
  //   item, is_bidding,
  //   saved_card_count,
  //   is_instant,
  //   price,
  //   wallet_amount,
  //   shipping_amount,
  //   isToppedUpBefore,
  //   is_winning,
  //   last_payment_status,
  //   winner,
  //   winners,
  //   is_waiting
  // ])

  useEffect(() => {props.isBiddable(is_biddable)}, [is_biddable]);
  useEffect(() => {props.setItem(item)}, [item]);
  useEffect(() => {props.setAmount(amount)}, [amount]);
  useEffect(() => {props.isBidding(is_bidding)}, [is_bidding]);
  useEffect(() => {props.setSavedCardCount(saved_card_count)}, [saved_card_count]);
  useEffect(() => {props.isInstant(is_instant)}, [is_instant]);
  useEffect(() => {props.setPrice(price)}, [price]);
  useEffect(() => {props.setWalletAmount(wallet_amount)}, [wallet_amount]);
  useEffect(() => {props.setShippingAddressCount(shippingAddressCount)}, [shippingAddressCount]);
  useEffect(() => {props.setHasShippingAddress(hasShippingAddress)}, [hasShippingAddress]);
  useEffect(() => {props.setHasPaymentMethod(hasPaymentMethod)}, [hasPaymentMethod]);
  useEffect(() => {props.setShippingAmount(shipping_amount)}, [shipping_amount]);
  useEffect(() => {props.setIsToppedUpBefore(isToppedUpBefore)}, [isToppedUpBefore]);
  useEffect(() => {props.isWinning(is_winning)}, [is_winning]);
  useEffect(() => {props.setLastPaymentStatus(last_payment_status)}, [last_payment_status]);
  useEffect(() => {props.setWinner(winner)}, [winner]);
  useEffect(() => {props.setWinners(winners)}, [winners]);
  useEffect(() => {props.isWaiting(is_waiting)}, [is_waiting]);
  useEffect(() => {props.setType(type)}, [type]);
  useEffect(() => {props.setCardBrand(cardBrand)}, [cardBrand]);
  useEffect(() => {props.setShippingFeeDescription(shippingFeeDescription)}, [shippingFeeDescription]);
  useEffect(() => {props.setShippingFee(shippingFee)}, [shippingFee]);
  useEffect(() => {props.setUpgradedShippingData(shippingUpgradeData)}, [shippingUpgradeData]);
  useEffect(() => {props.setUpgradeShippingPaymentStatus(upgradeShippingPaymentStatus)}, [upgradeShippingPaymentStatus]);

  useEffect(() => {

    if (io.connected) {

    }
    // return () => props.destroyAgora()
  }, [io.connected])

  const emit = (eventname: string, params: any) => {
    const show: any = users.currentShow

    if (typeof params !== 'object') params = {};
    params.user = {
      id: users.user.id,
      email: users.user.email,
      username: users.user.username,
      name: users.user.name,
      phone: users.user.phone,
      pic: users.user.pic,
    };
    params.show = {"id": show.id, "link": show.link, "user_id": show.user_id, "seller": show.seller};
    io.emit(eventname, params);
  };
  const fetchMyAPI = async () => {
    if (!users?.currentShow?.id) {

      if (!users.loader) {

        if (!users?.currentShow?.id) {
          props.setIsShowOn(true)
        }

      }
    } else {
      props.setIsShowOn(true)
    }

    if (users?.currentShow?.id && !initializing && !ioConnected && users.isFetchedUser) {
      props?.init()
      liveShow = users.currentShow;
      props.setIsConnecting(true)
      io.off('connect', onConnect);
      io.off('connect_error', onConnectError);
      io.off('disconnect', onDisconnect);
      io.off('error', onError);
      io.on('connect', onConnect);
      io.on('connect_error', onConnectError);
      io.on('disconnect', onDisconnect);
      io.on('error', onError);
      setLoading(true);
      await connectIO()

    }
    // if (!users?.user?.username && !_.isEmpty(users.currentShow)) {
    //   disconnectIO()
    // }
  }
  useEffect(() => {

    if (!users.isFetchedUser) {
      disconnectIO()
      ioConnected = false;
      initializing = false;
    }
    if (users.currentShow && users.isFetchedUser) {

      fetchMyAPI()
    }


  }, [users.currentShow, users.loader, users.isFetchedUser, users.user.id])

  useEffect(() => {
    if (users.user?.id) {
      if (users.currentShow?.followed_by_me && users.user?.id)
        setIsFollowing(true)
      else
        setIsFollowing(false)
    }
    setFollowersCount(users.currentShow?.followed_by_ids?.length)
  }, [users.currentShow])

  useEffect(() => {
    if (users.user?.id && users.isFetchedUser) {
      if(users.currentShow?.isGameShow === true && users.currentShow?.isGameDisclaimerAccepted === false) {
        setGameShowVisible(true)
      }
      else if (users.currentShow?.isGameShow && users.currentShow?.isGameDisclaimerAccepted) {
        setGameShowVisible(false)
      }
    }
  }, [users.user?.id, users.currentShow, users.isFetchedUser])


  useEffect(() => {
    if (users?.userExistance?.isUserExist && users.user.id) {
      if (localStorage.getItem('loggedInFrom') === 'Gmail' && !users.user?.phone) {
        return
      }
      window.location.reload()
    }
  }, [users?.userExistance?.isUserExist, users.user.id, users.user?.username, users.user?.phone])

  useEffect(() => {
    const chatLists = document.getElementById('chat-list')!
    const scrollableCheck = chatLists?.scrollHeight > chatLists?.clientHeight
    setChatScrollable(scrollableCheck)
  }, [messages])

  const disconnectIO = () => {

    if (initializing || ioConnected) {
      io.disconnect();
      initializing = false;
      // setIoConnected(false);
      ioConnected = false;
      setMessages([]);
      //   log('IO disconnected');
      //   log('---------------');
      //   Bugsnag.leaveBreadcrumb('Disconnect IO');
    } else {
      //   log('IO already disconnected');
    }
    console.log('disconenctIO', ioConnected)
  };

  const onConnect = async () => {

    if (!ioConnected) {
      // await setIoConnected(true)
      ioConnected = true;
      io.off('init', onInit);
      io.off('hi', onHi);
      io.off('item', onItem);
      io.off('price', onPrice);
      io.off('bid', onBid);
      io.off('won', onWon);
      io.off('winner', onWinner);
      io.off('lost', onLost);
      io.off('message', onMessage);
      io.off('is_biddable', onBiddable);
      io.on('init', onInit);
      io.on('hi', onHi);
      io.on('message', onMessage);
      io.on('bye', onBye);
      io.on('alert', onAlert);
      io.on('won', onWon);
      io.on('sells', onSells);
      io.on('countdown', onCountdown);
      io.on('is_paused', onEndShow);
      io.on('mute_user', onMuteUser);
      io.on('kick_user', onKickUser);
      io.on('ban_user', onBanUser);
      io.on('giveaway_toggle', toggleGiveawayModal);
      io.on('giveaway_popup', onClaimGiveaway);
      io.on('item', onItem);
      io.on('price', onPrice);
      io.on('bid', onBid);
      io.on('winner', onWinner);
      io.on('lost', onLost);
      io.on('is_biddable', onBiddable);

      // Payment Socket events
      io.off('payment', onPayment);
      io.off('item_count_updated', onItemCountUpdated);
      io.off('invalid_bid', onInvalidBid);
      io.off('wallet_updated', onWalletUpdated);
      io.off('sell_cancelled', onSellCancelled);
      io.off('disclaimerAccepted', disclaimerAccepted);
      io.off('shippingUpgraded', onShippingUpgraded);
      io.off('updateNoShipping', onNoShippingUpdate);

      io.on('payment', onPayment);
      io.on('item_count_updated', onItemCountUpdated);
      io.on('invalid_bid', onInvalidBid);
      io.on('wallet_updated', onWalletUpdated);
      io.on('sell_cancelled', onSellCancelled);
      io.on('disclaimerAccepted', disclaimerAccepted);
      io.on('shippingUpgraded', onShippingUpgraded);
      io.on('updateNoShipping', onNoShippingUpdate);
      bindedIO()
      ioConnected = true;
      // bothConnected();
      // bindedIO()
    }
  };

  const onConnectError = (err: any) => {
    console.log("onConnectError", err)
    setTimeout(() => {
      props.setIsConnecting(false)
    }, 4000);
    // log('IO connection error (' + this.io.id + ' ' + err?.message + ')');
    // log('-------------------');
    // this.setState({ io_reconnecting: true });
    // useage of err parameter https://socket.io/docs/v4/server-api/#namespaceusefn
  };

  const onDisconnect = (reason: string) => {
    console.log("onDisconnect", reason)    // log('IO disconnected due to ' + reason + ' (' + this.io.id + ')');
    // this.setState({ io_reconnecting: true });
    // if (reason === 'io server disconnect') {
    //   log('IO disconnected > reconnecting');
    //   this.io.connect();
    // }
  };

  const updateItemAmount = (data: any) => {
    isInstant(data?.item?.instant || false);
    setAmount(data?.item?.instant ? data?.item?.amount || 0 : 1);
  };

  const onInit = async (data: any) => {
    const state: any = {};
    setPrice(data?.price);

    if (data?.online) state.userCount = data.online;
    if (data?.unpaid) state.cartCount = data.unpaid;
    if (data?.is_paused) state.is_ended = data.is_paused;
    if (data?.item) state.item = data.item;
    if (data?.sells) {
      state.sells = data.sells;
      state.sells_total = data.sells.reduce((sum: number, sell: any) => sum + sell.price, 0);
      console.log('IO-Show onInit ' + data.sells.length + 'x for ' + state.sells_total + '€', data.sells);
    }
    if (data?.messages.length > 0) {
      setMessages(data.messages)
      init_messages = data.messages;
    }
    // if (typeof data.item.instant != 'undefined') {
    //   isInstant(data.item.instant ? true : false);
    //   setAmount(data.item.instant ? (data.item.amount || 0) : 1);
    // }
    updateItemAmount(data)

    console.log("data.item", data.item)
    if (data?.item?.id) {
      item_viewed(users.user, users.currentShow, data?.item);
    }
    setItem(data.item.id ? data.item : undefined);
    if (data?.online) await props.setUserCount(data?.online)
    // state.io_initialized = true;
    setLoading(false);
    setShippingWinner(data?.item?.shipping_on_winner || false);
    isBiddable(data?.is_biddable || false);
    isBidding(data?.is_bidding || false);
    users?.user?.id && isWinning(data?.winner?.id === users?.user?.id ? true : false);
    setWinner(data?.winner?.username || undefined);
    setBidDisabled(data?.disable_bid || false);
    setLastPaymentStatus(data?.last_payment_status || '');
    setSavedCardCount(data?.saved_card_count || 0);
    setWalletAmount(data?.wallet_amount || 0);
    setShippingAddressCount(data?.shipping_address_count || 0);
    setHasShippingAddress(data?.shipping_address_count > 0 ? 'yes' : 'no')
    setHasPaymentMethod(data?.wallet_amount > 0 || data?.saved_card_count > 0 ? 'yes' : 'no')
    setUpgradeShippingPaymentStatus(data?.upgradeShippingPaymentStatus)

    if(!!data?.bids?.[0]?.bigParcel) {
      const bigShippingUpgradeData = {
        price: data?.bigBoxUpgradeCost,
        subTitle: `Your order at ${data?.room?.host} show just got bigger and more expensive to ship. Please pay an additional €${data?.bigBoxUpgradeCost} to match the new shipping fees for your order`,
        title:  'An even bigger parcel coming your way!',
      }
      setUpgradedShippingData(bigShippingUpgradeData)
    } else if (!!data?.bids?.[0]?.hugeParcel) {
      const hugeShippingUpgradeData = {
        price: data?.last_payment_status === 'paid' ? Number(data?.hugeBoxUpgradeCost - data?.bigBoxUpgradeCost).toFixed(2) : data?.hugeBoxUpgradeCost,
        subTitle: `Your order at ${data?.room?.host} show just got bigger and more expensive to ship. Please pay an additional €${data?.last_payment_status === 'paid' ? Number(data?.hugeBoxUpgradeCost - data?.bigBoxUpgradeCost).toFixed(2) : data?.hugeBoxUpgradeCost} to match the new shipping fees for your order`,
        title:  'An even bigger parcel coming your way!',
      }
      setUpgradedShippingData(hugeShippingUpgradeData)
    }

    updateInfo(data);
    if (typeof data?.winners === 'object') setWinners(data?.winners);
    isWaiting(false);
    if (data?.item?.type) setType(data?.item?.type);
  };

  const emitInit = async () => {
    const guestId = appConfig.visitor;
    const show: any = liveShow
    const userDetails = {
      "user": {
        "id": users?.user?.id || guestId,
        "email": users?.user?.email,
        "username": users?.user?.username,
        "name": users?.user?.name,
        "phone": users?.user?.phone,
        "pic": users?.user?.pic,
        "access": users?.user?.access || 1
      },
      "invisible": false,
      "show": {"id": show.id, "link": show.link, "user_id": show.user_id, "seller": show.seller}
    }
    // now init is manually triggered
    io.emit('init', userDetails)
    console.log("emitInit", userDetails)
    console.log("showDashboard", showDashboard)
  };


  const onHi = (data: any) => {
    if (data.online) props.setUserCount(data.online);
    if (data.user) {
      props.setLiveUsers(data.users)
      onMessage({
        text: "joined the room",
        user: {id: data.user.id, username: data.user.username, pic: data.user.pic}
      });
    }
  };

  const onMessage = function (message: any) {
    const modifyMessageList = messages;
    if (message.text) {
      if (!message.time) message.time = (new Date()).getTime();
      if (message_ids.indexOf(message.time) < 0) {
        // let added = messages.slice(0);
        // added.push(message);
        // messages.unshift(message);`
        if (init_messages.length) {
          // log('IO-Chat onMessage (has ' + init_messages.length + 'x init_messages)');
          for (let i = 0; i < init_messages.length; i++) {
            modifyMessageList.push(init_messages[i]);
          }
          init_messages = [];
        }
        modifyMessageList.push(message);
        // log('IO-Chat onMessage (' + message.text + ' @' + message.time + ' by ' + message.user?.username + ') > ' + messages.length + 'x');
        for (let i = 0; i < modifyMessageList.length; i++) {
          message_ids.push(modifyMessageList[i].time);
          // log('msg ' + (i + 1) + ' (' + messages[i].time + '): ' + messages[i].text.slice(0, 10));
        }
        setMessages(cloneDeep(modifyMessageList));
        // updateMessageTimestamp((new Date()).getTime());
      } else {
        // error('IO-Chat onMessage (duplicate id)', message);
      }
    } else {
      // error('IO-Chat onMessage (invalid)', message);
    }
    const myDiv: any = document.getElementById("chat-list");
    myDiv.scrollTop = myDiv.scrollHeight;

    console.log('onMessage', message)
  }

  const onBye = (data: any) => {
    if (data.online) props.setUserCount(data.online);
    console.log('onBye', data);
  };


  const onWon = async (data: any) => {
    console.log("onWon", data)
    if (data?.total && typeof data?.price !== 'undefined' && data?.item) {
      isBidding(false);
      isWinning(data?.item?.id === item?.id);
      isWaiting(false);
      if (typeof data.winners === 'object') setWinners(data.winners);
      if (typeof data.item.instant !== 'undefined') {
        isInstant(data.item.instant ? true : false);
        setAmount(data.item.instant ? data.item.amount || 0 : 1);
        if (data.item.type) setType(data.item.type);

        if (data?.item?.instant) {
          setWinner(data.winner?.username || undefined);
          bid_placed(users?.currentShow, data?.item, data?.price, data?.price, users.user);
        }
      }
      isBiddable(data?.is_biddable || false);
      updateInfo(data);
      setWalletAmount(data?.wallet_amount);
      purchase(users?.currentShow, data?.item, price, data?.bid?.id, data?.price, users?.user);
      if (data?.wallet_amount === users.user?.walletAmount) {
        payment_event(
          strings.paymentSuccessEvent,
          strings.showScreen,
          strings.show,
          users.currentShow?.currency,
          shipping_amount,
          data?.item?.amount + data?.price,
          'card',
          users.user
        );
      }

      onMessage({
        text: `Sold to ${data.user.username} for ${data.price}€`,
        bid: true,
        user: {id: data.user.id, username: data.user.username, pic: data.user.pic}
      });
      // await dispatch(fetchUserDetails())
      displayInfoToast('Your payment was successful')

      if (users.user?.firstBid || users.user?.firstPurchase || users.user?.firstPayment) {
        dispatch(updateUser({
          firstBid: false,
          firstPurchase: false,
          firstPayment: false
        }))
        users.user.firstBid = false;
        users.user.firstPurchase = false;
        users.user.firstPayment = false;
      }
    }
    // log('IO-Show onWon', data);
    // if (data.items) this.setState({ cartCount: data.items.length });
  };

  const onSells = (data: any) => {
    console.log("onSells", data)
    setItem({...data?.sell, ...data?.sell?.item})
    setPrice(data?.sell?.price);
    // if(this.isHost() && data.sells){
    //   log('IO-Show onSells');
    //   const sells_total = data.sells.reduce((sum:number,sell:any) => sum + sell.price, 0);
    //   this.setState({sells: data.sells, sells_total: sells_total});
    // };
  }

  const onItem = function (data: any) {
    if (data.item && data.price) {
      item_viewed(users.user, users.currentShow, data?.item);
      setAmount(data.item.instant ? (data.item.amount || 0) : 1);
      setPrice(data.price);
      console.log("data 123", data)
      setItem(data.item.id ? data.item : undefined);
      setPrice(data.price);
      if (typeof data.item.instant !== 'undefined') {
        isInstant(data.item.instant ? true : false);
        setAmount(data.item.instant ? data.item.amount || 0 : 1);
      }
      if (data.item.type) setType(data.item.type);
      setShippingWinner(data.item.shipping_on_winner || false);
      isBiddable(data?.is_biddable || false);
      isBidding(data.is_bidding || false);
      users?.user?.id &&  isWinning(data?.winner?.user?.id === users.user?.id ? true : false);
      setWinner(data.winner?.username || undefined);
      if (typeof data.winners === 'object') setWinners(data.winners);
      isWaiting(false);

      onMessage({
        text: "Item '" + data.item.title + "'" + (data.item.instant ? " for " : " starts at ") + data.price + "€",
        user: {username: "GAVEL", pic: false}
      });
    }
    console.log('onItem', data)
  }

  const onPrice = function (data: any) {
    // if (data.price) {
      setPrice(data?.price);
      // if (typeof data.item.instant !== 'undefined') {
      //   isInstant(data.item?.instant || false);
      //   setAmount(data.item?.instant ? data.item?.amount || 0 : 1);
      // }
      updateItemAmount(data)
      setType(data.item?.type);
      onMessage({
        text: "Item now at " + data.price + "€",
        user: {username: "GAVEL", pic: false}
      });
    // }
    console.log('onPrice', data)
  }
  const onBid = function (data: any) {
    if (typeof data?.price!== 'undefined') {
      // log('IO-Chat onBid (user id ' + data.user.id + '=' + user.id + ')');
      if (data.user.id === users.user.id) {
        isBidding(true);
        isWinning(true);
        users?.currentShow && bid_placed(users?.currentShow, data?.item, data?.item?.amount + data?.price, data?.price, users.user);
        if (users.user?.firstBid) {
          users.user.firstBid = false;
          dispatch(updateUser({
            firstBid: false
          }));
        }
        onMessage({
          text: `You bid ${data.price} €. Others have 10s to overbid you.`,
          user: {username: "GAVEL", pic: false},
          bid: true
        });
      } else {
        isBidding(true);
        isWinning(false);
        onMessage({
          text: `${data.user.username} bid ${data.price} € 🤩`,
          user: {username: "GAVEL", pic: false},
          bid: true
        });
      }
      setWinner(data.user?.username);
      setPrice(data?.price);
    }
    console.log('onBid', data)
  }
  const onBiddable = function (data: any) {
    if (data.item && data.is_biddable) {
      isBiddable(data?.is_biddable || false);
      isBidding(data?.is_bidding);
      if (typeof data.item.instant !== 'undefined') {
        isInstant(data?.item?.instant ? true : false);
        setAmount(data?.item?.instant ? data?.item?.amount || 0 : 1);
      }
      onMessage({
        text: "Let's gavel 🙌",
        user: {username: "GAVEL", pic: false}
      });
    }
    console.log('onBiddable', data)
  };

  const onWinner = function (data: any) {
    if (data.winner) {
      setWinner(data.winner?.username || undefined);
      if (typeof data.winners === 'object') setWinners(data.winners);
      setPrice(-1)
      onMessage({
        text: "Congratulations " + data.winner.username + "! 🎉",
        user: {username: "GAVEL", pic: false}
      });
    } else console.log('IO-Chat onWinner (without winner)', data);
    console.log('onWinner', data)
  }
  const onLost = function (data: any) {
    if (data.winner && typeof data.price !== 'undefined' && data.item) {
      isBidding(false);
      isWinning(false);
      !data.item.instant && isWaiting(false);
      setWinner(data.winner?.username || undefined);
      if (typeof data.winners === 'object') setWinners(data.winners);
      if (typeof data.item.instant !== 'undefined') {
        isInstant(data.item.instant ? true : false);
        setAmount(data.item.instant ? data.item.amount || 0 : 1);
      }
      if (data.item.type) setType(data.item.type);
      isBiddable(data?.is_biddable || false);
      if (data.user.id !== users.user.id) { // user, not winner, because winner is defined afterwards (in case of fixed price)
        onMessage({
          text: "Sold to " + data.user.username + " for " + data.price + "€" + " 🎉",
          user: {username: "GAVEL", pic: false}
        });

      } else console.log('IO-Chat onWon (you won)');
    }
    console.log('onLost', data)
  }

  const onCountdown = (data: any) => {
    isBidding(true);
    if (data.seconds >= 0) {
      props.setCountDown({count: data.seconds, isCount: true})
    } else {
      props.setCountDown({count: data.seconds, isCount: false})
    }
    console.log('onCountdown', data)
  };

  const onEndShow = (data: any) => {
    if (data.is_paused) {
      props.setIsShowEnded(true)
    }
    console.log('onEndShow', data)
  };

  const onAlert = (data: any) => {
    isWaiting(false);
  };

  const onError = (data: any) => {
    setTimeout(() => {
      props.setIsConnecting(false)
    }, 4000);
  }

  const toggleGiveawayModal = (data: any) => { // io trigger (for all users)
    props?.setIsShowGiveawayModal(data.visible)
    give_away_started(users.user, props?.show, 'Shows', 'Show Screen');
  }

  const onClaimGiveaway = (data: any) => {
    // log('Show onClaimGiveaway for '+data.user_id);
    // if(data.user_id == this.user.id){
    //   if(this.isHost()) log('Show onClaimGiveaway not for hosts'); // this.emit('giveaway_claimed', { claimed: false });
    //   else this.setState({ claimGiveawayTexts: data, claimGiveawayModal: true });
    // }
  }

  const onMuteUser = (data: any) => {
    // log('Show onMuteUser ('+data.user_id+' ?= '+this.user.id+')');
    // if(data.user_id == this.user.id){
    //   if(data.muted) Alert.alert('You\'re muted', "The host has muted you, because you do not contribute to the show in a positive way.");
    //   else Alert.alert('You\'re unmuted again', "The host has un-muted you. Please contribute to the show in a positive way and have fun!");
    // }
    // if(data.users) {
    //   this.setState({ users: data.users });
    //   log('Show onMuteUser users', data.users);
    // }
  }

  const onKickUser = (data: any) => {
    // log('Show onKickUser ('+data.user_id+' ?= '+this.user.id+')');
    // if(data.user_id == this.user.id){
    //   if(data.kicked){
    //     //alert user then redirect them out of the show after 2seconds
    //     this.props.navigation?.navigate('Shows')
    //     Alert.alert('You\'re kicked', "The host has kicked you from the show, but you can enter upcoming shows from him/her.")
    //   }
    // }
    // if(data.users) this.setState({ users: data.users });
  }

  const onBanUser = (data: any) => {
    // log('Show onBanUser ('+data.user_id+' ?= '+this.user.id+')');
    // if(data.user_id == this.user.id){
    //   if(data.banned){
    //     //alert user then redirect them out of the show after 2seconds
    //     this.props.navigation?.navigate('Shows');
    //     Alert.alert('You\'re banned', "The host has banned you from this and future shows of him/her. Please contact our support in case you want to talk about this.")
    //   }
    // }
    // if(data.users) this.setState({ users: data.users });
  }

  const onCloseDisclaimerModal = () => {
    setGameShowVisible(false)
    props?.navigate('/shows')
  }

  const emitAcceptDisclaimer = async () => {
    if (!users.user?.id) {
      dispatch(openLoginSignUpModal(true))
    }
    else {
      emit('acceptDisclaimer', {});
    }
  };

  const disclaimerAccepted = (data: any) => {
    setGameShowVisible(!data.isGameDisclaimerAccepted)
 };
  const updateInfo = (data: any) => {
    data?.wallet_amount && setWalletAmount(data?.wallet_amount);
    data?.disable_bid && setBidDisabled(data?.disable_bid);
    data?.saved_card_count && setSavedCardCount(data?.saved_card_count);
    data?.cardBrand && setCardBrand(data?.cardBrand);
    data?.isToppedUpBefore && setIsToppedUpBefore(data?.isToppedUpBefore);
    data?.last_payment_status && setLastPaymentStatus(data?.last_payment_status);
    data?.shipping_address_count && setShippingAddressCount(data?.shipping_address_count)
    data?.shipping_address_count && setHasShippingAddress(data?.shipping_address_count > 0 ? 'yes' : 'no');
    data?.shipping_amount && setShippingAmount(data?.shipping_amount);
    data?.shipping_fee_description && setShippingFeeDescription(data?.shipping_fee_description)
    data?.shipping_fee && setShippingFee(data?.shipping_fee)
    data?.upgradeShippingPaymentStatus === 'unpaid' && !!data?.bids[0] && setshippingCostModalVisible(true)
    data?.upgradeShippingPaymentStatus && setUpgradeShippingPaymentStatus(data?.upgradeShippingPaymentStatus)
  };

  const onPayment = async (data: any) => {
    const stripePromise = loadStripe(users.user.publishableKey);
    const stripe: Stripe | null = await stripePromise;
    if (data && data?.clientSecret && data?.paymentMethod && stripe) {
      const paymentStatus = await stripe?.confirmCardPayment(data?.clientSecret, {
        payment_method: data?.paymentMethod,
      });
      if(paymentStatus.error){
        payment_fail( users?.user, users.currentShow?.currency, shipping_amount, price, `${users.currentShow?.id}_${item?.id}`, 'Card', 'Failed', 'Failed', 'Failed');
        isWaiting(false);
      }
    }
    if (data?.status === 'failed' || !is_instant) {
      updateInfo(data);
      isWaiting(false);
      isWinning(false);
      payment_fail( users?.user, users.currentShow?.currency, shipping_amount, price, `${users.currentShow?.id}_${item?.id}`, 'Card', 'Failed', 'Failed', 'Failed');
    } else if (data?.status === 'unpaid' || !is_instant) {
      setLastPaymentStatus(data?.last_payment_status);
      isWaiting(false);
      isWinning(false);
      payment_fail( users?.user, users.currentShow?.currency, shipping_amount, price, `${users.currentShow?.id}_${item?.id}`, 'Card', 'Failed', 'Failed', 'Failed');
    }
    if (data?.status === 'failed') {
      displayErrorToast(`Oh no! We couldn't complete your payment. Try once again!`)
    }
  };

  const onItemCountUpdated = async (data: any) => {
    if (data?.item?.instant && data?.item?.amount) {
      isInstant(data?.item?.instant ?? false);
      setAmount(data?.item?.instant ? data?.item?.amount || 0 : 1);
    }
    isBiddable(data.item?.is_biddable || false);
    if (typeof data?.item?.winners === 'object') setWinners(data?.item?.winners);
  };

  const onInvalidBid = async (data: any) => {
    updateInfo(data);
    isWaiting(false);
    data?.disable_bid && antdMessage.info('Disable Bid')
    // TODO:
    // if (data?.isLimitBlocked) {
    //   setCreditLimitPopUp(true);
    //   data?.title && setCreditLimitTitle(data?.title);
    //   data?.text && setCreditLimitText(data?.text);
    // } else if (data?.blocked) {
    //   setBlockedUserPopUp(true);
    //   data?.title && setBlockedUserTitle(data?.title);
    //   data?.text && setBlockedUserText(data?.text);
    // }
  };

  const onWalletUpdated = async (data: any) => {
    updateInfo(data);
  };

  const onSellCancelled = async (data: any) => {
    data?.wallet_amount && setWalletAmount(data?.wallet_amount);
    setLastPaymentStatus('paid');
    displayErrorToast(data.text);
  };

  const emitMessage = function () {

    if (message.length >= 1) {
      // log('IO-Chatbox emitMessage (' + message + ')');
      // io.emit('message', { 'text': message, 'user': user, 'time': new Date() });
      // setMessage('');
      // analytics.track('Show Chat', { text: message, product_id: item.id, brand: show.user_id, show: show.id });
    } else {
      // log('IO-Chatbox emitMessage (invalid)', message);
    }
  }
  const onHotKeys = (message: string) => {
    if (!users.isGuestUser && !users.user.username) {
      dispatch(openLoginSignUpModal(true))
    } else {
      if (users.isGuestUser) {
        dispatch(openLoginSignUpModal(true))
      } else {
        emit('message', {'text': message, 'user': users.user, 'time': new Date()});
        chat_message_sent(props?.showDetails, users.user);
      }
    }
    console.log('onHotKeys', message)
  }

  const emitBid = function (bid: number) {

    if (typeof bid !== 'undefined') {
      if (!is_waiting) {
        isWaiting(true);

        emit('bid', {price: price + bid, item: item, user: users.user, time: new Date()});
        !is_instant && setTimeout(() => isWaiting(false), 800);
      }
      else {
        console.error('IO-Itembox emitBid (double-bid)');
        // Alert.alert('1 Buy 1 Second', 'You can only buy every second, so everybody has a chance. Please buy consciously!');
      }
    }
    else console.error('IO-Itembox emitBid (invalid)');
  }



  const onBidClick = (bid: any) => {

    // props.openDownloadModal()
    // return
    if (!users.isGuestUser && !users.user.username) {
      dispatch(openLoginSignUpModal(true))
    } else {
      if (users.isGuestUser) {
        dispatch(openLoginSignUpModal(true))
      } else {
        const actual_price = price + (is_instant ? 0 : is_bidding ? 1 : 0);

        let final_amount = actual_price;

        if (upgradeShippingPaymentStatus === 'unpaid') {
          displayInfoToast('Please pay your upgraded shipping costs to proceed.');
        }
        else if (
          (saved_card_count ?? 0) > 0 &&
          (last_payment_status === 'failed' || last_payment_status === 'unpaid')
        ) {
          displayInfoToast('Please add a new card to proceed or retry payment for old unpaid transaction');
        } else if (
          wallet_amount === 0 &&
          (saved_card_count ?? 0) === 0 &&
          !isToppedUpBefore
        ) {
          // RED: Setup wallet
          displayInfoToast('Please setup your wallet to proceed');
        } else if (
          wallet_amount === 0 &&
          (saved_card_count ?? 0) === 0 &&
          isToppedUpBefore
        ) {
          // RED: €0
          displayInfoToast('Please add a wallet amount or card to proceed');
        } else if (shippingAddressCount === 0) {
          // RED: Setup wallet
          displayInfoToast('Please add a shipping address to proceed');
        } else if (
          wallet_amount &&
          wallet_amount !== 0 &&
          wallet_amount < final_amount &&
          (saved_card_count ?? 0) === 0
        ) {
          // YELLOW: €5
          displayInfoToast('Wallet amount is less than the final amount');
        } else {
          is_winning && !is_instant
            ? displayInfoToast('You are currently the winner with the highest bid.')
            : emitBid(is_instant ? 0 : is_bidding ? 1 : 0);
        }
      }
    }
  }
  const onEmojiClick = (event: any, emojiObject: any) => {

    setSendable(`${sendable}${emojiObject.emoji}`)
  }
  const onSend = (event: any) => {
    console.log("analytics", analytics)
    if (!users.isGuestUser && !users.user.username) {
      dispatch(openLoginSignUpModal(true))
    } else {
      if (users.isGuestUser) {
        dispatch(openLoginSignUpModal(true))
      } else {
        if (sendable) {
          emit('message', {'text': sendable, 'user': users.user, 'time': new Date()});
          setSendable("")
          chat_message_sent(props?.showDetails, users.user);
        }
      }
    }
    console.log('onSend', event)
  }

  const onUserClick = (userId: any, username: string) => {
    if (userId === users?.user?.id) {
      openInNewTab('/my-profile')
    }
    else {
      openInNewTab(`/${username}`)
    }
    // props.handleCancel()
  };

  const onShippingUpgraded = (data: any) => {
    console.log('onShippingUpgraded ', data)
    setUpgradedShippingData(data)
    setshippingCostModalVisible(true)

    if (!!data?.price) {
      setUpgradeShippingPaymentStatus('unpaid')
    } else {
      setUpgradeShippingPaymentStatus('paid')
    }
  }

  // Waiting for user events for noShipping socket event to trigger toast
  const onNoShippingUpdate = (data: any) => {
    console.log('onNoShippingUpdate data', data)
    displayInfoToast('The host cancelled your shipping fees for this show. You will be refunded all shipping fees.')
  }

  const anotherObserver = useMemo(
    () => new IntersectionObserver(([entry]) => setLastVisible(entry.isIntersecting)),
    [setLastVisible],
  );

  const lastCardElementRef = useCallback(
    (node) => {
      if (node) {
        observer.current = node
        anotherObserver.observe(node)
      }
      else {
        anotherObserver.disconnect()
        setLastVisible(false)
      }
    },
    []
  );

  const scrollToLastMessage = () => {
    if (observer && observer.current) {
      observer.current.scrollIntoView({behaviour: 'smooth', block: 'nearest'})
    }
  };

  const handleShippingSizeCancel = () => {
    setshippingCostModalVisible(false)
  }

  const shippingUpgradePayment = () => {
    navigate('/account-settings/payment-method')
  }

  return (
    <>
      <div className="video-newsfeed">
        <div className="leftBottom">
          <div className="chatbox">
            {console.log("item", item)}
            <div className="chatListing" id="chat-list">
              {messages.map((e: any, index: number) => {
                return <div className="list-item" key={index} ref={messages?.length - 1 === index ? lastCardElementRef : null}>
                  <div className="userProfile" onClick={() => {
                    onUserClick(e.user?.id, e.user?.username)
                  }}>
                    <img src={e.user?.pic && e.user?.pic?.startsWith('https://') ? e.user?.pic : e.user?.picture && e.user?.picture !== ''
                      ? e.user?.picture : 'https://beta.letsgavel.com/Favicon.svg'} alt="img" />
                  </div>
                  <div className="usercgat">
                    <p>{e.user.username} {e?.user?.admin && <ModBadge />} {e?.user?.host && <ProfileBadges user={e?.user} />} </p>
                    {/* @ts-ignore */}
                    {e?.bid ? <div className="Userbid">
                      {e.text}
                    </div> :
                      <span>
                        {/* <span className="TagText">@jay</span> */}

                        {e.text}
                      </span>}
                  </div>
                </div>
              })}
            </div>
            <div className="chatinput">
              <div className="Recent_message">
                {(isChatScrollable && !isLastVisible) && (<Button type="ghost" className="Recent_message_btn" onClick={scrollToLastMessage}>
                  <span>
                    <Arrowdown />
                  </span>
                  Recent message
                </Button>)}
              </div>
              <div className="chatInput-box">
                {/* <input type="text" placeholder="Start a message" onChange={onChangeMessage} className="textinput" /> */}
                <InputEmoji
                  value={sendable}
                  onChange={setSendable}
                  cleanOnEnter
                  onEnter={onSend}
                  placeholder="Type a message"
                />
                <button className="sendbtn" onClick={onSend}><ChatSend/></button>
              </div>
              <div className="suggesion-tags">
                <div className="tags-item" onClick={() => {onHotKeys('👋 HI')}}>👋 HI</div>
                <div className="tags-item" onClick={() => {onHotKeys('🎉 GG')}}>🎉 GG</div>
                <div className="tags-item" onClick={() => {onHotKeys('🙏 Thx')}}>🙏 Thx</div>
                <div className="tags-item" onClick={() => {onHotKeys('✂️ Rip')}}>✂️ Rip</div>
                <div className="tags-item" onClick={() => {onHotKeys('🎖 F')}}>🎖 F</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ShowScreen onMessage= {onMessage} emitBid={emitBid}/> */}
      {gameShowVisible && <GameShowDisclaimer visible={gameShowVisible} emitAcceptDisclaimer={emitAcceptDisclaimer}
        disclaimerText={users.currentShow?.disclaimerText ?? ''} handleOk={onCloseDisclaimerModal} handleCancel={onCloseDisclaimerModal}
        showModal={() => { }} user={users?.user} />}
      <ShippingSizeCostModal
        modalOpen={shippingCostModalVisible}
        onCancel={handleShippingSizeCancel}
        shippingUpgradedData={shippingUpgradeData}
        shippingUpgradePayment={shippingUpgradePayment}
      />
    </>
  );
})

export default Chat;

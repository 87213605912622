import React, { useCallback, useEffect, useState } from 'react'
import {Modal, Form, Button, message} from 'antd'
import PhoneInput from 'react-phone-input-2'

import { Close } from '../../../Icons'
import Loader from '../../../CommonComponents/Loader'
import { auth } from '../../../../Utils/firebase';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../../store"
import UserName from "../UserName"
import { checkUserExistance, signUpuser, signInUser, setUpUserNameBirthdate, openLoginSignUpModal } from "../../../../store/Actions/users"
import OTPInput from '../OTPInput';
import {displayErrorToast, displayInfoToast} from '../../../../Utils/helper'


const PhoneModal = (props: any) => {
    const [form] = Form.useForm();
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState<any>('');
    const [phone, setPhone] = useState("")
    const [isUserName, setIsUserName] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [otp, setOtp] = useState('')
    const [timer, setTimer] = useState(0);
    const [isResendEnable, setResendEnable] = useState(false)
    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
    const [isClosable, setIsClosable] = useState(true)

    const dispatch = useDispatch();
    const users = useSelector(
        (state: RootState) => state?.usersReducer
    );

    const [isPhoneValid, setPhoneValid] = useState(false)

    const reset = () => {
        setshow(false)
        setfinal(null)
        setPhone("")
        setPhoneValid(false)
        setIsUserName(false)
        // setIsUserNameExist({})
        setLoader(false)
        props.handleOk()
    }
    const onFinish = (values: any) => {
        setPhone("+"+values.phone)
        console.log('Success:', values.phone);
        if (values.phone === "" || values.phone.length < 10) return;
        const verify = new RecaptchaVerifier("recaptcha-container", {'size': 'invisible'}, auth);

        signInWithPhoneNumber(auth, "+"+values.phone, verify).then((result: any) => {
            setfinal(result);
            displayInfoToast("OTP sent")
            resetTimer()
            verify.clear()
            setshow(true);
        }).catch((err) => {
            alert(err);
            window.location.reload()
        });
    };

    const resetTimer = function () {
        if (!timer) {
            setTimer(30);
        }
    };


    const onResendOTP = () => {
        if (phone === "" || phone.length < 10) return;

        setResendEnable(true)
        const containerRef = document.getElementById('wrapper-container')!
        const ele: any = document.createElement('div',{})!
        ele.id = 'recaptcha-container-resend'
        containerRef.appendChild(ele)

        const verify: any = new RecaptchaVerifier("recaptcha-container-resend", {
            'size': 'invisible'
        }, auth);

        signInWithPhoneNumber(auth, phone, verify).then((result: any) => {
            setfinal(result);
            displayInfoToast("OTP sent")

            setshow(true);

            resetTimer()

            const captchaRef = document.getElementById('recaptcha-container-resend')!
            containerRef.removeChild(captchaRef)
        }).catch((err) => {
            alert(err);
            setResendEnable(true)
            window.location.reload()
        });
    };

    useEffect(() => {
        if (props?.isFromGmail && !users.userExistance?.phone) {
            setIsClosable(false)
        }
        else if (props?.isFromGmail && users.userExistance?.phone) {
            setIsClosable(true)
        }

        if ((isUserName && !props.isFromGmail) && !users.userExistance?.username) {
            setIsClosable(false)
        }
        else if ((isUserName && !props.isFromGmail) && users.userExistance?.username) {
            setIsClosable(true)
        }
    }, [props?.isFromGmail, users.userExistance, isUserName]);

    useEffect(() => {
        if (Object.keys(users.userExistance).length > 0 && phone.length) {
            if (!users?.userExistance?.isUserExist) {
                if (props?.isFromGmail) {
                    setLoader(true)
                    dispatch(setUpUserNameBirthdate({
                        phone
                    }))
                    setLoader(false)
                } else {
                    dispatch(signUpuser({
                        "phone": phone,
                        "app": "collectibles",
                        "source": "app"
                    }))
                    setIsUserName(true)
                }
            } else if (users?.userExistance?.isUserExist && props?.isFromGmail) {
                displayErrorToast('User already exists with this phone number!')
                form.setFieldsValue({phone: '+49'})
                setIsClosable(false)
                reset()
            }
            else if (users.userExistance?.phone && (!users?.userExistance?.username)) {
                dispatch(signInUser({phone}))
                setIsUserName(true)
            } else if (users.userExistance?.phone && (users?.userExistance?.username)) {
                dispatch(signInUser({phone}))
                setIsUserName(true)
                reset()
            }
        }
    }, [users.userExistance, props?.isFromGmail]);
    useEffect(() => {
        if (users.user?.user?.id) {
            if (!users.user?.user?.username) {
                setIsUserName(true)
            }
        }
    }, [users.user])

    useEffect(() => {
        if (timer <= 0) {
            setResendEnable(false)
        }
        else {
            setResendEnable(true)
            setTimeout(timeOutCallback, 1000)
        }
    }, [timer, timeOutCallback])

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    // Validate OTP
    const ValidateOtp = (values: any) => {
        if (otp.length <6 || final === null){
           displayErrorToast('Please enter valid otp')
            return;
        }

        setLoader(true)
        final.confirm(otp).then((result: any) => {
            // success
            displayInfoToast("You are successfully logged in")
            dispatch(checkUserExistance({ phone }))
            setLoader(false)
        }).catch((err: any) => {
            if (err?.code === 'auth/invalid-verification-code') {
                displayErrorToast('INVALID_CODE')

            } else if (err?.code === 'auth/missing-code') {
                displayErrorToast('MISSING_CODE')

            }
            setLoader(false)
        })
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onValidatorFail = () => {

    }

    const onChange = (value: any) => {
        console.log(`selected ${value}`);
    }

    const onSearch = (val: any) => {
        console.log('search:', val);
    }

    const onHandleCancel = () => {
        if (props?.isFromGmail && !users.userExistance?.phone) return

        if ((isUserName && !props.isFromGmail) && !users.userExistance?.username) return

        props.handleCancel()
    }

    const onBacktoLoginClick = () => {
        props?.logout()
        dispatch((openLoginSignUpModal(true)))
    }

    const getModalTitle = () => {
        if (props.isFromGmail) {
            return 'One-time Verification'
        }
        else {
            if (isUserName) {
                return 'Almost Done'
            }
            else {
                return 'Access with Phone'
            }
        }
    }

    return (
        <Modal title={getModalTitle()} className={`themeModal phonmodal ${isClosable ? '' : 'disable-modal-close'}`} width={496} visible={props.continueWithPhone} onOk={props.handleOk} onCancel={onHandleCancel} footer={false} centered closeIcon={<Close />}>
            {loader && <Loader />}
            <div className='modal-body'>
                {!show ? <>
                    <p> {props.isFromGmail ? 'Only once we require you to verify your phone number' : 'Please enter your phone number.'}</p>
                    <Form
                        name="register"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        layout='vertical'
                    >
                        <Form.Item label="Phone Number" name="phone" rules={[{
                            required: true,
                            message: 'Please input your phone!'
                        }]}>
                            <PhoneInput inputClass="ant-input phoneInput" country={'de'} enableSearch
                                onChange={(value, country: any, e, formattedValue) => {
                                const {format, dialCode} = country
                                if (format?.length === formattedValue?.length && (value.startsWith(dialCode) || dialCode.startsWith(value))) {
                                    setPhoneValid(true)
                                }
                                else {
                                    if (formattedValue.length >= 15 && (value.startsWith(dialCode) || dialCode.startsWith(value))) {
                                        setPhoneValid(true)
                                    }
                                    else {
                                        setPhoneValid(false)
                                    }
                                }
                            }} />
                        </Form.Item>
                        <div id="recaptcha-container"></div>
                        <div className='modalbtns'>
                            <Button type="primary" className='modalbtn filledbtn' disabled={!isPhoneValid} htmlType="submit">Continue</Button>
                        </div>
                        {props.isFromGmail && <div className='resendlink'>
                            Back to <Button onClick={onBacktoLoginClick}>Login? </Button>
                        </div>}
                    </Form>
                </> :
                    !isUserName && <>
                        <p>Check your SMS and enter the code here:</p>
                        <div className='popupform otpform'>
                            <Form form={form} name="register" onFinish={ValidateOtp}
                                onFinishFailed={onValidatorFail} >
                                <Form.Item name="otp" rules={[{ required: true, message: 'Please enter OTP!' }]}>
                                <OTPInput length={6}
                                        onChangeOTP={(otp) => setOtp(otp)}
                                        className="otpContainer"
                                        autoFocus
                                        inputClassName="otpInput"
                                    />
                                </Form.Item>

                                <div className='modalbtns'>
                                    <button type='submit' className='modalbtn filledbtn'>Verify Phone </button>
                                </div>
                                <div id='wrapper-container'>
                                </div>
                                <div className='resendlink'>
                                    Didn't receive a SMS? <Button onClick={onResendOTP} disabled={isResendEnable}>Resend {timer <= 0 ? `` : ` in ${timer}`} </Button>
                                </div>
                            </Form>

                        </div>
                    </>}
                {isUserName && !props.isFromGmail ? <>
                    <p>Choose your username and get started</p>
                    <div className='popupform usernameform'>
                        <UserName isUserName={users?.userExistance?.username} reset={reset} setLoader={setLoader} loader={loader}/>
                    </div>
                </> : null}
            </div>
        </Modal>
    )
}

export default PhoneModal;
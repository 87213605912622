import React from 'react'
import {ProfileBadge, Ribbons} from '../../Icons'

type Props = {
    user: any
}

const ProfileBadges = (props: Props) => {
  return (
    <>
      {props.user?.badgeTopSeller && <ProfileBadge />}
      {props.user?.badgeFrequentHost && <Ribbons />}
    </>
  )
}

export default ProfileBadges
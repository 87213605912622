import {Button, message} from 'antd';
import {useEffect, useState} from 'react';
// import { images } from '../../Imageconfig/images'
// import { Share, Eye, Live, Nbell, Time } from '../Icons'
import {Carousel} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RootState} from '../../store';
import {notifyMe, resetAllShows} from '../../store/Actions/showDashboard';
// import {sliderSettings} from "../../Utils/constants";
import Card from '../CommonComponents/Card';
import CardEmpty from '../CommonComponents/CardEmpty';
import {Edit} from '../Icons';
import TopicsModal from '../Profile/Components/TopicsModal';
import './ShowLayout.scss';
import {getFailedItemsList, openLoginSignUpModal} from '../../store/Actions/users';
import UnsuccessfulPaymentModal from '../AccountSettings/Components/UnsuccessfulPaymentModal';
import {displayErrorToast, getChannelURL, sliceArr} from '../../Utils/helper';
import appConfig from '../../Utils/config';

const PICK_INTEREST = 'Choose more interests to get better suggestions';
const NOTIFY_ME = 'Tap here to be notified when the next show starts';
const ADD_INTERESTS = 'Add interests';
const SELECT_INTERESTS = 'Select interests to personalise your experience';
const NO_LIVE_SHOWS = 'Sorry, there are no live shows at the moment';
const APPLY_HOST = 'Apply as a Host and start selling yourself';
const No_MATCHING_PREFERENCES = 'Sorry, there are no shows matching your preferences';

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  }
}

function SampleNextArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <div className={className} >
      <div className='nextarrow' onClick={onClick}></div>
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <div className={className}  >
      <div className='prevarrow' onClick={onClick}></div>
    </div>
  );
}
const slideToShow = 4;
const slideToScroll = 3;
const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  draggable: true,
  slidesToShow: slideToShow,
  slidesToScroll: slideToScroll,
  // swipeToSlide: true,
  arrows: true,
  adaptiveHeight: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
      }
    }
  ]
}


function ShowsScreen(props: any) {


  useEffect(() => {
    const slideshow = document.querySelector('.ant-carousel ')
    const slides = document.querySelectorAll('.lastcard  .slick-slide');
    // console.log(slides.length);
    // If we have some slides
    if (slides.length) {
      // Add the 'highlight' class to the last slide in the list
      slides[slides.length - 1].classList.add('highlight');
    }
  }, []);



  const navigate = useNavigate();
  const [slide, setSlider] = useState(0)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [cardCount, setCardCount] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false)
  const [chosenLanguage, setChosenLanguage] = useState({});

  const [topicsModalVisible, setTopicsModalVisible] = useState(false)
  const [retryModalVisible, setRetryModalVisible] = useState(false)
  const [shippingUpgradeData, setUpgradedShippingData] = useState<object>({})
  const [failedItemsData, setFailedItemsData] = useState<object>({})

  const dispatch = useDispatch()
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const notifyMeSuccess = useSelector((state: RootState) => state.showDashboard.notifyMeSuccess);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (windowDimensions.width > 800)
      setCardCount(3)
    else setCardCount(2)

  }, [windowDimensions])

  async function getUnpaidItems() {
    try {
      await dispatch(getFailedItemsList(users.user?.id, users.user?.airtable_id))
    } catch (error: any) {
      if (error.response?.data?.message)
        displayErrorToast(error.response?.data?.message)
    }
  }

  useEffect(() => {
    if(users.user?.id && users.user?.airtable_id) getUnpaidItems()
  }, [users.user?.id])

  useEffect(() => {
    if (users.failedItemsList?.failedItems?.length) {
      setRetryModalVisible(true)
      setFailedItemsData(users.failedItemsList?.failedItems)
    }
    else if (!!users?.failedItemsList?.shippingUpgradeData?.price) {
      setUpgradedShippingData(users?.failedItemsList?.shippingUpgradeData)
      setRetryModalVisible(true)
    }
  }, [users.failedItemsList?.failedItems, users?.failedItemsList?.shippingUpgradeData])

  useEffect(() => {
    dispatch(resetAllShows('ready'));
  }, [dispatch, notifyMeSuccess])


  const handleOk = () => {
    setTopicsModalVisible(false)
    props.fetchShows()
  }
  const handleCancel = () => {
    setTopicsModalVisible(false)
  }
  const showTopicsModal = () => {
    setTopicsModalVisible(true);
  }

  const closeRetryPaymentPopup = () => {
    setRetryModalVisible(false)
  }

  const handleNotifyMe = async () => {
    try {
      await dispatch(notifyMe(chosenLanguage || {}));
      showNotifyMeSuccessToast();
    }
    catch (err) {
      console.log(err);
    }

  }

  const showNotifyMeSuccessToast = () => {
    toast(' We can notify you when the first live show starts today', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleInterests = () => {
    if (users.user?.id) {
      showTopicsModal()
    }
    else {
      dispatch(openLoginSignUpModal(true))
    }
  }

  const onRetryPayment = () => {
    navigate('/account-settings/payment-method')
  }

  const onSupportChat = () => {
    if (users?.user?.id) {

      const supportObj = {
        id: appConfig.support,
        name: 'Support',
        pic: getChannelURL('support')
      }

      const subscribers = [supportObj.id, users.user?.id]

      const profile_pics = {
        [users.user?.id]: users.user?.pic,
        [supportObj.id]: supportObj.pic
      }

      const usernames = {
        [users.user?.id]: String(users.user?.username),
        [supportObj.id]: 'Support'
      }

      const receiverId = sliceArr(subscribers, users.user?.id.toString())[0];
      const displayName = usernames[receiverId];

      const channel = {
        id: `${subscribers[0]}:${subscribers[1]}`,
        time: new Date(),
        title: 'Support',
        type: 'private',
        subscribers,
        profile_pics,
        usernames,
        username: displayName,
        message: {
          time: new Date()
        },
      }
      navigate('/chat', {state: {channel}});
    }
    else {
      dispatch(openLoginSignUpModal(true))
    }
  };

  return (
    <>

      <div className='feature-section'>

        {/* <div className="BreadCrumbs">
        <div className='container'>
          <div className='pagetopheader'>
            <h3>Shows</h3>
            <div className='btnRight'> */}
        {/* <Button type='primary'>CREATE SHOW</Button> */}
        {/* </div>
          </div>
        </div>
      </div> */}
        {/* <div className="BreadCrumbs">
        <div className='container'>
          <div className='pagetopheader'>
            <h3>Shows</h3>
            <div className='btnRight'>
              <Button type='primary'>CREATE SHOW</Button>
             </div>
          </div>
        </div>
      </div> */}
        <div className='languageToggle'>
          <div className='container'>
            <div className='langInner'>
              <Button type='primary' onClick={() => {
                setChosenLanguage({});
                props.onLanguageChange();
              }} className={`spanLangtoggle ${!props?.activeLanguage ? 'active' : ''}`}>All Languages</Button>
              <Button type='primary' onClick={() => {
                setChosenLanguage({filter: 1});
                props.onLanguageChange('1');
              }} className={`spanLangtoggle ${props?.activeLanguage === '1' ? 'active' : ''}`}>Only English</Button>
              <Button type='primary' onClick={() => {
                setChosenLanguage({filter: 2});
                props.onLanguageChange('2');
              }} className={`spanLangtoggle ${props?.activeLanguage === '2' ? 'active' : ''}`}>Only German</Button>
            </div>
          </div>
        </div>

        <div className='featurebox suggestedSection'>
          <div className='container'>
            <div className='feature-title'>
              <div className='feature-ttitletext'>
                Live shows
              </div>
              {props.liveShows.length > cardCount && (
                <div className='title-btn'>
                  <Button ghost onClick={() => {
                    navigate({
                      pathname: "/all-shows/live-show",
                      search: props?.activeLanguage ? `language=${props.activeLanguage}` : ''
                    })
                  }}>View All</Button>
                </div>
              )}
            </div>

            <div className={`featureCardlist ${props.liveShows.length > cardCount && `lastcard`} prevent-select`}>
              {props.liveShows?.length > 0 ? (<Carousel    {...sliderSettings}
                onSwipe={() => {
                  setIsSwiping(true)
                  setTimeout(() => {
                    setIsSwiping(false)
                  }, 2500);
                }}>
                {props.liveShows.map((show: any, index: number) => {
                  return (
                    <Card key={index} show={show} isSwiping={isSwiping} />
                  )
                })}
              </Carousel>) : <CardEmpty onPressSubtitle={handleNotifyMe} titleText={NO_LIVE_SHOWS} subtitleText={NOTIFY_ME} subtitle2Text={APPLY_HOST} />}
            </div>
          </div>
        </div>

        {/* <div className='discordBtndiv'>
          <div className='container'>
            <a href='/discord' className='discordLink' target="_blank" rel='noopener noreferrer'>
              <span className='spantext'>Join us on <span>Discord</span></span>
              <svg width="63" height="42" viewBox="0 0 63 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.8445 37.6312C46.5544 36.5004 49.7757 34.9038 52.1557 31.9809L50.6514 32.8229C44.6017 36.2153 38.1246 37.9888 31.1053 37.6666C26.28 37.4449 21.6349 36.3625 17.1163 34.7902C15.018 34.0543 12.9925 33.1601 10.9401 32.3255C10.8348 32.2758 10.7176 32.2546 10.6009 32.264C12.9273 35.1143 16.07 36.7351 19.5903 37.8398C18.4788 39.1923 17.4134 40.5057 16.3172 41.7948C16.1432 41.9402 15.9177 42.0138 15.6887 41.9998C11.4154 41.9252 7.44864 40.8038 3.94183 38.4229C0.946665 36.3867 -0.287427 33.4507 0.0555897 29.9223C0.628561 24.0317 1.95463 18.2883 4.21586 12.7963C7.22252 5.51221 12.8028 1.28706 20.7918 0.102226C21.3355 0.01396 21.8875 -0.0160266 22.4379 0.0128044C22.7617 0.0332968 23.0702 0.245672 23.3596 0.467362C19.3833 1.51992 15.6101 2.87614 12.1627 4.99617C11.3573 5.5041 10.5825 6.05643 9.84209 6.65046C24.0418 0.541259 38.1994 0.608946 52.3148 6.85352C48.5301 3.96224 44.1916 2.19617 39.5944 0.852991C39.8953 0.260575 40.3284 0.0109415 41.0087 0.0742815C49.5227 0.849265 55.492 5.05951 58.7554 12.7255C61.0281 18.0647 62.3581 23.6666 62.9387 29.4175C63.3219 33.1527 61.8617 36.1445 58.6481 38.2124C55.1145 40.4852 51.1746 41.6104 46.9357 41.7352C46.7371 41.756 46.5363 41.7252 46.3539 41.646C46.1714 41.5668 46.0138 41.4421 45.8971 41.2844C44.9275 40.0809 43.9195 38.9036 42.8445 37.6312ZM46.7403 23.9795C46.7269 20.9578 44.1667 18.3777 41.206 18.3907C38.2454 18.4038 35.7024 20.9802 35.7024 23.9367C35.7024 26.9453 38.2511 29.5404 41.2156 29.5516C44.1801 29.5628 46.7575 26.9826 46.7403 23.9702V23.9795ZM15.9646 23.9236C15.9646 26.9547 18.4558 29.5274 21.4337 29.5572C24.3772 29.587 27.0006 26.9658 27.0006 23.9982C27.0006 21.0026 24.4251 18.3944 21.4721 18.3982C18.5191 18.4019 15.9704 20.9448 15.9646 23.9143V23.9236Z" fill="#7289DA" />
              </svg>
            </a>
          </div>
        </div> */}

      {Boolean(props.gameShows?.length)&&<div className='featurebox suggestedSection'>
          <div className='container'>
            <div className='feature-title'>
              <div className='feature-ttitletext'>
              Live game shows
              </div>
              {props.gameShows?.length > cardCount && (
                <div className='title-btn'>
                  <Button ghost onClick={() => {
                    navigate({
                      pathname: "/all-shows/game-show",
                      search: props?.activeLanguage ? `language=${props.activeLanguage}` : ''
                    })
                  }}>View All</Button>
                </div>
              )}
            </div>
            <div className={`featureCardlist ${props.gameShows?.length > cardCount && `lastcard`} prevent-select`}>
              {props.gameShows?.length > 0 ? <Carousel    {...sliderSettings} onSwipe={() => {
                setIsSwiping(true)
                setTimeout(() => {
                  setIsSwiping(false)
                }, 2500);

              }}>
                {props.gameShows.map((show: any, index: number) => {
                  return (
                    <Card key={index} show={show} isSwiping={isSwiping} />
                  )
                })}
              </Carousel> : <CardEmpty onPressSubtitle={handleInterests} titleText={SELECT_INTERESTS} subtitleText={ADD_INTERESTS} />}
            </div>

          </div>

        </div>}

        <div className='featurebox suggestedSection'>
          <div className='container'>
            <div className='feature-title'>
              <div className='feature-ttitletext'>
                Upcoming shows
              </div>
              {props.upComingShows?.length > cardCount && (
                <div className='title-btn'>
                  <Button ghost onClick={() => {
                    navigate({
                      pathname: "/all-shows/upcoming-show",
                      search: props?.activeLanguage ? `language=${props.activeLanguage}` : ''
                    })
                  }}>View All</Button>
                </div>
              )}
            </div>

            <div className={`featureCardlist ${props.upComingShows?.length > cardCount && `lastcard`} prevent-select`}>
              {props.upComingShows?.length > 0 ? (<Carousel    {...sliderSettings} onSwipe={() => {
                setIsSwiping(true)
                setTimeout(() => {
                  setIsSwiping(false)
                }, 2500);
              }}>

                {props.upComingShows.map((show: any, index: number) => {
                  return (
                    <Card key={index} show={show} isSwiping={isSwiping} />
                  )
                })}
              </Carousel>) : <CardEmpty onPressSubtitle={handleInterests} titleText={No_MATCHING_PREFERENCES} subtitleText={PICK_INTEREST} />}
            </div>
          </div>
        </div>

        <div className='featurebox suggestedSection'>
          <div className='container'>
            <div className='feature-title'>
              <div className='feature-ttitletext'>
                Suggested shows
              </div>
              {props.suggestedShows?.length > cardCount && (
                <div className='title-btn'>
                  <Button ghost onClick={() => {
                    navigate({
                      pathname: "/all-shows/suggested-show",
                      search: props?.activeLanguage ? `language=${props.activeLanguage}` : ''
                    })
                  }}>View All</Button>
                </div>
              )}
            </div>
            <div className={`featureCardlist ${props.suggestedShows?.length > cardCount && `lastcard`} prevent-select`}>
              {props.suggestedShows?.length > 0 ? <Carousel    {...sliderSettings} onSwipe={() => {
                setIsSwiping(true)
                setTimeout(() => {
                  setIsSwiping(false)
                }, 2500);

              }}>
                {props.suggestedShows.map((show: any, index: number) => {
                  return (
                    <Card key={index} show={show} isSwiping={isSwiping} />
                  )
                })}
              </Carousel> : <CardEmpty onPressSubtitle={handleInterests} titleText={SELECT_INTERESTS} subtitleText={ADD_INTERESTS} />}
            </div>

          </div>

        </div>

        <div className='featurebox suggestedSection mb100'>
          <div className='container'>
            <div className='feature-title interestTitle'>
              <div className='feature-ttitletext'>
                Interests
              </div>
              <div className='title-btn'>
                <button onClick={handleInterests} className='EditLink'><Edit /></button>
              </div>
            </div>
            {
              users.user?.userTopics?.length ? (
                <div className='interestTag'>
                  <div className='tagsspans'>
                    {
                      users.user?.userTopics?.map((topic: any, index: number) => (
                        <span className='tagsSpans' key={index}>
                          {topic.topicName}
                        </span>
                      ))
                    }
                  </div>
                </div>
              ) : (
                <p className='bold white max-500'>Choose a language and a few interests to see live shopping show that match best.</p>
              )
            }
          </div>
        </div>
        {/* Feature shows section remove for now ref of ticket ENT-103  */}
        {/* <div className='featurebox suggestedSection'>
          <div className='container'>
            <div className='feature-title'>
              <div className='feature-ttitletext'>
                Featured Shows
              </div>
              {props.featuredShows?.length > cardCount && (
                <div className='title-btn'>
                  <Button ghost onClick={() => {
                    navigate({
                      pathname: "/all-shows/feature-show",
                      search: props?.activeLanguage ? `language=${props.activeLanguage}` : ''
                    })
                  }}>View All</Button>
                </div>
              )}
            </div>
            <div className={`featureCardlist ${props.featuredShows?.length > cardCount && `lastcard`} prevent-select`}>
              {props.featuredShows?.length > 0 ? (<Carousel    {...sliderSettings} onSwipe={() => {
                setIsSwiping(true)
                setTimeout(() => {
                  setIsSwiping(false)
                }, 2500);
              }}>
                {props.featuredShows.map((show: any, index: number) => {
                  return (
                    <Card key={index} show={show} isSwiping={isSwiping} />
                  )
                })}
              </Carousel>) : <div className='mb100'> <CardEmpty onPressSubtitle={handleInterests} titleText={No_MATCHING_PREFERENCES} subtitleText={PICK_INTEREST} /></div>}
            </div>
          </div>
        </div> */}

      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {
        topicsModalVisible && <TopicsModal visible={topicsModalVisible} onOk={handleOk} onCancel={handleCancel} />
      }
      {
        retryModalVisible &&
        <UnsuccessfulPaymentModal
        shippingUpgradeData={shippingUpgradeData}
        failedItemsData={failedItemsData}
        isUnsuccesssfulpayment={retryModalVisible}
        handleOk={closeRetryPaymentPopup}
        handleCancel={closeRetryPaymentPopup}
        onRetryPayment={onRetryPayment}
        description={`We couldn't complete your payment again. Please retry using a different payment method or contact our customer support.`} onSupportChat={onSupportChat} />
      }
    </>
  );
}

export default ShowsScreen

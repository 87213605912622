import React from 'react'
import { images } from '../../../Assets/ImageConfig/images';

function Playstore(props:any) {
	return (
    <a href="https://letsgavel.com/android" title="Download Gavel Android App">
      {/* <img src={images.Playstore} style={{maxWidth: 300, height: props.height, width: props.width}} /> */}
      <img src={images.appandroid} style={{maxWidth: 300, height: props.height, width: props.width}} />
    </a>
  )

	// return (
	// 	<svg width="200" height="69" viewBox="0 0 200 69" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path fill-rule="evenodd" clip-rule="evenodd" d="M6.91978 0H193.08C196.885 0 200 3.11474 200 6.91978V61.5035C200 65.3085 196.885 68.4233 193.08 68.4233H6.91978C3.11474 68.4233 0 65.3085 0 61.5035V6.91978C0 3.11474 3.11474 0 6.91978 0Z" fill="black"/>
  //     <path d="M77.3636 52.5128C76.8922 53.0853 76.2187 53.523 75.3432 53.8429C74.4677 54.1628 73.5081 54.3143 72.4474 54.3143C71.3362 54.3143 70.3597 54.0786 69.5178 53.5904C68.676 53.1021 68.0362 52.395 67.5816 51.469C67.1271 50.543 66.8913 49.4654 66.8745 48.2195V47.344C66.8745 46.0645 67.0934 44.9533 67.5311 44.0104C67.9689 43.0676 68.5918 42.3605 69.4 41.8554C70.2081 41.3503 71.1678 41.1146 72.2622 41.1146C73.7943 41.1146 74.9728 41.485 75.8315 42.2089C76.6902 42.9329 77.1952 43.9936 77.3636 45.3742H74.7876C74.6698 44.6334 74.4172 44.0946 74.0132 43.7579C73.6091 43.4212 73.0535 43.2528 72.3464 43.2528C71.454 43.2528 70.7637 43.5895 70.2923 44.263C69.8209 44.9364 69.5852 45.9466 69.5852 47.2767V48.1017C69.5852 49.4486 69.8377 50.4756 70.3597 51.1491C70.8647 51.8394 71.6224 52.1761 72.6157 52.1761C73.6091 52.1761 74.3162 51.9572 74.7371 51.5363V49.3139H72.3295V47.3609H77.3804V52.5128H77.3636Z" fill="white"/>
  //     <path d="M78.9458 49.2634C78.9458 48.3206 79.131 47.4787 79.5014 46.7379C79.8718 45.9971 80.3937 45.4247 81.084 45.0206C81.7743 44.6165 82.5656 44.4145 83.4748 44.4145C84.7712 44.4145 85.8151 44.8017 86.6232 45.593C87.4314 46.3844 87.8859 47.4451 87.987 48.8088L88.0038 49.4654C88.0038 50.9302 87.5997 52.1087 86.7747 52.9842C85.9498 53.8766 84.8554 54.3143 83.4748 54.3143C82.0942 54.3143 80.9998 53.8766 80.1749 52.9842C79.3499 52.1087 78.9458 50.8965 78.9458 49.3812V49.2634V49.2634ZM81.5049 49.4486C81.5049 50.3578 81.6733 51.0481 82.01 51.5363C82.3468 52.0246 82.835 52.2603 83.4748 52.2603C84.0977 52.2603 84.5692 52.0246 84.9227 51.5531C85.2763 51.0817 85.4447 50.3241 85.4447 49.2802C85.4447 48.3879 85.2763 47.6976 84.9227 47.2093C84.5692 46.7211 84.0809 46.4685 83.458 46.4685C82.835 46.4685 82.3468 46.7042 82.01 47.1925C81.6733 47.6808 81.5049 48.4384 81.5049 49.4486Z" fill="white"/>
  //     <path d="M89.1658 49.2634C89.1658 48.3206 89.351 47.4787 89.7214 46.7379C90.0918 45.9971 90.6137 45.4247 91.304 45.0206C91.9943 44.6165 92.7856 44.4145 93.6948 44.4145C94.9912 44.4145 96.035 44.8017 96.8432 45.593C97.6513 46.3844 98.1059 47.4451 98.2069 48.8088L98.2238 49.4654C98.2238 50.9302 97.8197 52.1087 96.9947 52.9842C96.1697 53.8766 95.0754 54.3143 93.6948 54.3143C92.3142 54.3143 91.2198 53.8766 90.3948 52.9842C89.5699 52.1087 89.1658 50.8965 89.1658 49.3812V49.2634V49.2634ZM91.7249 49.4486C91.7249 50.3578 91.8933 51.0481 92.23 51.5363C92.5667 52.0246 93.055 52.2603 93.6948 52.2603C94.3177 52.2603 94.7891 52.0246 95.1427 51.5531C95.4963 51.0817 95.6646 50.3241 95.6646 49.2802C95.6646 48.3879 95.4963 47.6976 95.1427 47.2093C94.7891 46.7211 94.3009 46.4685 93.6779 46.4685C93.055 46.4685 92.5667 46.7042 92.23 47.1925C91.8933 47.6808 91.7249 48.4384 91.7249 49.4486Z" fill="white"/>
  //     <path d="M99.4192 49.2803C99.4192 47.8155 99.7727 46.637 100.463 45.7446C101.153 44.8523 102.096 44.3977 103.292 44.3977C104.335 44.3977 105.16 44.7513 105.733 45.4752L105.834 44.5829H108.14V53.8093C108.14 54.6511 107.955 55.3751 107.568 55.998C107.181 56.621 106.659 57.0924 105.969 57.4123C105.278 57.7322 104.47 57.9005 103.544 57.9005C102.837 57.9005 102.164 57.7658 101.49 57.4796C100.817 57.1934 100.328 56.8398 99.9748 56.4021L101.103 54.8531C101.743 55.5603 102.517 55.9138 103.409 55.9138C104.083 55.9138 104.605 55.7286 104.992 55.3751C105.362 55.0215 105.564 54.4996 105.564 53.8429V53.3378C104.975 54.0113 104.201 54.3312 103.241 54.3312C102.096 54.3312 101.17 53.8766 100.463 52.9843C99.7559 52.092 99.4023 50.8966 99.4023 49.415V49.2803H99.4192ZM101.961 49.4823C101.961 50.341 102.13 51.0313 102.483 51.5195C102.837 52.0078 103.308 52.2603 103.915 52.2603C104.689 52.2603 105.245 51.9741 105.581 51.3848V47.3609C105.245 46.7716 104.689 46.4854 103.931 46.4854C103.325 46.4854 102.837 46.738 102.483 47.2431C102.147 47.7313 101.961 48.489 101.961 49.4823Z" fill="white"/>
  //     <path d="M112.804 54.1291H110.245V40.5758H112.804V54.1291Z" fill="white"/>
  //     <path d="M119.303 54.3144C117.906 54.3144 116.761 53.8766 115.885 53.018C115.01 52.1593 114.572 51.0144 114.572 49.5833V49.3308C114.572 48.3711 114.757 47.5124 115.128 46.7548C115.498 45.9972 116.02 45.4079 116.71 45.0038C117.384 44.5997 118.158 44.3977 119.034 44.3977C120.347 44.3977 121.374 44.8018 122.115 45.6268C122.872 46.4517 123.243 47.6135 123.243 49.1287V50.1726H117.165C117.249 50.7955 117.502 51.3006 117.906 51.671C118.327 52.0414 118.848 52.2435 119.488 52.2435C120.465 52.2435 121.239 51.8899 121.795 51.1828L123.041 52.5802C122.654 53.119 122.148 53.5399 121.492 53.8429C120.818 54.1628 120.094 54.3144 119.303 54.3144ZM119 46.4686C118.495 46.4686 118.091 46.637 117.771 46.9905C117.451 47.3272 117.249 47.8323 117.165 48.4553H120.717V48.2533C120.7 47.6808 120.549 47.2431 120.263 46.94C119.96 46.6201 119.556 46.4686 119 46.4686Z" fill="white"/>
  //     <path d="M131.998 49.6002V54.1292H129.354V41.283H134.372C135.331 41.283 136.19 41.4513 136.914 41.8049C137.655 42.1584 138.21 42.6635 138.597 43.3033C138.985 43.96 139.187 44.7008 139.187 45.5257C139.187 46.7885 138.749 47.7818 137.89 48.5058C137.032 49.2298 135.836 49.6002 134.304 49.6002H131.998V49.6002ZM131.998 47.4619H134.372C135.079 47.4619 135.601 47.2936 135.971 46.9737C136.341 46.6369 136.527 46.1655 136.527 45.5594C136.527 44.9365 136.341 44.4145 135.971 44.0273C135.601 43.6401 135.095 43.438 134.439 43.4212H131.998V47.4619Z" fill="white"/>
  //     <path d="M143.531 54.1291H140.971V40.5758H143.531V54.1291Z" fill="white"/>
  //     <path d="M151.157 54.1291C151.04 53.8934 150.955 53.6072 150.905 53.2705C150.282 53.9608 149.491 54.2975 148.497 54.2975C147.554 54.2975 146.78 54.0281 146.174 53.4894C145.551 52.9506 145.248 52.2603 145.248 51.4353C145.248 50.4251 145.618 49.6507 146.376 49.1119C147.133 48.5731 148.211 48.3037 149.625 48.2869H150.804V47.7313C150.804 47.2936 150.686 46.94 150.467 46.6706C150.248 46.4012 149.878 46.2665 149.39 46.2665C148.952 46.2665 148.615 46.3676 148.379 46.5696C148.127 46.7716 148.009 47.0578 148.009 47.4114H145.467C145.467 46.8558 145.635 46.3507 145.972 45.8793C146.309 45.4079 146.797 45.0375 147.42 44.7681C148.043 44.4987 148.75 44.364 149.524 44.364C150.703 44.364 151.629 44.6671 152.319 45.2563C153.009 45.8456 153.363 46.6874 153.363 47.7481V51.8899C153.363 52.7991 153.498 53.4894 153.75 53.9439V54.0955H151.157V54.1291ZM149.053 52.3613C149.423 52.3613 149.777 52.2771 150.097 52.1088C150.417 51.9404 150.652 51.7215 150.804 51.4353V49.7853H149.844C148.565 49.7853 147.891 50.2231 147.807 51.1154L147.79 51.267C147.79 51.5868 147.908 51.8394 148.127 52.0583C148.363 52.2603 148.666 52.3613 149.053 52.3613Z" fill="white"/>
  //     <path d="M158.751 50.5261L160.519 44.5829H163.263L159.424 55.6107L159.205 56.1158C158.633 57.3617 157.69 57.9847 156.377 57.9847C156.006 57.9847 155.636 57.9342 155.249 57.8163V55.8801L155.636 55.897C156.124 55.897 156.478 55.8296 156.713 55.6781C156.949 55.5266 157.134 55.2908 157.269 54.9373L157.572 54.146L154.222 44.566H156.966L158.751 50.5261Z" fill="white"/>
  //     <path d="M67.7682 30H66.3833L70.389 19.0909H71.7526L75.7583 30H74.3734L71.1134 20.8168H71.0282L67.7682 30ZM68.2796 25.7386H73.862V26.9105H68.2796V25.7386Z" fill="white"/>
  //     <path d="M82.952 21.8182L79.9264 30H78.648L75.6225 21.8182H76.9861L79.2446 28.3381H79.3299L81.5884 21.8182H82.952Z" fill="white"/>
  //     <path d="M86.7699 30.1918C86.2515 30.1918 85.7809 30.0941 85.3584 29.8988C84.9358 29.6999 84.6002 29.4141 84.3516 29.0412C84.103 28.6648 83.9787 28.2102 83.9787 27.6776C83.9787 27.2088 84.0711 26.8288 84.2557 26.5376C84.4404 26.2429 84.6872 26.0121 84.9961 25.8452C85.3051 25.6783 85.646 25.554 86.0189 25.4723C86.3953 25.3871 86.7735 25.3196 87.1535 25.2699C87.6506 25.206 88.0537 25.158 88.3626 25.1261C88.6751 25.0906 88.9024 25.032 89.0444 24.9503C89.19 24.8686 89.2628 24.7266 89.2628 24.5241V24.4815C89.2628 23.956 89.119 23.5476 88.8314 23.2564C88.5473 22.9652 88.1158 22.8196 87.537 22.8196C86.9368 22.8196 86.4663 22.951 86.1254 23.2138C85.7845 23.4766 85.5448 23.7571 85.4063 24.0554L84.2131 23.6293C84.4262 23.1321 84.7103 22.745 85.0654 22.468C85.4241 22.1875 85.8147 21.9922 86.2373 21.8821C86.6634 21.7685 87.0824 21.7116 87.4944 21.7116C87.7571 21.7116 88.059 21.7436 88.3999 21.8075C88.7444 21.8679 89.0764 21.994 89.396 22.1857C89.7191 22.3775 89.9873 22.6669 90.2003 23.054C90.4134 23.4411 90.5199 23.9595 90.5199 24.6094V30H89.2628V28.892H89.1989C89.1137 29.0696 88.9716 29.2596 88.7728 29.462C88.5739 29.6644 88.3093 29.8366 87.9791 29.9787C87.6488 30.1207 87.2458 30.1918 86.7699 30.1918ZM86.9617 29.0625C87.4589 29.0625 87.8779 28.9648 88.2188 28.7695C88.5633 28.5742 88.8225 28.3221 88.9965 28.0131C89.174 27.7042 89.2628 27.3793 89.2628 27.0384V25.8878C89.2096 25.9517 89.0924 26.0103 88.9113 26.0636C88.7337 26.1133 88.5277 26.1577 88.2934 26.1967C88.0625 26.2322 87.837 26.2642 87.6169 26.2926C87.4003 26.3175 87.2245 26.3388 87.0895 26.3565C86.7628 26.3991 86.4574 26.4684 86.1733 26.5643C85.8928 26.6566 85.6655 26.7969 85.4915 26.9851C85.3211 27.1697 85.2358 27.4219 85.2358 27.7415C85.2358 28.1783 85.3974 28.5085 85.7206 28.7322C86.0473 28.9524 86.461 29.0625 86.9617 29.0625Z" fill="white"/>
  //     <path d="M92.8144 30V21.8182H94.0715V30H92.8144ZM93.4536 20.4545C93.2086 20.4545 92.9973 20.3711 92.8197 20.2042C92.6457 20.0373 92.5587 19.8366 92.5587 19.6023C92.5587 19.3679 92.6457 19.1673 92.8197 19.0004C92.9973 18.8335 93.2086 18.75 93.4536 18.75C93.6986 18.75 93.9082 18.8335 94.0822 19.0004C94.2597 19.1673 94.3485 19.3679 94.3485 19.6023C94.3485 19.8366 94.2597 20.0373 94.0822 20.2042C93.9082 20.3711 93.6986 20.4545 93.4536 20.4545Z" fill="white"/>
  //     <path d="M97.6311 19.0909V30H96.374V19.0909H97.6311Z" fill="white"/>
  //     <path d="M102.341 30.1918C101.823 30.1918 101.352 30.0941 100.93 29.8988C100.507 29.6999 100.171 29.4141 99.9229 29.0412C99.6743 28.6648 99.55 28.2102 99.55 27.6776C99.55 27.2088 99.6424 26.8288 99.827 26.5376C100.012 26.2429 100.258 26.0121 100.567 25.8452C100.876 25.6783 101.217 25.554 101.59 25.4723C101.967 25.3871 102.345 25.3196 102.725 25.2699C103.222 25.206 103.625 25.158 103.934 25.1261C104.246 25.0906 104.474 25.032 104.616 24.9503C104.761 24.8686 104.834 24.7266 104.834 24.5241V24.4815C104.834 23.956 104.69 23.5476 104.403 23.2564C104.119 22.9652 103.687 22.8196 103.108 22.8196C102.508 22.8196 102.038 22.951 101.697 23.2138C101.356 23.4766 101.116 23.7571 100.978 24.0554L99.7844 23.6293C99.9975 23.1321 100.282 22.745 100.637 22.468C100.995 22.1875 101.386 21.9922 101.809 21.8821C102.235 21.7685 102.654 21.7116 103.066 21.7116C103.328 21.7116 103.63 21.7436 103.971 21.8075C104.316 21.8679 104.648 21.994 104.967 22.1857C105.29 22.3775 105.559 22.6669 105.772 23.054C105.985 23.4411 106.091 23.9595 106.091 24.6094V30H104.834V28.892H104.77C104.685 29.0696 104.543 29.2596 104.344 29.462C104.145 29.6644 103.881 29.8366 103.55 29.9787C103.22 30.1207 102.817 30.1918 102.341 30.1918ZM102.533 29.0625C103.03 29.0625 103.449 28.9648 103.79 28.7695C104.135 28.5742 104.394 28.3221 104.568 28.0131C104.745 27.7042 104.834 27.3793 104.834 27.0384V25.8878C104.781 25.9517 104.664 26.0103 104.483 26.0636C104.305 26.1133 104.099 26.1577 103.865 26.1967C103.634 26.2322 103.408 26.2642 103.188 26.2926C102.972 26.3175 102.796 26.3388 102.661 26.3565C102.334 26.3991 102.029 26.4684 101.745 26.5643C101.464 26.6566 101.237 26.7969 101.063 26.9851C100.892 27.1697 100.807 27.4219 100.807 27.7415C100.807 28.1783 100.969 28.5085 101.292 28.7322C101.619 28.9524 102.032 29.0625 102.533 29.0625Z" fill="white"/>
  //     <path d="M108.556 30V19.0909H109.813V23.1179H109.92C110.012 22.9759 110.14 22.7947 110.303 22.5746C110.47 22.3509 110.708 22.152 111.017 21.978C111.33 21.8004 111.752 21.7116 112.285 21.7116C112.974 21.7116 113.581 21.8839 114.107 22.2283C114.632 22.5728 115.042 23.0611 115.337 23.6932C115.632 24.3253 115.779 25.071 115.779 25.9304C115.779 26.7969 115.632 27.5479 115.337 28.1836C115.042 28.8157 114.634 29.3058 114.112 29.6538C113.59 29.9982 112.988 30.1705 112.306 30.1705C111.781 30.1705 111.36 30.0835 111.044 29.9094C110.728 29.7319 110.484 29.5313 110.314 29.3075C110.144 29.0803 110.012 28.892 109.92 28.7429H109.771V30H108.556ZM109.792 25.9091C109.792 26.527 109.883 27.0721 110.064 27.5444C110.245 28.0131 110.509 28.3807 110.857 28.647C111.205 28.9098 111.631 29.0412 112.136 29.0412C112.661 29.0412 113.1 28.9027 113.451 28.6257C113.807 28.3452 114.073 27.9687 114.25 27.4964C114.432 27.0206 114.522 26.4915 114.522 25.9091C114.522 25.3338 114.433 24.8153 114.256 24.3537C114.082 23.8885 113.817 23.521 113.462 23.2511C113.11 22.9776 112.668 22.8409 112.136 22.8409C111.624 22.8409 111.195 22.9705 110.847 23.2298C110.499 23.4854 110.236 23.8441 110.058 24.3058C109.881 24.7638 109.792 25.2983 109.792 25.9091Z" fill="white"/>
  //     <path d="M118.959 19.0909V30H117.702V19.0909H118.959Z" fill="white"/>
  //     <path d="M124.692 30.1705C123.904 30.1705 123.224 29.9964 122.652 29.6484C122.084 29.2969 121.645 28.8068 121.336 28.1783C121.031 27.5462 120.878 26.8111 120.878 25.973C120.878 25.1349 121.031 24.3963 121.336 23.7571C121.645 23.1143 122.075 22.6136 122.625 22.255C123.179 21.8928 123.826 21.7116 124.564 21.7116C124.99 21.7116 125.411 21.7827 125.827 21.9247C126.242 22.0668 126.62 22.2976 126.961 22.6172C127.302 22.9332 127.574 23.3523 127.776 23.8743C127.979 24.3963 128.08 25.0391 128.08 25.8026V26.3352H121.773V25.2486H126.801C126.801 24.7869 126.709 24.375 126.524 24.0128C126.343 23.6506 126.084 23.3647 125.747 23.1552C125.413 22.9457 125.019 22.8409 124.564 22.8409C124.064 22.8409 123.63 22.9652 123.265 23.2138C122.902 23.4588 122.624 23.7784 122.428 24.1726C122.233 24.5668 122.135 24.9893 122.135 25.4403V26.1648C122.135 26.7827 122.242 27.3065 122.455 27.7362C122.671 28.1623 122.972 28.4872 123.355 28.7109C123.739 28.9311 124.184 29.0412 124.692 29.0412C125.022 29.0412 125.321 28.995 125.587 28.9027C125.857 28.8068 126.089 28.6648 126.285 28.4766C126.48 28.2848 126.631 28.0469 126.738 27.7628L127.952 28.1037C127.824 28.5156 127.609 28.8778 127.307 29.1903C127.006 29.4993 126.633 29.7408 126.189 29.9148C125.745 30.0852 125.246 30.1705 124.692 30.1705Z" fill="white"/>
  //     <path d="M137.535 30.1705C136.796 30.1705 136.148 29.9947 135.591 29.6431C135.037 29.2915 134.603 28.7997 134.291 28.1676C133.982 27.5355 133.827 26.7969 133.827 25.9517C133.827 25.0994 133.982 24.3555 134.291 23.7198C134.603 23.0842 135.037 22.5906 135.591 22.239C136.148 21.8874 136.796 21.7116 137.535 21.7116C138.273 21.7116 138.92 21.8874 139.474 22.239C140.031 22.5906 140.464 23.0842 140.773 23.7198C141.086 24.3555 141.242 25.0994 141.242 25.9517C141.242 26.7969 141.086 27.5355 140.773 28.1676C140.464 28.7997 140.031 29.2915 139.474 29.6431C138.92 29.9947 138.273 30.1705 137.535 30.1705ZM137.535 29.0412C138.096 29.0412 138.557 28.8974 138.92 28.6097C139.282 28.3221 139.55 27.9439 139.724 27.4751C139.898 27.0064 139.985 26.4986 139.985 25.9517C139.985 25.4048 139.898 24.8952 139.724 24.4229C139.55 23.9506 139.282 23.5689 138.92 23.2777C138.557 22.9865 138.096 22.8409 137.535 22.8409C136.974 22.8409 136.512 22.9865 136.15 23.2777C135.788 23.5689 135.519 23.9506 135.345 24.4229C135.171 24.8952 135.084 25.4048 135.084 25.9517C135.084 26.4986 135.171 27.0064 135.345 27.4751C135.519 27.9439 135.788 28.3221 136.15 28.6097C136.512 28.8974 136.974 29.0412 137.535 29.0412Z" fill="white"/>
  //     <path d="M144.418 25.0781V30H143.161V21.8182H144.376V23.0966H144.482C144.674 22.6811 144.965 22.3473 145.356 22.0952C145.746 21.8395 146.251 21.7116 146.868 21.7116C147.422 21.7116 147.907 21.8253 148.323 22.0526C148.738 22.2763 149.061 22.6172 149.292 23.0753C149.523 23.5298 149.638 24.1051 149.638 24.8011V30H148.381V24.8864C148.381 24.2436 148.214 23.7429 147.881 23.3842C147.547 23.022 147.089 22.8409 146.506 22.8409C146.105 22.8409 145.746 22.9279 145.43 23.1019C145.118 23.2759 144.871 23.5298 144.69 23.8636C144.509 24.1974 144.418 24.6023 144.418 25.0781Z" fill="white"/>
  //     <path d="M159.712 21.8182V22.8835H155.472V21.8182H159.712ZM156.708 19.858H157.965V27.6562C157.965 28.0114 158.017 28.2777 158.12 28.4553C158.226 28.6293 158.361 28.7464 158.525 28.8068C158.692 28.8636 158.867 28.892 159.052 28.892C159.19 28.892 159.304 28.8849 159.393 28.8707C159.482 28.853 159.553 28.8388 159.606 28.8281L159.862 29.9574C159.776 29.9893 159.657 30.0213 159.505 30.0533C159.352 30.0888 159.159 30.1065 158.924 30.1065C158.569 30.1065 158.221 30.0302 157.88 29.8775C157.543 29.7248 157.262 29.4922 157.038 29.1797C156.818 28.8672 156.708 28.473 156.708 27.9972V19.858Z" fill="white"/>
  //     <path d="M163.036 25.0781V30H161.779V19.0909H163.036V23.0966H163.143C163.335 22.674 163.622 22.3384 164.006 22.0898C164.393 21.8377 164.908 21.7116 165.551 21.7116C166.108 21.7116 166.596 21.8235 167.015 22.0472C167.434 22.2674 167.759 22.6065 167.99 23.0646C168.225 23.5192 168.342 24.098 168.342 24.8011V30H167.085V24.8864C167.085 24.2365 166.916 23.734 166.579 23.3789C166.245 23.0202 165.781 22.8409 165.188 22.8409C164.776 22.8409 164.407 22.9279 164.08 23.1019C163.757 23.2759 163.502 23.5298 163.313 23.8636C163.129 24.1974 163.036 24.6023 163.036 25.0781Z" fill="white"/>
  //     <path d="M174.072 30.1705C173.284 30.1705 172.604 29.9964 172.032 29.6484C171.464 29.2969 171.025 28.8068 170.716 28.1783C170.411 27.5462 170.258 26.8111 170.258 25.973C170.258 25.1349 170.411 24.3963 170.716 23.7571C171.025 23.1143 171.455 22.6136 172.005 22.255C172.559 21.8928 173.205 21.7116 173.944 21.7116C174.37 21.7116 174.791 21.7827 175.207 21.9247C175.622 22.0668 176 22.2976 176.341 22.6172C176.682 22.9332 176.954 23.3523 177.156 23.8743C177.359 24.3963 177.46 25.0391 177.46 25.8026V26.3352H171.153V25.2486H176.181C176.181 24.7869 176.089 24.375 175.904 24.0128C175.723 23.6506 175.464 23.3647 175.127 23.1552C174.793 22.9457 174.399 22.8409 173.944 22.8409C173.443 22.8409 173.01 22.9652 172.644 23.2138C172.282 23.4588 172.003 23.7784 171.808 24.1726C171.613 24.5668 171.515 24.9893 171.515 25.4403V26.1648C171.515 26.7827 171.622 27.3065 171.835 27.7362C172.051 28.1623 172.351 28.4872 172.735 28.7109C173.118 28.9311 173.564 29.0412 174.072 29.0412C174.402 29.0412 174.701 28.995 174.967 28.9027C175.237 28.8068 175.469 28.6648 175.665 28.4766C175.86 28.2848 176.011 28.0469 176.117 27.7628L177.332 28.1037C177.204 28.5156 176.989 28.8778 176.687 29.1903C176.386 29.4993 176.013 29.7408 175.569 29.9148C175.125 30.0852 174.626 30.1705 174.072 30.1705Z" fill="white"/>
  //     <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2065 8.65398C11.6341 9.29376 11.2974 10.2871 11.2974 11.5667V57.2102C11.2974 58.4898 11.6341 59.4832 12.2234 60.1061L12.3749 60.2408L37.0403 34.6494V34.077L12.3581 8.51929L12.2065 8.65398Z" fill="url(#paint0_linear_2_13)"/>
  //     <path fill-rule="evenodd" clip-rule="evenodd" d="M45.2396 43.2023L37.0234 34.38L45.2396 25.5409L45.4248 25.6588L55.1563 31.4C57.9343 33.0331 57.9343 35.7101 55.1563 37.3601L45.4248 43.1013C45.4248 43.1013 45.2396 43.2023 45.2396 43.2023Z" fill="url(#paint1_linear_2_13)"/>
  //     <path fill-rule="evenodd" clip-rule="evenodd" d="M45.4248 43.1013L37.0234 34.38L12.2065 60.1061C13.1157 61.1163 14.631 61.2341 16.3483 60.2408L45.4248 43.1013Z" fill="url(#paint2_linear_2_13)"/>
  //     <path fill-rule="evenodd" clip-rule="evenodd" d="M45.4248 25.6587L16.3483 8.53606C14.6478 7.52587 13.1157 7.66056 12.2065 8.67075L37.0234 34.38L45.4248 25.6587Z" fill="url(#paint3_linear_2_13)"/>
  //     <defs>
  //     <linearGradient id="paint0_linear_2_13" x1="34.8517" y1="11.883" x2="-4.93201" y2="22.4834" gradientUnits="userSpaceOnUse">
  //     <stop stop-color="#00A0FF"/>
  //     <stop offset="0.007" stop-color="#00A1FF"/>
  //     <stop offset="0.26" stop-color="#00BEFF"/>
  //     <stop offset="0.512" stop-color="#00D2FF"/>
  //     <stop offset="0.76" stop-color="#00DFFF"/>
  //     <stop offset="1" stop-color="#00E3FF"/>
  //     </linearGradient>
  //     <linearGradient id="paint1_linear_2_13" x1="58.7785" y1="34.3796" x2="10.6434" y2="34.3796" gradientUnits="userSpaceOnUse">
  //     <stop stop-color="#FFE000"/>
  //     <stop offset="0.409" stop-color="#FFBD00"/>
  //     <stop offset="0.775" stop-color="#FFA500"/>
  //     <stop offset="1" stop-color="#FF9C00"/>
  //     </linearGradient>
  //     <linearGradient id="paint2_linear_2_13" x1="40.9157" y1="39.0429" x2="8.45968" y2="93.6686" gradientUnits="userSpaceOnUse">
  //     <stop stop-color="#FF3A44"/>
  //     <stop offset="1" stop-color="#C31162"/>
  //     </linearGradient>
  //     <linearGradient id="paint3_linear_2_13" x1="5.20873" y1="-5.98873" x2="19.6882" y2="18.4082" gradientUnits="userSpaceOnUse">
  //     <stop stop-color="#32A071"/>
  //     <stop offset="0.069" stop-color="#2DA771"/>
  //     <stop offset="0.476" stop-color="#15CF74"/>
  //     <stop offset="0.801" stop-color="#06E775"/>
  //     <stop offset="1" stop-color="#00F076"/>
  //     </linearGradient>
  //     </defs>
  //   </svg>
	// )
}

export default Playstore

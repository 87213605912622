import React from "react";

function Eye() {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.39829 6C4.39829 7.42222 5.56252 8.57939 6.99992 8.57939C8.43081 8.57939 9.59504 7.42222 9.59504 6C9.59504 4.57131 8.43081 3.41414 6.99992 3.41414C5.56252 3.41414 4.39829 4.57131 4.39829 6ZM10.8243 2.0307C11.9625 2.9099 12.9316 4.19636 13.6276 5.80606C13.6796 5.92889 13.6796 6.07111 13.6276 6.18747C12.2357 9.40687 9.75764 11.3333 6.99992 11.3333H6.99341C4.2422 11.3333 1.76415 9.40687 0.372276 6.18747C0.320244 6.07111 0.320244 5.92889 0.372276 5.80606C1.76415 2.58666 4.2422 0.666664 6.99341 0.666664H6.99992C8.37878 0.666664 9.6861 1.14505 10.8243 2.0307ZM7.0007 7.60827C7.89176 7.60827 8.62021 6.88423 8.62021 5.99857C8.62021 5.10645 7.89176 4.38241 7.0007 4.38241C6.92265 4.38241 6.8446 4.38888 6.77306 4.40181C6.74704 5.11292 6.16167 5.68181 5.43972 5.68181H5.4072C5.38769 5.78524 5.37468 5.88868 5.37468 5.99857C5.37468 6.88423 6.10314 7.60827 7.0007 7.60827Z" fill="#FCFCFD"/>
    </svg>


  );
}

export default Eye;
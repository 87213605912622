import React from 'react'
import './Loader.scss'
  import PuffLoader from "react-spinners/ClipLoader";

function Loader() {
  return (

      <div className="LoaderBlock">
        <div className='loaderImg'>
        {/* <img src={images.Chikorita} alt="" title="" /> */}
        <PuffLoader color={'#0CFDE4'} loading={true} size={80} />
        </div>
      </div>

  )
}

export default Loader

import {Row, Col, Breadcrumb} from 'antd'
import {useCallback, useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import './AllShow.scss'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../store'
import Card from '../CommonComponents/Card'
import {getAllShows, resetAllShows} from '../../store/Actions/showDashboard'
// import _ from 'lodash'
import Loader from '../CommonComponents/Loader';
import {RightArrow} from '../Icons'
let allReset = true;
function AllShow(props: any) {


  const [shows, setShows] = useState<object[]>([])
  const [title, setTitle] = useState<string>()
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(0)

  const [userId, setUserId] = useState(undefined)

  const [searchParams, setSearchParams] = useSearchParams();

  const language = searchParams.get('language')

  const dispatch = useDispatch()
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const showDashboard = useSelector(
    (state: RootState) => state?.showDashboard
  );


  async function fetchShows() {
    switch (props.path) {
      case "feature-show":
        if (page <= 1 || (page > 1 && showDashboard.shows?.featured?.totalCount > showDashboard.shows?.featured?.shows.length)) {
          setLoader(true)
          await dispatch(getAllShows('featured', page, users.user?.id, [...showDashboard?.shows?.featured?.shows?.map((show: any) => show.id) ?? []], language))
        }
        break;
      case "upcoming-show":
        if (page <= 1 || (page > 1 && showDashboard.shows?.ready?.totalCount > showDashboard.shows?.ready?.shows.length)) {
          setLoader(true)
          await dispatch(getAllShows('ready', page, users.user?.id, [...showDashboard.shows?.ready?.shows?.map((show: any) => show.id) ?? []], language))
        }
        break;

      case "live-show":
        if (page <= 1 || (page > 1 && showDashboard.shows?.live?.totalCount > showDashboard.shows?.live?.shows.length)) {
          setLoader(true)
          await dispatch(getAllShows('live', page, users.user?.id, [...showDashboard.shows?.live?.shows?.map((show: any) => show.id) ?? []], language))
        }
        break;

        case "game-show":
        if (page <= 1 || (page > 1 && showDashboard.shows?.game_show?.totalCount > showDashboard.shows?.game_show?.shows.length)) {
          setLoader(true)
          await dispatch(getAllShows('game_show', page, users.user?.id, [...showDashboard.shows?.game_show?.shows?.map((show: any) => show.id) ?? []], language))
        }
        break;

      case "suggested-show":
        if (page <= 1 || (page > 1 && showDashboard.shows?.suggested?.totalCount > showDashboard.shows?.suggested?.shows.length)) {
          setLoader(true)
          await dispatch(getAllShows('suggested', page, users.user?.id, [...showDashboard.shows?.suggested?.shows?.map((show: any) => show.id) ?? []], language))
        }
        break;
    }
    setLoader(false)
  }

  useEffect(() => {
    switch (props.path) {
      case "feature-show":
        setTitle("Feature shows")
        setShows([...showDashboard.shows?.featured?.shows] || [])
        break;
      case "upcoming-show":
        setTitle("Upcoming shows")
        setShows([...showDashboard.shows?.ready?.shows] || [])
        break;
      case "live-show":
        setTitle("Live shows")
        setShows([...showDashboard.shows?.live?.shows] || [])
        break;
      case "game-show":
        setTitle("Game shows")
        setShows([...showDashboard.shows?.game_show?.shows] || [])
        break;
      case "suggested-show":
        setTitle("Suggested shows")
        setShows([...showDashboard.shows?.suggested?.shows] || [])
        break;
      default:
        setTitle("All shows")
        setShows([...showDashboard.shows?.featured?.shows ?? null, ...showDashboard.shows?.ready?.shows ?? null, ...showDashboard.shows?.live?.shows ?? null, ...showDashboard.shows?.suggested?.shows ?? null] || [])
        break;
    }
  }, [showDashboard.shows])

  useEffect(() => {
    users?.isFetchedUser && setUserId(users?.user?.id ?? null)
  }, [users?.isFetchedUser])

  useEffect(() => {
    if(users?.isFetchedUser){
      resetShows()
      setPage(1)
    }
  }, [userId, language]);

  useEffect(() => {
    if (users?.isFetchedUser || userId !== undefined) fetchShows()
  }, [props.path, page]);

  const fetchMore = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, [page]);

  const resetShows = async () => {

    if (!allReset) {
      await Promise.all([
        dispatch(resetAllShows('live')),
        dispatch(resetAllShows('game_show')),
        dispatch(resetAllShows('featured')),
        dispatch(resetAllShows('ready')),
        dispatch(resetAllShows('suggested')),
      ])
      allReset = true;
    }

  }

  return (

    <div className='feature-section'>
        {/* <div className='Breadcrumpdiv'>
          <div className='container'>
            <Breadcrumb separator={<RightArrow />}>

              <Breadcrumb.Item>
                <a href="/shows">Show</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item> {title}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div> */}
      {(loader) && <Loader />}
      {/* <div className="BreadCrumbs">
        <div className='container'>
          <div className='pagetopheader'>
            <h3>Shows</h3>
            <div className='btnRight'> */}
      {/* <Button type='primary'>CREATE SHOW</Button> */}
      {/* </div>
          </div>
        </div>
      </div> */}

      <div className={`featurebox suggestedSection`}>
        <div className='container'>
          <div className='feature-title'>
            <div className='feature-ttitletext'>
              {title}
            </div>
            <div className='title-btn'>
              {/* <Button ghost>View All</Button> */}
            </div>
          </div>
          <div className='listacrds'>
            <Row gutter={30}>
              {shows.map((show: any, index: number) => {
                return <Col xs={24} sm={12} lg={6} key={index}>
                  <Card show={show} fetchMore={() => fetchMore()} lastElement={index === shows.length - 1 ? true : false} />
                </Col>
              })}
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}


export default AllShow

import axios from 'axios';
import React, {useEffect} from 'react';
import {message} from 'antd'

import Home from '../../Components/HomePage';
import country from '../../Utils/ContryService/country';

const HomePage = () => {
    const getGeoInfo = () => {
        axios
          .get("https://ipapi.co/json/")
          .then((response: any) => {
            const countryList = country as any
            const isSupported = countryList[response.data.country] || false
            sessionStorage.setItem("supportedCountry", isSupported);
            !isSupported && message.info({
              icon: '👉 ',
              content: ` Your system's region setting is set to a country (${response.data.country}) we do not currently support. If you have added a valid shipping address, we are good to go.`
            })
          })
          .catch((error) => {
            console.log(error);
          });
      };

    useEffect(()=>{
      let supportedCountry = sessionStorage.getItem("supportedCountry");
      console.log("supportedCountry",supportedCountry)
      supportedCountry===null&& getGeoInfo()
    })
    return (
        <>
        <Home />
        </>
    )
}

export default HomePage;
import React, {useRef} from 'react'
import './NavMenu.scss'
import {useNavigate, useLocation} from 'react-router'
import {images} from '../../../../Assets/ImageConfig/images'
import {Button} from 'antd'
import Appstore from '../../AppStore'
import Playstore from '../../Playstore/Playstore'
import {Msg, Show} from '../../../Icons';
const Navmenu = (handleDrawer: any, isDrawer: any) => {

  const location = useLocation()
  const navigate = useNavigate()

  const ref: any = useRef()
  return (
    <div className="NavmenuStyles">
      <div className="menu" ref={ref}>
        <ul>
          <div className='appdownload-link headerLinks'>
            {/* <a href='https://apps.apple.com/at/app/gavel-live-collectibles-sale/id1570409915'>
            <img src={images.aap1} alt='appstore' />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.gavel.collectibles'>
          <img src={images.aap2} alt='Playstore' />
          </a> */}
            <Appstore width={120} />
            <Playstore width={120} />
          </div>
          <li>
            <Button
              onClick={(e) => {
                navigate('/shows')
                isDrawer && handleDrawer(e)
              }}
              type="link" className={`${location.pathname === '/shows' ? 'active-link' : ''}`}
            >
              <Show /> Shows
            </Button>
          </li>
          <li>
            <Button
              onClick={(e) => {
                navigate('/chat')
                isDrawer && handleDrawer(e)
              }}
              type="link"
              // href="/chat"
              className={`${location.pathname === '/chat' ? 'active-link' : ''}`}
            > <Msg />
              Chat
            </Button>
          </li>
          {/* <li>
            <Button
              onClick={(e) => {
                isDrawer && handleDrawer(e)
              }}
              type="link"
              href="/#Cards"
            > <Card/>
             Cards
            </Button>
          </li>
          <li>
            <Button
              onClick={(e) => {
                isDrawer && handleDrawer(e)
              }}
              type="link"
              href="/#Messages"
            > <Msg/>
              Messages
            </Button>
          </li>
          <li>
            <Button
              onClick={(e) => {
                isDrawer && handleDrawer(e)
              }}
              type="link"
              href="/#Discover"
            > <Search />
              Discover

          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default Navmenu

import React from "react";

function Msg() {
  return (
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4026 7.35107L12.9593 10.9641C12.1198 11.6301 10.9387 11.6301 10.0992 10.9641L5.61844 7.35107" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4089 19.5C19.4502 19.5084 21.5 17.0095 21.5 13.9384V7.07001C21.5 3.99883 19.4502 1.5 16.4089 1.5H6.59114C3.54979 1.5 1.5 3.99883 1.5 7.07001V13.9384C1.5 17.0095 3.54979 19.5084 6.59114 19.5H16.4089Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    

  );
}

export default Msg;
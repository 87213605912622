import React, {useCallback, useEffect, useRef, useState} from 'react'
import {AutoComplete, Button, Form, Input, message, Modal, } from 'antd'
import {images} from '../../../Assets/ImageConfig/images'

import {Cancel, Delete, Plus} from '../../Icons'
import {debounce} from 'lodash';

import './CreateGroupModal.scss'
import {displayErrorToast, getUserById, placeHolderProfileHelper, profilePictureHelper} from '../../../Utils/helper';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {searchUsers, searchUsersAction} from '../../../store/Actions/users';
import LoadingSpinner from '../../CommonComponents/LoadingSpinner';
import ProfileBadges from '../../CommonComponents/ProfileBadges';
import ModBadge from '../../CommonComponents/ModBadge';

const {Option} = AutoComplete
const {Search} = Input;

type Props = {
  isModalVisible: boolean, handleOk: any, handleCancel: any, createGroupChat?: any, isSettingsModal?: boolean,
  groupName?: string, subsList?: any, isAdmin?: any, admins?: any, onSaveGroup?: any, onLeaveGroup?: any
};

const CreateGroupModal = (props: Props) => {

  const debounceTimeout = 1000

  const dispatch = useDispatch()

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const [form] = Form.useForm();

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false)

  const [formValid, setFormValid] = useState(false)

  const [subscribersList, setSubscribersList] = useState<any[]>([])
  const [adminsList, setAdminsList] = useState<any[]>([])
  const [removedList, setRemovedList] = useState<any[]>([])

  const [isChanged, setChanged] = useState(false)

  const onCreateGroup = (values: any) => {
    if (!values.groupName || !subscribersList.length) return

    if (props.isSettingsModal) {
      props.onSaveGroup(values.groupName, subscribersList, adminsList, removedList)
    }
    else {
      props.createGroupChat(values.groupName, [...subscribersList])
    }

  }

  const leaveGroup = () => {
    props.onLeaveGroup(subscribersList)
  }

  const onRemoveSubscriber = (userIndex: number) => {
    const newSubscribers = [
      ...subscribersList.slice(0, userIndex),
      ...subscribersList.slice(userIndex + 1, subscribersList.length)
    ]

    setSubscribersList([...newSubscribers])
  }

  const isUserAdmin = (id: string) => {
    return adminsList?.includes(id);
  };

  const addAsAdmin = (user: any) => {
    setChanged(true)
    if (!isUserAdmin(String(user?.id))) {
      setAdminsList([...adminsList, String(user?.id)])
    }
    else {
      setAdminsList([...adminsList?.filter((id: any) => id !== String(user?.id))])
    }
  }

  const onRemoveFromGroup = (user: any, userIndex: any) => {
    setChanged(true)
    const newSubscribers = [
      ...subscribersList.slice(0, userIndex),
      ...subscribersList.slice(userIndex + 1, subscribersList.length)
    ]

    setSubscribersList([...newSubscribers])
    setRemovedList([...removedList, user?.id])
  }

  async function fetchUsersInfo() {
    try {
      setLoading(true)
      const groupMembers: any[] = await Promise.all(props?.subsList?.map((sub: any) => {
        return getUserById(sub)
      }))
      setSubscribersList([...groupMembers])
      setLoading(false)
    } catch (error) {
      console.log({error});
      setSubscribersList([])
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.isSettingsModal) {
      !!props.admins?.length && setAdminsList([...props.admins])
      form.setFieldsValue({groupName: props.groupName});

      if (props.subsList?.length && subscribersList.length === 0) {
        fetchUsersInfo()
      }
    }
  }, [props.isSettingsModal])


  useEffect(() => {
    setOptions([...users.allUsersList ?? []])
  }, [users.allUsersList])

  useEffect(() => {
    return () => {
      dispatch(searchUsersAction([], 'RESET'))
    }
  }, [])


  const onSearch = async (searchValue: string) => {
    if (!searchValue.length) return

    try {
      setFetching(true);
      await dispatch(searchUsersAction([], 'RESET'))
      await dispatch(searchUsers(searchValue.toLowerCase()))
    } catch (error) {
      console.log({error});
    }
    setFetching(false);
  };

  const debounceFetcher = useCallback(debounce(onSearch, debounceTimeout), [])

  const optionsRenderer = options.map((option: any, index: number) => (
    <Option key={option?.id} value={option?.username} userdata={option}>
      <div className='follow-item create_group_item'>
        <div className='follow-left'>
          <div className='follower-img'>
            <img src={profilePictureHelper(option?.pic)} alt="" onError={placeHolderProfileHelper} />
          </div>
          <div className='follower-name'>
            <p>{option?.username} {option?.admin && <ModBadge />} {
              option?.host ? (
                <ProfileBadges user={option} />
              ) : null
            } </p>
          </div>
        </div>
        <div className='follower-right'>
          <Button onClick={() => { }} type="link"><Plus/></Button>
        </div>
      </div>
    </Option>
  ));

  return (
    <Modal title={`${props.isSettingsModal ? 'Chat Settings' : 'Create New Group'}`} className='themeModal followerModal creategroupModal Create_GroupModal' width={640} visible={props.isModalVisible} onOk={props.handleOk} onCancel={props.handleCancel} footer={false} centered closeIcon={<Cancel />} >
      <Form form={form} onFinish={onCreateGroup}
        onFieldsChange={() => setFormValid(form.getFieldsError().filter(({errors}) => errors.length).length !== 0)}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}>
        {!props.isSettingsModal && (
          <div className='createbtn'>
            <Button type='ghost' htmlType='submit' disabled={formValid || !subscribersList.length} >+ Create</Button>
          </div>
        )}
        <div className='modal-body'>
          <div className='chatgroup-body'>
            <Form.Item name='groupName' rules={[{
              required: true,
              message: 'Please input your Group Name!'
            },
            {
              max: 300,
              message: 'Group Name can be upto 300 characters!'
            }
            ]}>
              <Input placeholder="Enter Group Name" autoComplete='off' onChange={() => {
                if (isChanged) return;
                setChanged(true)
              }} />
            </Form.Item>
            {(!props.isSettingsModal || (props.isSettingsModal && props.isAdmin)) && <Form.Item name='Adduser'>
              <AutoComplete
                autoClearSearchValue
                filterOption={false} notFoundContent={null}
                className='AddUser_Wrapper'
                onSearch={debounceFetcher}
                onSelect={(data: any, option: any) => {
                  setOptions([])
                  if (subscribersList.length <= 20) {
                    const findUserIndex = subscribersList?.length ? subscribersList?.findIndex((subscriber: any) => subscriber?.id === option?.userdata?.id) : -1;
                    if (findUserIndex === -1) {
                      setSubscribersList([...subscribersList, option?.userdata])
                    }
                    else {
                     displayErrorToast('User already added to group!')
                    }
                  }
                  else {
                    displayErrorToast('Maximum 20 people are allowed in a group!')
                  }
                }
                }
                dataSource={optionsRenderer}
                open={options.length > 0}
              >
                <div className='Input_search_modal'>
                  <Search size='large' loading={fetching} placeholder="Add a user by searching for username" allowClear onSearch={debounceFetcher} />
                </div>
              </AutoComplete>
            </Form.Item>}
            {(loading) && <LoadingSpinner />}
            <div className='followList'>
              {
                subscribersList?.map((subscriber: any, index: number) => (
                  <div className='follow-item create_group_item' key={subscriber?.id}>
                    <div className='follow-left'>
                      <div className='follower-img'>
                        <img src={profilePictureHelper(subscriber?.pic)} alt="" onError={placeHolderProfileHelper} />
                      </div>
                      <div className='follower-name'>
                        <p>{subscriber?.username} {props.isSettingsModal && isUserAdmin(String(subscriber?.id)) ? '~Admin' : null}
                          {subscriber?.admin && <ModBadge />}
                          {
                            subscriber?.host ? (
                              <ProfileBadges user={subscriber} />
                            ) : null
                          }
                        </p>
                      </div>
                    </div>
                    <div className='follower-right'>
                      {
                        props.isSettingsModal ? (
                          props.isAdmin && (
                            users.user?.id !== subscriber?.id &&
                            <>
                              <Button type='link' className='Link_btn linl_img_btn' onClick={() => {
                                addAsAdmin(subscriber)
                              }}>
                                <img src={images.userPlus} alt="" />
                              </Button>
                              <Button type='link' className='Link_btn ml-16' onClick={() => {
                                onRemoveFromGroup(subscriber, index)
                              }}>
                                <Delete />
                              </Button>
                            </>
                          )
                        ) : (
                          <>
                            <Button type='link' className='Link_btn' onClick={() => {
                              onRemoveSubscriber(index)
                            }}>
                              <Cancel />
                            </Button>
                          </>
                        )
                      }

                    </div>
                  </div>
                ))
              }
            </div>

            {props.isSettingsModal && (
              <div className='modle-btn'>
                <Button type='ghost' onClick={leaveGroup} >Leave Group</Button>
                <Button type='primary' disabled={(formValid || !subscribersList.length) || !isChanged} htmlType='submit'>Save</Button>
              </div>
            )}

          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default CreateGroupModal
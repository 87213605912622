import React from 'react';
import {images} from '../../../../Assets/ImageConfig/images';
import './Transactionhistory.scss'


function EmptyTransactionHistory() {



  return (
    <div className='No_massge_wrapper'>
      <div className='No_massge_img'>
        <img src={images.Notransactionsyet} alt="" />
        <div className='Nomassge_content'>
          <h4>No transactions yet</h4>
          <p>Here you will find a record of every transaction you make on GAVEL.  For example, when you pay for an item in a live show or top up your balance. </p>
        </div>
      </div>
    </div>

  )
}

export default EmptyTransactionHistory

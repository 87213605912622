import React from "react";

function More() {
  return (
    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M2.16667 0.333008C1.25 0.333008 0.5 1.08301 0.5 1.99967C0.5 2.91634 1.25 3.66634 2.16667 3.66634C3.08333 3.66634 3.83333 2.91634 3.83333 1.99967C3.83333 1.08301 3.08333 0.333008 2.16667 0.333008ZM13.8333 0.333008C12.9167 0.333008 12.1667 1.08301 12.1667 1.99967C12.1667 2.91634 12.9167 3.66634 13.8333 3.66634C14.75 3.66634 15.5 2.91634 15.5 1.99967C15.5 1.08301 14.75 0.333008 13.8333 0.333008ZM8 0.333008C7.08333 0.333008 6.33333 1.08301 6.33333 1.99967C6.33333 2.91634 7.08333 3.66634 8 3.66634C8.91667 3.66634 9.66667 2.91634 9.66667 1.99967C9.66667 1.08301 8.91667 0.333008 8 0.333008Z" fill="currentColor"/>
    </svg>



  );
}

export default More;
/* eslint-disable react/jsx-key */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {message, Table} from 'antd';
import {Cancel, Tick, Cancelpayment} from '../../../Icons/index'

import {RootState} from '../../../../store';

import './Transactionhistory.scss';
import {fetchTransactionHistory, fetchTransactionHistoryAction, getFailedItemsList} from '../../../../store/Actions/users';
import LoadingSpinner from '../../../CommonComponents/LoadingSpinner';
import {images} from '../../../../Assets/ImageConfig/images';
import {capitalizeFirstLetter, displayErrorToast} from '../../../../Utils/helper';
import moment from 'moment';
import EmptyTransactionHistory from './EmptyTransactionHistory';
import Loader from '../../../CommonComponents/Loader';

const columns = [
  {
    title: 'Transaction Type',
    dataIndex: 'shopName',
  },
  {
    title: 'Payment Method',
    dataIndex: 'payoutMethod',
    width:300,
  },
  {
    title: 'Date & Time',
    dataIndex: 'dateTime',
    width:180,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width:130,
  },
];

function Transactionhistory(props: any) {

  const dispatch = useDispatch()

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1);
  const [transactionsList, setTransactionsList] = useState<any>([])
  const [checkHistory, setCheckHistory] = useState<boolean>(false)

  const observer = useRef<any>()

  const fetchMore = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const lastCardElementRef = useCallback(
    (node) => {

      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setTimeout(() => {

            fetchMore()
          }, 2000);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  async function getTransactionsHistory() {
    try {
      if (page <= 1 || (page > 1 && users.transactionHistory?.totalCount > transactionsList.length)) {
        setLoading(true)
        await dispatch(fetchTransactionHistory(page, props?.isTopUpHistory ? 'topup' : '' ))
        await dispatch(getFailedItemsList(users.user?.id, users.user?.airtable_id))
      }
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.message) displayErrorToast(error.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPage(0)
  }, [users.user?.id])


  useEffect(() => {
    getTransactionsHistory()
  }, [page, props?.isTopUpHistory, !!checkHistory])

  useEffect(() => {
    setTransactionsList([...users.transactionHistory?.transactions ?? []])
  }, [users.transactionHistory])

  useEffect(() => {
    return () => {
      dispatch(fetchTransactionHistoryAction([], 'RESET'))
    }
  }, [])

  const isCreditAmount = (transaction: any) => {
    return transaction?.status === 'refunded' || (transaction?.method === 'topup' && (transaction?.status === 'succeeded' || transaction?.status === 'paid'))
  }

  const isDebitAmount = (transaction: any) => {
    return transaction?.method !== 'topup' && (transaction?.status === 'paid' || transaction?.status === 'succeeded')
  }

  const isSucceedPayment = (transaction: any) => {
    return transaction?.status === 'paid' || transaction?.status === 'succeeded';
  };

  const isRefundPayment = (transaction: any) => {
    return transaction?.status === 'refunded';
  };

  const paymentMethodChecks = (transaction: any) => {
    if (!checkHistory && transaction?.status === 'paid' && (Number(transaction?.amount) === Number(users?.failedItemsList?.shippingUpgradeData?.price))) {
       setCheckHistory(true)
       return `${capitalizeFirstLetter(transaction?.method === 'topup' ? transaction?.topUpMethod : transaction?.method)} ${transaction?.method === 'credit card' ? `${transaction?.cardType} xx${transaction?.cardNumber}` : ''}`
    } else if (transaction?.status !== 'paid' && (Number(transaction?.amount) === Number(users?.failedItemsList?.shippingUpgradeData?.price))) {
       return 'Shipping Upgrade Payment Unpaid';
    }
    // When there's no topUpMethod
    else if (transaction?.status !== 'paid' && !transaction?.topUpMethod) {
      return 'Payment Failure';
    }
    else {
      return `${capitalizeFirstLetter(transaction?.method === 'topup' ? transaction?.topUpMethod : transaction?.method)} ${transaction?.method === 'credit card' ? `${transaction?.cardType} xx${transaction?.cardNumber}` : ''}`
    }
  }

  const data = transactionsList.sort((a: any, b: any) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()).map((transaction: any, index: number) => {
    return {
      key: `${transaction?.transactionId}-${index + 1}`,
      shopName: (
        <div className='shopname' ref={transactionsList.length - 1 === index ? lastCardElementRef : null}>
          <p>{transaction?.title}
            {isSucceedPayment(transaction) || isRefundPayment(transaction) ?
              (<Tick />) :
              (<Cancelpayment />)}
          </p>
          {/* <span>{capitalizeFirstLetter(transaction?.topUpMethod)}</span> */}
        </div>
      ),
      payoutMethod: `${paymentMethodChecks(transaction)}`,
      dateTime: `${moment(transaction?.createdAt).locale('en').format('DD-MMM-YY hh:mm a')}`,
      amount: (
        <div className='AmountSpanWrapper'>
          <span className={`${isSucceedPayment(transaction) || isRefundPayment(transaction) ? '' : 'amountSpan'}`}>
            {isCreditAmount(transaction) && '+'} {isDebitAmount(transaction) && '-'}
            {!isCreditAmount(transaction) && !isDebitAmount(transaction) && '( '}
            {transaction?.currency ?? '€'} {transaction?.amount} {` `}
            {!isCreditAmount(transaction) && !isDebitAmount(transaction) && ')'}
          </span>
          <span className={`${isSucceedPayment(transaction) || isRefundPayment(transaction) ? '' : 'amountSpan'}`}>
            {isSucceedPayment(transaction) || isRefundPayment(transaction) ? '' : 'Failed'}
          </span>
        </div>),
      }
  });

  return (
    data?.length > 0 ? (
      <div className='TransactionhistoryStyles'>
        {!props?.isTopUpHistory &&  <h3>Transaction History</h3>}
        <Table columns={columns} dataSource={data} pagination={false} />
        {loading && <LoadingSpinner />}
      </div>
    ) : (
      <>
        {loading && <Loader />}
        <EmptyTransactionHistory />
      </>
    )
  );
}

export default Transactionhistory

import {Button, Modal} from 'antd'
import Close from '../../../Icons/Close/Close';
import './ShippingFeeDescripionPopup.scss'

const ShippingFeeDescModal = (props: any) => {

    const shippingInfo = props.shippingInfo.split('\n')

return (
    <Modal title="Shipping" className='themeModal topicsModal shippingFeeModal' width={550} visible={props.modalOpen} onOk={props.onOk} onCancel={props.onCancel} footer={false} centered closeIcon={<Close />}>
<div>
    <h5 className="shipPop-sub-heading">Fast, easy, international</h5>

    <div>
        <h5 className="shipPop-alt-sub-heading">Shipping Fee in the EU</h5>
            <span>{shippingInfo[0]}</span><br/>
            <span>{shippingInfo[1]}</span><br/>
            <span>{shippingInfo[2]}</span><br/>
            <span>{shippingInfo[3]}</span><br/>
            <span>{shippingInfo[4]}</span><br/>
            <span>{shippingInfo[5]}</span><br/>
    </div>

    <div>
        <h5 className="shipPop-alt-sub-heading">Charged once per show</h5>
        <span>The shipping fee will be charged only once in a show, when you pay for the first purchase.</span>
    </div>

    <div className="ship-status-box">
        <h5 className="shipPop-alt-sub-heading">Track shipment status</h5>
        <span>You will be notified when the status of your shipping changes to "Out for Delivery".</span>
    </div>

    <Button className="ant-btn ant-btn-primary btn-full large" onClick={props.onCancel}>Back to the show</Button>
</div>
    </Modal>
)
}

export default ShippingFeeDescModal;

import React, {useCallback, useEffect, useRef, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useNavigate} from 'react-router'
import {Modal, Radio, Input} from 'antd'
import debounce from 'lodash/debounce'

import {Close} from '../../../Icons'
import LoadingSpinner from '../../../CommonComponents/LoadingSpinner'

import {RootState} from '../../../../store'
import {getFollowersList, getFollowingList, resetFollowersFollowingListAction} from '../../../../store/Actions/users';
import {openInNewTab, placeHolderProfileHelper, profilePictureHelper} from '../../../../Utils/helper'

import './FollowingFollowersList.scss'
import EmptyFollowerFollowing from './EmptyFollowerFollowing'
import ProfileBadges from '../../../CommonComponents/ProfileBadges'
import ModBadge from '../../../CommonComponents/ModBadge'

const {Search} = Input;

const FollowingFollowersList = (props: any) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const users = useSelector(
        (state: RootState) => state?.usersReducer
    );

    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(1)
    const [filterBy, setFilterBy] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [usersList, setUsersList] = useState<any[]>([])

    const observer = useRef<any>()

    const fetchMore = useCallback(() => {
        setPage(page + 1);
    }, [page]);


    const lastCardElementRef = useCallback(
        (node) => {

            if (loading) return;

            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setTimeout(() => {

                        fetchMore()
                    }, 2000);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading]
    );

    const onChange = (e: any) => {
        setFilterBy(e.target.value)
    };

    async function fetchUsersList() {
        switch (props.usersListType) {
            case 'follower':
                setLoading(true)
                if (page <= 1 || (page > 1 && users.usersList?.followers?.totalCount > usersList.length)) {
                    await dispatch(getFollowersList(props?.user?.id, page, filterBy, searchTerm))
                }
                break;

            case 'following':
                setLoading(true)
                if (page <= 1 || (page > 1 && users.usersList?.followings?.totalCount > usersList.length)) {
                    await dispatch(getFollowingList(props?.user?.id, page, filterBy, searchTerm))
                }
                break;

            default:
                break;
        }
        setLoading(false);
    }

    async function resetFollowerFollowingList() {
        await dispatch(resetFollowersFollowingListAction())
    }

    useEffect(() => {
        setPage(1)
        resetFollowerFollowingList()
    }, [filterBy, searchTerm])

    useEffect(() => {
        fetchUsersList()
    }, [props.user?.id, page, filterBy, searchTerm]);

    useEffect(() => {
        switch (props.usersListType) {
            case 'follower':
                setUsersList([...users.usersList?.followers?.users ?? []])
                break;

            case 'following':
                setUsersList([...users.usersList?.followings?.users ?? []])
                break;

            default:
                break;
        }

    }, [users.usersList]);

    const onSearch = (searchValue: string) => {
        setSearchTerm(searchValue?.trim())
    }

    const debounceFn = useCallback(debounce(onSearch, 1000), [])

    const onSearchChange = (e: any) => {
        debounceFn(e.target.value)
    }

    const onUserClick = (userId: any, username: string) => {
        if (userId === users?.user?.id) {
            openInNewTab('/my-profile')
        }
        else {
            openInNewTab(`/${username}`)
        }
        // props.handleCancel()
    }

    const UserCard = (props: any) => {
        return (
            <div className='follow-item'
                ref={props.lastElement ? lastCardElementRef : null}
            >
                <div className='follow-left' onClick={() => onUserClick(props?.item?.followerId ?? props?.item?.userId, props?.item?.username)}>
                    <div className='follower-img'>
                        <img src={profilePictureHelper(props?.item?.pic)} alt="" onError={placeHolderProfileHelper} />
                    </div>
                    <div className='follower-name'>
                        <p>{props?.item?.username ?? ''} {props?.item?.admin && <ModBadge />} {props?.item?.host ? <ProfileBadges user={props?.item} /> : null} </p>
                        {props?.item?.host && <span>Host</span>}
                    </div>
                </div>
                <div className='follower-right'>
                    {
                        (props.isMyProfile && props.isFollower) ? (
                            <button
                                onClick={() => {
                                    props.handleRemoveFollower(props?.item?.followerId)
                                }}
                                className='follow-btn following'
                                type='button'>
                                Remove

                            </button>
                        ) : (
                            <button
                                onClick={() => {
                                    props.handleFollow(props?.item?.followerId ?? props?.item?.userId,
                                        users.user?.followedByMeUserIds?.includes(props?.item?.followerId ?? props?.item?.userId,
                                            false))
                                }}
                                className={`follow-btn ${users.user?.followedByMeUserIds?.includes(props?.item?.followerId ?? props?.item?.userId) ? 'following' : ''}`}
                                type='button'>
                                {users.user?.followedByMeUserIds?.includes(props?.item?.followerId ?? props?.item?.userId) ? 'Following' : 'Follow '}

                            </button>
                        )
                    }

                </div>

            </div>
        );
    }

    return (
        <Modal title={props.usersListType === 'follower' ? 'Followers' : 'Following' } zIndex={4} footer={false} className='themeModal followerModal FollowingFollowersList' visible={props.isModalVisible}
            onOk={props.handleOk} onCancel={props.handleCancel} centered closeIcon={<Close />} width={885}>
            <div className='modal-body'>

                <div className='modalFilter'>
                    <div className='filterLeft'>
                        <Radio.Group onChange={onChange} value={filterBy}>
                            <Radio value={1}>All Users</Radio>
                            <Radio value={2}>Hosts</Radio>
                        </Radio.Group>
                    </div>
                    <div className='filterright'>
                        <div className='title-btn'>
                            <Search placeholder="Search"
                                allowClear
                                // onSearch={onSearch}
                                onChange={onSearchChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='followList'>
                    {
                       usersList?.length ? usersList?.map((item: any, index: number) => (
                            <UserCard
                                key={`${index + 1}`}
                                lastElement={index === usersList.length - 1 ? true : false}
                                item={item}
                                handleFollow={props.handleFollow}
                                handleRemoveFollower={props.handleRemoveFollower}
                                isMyProfile={props.isMyProfile}
                                isFollower={props.usersListType === 'follower'}
                            />
                       )) : (
                            <EmptyFollowerFollowing isFollower={props.usersListType === 'follower'} isMyProfile={props.isMyProfile} />
                        )
                    }
                    {loading && <LoadingSpinner />}
                </div>
            </div>
        </Modal>
    );
};

export default FollowingFollowersList
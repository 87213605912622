import React from "react";

function History() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="#0CFDE4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.46"
        clipPath="url(#clip0_15307_2385)"
      >
        <path
          d="M14.757 8A6.666 6.666 0 111.423 8a6.666 6.666 0 1113.334 0z"
          clipRule="evenodd"
        ></path>
        <path d="M10.563 10.12L7.846 8.5V5.006"></path>
      </g>
      <defs>
        <clipPath id="clip0_15307_2385">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default History;
import React from 'react';
import ShowScreenShare from '../../Components/ShowSharing';

const ShowScreenSharing = () => {

  return (
    <>
      <ShowScreenShare />
    </>
  );
}

export default ShowScreenSharing;
import {Modal} from "antd";
import {signInWithPopup, OAuthProvider} from "firebase/auth";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {checkUserExistance, signInWithApple} from "../../../../store/Actions/users";
import {auth} from "../../../../Utils/firebase";
import {Close} from "../../../Icons";
import Loader from "../../../CommonComponents/Loader";
import UserName from "../UserName";
import AppleIcon from './../../../Icons/Apple/Apple';

const Apple = (props: any) => {


    const [userExists, setUserExists] = useState(false)
    const [showUserNameModal, setShowUserNameModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userNameFetched, setUserNameFetched] = useState(false)

    const dispatch = useDispatch()

    const users = useSelector(
        (state: RootState
        ) => state?.usersReducer
    );


    useEffect(() => {
        if (users.userExistance?.isUserExist || users.user?.isUserExists) {
            setUserExists(true)
        }
        else {
            setUserExists(false)
        }
    }, [users.userExistance, users.user?.isUserExists])

    useEffect(() => {
        if (userNameFetched && !loading) {
            if (!userExists && users?.user?.id) {
                setShowUserNameModal(true)
            }
        }
    }, [userExists, userNameFetched, users?.user.id, loading])



    const handleLogin = async () => {
        setLoading(true)
        const provider = new OAuthProvider('apple.com')

        provider.addScope('email');
        provider.addScope('name');

        try {
            const result = await signInWithPopup(auth, provider)

            const user = result.user;

            await dispatch(checkUserExistance({
                email: user.providerData[0].email
            }))
            setUserNameFetched(true)
            const creds = {
                appleId: user.providerData[0].uid,
                email: user.providerData[0].email,
                name: user.providerData[0].displayName,
                pic: user.providerData[0].photoURL,
                app: "collectibles",
                source: "apple"
            }
            await dispatch(signInWithApple(creds))

        } catch (error: any) {            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The credential that was used.
            const credential = OAuthProvider.credentialFromError(error);
            console.log(error.message);
        }
        localStorage.setItem('loggedInFrom', 'Apple')
        props.handleCancel()
        setLoading(false)
    }


    const reset = () => {
        setShowUserNameModal(false)
        setUserExists(true)
    }
    const finish = () => {
        setUserExists(true)
        setShowUserNameModal(false)
    }

    const userNameCancel = () => {
        if (!users.userExistance?.username) return
        finish()
    }

    return (
        <>
            {loading && <Loader />}
            <button type='button' onClick={handleLogin} className='modalbtn transparentbtn'><AppleIcon /> Sign in with Apple</button>
            {showUserNameModal && <Modal title="Almost Done" className={`themeModal ${!users.userExistance?.username ? 'disable-modal-close' : ''}`} width={448} visible={showUserNameModal} onOk={finish} onCancel={userNameCancel} footer={false} centered closeIcon={<Close />}>
                <div className='modal-body'>
                    <p>Choose your username and get started</p>
                    <div className='popupform usernameform'>
                        <UserName isUserName={users?.userExistance?.username} reset={reset} setLoader={setLoading} loader={loading} />
                    </div>
                </div>
            </Modal>}
        </>

    )
}

export default Apple;
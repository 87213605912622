import {Breadcrumb, Col, message, Row} from 'antd'
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Settingsmenu from './Components/SettingsMenu'
import Pagefooter from '../CommonComponents/PageFooter'
import Transactionhistory from './Components/TransactionHistory'
import Orderhistory from './Components/OrderHistory'

import './AccountSettings.scss'
import Settingform from './Components/SettingForm'
import Helpemail from './Components/HelpEmail'
import Faq from './Components/Faq'
import Privacy from './Components/Privacy'
import Termsconditions from './Components/TermsConditions'
import PaymentPage from './Components/Payment';
import {failedItemsAction, getFailedItemsList, openLoginSignUpModal, softDeleteUser} from '../../store/Actions/users';
import {SocketContext} from '../../Context/socket';
import {RootState} from '../../store';
import {displayErrorToast, getChannelURL, sliceArr} from '../../Utils/helper';
import appConfig from '../../Utils/config';
import Loader from '../CommonComponents/Loader';
import {RightArrow} from '../Icons';



function Accountsettings() {
  const {tab} = useParams();
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const io = useContext(SocketContext)

  const [loading, setLoading] = useState(false)

  // socket.io connection
  const [ioConnected, setIoConnected] = useState<boolean>(false)
  const [initializing, setInitializing] = useState<boolean>(false)

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const onDeleteUser = async () => {
    await dispatch(softDeleteUser())
    navigate('/shows')
  }

  async function getUnpaidItems() {
    try {
      // setLoading(true)
      await dispatch(getFailedItemsList(users.user?.id, users.user?.airtable_id))
    } catch (error: any) {
      if (error.response?.data?.message)
        displayErrorToast(error.response?.data?.message)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(users.user?.id) getUnpaidItems()
  }, [users.user?.id])

  useEffect(() => {
    return () => {
      dispatch(failedItemsAction({}, 'RESET'))
    }
  }, [])


  const settingScreen = () => {
    switch (tab) {
      case 'setting':
        return <Settingform />
      case 'payment-method':
        return <PaymentPage onStartSupportChat={onStartSupportChat} />
      case 'transaction-history':
        return <Transactionhistory />
      case 'order-history':
        return <Orderhistory />
      case 'email':
        return <Helpemail />
      case 'faqs':
        return <Faq />
      case 'privacy-policy':
        return <Privacy />
      case 'terms-condition':
        return <Termsconditions />
      default:
        return <Settingform />

    }
  }

  const connectIO = async () => {
    if (ioConnected) {
      return;
    } else {
      await io.connect();
      setIoConnected(true);
      setInitializing(true);
    }
  };

  const disconnectIO = () => {

    if (initializing || ioConnected) {
      io.disconnect();
      setIoConnected(false);
      setIoConnected(true)
    } else {
      //   log('IO already disconnected');
    }
    console.log('disconenctIO', ioConnected)
  };

  const onConnect = async () => {
    if (!ioConnected) {
      await io.connect()
      setIoConnected(true)
    }
  };

  const onConnectError = (err: any) => {
    console.log("onConnectError", err)
    console.log('IO connection error (' + io.id + ' ' + err?.message + ')');
  };

  const onDisconnect = (reason: string) => {
    console.log("onDisconnect", reason)    // log('IO disconnected due to ' + reason + ' (' + this.io.id + ')');
  };

  const onError = (data: any) => {
    console.log('onError', data)
  }

  const onStartSupportChat = (messageStr?: string) => {
    if (ioConnected) {
      if (users?.user?.id) {
        setLoading(true)
        console.log('start support');
        const supportObj = {
          id: appConfig.support,
          name: 'Support',
          pic: getChannelURL('support')
        }

        const subscribers = [supportObj.id, users.user?.id]

        const profile_pics = {
          [users.user?.id]: users.user?.pic,
          [supportObj.id]: supportObj.pic
        }

        const usernames = {
          [users.user?.id]: String(users.user?.username),
          [supportObj.id]: 'Support'
        }

        const receiverId = sliceArr(subscribers, users.user?.id.toString())[0];
        const displayName = usernames[receiverId];

        const channel = {
          id: `${subscribers[0]}:${subscribers[1]}`,
          time: new Date(),
          title: 'Support',
          type: 'private',
          subscribers,
          profile_pics,
          usernames,
          username: displayName,
          message: {
            time: new Date()
          },
        }
        // io.emit('chatNewChannel', channel)
        setLoading(false)
        if (messageStr?.length) {
          navigate('/chat', {state: {channel: {...channel, admin: true}, message: messageStr}});
        }
        else {
          navigate('/chat', {state: {channel: {...channel, admin: true}}});
        }
      }
      else {
        dispatch(openLoginSignUpModal(true))
      }
    }
    else {
     displayErrorToast(`Please send us an email to support@letsgavel.com`)
    }
  }

  // If socket.io disconnects reconnect
  useEffect(() => {
    connectIO();
    console.log('finished connecting to socket.io?', io.connected)
  }, [!ioConnected])

  useEffect(() => {
    console.log({users, username: users.user?.id, initializing, ioConnected, cond: (users.isGuestUser || users.user?.username) && initializing && ioConnected});

    if ((users.isGuestUser || users.user?.username) && initializing && ioConnected) {
      setLoading(true)

      io.on('connect', onConnect);
      io.on('connect_error', onConnectError);
      io.on('disconnect', onDisconnect);
      io.on('error', onError);
      setLoading(false)

      if (!users.user?.username) {
        disconnectIO()
      }
      console.log('get messages useEffect', io.connected)
    }
  }, [io.connected, users.isGuestUser, users.user])

  return (
    <div className="Editprofile">
     {/* <div className='Breadcrumpdiv'>
        <div className='container'>
          <Breadcrumb separator={<RightArrow />}>

            <Breadcrumb.Item>
              <a href="/my-profile">Profile</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div> */}
      {loading && <Loader />}
      <div className='EditeProfile-block'>
        <div className='container'>
          <Row gutter={32}>
            <Col xs={24} sm={10} lg={6}>
              <Settingsmenu onDeleteUser={() => onDeleteUser()} onStartSupportChat={() => onStartSupportChat()} />
            </Col>
            <Col xs={24} sm={14} lg={18}>
              {settingScreen()}
            </Col>
          </Row>
        </div>
      </div>
      <Pagefooter />
    </div>

  )
}

export default Accountsettings

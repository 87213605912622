
const slideToShow = 4;
const slideToScroll = 1;
export const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  draggable: true,
  slidesToShow: slideToShow,
  slidesToScroll: slideToScroll,
  arrows: true,
  adaptiveHeight: true,


  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const passwordRegex = /^(?=.*?[A-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*./-]).{5,}$/

export const dateFormat = 'YYYY-MM-DD';

// Please fill in this field.
export const requiredFieldMsg = 'Bitte füllen Sie dieses Feld aus.'

// Please enter an email address.
export const requiredEmailMsg = 'Bitte geben Sie eine E-mail-Adresse ein.'

export const messageStrings = {
  errorMessage: {
    emailNotValid: 'Please enter minimum 5 characters including 1 digit or 1 special character',

  }
}
export const gavelRetool= "https://gavel.retool.com/embedded/public/b23a36c5-56de-42c1-b69d-0d0e152f3d0a#"
export const game_show = "game_show"
export const GamesTag = '#Games'

export const GrowthBookFeatures = {
  flagPaymentApplePay: 'payment_applepay',
  flagPaymentEPS: 'payment_eps',
  flagPaymentGiroPay: 'payment_giropay',
  flagPaymentGooglePay: 'payment_googlepay',
  flagPaymentPaypal: 'payment_paypal',
  flagPaymentStripePaypal: 'payment_stripe_paypal'
}

export const strings = {
// Un used

   //analytics
   trackChangePassword: 'Change Password success',
   trackResetPassword: 'Reset Password success',
   trackSetUserNameSuccess: 'onboarding_username',
   trackOnBoardingGuest: 'onboarding_guest',
   trackOnBoardingLogin: 'onboarding_login',
   trackOnBoardingRegister: 'onboarding_register',
   trackTypeValidateUser: 'signup step-1 with email',

   trackOnboardingOTP: 'onboarding_otp',
   trackOnBoardingStart: 'onboarding_start',
   trackOnBoardingDone: 'onboarding_done',
   trackOnboardingFailed: 'onboarding_failed',
   trackLogoutFailed: 'logout_failed',
   trackDeleteUserFailed: 'delete_user_failed',
   trackShowLeft: 'Show Left',

   //analytics
   trackTypeEmail: 'email',
   trackTypeGuest: 'Guest',
   trackTypePhone: 'phone',
   trackTypeGoogle: 'google',
   trackTypeApple: 'apple',

   //normal

   shows: 'Shows',
   live: 'live',
   home: 'Home',
   currencyEuro: '€',
   euroText: 'EUR',
   poundText: 'GBP',
   firstPaymentSuccess: 'first_payment_success',
   show: 'Show',
   addAddress: 'Add address',
   invalidAddress: 'Your address could not be verified. Please check your address again to make sure your order can be delivered',
   partialValidAddress:
   'Your address is not found and could be improved. Please add any missing information',
   addCreditCardScreen: 'addCreditCardScreen',

   // wallet events names
    walletScreenViewed: 'wallet_screen_viewed',
    walletTopupSuccess: 'wallet_topup_success',
    walletTopupFail: 'wallet_topup_fail',
    walletCardAdded: 'wallet_card_added',
    addressScreenViewed: 'address_screen_viewed',
    addressAddedSuccess: 'address_added_success',
    addressAddedFail: 'address_added_fail',
    addTopupButtonClicked: 'add_topup_button_clicked',
    addCardButtonClicked: 'add_card_button_clicked',
    walletBadgeClicked: 'wallet_badge_clicked',
    // cardLimitPopupViewed: 'card_limit_popup_viewed',
    // completePaymentButtonClicked: 'complete_payment_button_clicked',
    bidClicked: 'bid_clicked',
    bidFailed: 'bid_failed',
    paymentFail: 'payment_fail',
    paymentSuccessEvent: 'payment_success',

    //Payment wallet and Address
    topUpLabel: 'Top up',
    googlePay: 'googlepay',
    applePay: 'applepay',
    payWith: 'Pay with ',
    giroPay: 'giropay',
    eps: 'eps',
    paypal: 'paypal',
    paymentWalletAndAddress: 'Wallet & addresses',


  //Other User Profile More Data For
  paymentWallets: 'Wallet & addresses',

  // Order History
  // lastUpdatedAt: 'Last update: ',
  // orderedStatus: 'Ordered',
  // inDeliveryStatus: 'In Delivery',
  // inDeliveryStatusValue: 'Out for delivery',
  // deliveredStatus: 'Delivered',
  topUpBalance: 'Top up balance',

  // Screen names
  showScreen: 'Show Screen',
  authScreen: 'Auth',
  signInScreen: 'SignIn Screen',
  signUpScreen: 'SignUp Screen',
  profileScreen: 'Profile',
  messagesScreen: 'All Messages',

  // MParticle events
  showListScreenViewed: 'show_list_screen_viewed',
  showEntered: 'show_entered',
  firstShowEntered: 'first_show_entered',
  showWatched: 'show_watched',
  firstShowWatched: 'first_show_watched',
  showLeft: 'show_left',
  itemViewed: 'item_viewed',
  bidPlaced: 'bid_placed',
  firstBidPlaced: 'first_bid_placed',
  firstPurchase: 'first_purchase',
  userFollow: 'user_follow',
  userUnfollow: 'user_unfollow',
  discoverScreenViewed: 'discover_screen_viewed',
  discordButtonClicked: 'discord_button_clicked',
  hostApplicationButtonClicked: 'host_application_button_clicked',
  signInSuccess: 'sign_in_success',
  authFail: 'auth_fail',
  registrationSuccess: 'registration_success',
  registrationFail: 'registration_fail',
  profileScreenView: 'profile_screen_view',
  giveAwayStarted: 'give_away_started',
  giveAwayWon: 'give_away_won',
  chatMessageSent: 'chat_message_sent',
  privateMessageScreenView: 'private_message_screen_view',
  privateMessageChatOpen: 'private_message_chat_open',
  privateMessageSent: 'private_message_sent',
  authMethodSelected: 'auth_method_selected',
  guestSelected: 'guest_selected',
  showReminderActivated: 'show_reminder_activated',
  showReminderDeactivated: 'show_reminder_deactivated',
  accountCreatedSuccess: 'account_created_success',
  showRedSetupWallet: 'Show_Red_setup_wallet',
  showRedSetupCard: 'Show_Red_setup_card',
  showRedSetupTopup: 'Show_Red_setup_topup',
  showRedSetupAddress: 'Show_Red_setup_address',
  showYellowSetupTopup: 'Show_Yellow_setup_topup',
  showGreenSetupTopup: 'Show_Green_add_topup',
  showLive: 'show_live',

// MParticle types
  guest: 'Guest',
  existing: 'Existing',
  newUser: 'New',
  fixedPrice: 'Fixed price',
  auction: 'Auction',
  unexpectedError: 'unexpected_error',
}

import React from "react";

function Rightbannershape() {
  return (
    <svg width="351" height="248" viewBox="0 0 351 248" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.5838 2.5031C25.5838 2.5031 29.7881 56.8805 147.393 74.5293C264.998 92.1781 267.287 184.438 356.272 153.478C445.256 122.517 451.963 -136.815 303.408 -115.882C154.843 -94.9713 18.0143 -110.641 25.5838 2.5031V2.5031Z" fill="#B2A8EE"/>
    </svg>


  );
}

export default Rightbannershape;
import React from 'react'


import './Privacy.scss'
import { privacyData } from './privacyList'


function Privacy() {


  return (

    <div className="EditProfile-details ">
      <h3 >Privacy Policy</h3>
      <p>As an European company in the heart of the EU, we are fully dedicated to the privacy and security of your data! Feel free to send us all your questions to privacy@letsgavel.com</p>
      <div className='privacy-content'>
          {
            privacyData.map((policy: any, index: number) => (
            <div className='privacy-box' key={index} >
              <h5 dangerouslySetInnerHTML={{__html: policy.title}} />
              {policy.text.map((paragraph:string, index: number) => (
                <p dangerouslySetInnerHTML={{ __html: paragraph}} key={index} />
              ))}
            </div>
            ))
          }
      </div>
    </div>

  )
}

export default Privacy

/* eslint-disable @typescript-eslint/no-empty-interface */
import React, {useEffect, createContext, FunctionComponent} from 'react';
import Header from "../CommonComponents/Header"

import {useNavigate} from 'react-router';

interface AuthUserInterface {

}

const AuthUser = createContext<AuthUserInterface | null>(null);
interface AuthUserProviderProps {
}
const AuthUserProvider: FunctionComponent<AuthUserProviderProps> = ({
  children,
}) => {

  const navigate = useNavigate()
  const hasAccess = localStorage.getItem('access-token');

  useEffect(() => {
    if (!hasAccess) navigate('/shows', {replace: true})
  }, [])

  return (
    <>
      {<AuthUser.Provider
        value={{}}
      >
        <Header/>
        <div className='app'>{children}</div>
      </AuthUser.Provider>}

    </>
  );
};

export { AuthUserProvider as default, AuthUser };


// interface MyComponentPropsType{
//   location: any,
//   match: any,
//   history: any,
//   children: React.ReactNode
// }
// export default function(ComposedComponent:any) {
//     // class Authentication extends Component {


//     //     componentWillMount() {

//     //     }



//     //     render() {

//     //     }
//     // }

//     // function mapStateToProps() {
//     //     return { };
//     // }

//     // return connect(mapStateToProps)(Authentication);
// }
import {Modal} from 'antd';
import React from 'react'
import {Close} from '../../../../Icons';
import Transactionhistory from '../../TransactionHistory';
import '../Payment.scss';

type Props = {
    isModalVisible: boolean;
    handleOk: any;
    handleCancel: any;
}

const TopUpHistoryModal = (props: Props) => {
    return (
        <Modal title="Top-up history" className='themeModal AddNewCard TopUpHistoryModalStyle' centered closeIcon={<Close />} width={830} visible={props.isModalVisible} onOk={props.handleOk} footer={false} onCancel={props.handleCancel}>
            <Transactionhistory isTopUpHistory />
        </Modal>
    );
}

export default TopUpHistoryModal
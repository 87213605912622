/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Button, Collapse } from 'antd';
import './Faq.scss'
const { Panel } = Collapse;

function Faq() {
  return (

    <div className="EditProfile-details ">
      <h3 >FAQ's</h3>
      <div className='collaps-accordian'>
        <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition={'right'}>
          <Panel header="How can I help you?" key="1">
            <div className='faq-texts'>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              <Button type='primary' className='transparent'>Learn more</Button>
            </div>
          </Panel>
          <Panel header="How can I help you?" key="2">
          <div className='faq-texts'>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              <Button type='primary' className='transparent'>Learn more</Button>
            </div>
          </Panel>
          <Panel header="How can I help you?" key="3">
          <div className='faq-texts'>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              <Button type='primary' className='transparent'>Learn more</Button>
            </div>
          </Panel>
          <Panel header="How can I help you?" key="4">
          <div className='faq-texts'>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              <Button type='primary' className='transparent'>Learn more</Button>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>

  )
}

export default Faq

import React from 'react';
import ChatScreen from '../../Components/ChatScreen/ChatScreen';

const ChatPage = () => {
    return (
        <>
        <ChatScreen />
        </>
    )
}

export default ChatPage;
import * as appboy from "@braze/web-sdk";

export const notificationPermission = (id: any) => {
    let userOS = detectClientOS()
    if (userOS === 'unknown') {
        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted" && id !== "guest") {
            // If it's okay let's create a notification
            setServiceWorkerForPushNotification(id)
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted" && id !== "guest") {
                    setServiceWorkerForPushNotification(id)
                }
            });

        }
    }

}

export const setServiceWorkerForPushNotification = (id: any) => {
    console.log(id);
    const userOS = detectClientOS()
    if (userOS === 'unknown') {
        appboy.initialize('6adc5852-0bb5-424f-bcd9-d2d9bea1f0cc', { baseUrl: 'https://rest.fra-01.braze.eu', enableLogging: true,safariWebsitePushId:'web.com.gavel.safari'});
        appboy.changeUser(id);
        appboy.automaticallyShowInAppMessages();
        appboy.openSession();
        console.log(appboy.isPushPermissionGranted(),appboy.isPushSupported(),appboy.isPushBlocked());
    }
}

//detect scanned device OS
const detectClientOS = () => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
    }
    if (/android/i.test(userAgent)) {
        return 'Android'
    }
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
    }
    return 'unknown'
}
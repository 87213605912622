import React from "react";

function Delete() {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 4.25098H13" stroke="#ECF3FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.75 4.25098L2.5 13.251C2.5 13.6488 2.65804 14.0304 2.93934 14.3117C3.22065 14.593 3.60218 14.751 4.00001 14.751H10C10.3979 14.751 10.7794 14.593 11.0607 14.3117C11.342 14.0304 11.5 13.6488 11.5 13.251L12.25 4.25098" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.75 4.25099V2.00098C4.75 1.80207 4.82902 1.6113 4.96967 1.47065C5.11032 1.32999 5.30109 1.25098 5.5 1.25098H8.50001C8.69893 1.25098 8.88969 1.32999 9.03034 1.47065C9.171 1.6113 9.25002 1.80207 9.25002 2.00098V4.25099" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
}

export default Delete;
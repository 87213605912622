import React from 'react';
import {images} from '../../../../Assets/ImageConfig/images';
import './Orderhistory.scss'


function EmptyOrderHistory() {

  return (

    <div className='No_massge_wrapper'>
      <div className='No_massge_img'>
        <img src={images.Noordersyet} alt="" />
        <div className='Nomassge_content'>
          <h4>No orders yet</h4>
          <p>This is a record of all your final orders from live shows. Each time a show ends, all your transactions will be grouped into an order and a shipping label will be created. </p>
        </div>
      </div>
    </div>

  )
}

export default EmptyOrderHistory

import React, {useEffect, useState} from 'react';
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Form, message, Radio, Row, Select} from 'antd'
import {images} from '../../../../Assets/ImageConfig/images'
import {History, Delete, Edit, Tick} from '../../../Icons'
import type {RadioChangeEvent} from 'antd';
import NewcardModal from '../NewcardModal/index'
import './Payment.scss';
import TopUpModal from '../TopUpModal';
import ShippingAddModal from '../ShippingAddModal';
import UnsuccessfulPaymentModal from '../UnsuccessfulPaymentModal';
import {RootState} from '../../../../store';
import {deleteSavedCards, deleteShippingAddress, fetchShippingAddress, fetchUserDetails, getFailedItemsList, updateSavedCards, updateShippingAddress} from '../../../../store/Actions/users';
import LoadingSpinner from '../../../CommonComponents/LoadingSpinner';
import DeleteAddressModal from './DeleteAddressModal';
import DeleteCardModal from './DeleteCardModal';
import TopUpHistoryModal from './TopUpHistoryModal';

import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import {displayErrorToast, displayInfoToast, retryPaymentAPI} from '../../../../Utils/helper';
import Loader from '../../../CommonComponents/Loader';
import {add_card_button_clicked, add_topup_button_clicked, wallet_screen_viewed, wallet_topup_fail, wallet_topup_success} from '../../../../Utils/mParticle';
import {strings} from '../../../../Utils/constants';

const CARD_OPTIONS = {
  style: {
    base: {
      fontFamily: `"Inter", sans-serif`,
      letterSpacing: '0.8px',
      '::placeholder': {
        color: '#959798',
      },
    },
  },
};

let toastDisplayed: boolean = false;

const PaymentPage = (props: any) => {

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  let stripePromise
  if (users.user.publishableKey) {
    console.log("here")
    stripePromise = loadStripe(users.user.publishableKey);
  }

  return (<>
    {stripePromise ? <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Inter&display=swap',
          },
        ],
      }}
    >
      <ElementsConsumer>
        {({stripe, elements}) => (
          <div >
            <Payment stripe={stripe} elements={elements} {...props} />
          </div>

        )}
      </ElementsConsumer>
    </Elements > : <Payment {...props} />
    }
  </>
  )
}
const Payment = (props: any) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()


  // const users = useSelector(
  //   (state: RootState) => state?.usersReducer
  // );

  const [loading, setLoading] = useState(false)

  // const [visible, setVisible] = useState(true)
  const [isAddnewcard, setIsAddnewcard] = useState(false);
  const [isTopupModal, setIsTopupModal] = useState(false);
  const [isShippingAddress, setIsShippingAddress] = useState(false);
  const [isUnsuccesssfulpayment, setIsUnsuccesssfulpayment] = useState(false);
  const [isTopUpHistory, setIsTopUpHistory] = useState(false)

  const [shippingAddresses, setShippingAddresses] = useState<any>([])
  const [selectedAddress, setSelectedAddress] = useState<any>(null)

  const [showDeleteAddressModal, setDeleteAddressModal] = useState(false)
  const [showDeleteCardModal, setDeleteCardModal] = useState(false)

  const [selectedCard, setSelectedCard] = useState<any>(null)
  const [totalBalance, setTotalBalance] = useState(0)
  const [isRetrying, setIsRetrying] = useState(false)

  const {stripe, elements} = props;
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const handleOk = () => {
    setIsAddnewcard(false);
    setIsTopupModal(false);
    setIsShippingAddress(false);
    setIsUnsuccesssfulpayment(false);
    setSelectedAddress(null)
    setIsTopUpHistory(false)
  };

  const handleCancel = () => {
    setIsAddnewcard(false);
    // setIsTopupModal(false);
    setIsShippingAddress(false);
    setIsUnsuccesssfulpayment(false);
    setSelectedAddress(null)
    setIsTopUpHistory(false)
  };

  const Addnewcard = () => {
    add_card_button_clicked(users.user);
    setIsAddnewcard(true);
  };
  const TopupModal = () => {
    add_topup_button_clicked(users.user);
    setIsTopupModal(true);
  };
  const ShippingAddress = () => {
    setIsShippingAddress(true);
  };

  const closeTopUpModal = async () => {
    setIsTopupModal(false);
    await dispatch(fetchUserDetails())
  }

  const showTopUpHistory = () => {
    setIsTopUpHistory(true)
  }

  const onDeleteAddressCancel = () => {
    setSelectedAddress(null)
    setDeleteAddressModal(false)
  }

  const onDeleteCardCancel = () => {
    setSelectedCard(null)
    setDeleteCardModal(false)
  }

  const openTopUpModal = () => {
    handleCancel()
    TopupModal()
  }

  const openCardModal = () => {
    closeTopUpModal()
    Addnewcard()
  }

  const onFundWithdrawal = () => {
    props?.onStartSupportChat("Hi there, I'd like to withdraw the funds I have in my pre-paid balance")
  }


  async function getShippingAddresses() {
    try {
      setLoading(true)
      await dispatch(fetchShippingAddress())
    } catch (error: any) {
      if (error.response?.data?.message)
        displayErrorToast(error.response?.data?.message)
    }
    finally {
      setLoading(false)
    }
  }

  const onDefaultCardChange = async (e: RadioChangeEvent) => {
    try {
      const cardId = e.target.value
      await dispatch(updateSavedCards(cardId))
    } catch (error: any) {
      console.log({error});
      if (error.response?.data?.message)
        displayErrorToast(error.response?.data?.message)
      if (error.response?.data?.error)
        error.response?.data?.error.forEach((error: any) => {
          displayErrorToast(error.message)
        })
    }
  }

  const onDefaultAddressChange = async (e: RadioChangeEvent) => {
    try {
      const addressId = e.target.value
      const address = shippingAddresses?.find((address: any) => address?.id === addressId)
      const {uuid, ...newAddress} = address;
      await dispatch(updateShippingAddress({...newAddress, isDefault: !newAddress.isDefault}))
      getShippingAddresses()
    } catch (error: any) {
      console.log({error});
      if (error.response?.data?.message)
        displayErrorToast(error.response?.data?.message)
      if (error.response?.data?.error)
        error.response?.data?.error.forEach((error: any) => {
          displayErrorToast(error.message)
        })
    }
  }

  const onDeleteAddress = async () => {
    try {
      if (!selectedAddress) return

      await dispatch(deleteShippingAddress(selectedAddress?.id))
      getShippingAddresses()
    } catch (error: any) {
      console.log({error});
      if (error.response?.data?.message)
       displayErrorToast(error.response?.data?.message)
      if (error.response?.data?.error)
        error.response?.data?.error.forEach((error: any) => {
          displayErrorToast(error.message)
        })
    }
    finally {
      onDeleteAddressCancel()
    }
  }

  const onDeleteCard = async () => {
    try {
      if (!selectedCard) return
      setDeleteCardModal(false)
      await dispatch(deleteSavedCards(selectedCard?.id))
    } catch (error: any) {
      console.log({error});
      if (error.response?.data?.message)
       displayErrorToast(error.response?.data?.message)
      if (error.response?.data?.error)
        error.response?.data?.error.forEach((error: any) => {
          displayErrorToast(error.message)
        })
    }
    finally {
      onDeleteCardCancel()
    }
  }

  const confirmPayment = async (clientSecret: string, paymentMethod: string) => {
    if (clientSecret && paymentMethod) {
      stripe.confirmCardPayment(clientSecret, {payment_method: paymentMethod}).then((result: any) => {
        if (result.paymentIntent) {
          displayInfoToast('Payment successfully done!');
          setTimeout(() => {
            dispatch(getFailedItemsList(users.user?.id, users.user?.airtable_id))
          }, 1000);
          setIsRetrying(false)
        }
        else if (result.error) {
          message.error({icon: '💥', content: 'Payment Failed'})
          setIsRetrying(false)
        }
      })
    }
  }

  const onRetryPayment = async() => {
    try {
      const response = await retryPaymentAPI();
      if (response?.success) {
       displayInfoToast('Payment successfully done!');
        await dispatch(fetchUserDetails())
        await dispatch(getFailedItemsList(users.user?.id, users.user?.airtable_id))
        setIsRetrying(false)
      }
      else if (response?.success === false && response?.clientSecret) {
        confirmPayment(response?.clientSecret, response?.paymentMethodId)
      }
      else {
        message.error({icon: '💥', content: response?.errMsg ?? 'Payment Failed'})
        setIsRetrying(false)
      }
    } catch (error) {
      console.log({error});

    }
  }

  const isRetryValid = () => {
    setIsRetrying(true)
    if (users.user?.savedCardCount === 0 && users.user?.walletAmount && users.user?.walletAmount < totalBalance) {
      displayErrorToast('Insufficient wallet balance. Please top up your balance or save a credit card to complete the payment')
      setIsRetrying(false)
    }
    else {
      onRetryPayment()
    }
  }

  useEffect(() => {
      wallet_screen_viewed( users.user, strings.paymentWallets, `${strings.profileScreen}_${strings.paymentWallets}`);
  }, []);

  useEffect(() => {

    if (!toastDisplayed && searchParams.has('redirect_status')) {
      const redirectStatus = searchParams.get('redirect_status')
      const redirectAmount = searchParams.get('amount')
      const redirectgateway = searchParams.get('gateway')

      if (redirectStatus === 'succeeded') {
        displayInfoToast(`Your balance top-up was successful!`)
        wallet_topup_success(users.user, redirectgateway, Number(redirectAmount));
        navigate({
          search: ''
        }, {replace: true})
      }
      else if (redirectStatus === 'failed') {
        displayErrorToast(`We couldn't add money to your pre-paid balance. Try once again! We are unable to authenticate your payment method. Please choose a different payment method and try again..`)
        wallet_topup_fail(users.user,redirectgateway, Number(redirectAmount), 'API', `We couldn't add money to your pre-paid balance. Try once again!`, 'PAYMENT');
        navigate({
          search: ''
        }, {replace: true})
      }
      toastDisplayed = true;
    }

  }, [searchParams, toastDisplayed])

  useEffect(() => {
    if(users.user?.id) {
      getShippingAddresses()
    }
  }, [users.user?.id])


  useEffect(() => {

    return () => {
      toastDisplayed = false
    }
  }, [])

  useEffect(() => {
    setShippingAddresses(users.addressesList)
  }, [users.addressesList])

  useEffect(() => {
    if (users?.failedItemsList?.failedItems) {
      let totalAllFailedItems: number = 0;
      for(let i = 0; i < users?.failedItemsList?.failedItems.length; i++) {
        if (users?.failedItemsList?.failedItems[i]?.paymentStatus === 'unpaid' || users?.failedItemsList?.failedItems[i]?.paymentStatus === 'failed') {
          totalAllFailedItems += users?.failedItemsList?.failedItems[i]?.price
        }
      }
      const total = (totalAllFailedItems || 0) + Number.parseFloat(users.failedItemsList?.failedItems?.[0]?.shippingCost || 0) + Number.parseFloat(users?.failedItemsList?.shippingUpgradeData?.price || 0)
      setTotalBalance(Number(total?.toFixed(2)))
    }
  }, [users.failedItemsList?.failedItems])


  return (
    <>
      {isRetrying && <Loader />}
      <div className='PaymentPageStyle'>
        <div className='SavedCards_wrapper'>
          {!!users?.failedItemsList?.failedItems?.length || !!users?.failedItemsList?.shippingUpgradeData?.price ? (
          <div className='Outstandingpayment'>
            <div className='OutstandingText-left'>
              <div className='text'>{!!users?.failedItemsList?.shippingUpgradeData?.price ? `Your order needs a bigger box! So you need to pay an additional
              ${users?.failedItemsList?.failedItems?.[0]?.hostCurrency || '€'}${users?.failedItemsList?.shippingUpgradeData?.price}` : "We could not complete your payment"}</div>
              <p>Choose another card or top up your balance and complete the transaction.</p>
            </div>
            <div className='OutstandingText-right'>
              <div className='Outstanding'>
                <div className='OutstandingText'>Outstanding payment: <span>{totalBalance} {users?.failedItemsList?.failedItems?.[0]?.hostCurrency || '€'}</span></div>
                <div className='Outstandingsubtext'>{!!users?.failedItemsList?.shippingUpgradeData?.price ? "Upgraded Shipping Cost" : "Item price + Shipping Charge"}</div>
              </div>
              <Button type='ghost' disabled={isRetrying} onClick={isRetryValid}>Complete payment</Button>
            </div>
          </div>
          ) : null}
          <div className='Card_Block'>
            <div className='Card_Header'>
              <div className='Header_left'>
                <div className='Card_title'>Saved cards</div>
                <div className='Card_logo'>
                  <img src={images.visacard} alt="visa card" />
                  <img src={images.mastercard} alt="master card" />
                </div>
              </div>
              {
                users.user?.cardList?.length >= 1 && (
                  <div className='Header_right'>
                    <Button type='ghost' onClick={Addnewcard}>+ Add New</Button>
                  </div>
                )
              }
            </div>
            <div className='Card_detail_wrapper'>
              <div className='cardList'>
                {users.loader ? <LoadingSpinner /> : (
                  <>
                        {
                          users.user?.cardList?.length > 0 ? (
                            <Radio.Group onChange={onDefaultCardChange} value={users.user?.cardList?.find((card: any) => card?.isPrimary)?.id}>
                           { users?.user?.cardList.map((card: any) => {
                              return (
                                <Radio value={card?.id} key={card?.id} checked={card?.isPrimary}>
                                  <div className={`savedcard ${card?.cardType === 'mastercard' ? 'mastercard' : ''} ${card?.isPrimary && 'activeCard'}`}>
                                    <div className='Img_Primary'>
                                      {card?.cardType === 'visa' && <img className='CardImg' src={images.visa} alt="visa" />}
                                      {card?.cardType === 'mastercard' && <img className='CardImg' src={images.mastercard} alt="mastercard" />}
                                      <div className='Primary'>Primary</div>
                                    </div>
                                    <div className='Cardnumber'>****  {card.cardNumber}</div>
                                    <div className='CardNameDelet'>
                                      <div className='Cardname'> <span>{card.expMonth}/{card.expYear}</span></div>
                                      <Button type='link' className='DeletBtn' onClick={() => {
                                        setSelectedCard(card)
                                        setDeleteCardModal(true)
                                      }}><Delete /></Button>
                                    </div>
                                  </div>
                                </Radio>
                              )
                            })}
                        </Radio.Group>
                          ) : (
                            <div className='Card_detail'>
                              <p>Save your credit card details and use it to pay for all your purchases in GAVEL live shows.</p>
                              <Button type='ghost' onClick={Addnewcard}>Add a new card</Button>
                            </div>
                          )
                        }
                      </>
                )}
              </div>
            </div>
          </div>

          <div className='Card_Block'>
            <div className='Card_Header'>
              <div className='Header_left'>
                <div className='Card_title'>Pre-paid balance</div>
                <div className='Card_logo'>
                  {/* <img src={images.otherpayment3} alt="Payment" /> */}
                  <img src={images.otherpayment1} alt="Payment" />
                  {/* <img src={images.otherpayment2} alt="Payment" /> */}
                  <img className='giropay' src={images.otherpayment4} alt="Payment" />
                  <img src={images.otherpayment5} alt="Payment" />
                </div>
              </div>
              <div className='Header_right'><Button type='ghost' onClick={showTopUpHistory} > <History />History</Button></div>
            </div>
            <div className='Card_detail_wrapper'>
              <div className='Card_detail'>
                <p><span>€{users?.user?.walletAmount}</span>Available Balance</p>
                <Button type='ghost' onClick={TopupModal}>Top up</Button>
              </div>
              <div className='fundwithdrawal'>
                <Button type='link' disabled={users?.user?.walletAmount == 0} onClick={onFundWithdrawal} className='RequestfundBtn'>Request fund withdrawal</Button>
              </div>
            </div>
          </div>

          <div className='Card_Block'>
            <div className='Card_Header'>
              <div className='Header_left'>
                <div className='Card_title'>Shipping Address</div>
              </div>
              {shippingAddresses.length >= 1 && (
                <div className='Header_right'>
                  <Button type='ghost' onClick={ShippingAddress}>+ Add New</Button>
                </div>
              )}
            </div>
            <div className='Card_detail_wrapper'>
              {
                loading ? <LoadingSpinner /> : (
                  <>
                    {
                      shippingAddresses.length > 0 ? (
                        <div className='ShippingAddressList'>
                          <Radio.Group onChange={onDefaultAddressChange} value={shippingAddresses?.find((address: any) => address?.isDefault)?.id}>
                            {
                              shippingAddresses.map((address: any) => (
                                <Radio value={address?.id} key={address?.id} checked={address?.isDefault}>
                                  <div className='ShippingAddressContent'>
                                    <div className='addressHeding'>
                                      <div className='title'>{address?.fullName}</div>
                                      <div className='Default'>Primary</div>
                                    </div>
                                    <div className='AddressText'>
                                      {address?.addressLine1} {address?.houseNumber}, {address?.postalCode}, {address?.addressLine2} {address?.city} {address?.country}
                                    </div>
                                    <div className='ValidatedAddress'>
                                      {address?.isValid ? (
                                        <div className='Validated'><span><img src={images.RightClick} alt="RightClick" /></span>Validated Address</div>) : (
                                        <div className='Validated red'><span><img src={images.infoicon} alt="infoicon" /></span>Unverified Address</div>
                                      )}
                                      <div className='EditDeleteBtn'>
                                        <Button type='link' className='DeletBtn' onClick={() => {
                                          setSelectedAddress(address)
                                          setDeleteAddressModal(true)
                                        }}><Delete /></Button>
                                        <Button type='link' className='DeletBtn' onClick={() => {
                                          setSelectedAddress(address)
                                          ShippingAddress()
                                        }}><Edit /></Button>
                                      </div>
                                    </div>
                                  </div>
                                </Radio>
                              ))
                            }
                          </Radio.Group>
                        </div>
                      ) : (
                        <div className='Card_detail'>
                          <p>Add the address where your purchases will be sent to.</p>
                          <Button type='ghost' onClick={ShippingAddress}>Add</Button>
                        </div>
                      )
                    }

                  </>
                )
              }

            </div>
          </div>


        </div>
      </div>

      {/* Add a new card */}
      {
        isAddnewcard && <NewcardModal isAddnewcard={isAddnewcard} isShowPage={false} handleOk={handleOk} handleCancel={handleCancel} openTopUpModal={openTopUpModal} stripe={stripe} elements={elements} />
      }

      {/* Top up */}

      {
        isTopupModal && <TopUpModal isTopupModal={isTopupModal} isShowPage={false} handleOk={handleOk} handleCancel={closeTopUpModal} openCardModal={openCardModal} stripe={stripe} elements={elements} />
      }


      {/* Shipping Address */}
      {
        isShippingAddress && <ShippingAddModal isShippingAddress={isShippingAddress} getShippingAddresses={getShippingAddresses} handleOk={handleOk} handleCancel={handleCancel} address={selectedAddress} />
      }

      {/* Unsuccesssful payment */}
      {
        isUnsuccesssfulpayment && <UnsuccessfulPaymentModal isUnsuccesssfulpayment={isUnsuccesssfulpayment} handleOk={handleOk} handleCancel={handleCancel} onRetryPayment={() => {
          setIsUnsuccesssfulpayment(false)
          isRetryValid()
        }} title='Payment failed again' description={`We couldn't complete your payment again. Please retry using a different payment method or contact our customer support.`} onSupportChat={props?.onStartSupportChat} />
      }

      {
        showDeleteAddressModal && <DeleteAddressModal isModalVisible={showDeleteAddressModal} handleOk={onDeleteAddress} handleCancel={onDeleteAddressCancel} onOkClick={onDeleteAddress} onCancelClick={onDeleteAddressCancel} />
      }

      {
        showDeleteCardModal && <DeleteCardModal isModalVisible={showDeleteCardModal} handleOk={onDeleteCard}
          handleCancel={onDeleteCardCancel} onOkClick={onDeleteCard} onCancelClick={onDeleteCardCancel}
          cardNumber={selectedCard?.cardNumber} />
      }

      {
        isTopUpHistory && <TopUpHistoryModal isModalVisible={isTopUpHistory} handleOk={handleOk} handleCancel={handleCancel}  />
      }
    </>
  );
}

export default PaymentPage
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from "react-router-dom";
import {RootState} from "../../store"
import ShowLayout from "../../Components/ShowLayout"
import Loader from "../../Components/CommonComponents/Loader"
import Pagefooter from "../../Components/CommonComponents/PageFooter";
import {getAllShows, resetAllShows} from './../../store/Actions/showDashboard';
import _ from "lodash";
import {Helmet} from "react-helmet";
import {goToTop} from "../../Utils/helper";
import {screen_viewed, show_list_screen_viewed} from "../../Utils/mParticle";

const ShowDashBoard = () => {
    const [loader, setLoader] = useState<boolean>(false)
    const [eventSent, setEventSent] = useState<boolean>(false)
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [userId, setUserId] = useState(undefined)
    const language = searchParams.get('language')

    const showDashboard = useSelector(
        (state: RootState) => state?.showDashboard
    );
    const users = useSelector(
        (state: RootState) => state?.usersReducer
    );

    // useEffect(() => {
    //     if(_.isEmpty(showDashboard.shows?.ready?.shows) && !users?.user?.id)
    //         fetchAllShows()
    // }, [])

    async function fetchAndResetShows() {
        await resetShows()
        fetchAllShows()
        screen_viewed('show_screen_view', 'Shows', 'Show_List_Page');
    }

    useEffect(() => {
        goToTop()
    }, []);

    useEffect(() => {
        if (users?.isFetchedUser || (!users?.loader && !Boolean(localStorage.getItem('access-token')))) {setUserId(users?.user?.id ?? null)}
        else {setUserId(users?.user?.id ?? undefined)}
    }, [users?.isFetchedUser, localStorage.getItem('access-token')])

    useEffect(() => {
        if (users?.isFetchedUser || userId !== undefined) fetchAndResetShows()
    }, [userId, language])

    useEffect(() => {
        if (
            !loader && !users.loader &&
            !eventSent && !showDashboard?.isFetching
        ) {
            show_list_screen_viewed(users.user, showDashboard.shows?.live);
            setEventSent(true)
        }
    }, [eventSent, loader, showDashboard.shows?.live?.shows])

    const resetShows = async () => {
        await Promise.all(
            [
                dispatch(resetAllShows('live')),
                dispatch(resetAllShows('game_show')),
                dispatch(resetAllShows('ready')),
                dispatch(resetAllShows('suggested')),
                {/* Feature shows section remove for now ref of ticket ENT-103  */}
                // dispatch(resetAllShows('featured')),
            ]
        )
    }

    const fetchAllShows = async () => {
        setLoader(true)
        await Promise.all(
            [
                dispatch(getAllShows('live', 1, users.user?.id, [], language)),
                dispatch(getAllShows('game_show', 1, users.user?.id, [], language)),
                dispatch(getAllShows('ready', 1, users.user?.id, [], language)),
                dispatch(getAllShows('suggested', 1, users.user?.id, [], language)),
                {/* Feature shows section remove for now ref of ticket ENT-103  */}
                // dispatch(getAllShows('featured', 1, users.user?.id, [], language)),
            ],
        )
        setLoader(false)
    }

    const onLanguageChange = (filterValue: any) => {
        if (filterValue === '1' || filterValue === '2') {
            setSearchParams({language: filterValue}, {replace: true})
        }
        else {
            setSearchParams({}, {replace: true})
        }
    }

    return <>
        {loader || users.loader ? <Loader /> : null}
        <Helmet>
            <title>GAVEL - Live shows for trading cards and much more</title>
            <meta property="og:title" content="GAVEL - Live shows for trading cards and much more" />
            <meta property="title" content="GAVEL - Live shows for trading cards and much more" />
            <meta property="og:description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
            <meta property="description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
            <meta property="og:url" content="https://letsgavel.com" />
            <meta property="og:image" content="https://i.ibb.co/1KqZpJ7/Meta-Image-EN.png" />
            <link rel="canonical" href="https://letsgavel.com" />
        </Helmet>
        <ShowLayout featuredShows={showDashboard.shows?.featured?.shows} suggestedShows={showDashboard.shows?.suggested?.shows} upComingShows={showDashboard.shows?.ready?.shows} liveShows={showDashboard.shows?.live?.shows} gameShows={showDashboard.shows?.game_show?.shows}
            activeLanguage={language} onLanguageChange={onLanguageChange} fetchShows={fetchAndResetShows} />
        <Pagefooter />
    </>
}

export default ShowDashBoard
import React from "react";

function Settings() {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.7663 4.89431L13.313 4.10764C12.9294 3.442 12.0795 3.21237 11.413 3.59431V3.59431C11.0957 3.78122 10.7171 3.83425 10.3606 3.7417C10.0042 3.64916 9.69924 3.41864 9.51296 3.10098C9.39313 2.89907 9.32873 2.66909 9.32629 2.43431V2.43431C9.3371 2.05789 9.19509 1.69313 8.93261 1.42311C8.67013 1.1531 8.30953 1.00082 7.93296 1.00098H7.01963C6.6507 1.00097 6.29698 1.14798 6.03674 1.40948C5.7765 1.67097 5.63119 2.02539 5.63296 2.39431V2.39431C5.62203 3.15601 5.0014 3.76772 4.23963 3.76764C4.00485 3.76521 3.77487 3.70081 3.57296 3.58098V3.58098C2.90641 3.19904 2.05648 3.42867 1.67296 4.09431L1.18629 4.89431C0.803238 5.55912 1.02974 6.40851 1.69296 6.79431V6.79431C2.12406 7.0432 2.38964 7.50318 2.38964 8.00098C2.38964 8.49877 2.12406 8.95875 1.69296 9.20764V9.20764C1.03058 9.59084 0.803833 10.4382 1.18629 11.101V11.101L1.64629 11.8943C1.82599 12.2186 2.12749 12.4578 2.48407 12.5592C2.84066 12.6605 3.22293 12.6156 3.54629 12.4343V12.4343C3.86418 12.2488 4.24299 12.198 4.59853 12.2931C4.95407 12.3883 5.25687 12.6215 5.43963 12.941C5.55946 13.1429 5.62386 13.3729 5.62629 13.6076V13.6076C5.62629 14.3772 6.25011 15.001 7.01963 15.001H7.93296C8.69988 15.001 9.32262 14.3812 9.32629 13.6143V13.6143C9.32451 13.2442 9.47074 12.8888 9.73242 12.6271C9.99411 12.3654 10.3495 12.2192 10.7196 12.221C10.9538 12.2272 11.1829 12.2914 11.3863 12.4076V12.4076C12.0511 12.7907 12.9005 12.5642 13.2863 11.901V11.901L13.7663 11.101C13.9521 10.7821 14.0031 10.4023 13.908 10.0456C13.8129 9.68902 13.5795 9.38503 13.2596 9.20098V9.20098C12.9397 9.01693 12.7064 8.71294 12.6113 8.35632C12.5162 7.9997 12.5672 7.61988 12.753 7.30098C12.8738 7.09003 13.0487 6.91513 13.2596 6.79431V6.79431C13.9189 6.40872 14.1448 5.56429 13.7663 4.90098V4.90098V4.89431Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="7.47957" cy="8.00105" r="1.92" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

    

  );
}

export default Settings;
import React from "react";

function SendArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="14"
      fill="none"
      viewBox="0 0 17 14"
    >
      <path
        fill="#202C47"
        d="M.903 13.75V8.512L12.153 7 .903 5.488V.25L16.653 7 .903 13.75z"
      ></path>
    </svg>
  );
}

export default SendArrow;
export const privacyData = [
    {
      title: "SECTION 1 – STORAGE OF ACCESS DATA IN SERVER LOG FILES",
      text: [
        "We store access data in ‘server log files’, such as the name of the data requested, date and time of retrieval, amount of data transferred and the requesting provider, for example. This data is evaluated to ensure trouble-free operation of the website and app and to improve our service.",

        "If the requested data is connected to your personal information (buying, bidding, chat, address change, payment, app usage in general, etc.), then information about you and the certain action might be stored within the log record. The storage of such records is furthermore important to avoid abuse by proving set activities."
      ]
    },
    {
      title: "SECTION 2 – DATA COLLECTION AND USE FOR CONTRACT PROCESSING, FULFILLMENT AND OPENING A CUSTOMER ACCOUNT",
      text: [
        "We collect personal data if you provide us with this as part of your order, or when you voluntarily contact us (by contact form or email, for example) or open a customer account. The data collected can be seen from the respective input forms; this includes, but is not limited to names, surnames, delivery address and possibly credit card information. These data your provided are necessary for the performance of pre-contractual measures. Without these data Gavel cannot conclude the contract. In addition you may voluntarily provide Gavel with your birth date for advertisement and contract fulfillment purposes, to receive corresponding offers on that occasion. When you visit our website, we automatically receive your computer’s IP address, in order to determine which browser and operating system you are using. We use the data provided by you for managing contracts and processing your requests. For contract fulfilment, we will pass on your data to the shipping company assigned for the delivery, insofar as this is necessary for delivery of goods ordered. Depending on which payment service provider you select in the order process, we will forward the payment data received to the credit institution assigned for payment and any payment service providers assigned by us to process the payments, or to the payment service selected. In part, the selected payment service providers also collect this data themselves, provided that you create an account there. In this case, you must log in to the payment service provider with your access data during the ordering process. In this, the privacy policy of the respective payment service provider applies.",

        "After completion of the contract or deletion of your customer account, your data will be blocked against further use and deleted after expiry of the statutory tax and company retention periods, unless you have expressly consented to a further use of your data, or we reserve the right to further data usage as permitted by law, about which we will notify you below.",

        "After the cancellation of the purchase process your data will be deleted. In case of a contract conclusion all data arising from the contract relation will be stored until the end of the tax law storage period. Furthermore your name, address, purchased goods and purchase time will be stored until the possible product liability claims are precluded.",

        "The legal basis for the transfer of this data is Sec 96 para 3 TKG (Telekommunikationsgesetz, Austrian Telecommunications Act) and art 6 para 1 lit b GDPR (contract fulfillment) and lit f (legitimate interest) GDPR.",

        "Deletion of your customer account is possible at any time and can be done either by sending a message to privacy@letsgavel.com or via a designated function in the customer account. Your rights regarding your personal data, as specified in section 19 of the Gavel Privacy Policy, remain untouched."

      ]
    },
    {
      title: "SECTION 3 – DATA USAGE FOR NEWSLETTER",
      text: [
        'If you sign up to our app, we will use the data required or provided by you separately, in accordance with your consent, to send you our regular email newsletter. You sign up by clicking the "log in" button in the app. Thereafter you declare your consent by clicking the checkbox during signup or by using the app continuously afterwards.',

        'In context with the submission of your email address and the sending of our newsletters we use services from IT providers, who may collect the data you provided us with, as far as necessary for the provision of the relevant IT services or additional data being voluntarily provided on your part in that context. Unsubscribing from the newsletter is possible at any time and can be done either by sending a message to the contact privacy@gavel.com or via a dedicated link in the newsletter. Consequentially Gavel shall delete all personal data collected in that context. Your rights regarding your personal data, as specified in section 19 of the Gavel Privacy Policy, remain untouched.'
      ]
    },
    {
      title: 'SECTION 4 – DATA USAGE FOR EMAIL ADVERTISING AND YOUR RIGHT TO OBJECTION',
      text: [
        'If we receive your email address in connection with downloading our app and you have not objected, we reserve the right to send you regular email offers and information about this and similar products from our range, such as those already purchased or pre-ordered.',

        'In context with the submission of your email address and the sending of our newsletters we use services from IT providers, who may collect the data you provided us with. For more details see section 3.',

        'You can object to this use of your email address at any time by sending a message to privacy@letsgavel.com or via a dedicated link in the promotional email, without incurring costs other than those for transmission according to the basic tariffs. Your rights regarding your personal data, as specified in section 19 of the Gavel Privacy Policy, remain untouched.'

      ]
    },
    {
      title: 'SECTION 5 – DATA USAGE FOR POSTAL ADVERTISING AND YOUR RIGHT TO OBJECT',
      text: [
        'In addition, we reserve the right to save your first and last name, mailing address and – insofar as we have received this from you in the context of the contractual relationship - your title, academic level, year of birth and job, industry or business title, in summary lists, to use for our own advertising purposes, such as for sending offers and information of interest concerning our products, by post. For customers based in Austria, this does not apply if you are registered in the Robinson list pursuant to §151 Abs. 9 GewO [Gewerbeordnung, Austrian Trade Act] . Customers based in Switzerland will not receive any postal advertising if they have made a corresponding note in the telephone book. You can object to the storage and use of your data for these purposes at any time by sending a message to the contact privacy@letsgavel.com. Your rights regarding your personal data, as specified in section 19 of the Gavel Privacy Policy, remain untouched.'
      ]
    },
    {
      title: 'SECTION 6 – PAYMENT PROVIDERS',
      text: [
        "We handle payments with Stripe and Paypal. These payment providers provide us with the platform that enables us to provide our goods to you. Your data is stored on the certain provider's data storage, databases and applications. They store your data on secure, firewall-protected servers.",

        "If you pay by credit card, bank or Paypal, they might store your information for future payments. This is encrypted using the Payment Card Industry Data Security Standard (PCI-DSS). Your transaction data will only be stored as long as is required. The credit card payment function follows the standards set by the PCI-DSS, managed by the PCI Security Standards Council, a joint venture between companies such as Visa, Mastercard, American Express and Discover. The PCI-DSS standards help ensure the secure handling of credit card information.",

        "For more information, please visit their Terms of Use and Privacy Policy at: https://stripe.com/en-at/privacy and https://www.paypal.com/us/webapps/mpp/ua/legalhub-full"
      ]
    },
    {
      title: 'SECTION 7 – THIRD-PARTY SERVICES',
      text: [
        'Third party providers such as PayPal (PayPal Holdings, Inc.), 2211 N 1st St, San Jose, CA 95131, USA or SOFORT transfer (Sofort GmbH), Theresienhöhe 12, 80339 Munich, Germany, only collect and process the data necessary to perform their services. Some of these third parties have their own privacy policies with respect to the information we provide them for transaction processing purposes. We encourage you to read these privacy policies in order to understand how these third-party providers process your information. In particular, please note that certain third-party providers do not have a registered office or server in Austria, or in your jurisdiction. As such, if you choose to use a third-party payment method, your data and the processing thereof may be governed by the law of that third-party provider.',

        "If you leave this website or the app or are redirected to a third-party website, your use of the internet will no longer be subject to Gavel's Privacy Policy and Terms of Use."
      ]
    },
    {
      title: 'SECTION 8 – USE OF COOKIES',
      text: [
        'To make the visit to our website and the usage of our app attractive and to enable the use of certain functions, we use ‘cookies’ on various pages. These are small text files that are stored on your device. Some cookies we use (called ‘session cookies’) are deleted after the end of the browser session, i.e. after closing your browser. Other cookies remain on your device and allow us to recognize your browser on your next visit (‘persistent’ cookies). You can set your browser so that you are notified about cookie settings and individually decide on their acceptance or block the acceptance of cookies for specific cases, or in general. Not accepting cookies may limit the functionality of our website and app.'
      ]
    },
    {
      title: 'SECTION 9 – USE OF GOOGLE ANALYTICS FOR WEB AND APP ANALYTICS',
      text: [
        'Our app and website use Google Analytics, a web analytics service provided by Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (www.google.com). Google Analytics uses methods enabling analysis of your use of the website or app, such as "cookies", text files stored on your computer. Hereby, data regarding geographic location, language settings, internet browser, internet service provider, operating systems, screen resolution, device type, gender, age, personal interest, online behaviour on the website or app, are collected by Google LLC and used by Gavel, in the form of statistics for the purpose of improving our products, marketing activities, website, app and its functioning. The information used by Gavel in this context, does not permit any conclusion in respect of the identity of a concerned individual person.',

        "Further details on the collection of data, their processing and use by Google LLC as well as your rights and setting options in context with your privacy are provided in the respective Privacy Policy under (https://policies.google.com/technologies/ads?hl=en and https://policies.google.com/privacy).",

        "The information generated about your use of this website and our app is usually transmitted to a Google server in the USA and stored there. By activating the IP anonymization on this website or the app, the IP address used will be abbreviated prior to transmission within European Union member states or other states contracting to the Agreement on the European Economic Area. The anonymized IP address provided by Google Analytics within the Google Analytics framework will not be amalgamated with other Google data.",

        "You can prevent Google’s collection of data generated by the cookie and related to your use of the website and app (including your IP address), as well as Google’s processing of this data, by downloading and installing the browser plug-in available at the following link: https://tools.google.com/dlpage/gaoptout?hl=en.",

        "The relation to Google LLC is based on the European Commission’s implementing Decision (EU) 2016/1250 of 12 July 2016 pursuant to Directive 95/46/EC of the European Parliament and of the Council on the adequacy of the protection provided by the EU-U.S. Privacy Shield (see https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).",

        "The data processing is based on the legal provisions in Sec 96 para 3 TKG (Telekommunikationsgesetz, Austrian Telecommunications Act) and art 6 para 1 lit f GDPR (legitimate interest)."
      ]
    },
    {
      title: 'SECTION 10 – USE OF GOOGLE ADWORDS WITH CONVERSION TRACKING',
      text: [
        'Our website and app uses Google AdWords and conversion tracking. This is a service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (www.google.com).',

        "If you click on an ad placed by Google, the conversion tracking we use stores a cookie on your device. These so-called conversion cookies expire after 30 days and do not otherwise identify you personally.",

        "If the cookie is still valid and you visit a specific page of our website or our app, both we and Google can evaluate that you clicked on one of our ads placed on Google and that you were then forwarded to our website.",

        "The data collected in this way is in turn used by Google to provide us with an evaluation of visits to our website or app and what visitors do once there. In addition, we receive information about the number of users who clicked on our advertisement(s) as well as about the pages on our site that are subsequently visited. Neither we nor third parties who also use Google AdWords will be able to identify you from this conversion tracking.",

        "Further details on the collection of data, their processing and use by Google LLC as well as your rights and setting options in context with your privacy are provided in the respective Privacy Policy under (https://policies.google.com/technologies/ads?hl=en and https://policies.google.com/privacy).",

        "You can also prevent or restrict the installation of cookies by making the appropriate settings in your browser. Likewise, you can use the browser to delete cookies that have already been stored. However, the steps and measures required vary, depending on the browser you use. If you have any questions, please use the help function or consult the documentation for your browser or contact its maker for support.",

        "The relation to Google LLC is based on the European Commission’s implementing Decision (EU) 2016/1250 of 12 July 2016 pursuant to Directive 95/46/EC of the European Parliament and of the Council on the adequacy of the protection provided by the EU-U.S. Privacy Shield (see https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).",

        "We use conversion tracking to provide targeted promotion of our offers. The legal basis is art. 6 para. 1 lit. f GDPR. Our legitimate interest lies in the analysis, optimization, and economic operation of our website or app."

      ]
    },
    {
      title: 'SECTION 11 – USE OF GOOGLE REMARKETING',
      text: [
        'Our website uses Google AdWords and conversion tracking. This is a service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (www.google.com).',

        "Gavel uses this feature to deliver interest-based, personalized advertising on third-party websites that also participate in Google's advertising network.",

        "To allow this advertising service to function, Google stores a cookie with a sequence of numbers on your device via your browser when you visit our website or app. This cookie records both your visit and the use of our website or app in anonymous form. However, personal data will not be passed on. If you subsequently visit a third-party website that also uses the Google advertising network, advertising may appear that refers to our website or app or our offers there.",

        "To permanently disable this feature, Google provides a browser plugin for most common browsers at https://www.google.com/settings/ads/plugin?hl=de. You can also completely prevent the use of cookies by using opt-out services for interest-based advertising by certain providers.",

        "Cross-device marketing allows Google to track your usage patterns across multiple devices, so you may see interest-based, personalized advertising even when you switch devices. However, this requires that you have agreed to link your browsing history to your existing Google account.",

        "Further details on the collection of data, their processing and use by Google LLC as well as your rights and setting options in context with your privacy are provided in the respective Privacy Policy under (https://policies.google.com/technologies/ads?hl=en and https://policies.google.com/privacy).",

        "The relation to Google LLC is based on the European Commission’s implementing Decision (EU) 2016/1250 of 12 July 2016 pursuant to Directive 95/46/EC of the European Parliament and of the Council on the adequacy of the protection provided by the EU-U.S. Privacy Shield (see https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).",

        "We use Google remarketing to provide targeted promotion of our website or app. The legal basis is art. 6 para. 1 lit. f GDPR. Our legitimate interest lies in the analysis, optimization, and economic operation of our website."

      ]
    },
    {
      title: 'SECTION 12 – USE OF FACEBOOK PIXEL FOR WEB ANALYSIS',
      text: [
        'Our website contains web beacons (also known as "pixel tags"). A web beacon is an electronic image, often invisible, placed on a website or app or in an email, usually no larger than 1 x 1 pixel, which observes the online behaviour of visitors on the website or app or while sending emails. Web beacons are provided by third parties, the provider on this website being Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA („Facebook“) to obtain information about the activity of the visitors on our website. This makes it possible to determine from which computer, when, and from which location a specific website is accessed. We also use “Custom Audience Pixel” analysis software to optimize our advertising campaigns. This software enables online behaviour to be observed in terms of time, geographic location, purchase behaviour and use of this website or app. This information is collected via web beacons and/or cookies. The information received from the web beacons and/or cookies is anonymous and will not be linked to personal data. This information will not be shared with third parties for their own use. Further information can be found at: https://en-gb.facebook.com/business/learn/facebook-ads-pixel.',

        "The relation to Facebook is based on the European Commission’s implementing Decision (EU) 2016/1250 of 12 July 2016 pursuant to the Directive 95/46/EC of the European Parliament and of the Council on the adequacy of the protection provided by the EU-U.S. Privacy Shield (see https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active).",

        "The data processing is based on the legal provisions in Sec 96 para 3 TKG (Telekommunikationsgesetz, Austrian Telecommunications Act) and art 6 para 1 lit f GDPR (legitimate interest)."
      ]
    },
    {
      title: 'SECTION 13 – USE OF FACEBOOK PLUGINS',
      text: [
        'Our website and app use social plugins of the Facebook social network, operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA ("Facebook"). The plugins are identified by a Facebook logo or the addition of “Soziales Plug-in von Facebook” or “Facebook Social Plugin”.',

        `Our website and app use social plugins of the Facebook social network, operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA ("Facebook"). The plugins are identified by a Facebook logo or the addition of “Soziales Plug-in von Facebook” or “Facebook Social Plugin”.`,

        "If you visit a page on our website or app that contains this plugin, your browser connects directly to the Facebook servers. The content of the plugin is transmitted by Facebook directly to your browser and integrated into the page. Through this integration, Facebook receives the information that your browser has accessed on the corresponding page of our website or app, even if you do not have a Facebook profile or are not currently logged in to Facebook. This information (including your IP address) will be transmitted from your browser directly to a Facebook server in the USA and stored there.",

        `If you are logged in to Facebook, Facebook can immediately attribute your visit to our website or app to your Facebook profile. If you interact with the plugins by clicking the "Like" button or by leaving a comment, for example, the corresponding information is also transmitted directly to a Facebook server, and stored there. The information will also be posted on your Facebook profile and displayed to your Facebook friends.`,
        `The purpose and scope of the data collection and the further processing and use of the data by Facebook, as well as your rights and setting options for the protection of your privacy can be found in the Facebook privacy policy, at http://www.facebook.com/policy.php.`,

        `If you do not wish Facebook to directly assign the data collected via our website or app to your Facebook profile, you must log out of Facebook before visiting our website or app. You can also completely prevent the loading of Facebook plugins with add-ons for your browser, such as a "Facebook Blocker" or script blocker, for example.`
      ]

    },
    {
      title: 'SECTION 14 – USE OF TWITTER PLUGINS (E.G. THE “TWEET” BUTTON)',
      text: [
        `Our website and app use social plugins of the Twitter microblogging service, operated by Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA (“Twitter”). The plugins are identified by a Twitter logo, in the form of a blue "Twitter bird", for example.`,

      `If you visit a page on our website or app that contains this plugin, your browser connects directly to the Twitter servers. The content of the plugin is transmitted by Twitter directly to your browser and integrated into the page. Through this integration, Twitter receives the information that your browser has accessed the corresponding page of our website, even if you do not have a Twitter profile or are not currently logged in to Twitter. This information (including your IP address) is sent from your browser directly to a Twitter server in the USA and stored there.`,

      `If you are logged in to Twitter, Twitter can immediately attribute your visit to our website or app to your Twitter account. If you interact with the plugins by clicking on the "Tweet" button, for example, the corresponding information is also transmitted directly to a Twitter server and stored there. The information will also be posted on your Twitter account and displayed there to your contacts.`,

      `The purpose and scope of the data collection and the further processing and use of the data by Twitter, as well as your rights and setting options for the protection of your privacy can be found in Twitter’s privacy policy, at https://twitter.com/privacy.`,

      `If you do not wish Twitter to directly assign the data collected via our website or app to your Twitter profile, you must log out of Twitter before visiting our website. You can also completely prevent the loading of Twitter plugins with add-ons for your browser, such as a script blocker, for example.`
      ]
    },
    {
      title: 'SECTION 15 – USE OF PINTEREST PLUGINS',
      text: [
        `Our website and app use social plugins of the Pinterest social network, operated by Pinterest Inc., 651 Brannan Street, San Francisco, CA 94103, USA (“Pinterest”). The plugins are identified by a Pinterest logo.`,

  `If you visit a page on our website or app that contains this plugin, your browser connects directly to the Pinterest servers. The content of the plugin is transmitted by Pinterest directly to your browser and integrated into the page. Through this integration, Pinterest receives the information that your browser has accessed the corresponding page of our website, even if you do not have a Pinterest profile or are not currently logged in to Pinterest. This information (including your IP address) is sent from your browser directly to a Pinterest server in the USA and stored there.`,

  `If you are logged in to Pinterest, Pinterest can immediately attribute your visit to our website or app to your Pinterest account. If you interact with the plugins by clicking on the “Pin it” button or by leaving a comment, for example, the corresponding information is also transmitted directly to a Pinterest server, and stored there. The information will also be posted on your Pinterest account and displayed there to your Pinterest followers.`,

  `The purpose and scope of the data collection and the further processing and use of the data by Pinterest, as well as your rights and setting options for the protection of your privacy can be found in the Pinterest privacy policy, at https://policy.pinterest.com/en/privacy-policy.`,

  `If you do not wish Pinterest to directly assign the data collected via our website or app to your Facebook profile, you must log out of Pinterest before visiting our website. You can also completely prevent the loading of Pinterest plugins with add-ons for your browser, such as a script blocker, for example.`
      ]
    },
    {
      title: 'SECTION 16 – DATA COLLECTION AND USE IN CONTEXT WITH THE USAGE OF THE GAVEL APPLICATION (APP)',
      text: [
        `When registering for the Gavel Application (in the following “App”) you will be requested to enter your email address as well as the type of operating system you are using. After the corresponding release of the use of the Beta edition of the App, further details in context with your usage of the Gavel App will be collected and evaluated by Gavel, for the purpose of the optimization of the same. The Gavel App locates and collects, for technical reasons, the details of the device, when the Gavel app is installed.`,

        `The required information for the registration will be collected by means of Google Forms. This is a service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (www.google.com).`,

        `Further details on the collection of data, their processing and use by Google LLC as well as your rights and setting options in context with your privacy are provided in the respective Google LLC Privacy Policy (https://policies.google.com/privacy).`,

        `Furthermore Gavel uses Google Firebase as service provider for the development of the Gavel App software. This is a service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (www.google.com).`,

        `Further details on the collection of data, their processing and use by Google LLC as well as your rights and setting options in context with your privacy are provided in the respective Privacy Policy under https://policies.google.com/privacy and https://firebase.google.com/terms/data-processing-terms/.`,

        `The relation to Google LLC is based on the European Commission’s implementing Decision (EU) 2016/1250 of 12 July 2016 pursuant to Directive 95/46/EC of the European Parliament and of the Council on the adequacy of the protection provided by the EU-U.S. Privacy Shield (see https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).`,

        `The data processing is based on the legal provisions in art 6 para 1 lit f GDPR (legitimate interest). Our legitimate interest lies in the analysis, optimization, and economic and user-friendly operation of our Gavel App.`
      ]
    },
    {
      title: 'SECTION 17 – FULL LEGAL CAPACITY',
      text: [
        'By using this website or our app, you declare that you have full legal capacity under the law of your country.'
      ]
    },
    {
      title: 'SECTION 18 – CHANGES TO THIS PRIVACY POLICY',
      text: [
        'We reserve the right to change this Privacy Policy at any time; as such, please refer to it regularly. Changes are subject to availability on this website or app. If this web shop is transferred to another company, your data may be transferred to the new owner so that we can continue to offer and sell our goods to you.'
      ]
    },
    {
      title: 'SECTION 19 – RIGHTS REGARDING YOUR PERSONAL DATA AND CONTACT OPTIONS',
      text: [
        'You have the right to information and data portability, free of charge, on the personal data stored by us, as well as a right to have this data corrected, deleted or if necessary, restrict their use. For questions on the collection, processing or use of your personal data, the exercise of the above mentioned rights as well as withdrawal of consent granted or objection to a particular use of data, please contact us directly via email at privacy@letsgavel.com. In case you believe that the usage of your data infringes your data protection rights, you can complaint at the responsible data protection authority. In Austria that is the Datenschutzbehörde.'
      ]
    }
  ]
import { createStore, combineReducers, applyMiddleware } from "redux";
import showDashboard, { ShowDashboard } from "./Reducers/showDashboard";
import usersReducer, { Users } from "./Reducers/users";
import thunk from "redux-thunk";
export interface RootState {
  showDashboard: ShowDashboard,
  usersReducer:Users,
}
export default createStore(
  combineReducers<RootState>({
    showDashboard,
    usersReducer,
  }),
  applyMiddleware(thunk)
);

import React from "react";

function Time() {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.8842 6.49994C10.8842 9.16175 8.72669 11.3192 6.06488 11.3192C3.40308 11.3192 1.24561 9.16175 1.24561 6.49994C1.24561 3.83814 3.40308 1.68066 6.06488 1.68066C8.72669 1.68066 10.8842 3.83814 10.8842 6.49994Z" stroke="currentColor" strokeWidth="1.4595" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.85285 8.03297L5.88867 6.86124V4.33594" stroke="currentColor" strokeWidth="1.4595" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
}

export default Time;
import React from 'react'
import { images } from '../../Assets/ImageConfig/images'
  import './Appdownload.scss'


function Appdownload() {
  return (

      <div className="Appdownload-container">
        <div className='container'>
        <div className='downloadbox'>
          <div className="blurry-bg" ></div>
            <div className='boxheader'>
            <div className='box-logo'>
              <img src={images.gavellogo} alt="logo"/>
            </div>
            <div className='gavel-signate'>
              <img src={images.gavelsignet} alt=""/>
            </div>
            <p>TCG Live Shows</p>
            </div>
            <div className='download-content'>
              <h2>Die nächste Live Show startet schon bald</h2>
              <p>Mit Gavel kannst du in täglichen Livestreams wertvolle Karten ersteigern und dich mit Peers connecten.</p>
              <span className='soonagain'>Soon again</span>


            </div>
            <div className='gavelFooter'>
                <div className='linkgavel'>
                    <h4>Already on GAVEL?</h4>
                    <a href="letsgavel.com://show/0" className='gavelLink'>
                      <img src={images.appgavel} alt=""/>
                    </a>
                </div>
                <div className='gaveldownload'>
                  <h4>Download the App now</h4>
                  <div className='downloadbts'>
                     <a href="https://apps.apple.com/at/app/gavel-live-collectibles-sale/id1570409915" className='gavelLink'>
                      <img src={images.appios} alt="appios"/>
                     </a>
                     <a href="https://play.google.com/store/apps/details?id=com.gavel.collectibles" className='gavelLink'>
                      <img src={images.appandroid} alt=""/>
                     </a>
                  </div>
                </div>
              </div>
        </div>
        </div>
      </div>

  )
}

export default Appdownload

import React, { useEffect, useState } from 'react'
import { Row, Input, Col } from 'antd';
import './Showlist.scss'
import Card from '../CommonComponents/Card';
const { Search } = Input;

function Showlist(props: any) {

  const onSearch = (value:any) => console.log(value);
  const [shows, setShows] = useState([])
  useEffect(() => {
    setShows(props.shows)
  },[props.shows])

  return (
      <div className="showlist">
            <div className=''>
              <Row gutter={30}>
                {shows?.map((show: any, index: number) => (
                <Col xs={24} sm={12} lg={6} key={index} >
                    <Card show={show} setUserLoader={props?.setUserLoader} fetchMore={props.fetchMore} lastElement={shows.length === index +1 ? true : false} isWatchList={props.isWatchList} />
                </Col>
                ))}
              </Row>
        </div>
      </div>
  )
}

export default Showlist

import {Button, Modal} from 'antd'
import React, {useState} from 'react';
import {images} from '../../../../Assets/ImageConfig/images'
import {Close} from '../../../Icons'
import './UnsuccessfuPaymentModalStyle.scss'
const UnsuccessfulPaymentModal = (props: any) => {

  const defaultTitle = 'Unsuccesssful payment'
  const defaultDescription = 'Unfortuantely, we couldn’t complete your payment. Please retry using a different payment method or contact our customer support.'
  const defaultRetryText = 'Retry payment'
  const {price, subTitle, title} = props?.shippingUpgradeData;
  const subTitleSplit = subTitle.split('\n')


  return (
    <>

      <Modal title="" className='themeModal AddNewCard' centered closeIcon={<Close />} width={507} visible={props.isUnsuccesssfulpayment} onOk={props.handleOk} footer={false} onCancel={props.handleCancel}>
      <div className='Unsuccesssfulpayment'>
        {props.failedItemsData.length > 0 ?
          <>
            <div className='Payment_img'>
              <img src={images.Unsuccesssfulpayment} alt="Unsuccesssful payment" />
            </div>
            <div className='Payment_Content'>
              <div className='title'>{props?.title ?? defaultTitle}</div>
              <p>{props?.description ?? defaultDescription}</p>
            </div>
            <div className='Payment_btn'>
              <Button type='primary' className='w100' onClick={props.onRetryPayment}>{props?.retryText ?? defaultRetryText}</Button>
              <Button type='ghost' className='w100' onClick={props.onSupportChat}>Get help</Button>
            </div>
            </>
          : null}

        {!!props?.shippingUpgradeData?.price ?
          <div className='Upgraded_Payment'>
            <div className='Payment_Content'>
              <div className='title'>{title}</div>
              <p>{subTitleSplit?.[0]}</p>
              <p>{subTitleSplit?.[1]}</p>
            </div>
            <div className='Payment_btn'>
              <Button type='primary' className='w100' onClick={props.onRetryPayment}>{`Pay €${price}`}</Button>
            </div>
          </div>
          : null}
        </div>
      </Modal>
    </>
  )
}

export default UnsuccessfulPaymentModal
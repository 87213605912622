import React from 'react'
import {images} from '../../../../Assets/ImageConfig/images'
import './Showended.scss'
import {useNavigate} from "react-router-dom";

function Showended() {
  const navigate = useNavigate();
  return (

    <div className="shownot-start">
      <img src={images.ShowEnd} alt="ShowEnd" />
      <h1>Show is ended</h1>
      <p>The current show ended. You can explore more!!</p>
      <button className="squrebtn" type="button" onClick={()=>{
         navigate("/shows")
      }}>Explore More</button>
    </div>

  )
}

export default Showended

// @ts-nocheck
import { createContext } from 'react';

// window?.analytics.identify('5989', {
//     email: 'jane.kim@example.com',
//     name: 'Jane Kim'
// }, {
//     integrations: {
//         'All': true
//     }
// });
// window?.analytics.track("onboarding_login", { type: "mitulfromreact5989" })
export const AnalyticsContext = createContext(window.analytics);

import React from "react";

function CardShare() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.71429 9.06431C3.66106 9.06431 4.42857 8.2968 4.42857 7.35003C4.42857 6.40325 3.66106 5.63574 2.71429 5.63574C1.76751 5.63574 1 6.40325 1 7.35003C1 8.2968 1.76751 9.06431 2.71429 9.06431Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.2858 4.77818C12.2326 4.77818 13.0001 4.01067 13.0001 3.0639C13.0001 2.11712 12.2326 1.34961 11.2858 1.34961C10.339 1.34961 9.57153 2.11712 9.57153 3.0639C9.57153 4.01067 10.339 4.77818 11.2858 4.77818Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.2858 13.3495C12.2326 13.3495 13.0001 12.582 13.0001 11.6352C13.0001 10.6884 12.2326 9.9209 11.2858 9.9209C10.339 9.9209 9.57153 10.6884 9.57153 11.6352C9.57153 12.582 10.339 13.3495 11.2858 13.3495Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.64282 6.42076L9.35711 3.99219" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.64282 8.27832L9.35711 10.7069" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    

  );
}

export default CardShare;
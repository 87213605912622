import React from 'react';
import Editprofile from "../../Components/Profile/EditProfile/Editprofile"

const EditProfilePage = () => {


    return (
       <>
       <Editprofile />
       </>
    );
}

export default EditProfilePage;
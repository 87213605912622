import React from "react";

function Mute() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#202C47" fill-opacity="0.4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5331 17.467L15.7138 24.2869C15.5365 24.1842 15.3686 24.1189 15.2193 24.1096C13.9226 24.0163 13.4189 24.1656 12.7099 23.5592C11.9449 22.9061 12.0009 21.1615 12.0009 19.8834C12.0009 18.6052 11.9449 16.8606 12.7099 16.2076C13.4189 15.6012 13.9226 15.7598 15.2193 15.6571C16.516 15.5545 19.2587 11.5988 21.3763 12.849C22.2345 13.5487 22.4678 14.8548 22.5331 17.467Z" stroke="#FAE8DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.5332 21.9172C22.4959 24.7907 22.272 26.1901 21.3764 26.9178C20.3969 27.4962 19.2868 26.9645 18.2419 26.2088" stroke="#FAE8DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.0015 28L15.7143 24.2869L22.5336 17.4671L28.0003 12" stroke="#FAE8DC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <rect x="0.504346" y="0.504346" width="38.9913" height="38.9913" rx="19.4957" stroke="white" stroke-opacity="0.19" stroke-width="1.00869"/>
    </svg>

  );
}

export default Mute;
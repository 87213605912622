import React, {useEffect, useRef, useState} from 'react';
import {Send, SendArrow} from '../../Icons'

type Props = {
  emitMessage: (message: string) => void;
  preDefinedMessage: string
  selectChannel: any
}

const MessageInput = (props: Props) => {
  const {emitMessage} = props;
  const [message, setMessage] = useState<string>("");

  const inputRef = useRef<any>(null)

  const handleSubmit = () => {
    console.log(message)
    if (message?.trim()?.length <= 0) return
    setMessage("");
    emitMessage(message);
  }

  useEffect(() => {
    inputRef.current?.focus()
  },[props?.selectChannel])


  useEffect(() => {
    if (props?.preDefinedMessage?.length) {
      setMessage(props.preDefinedMessage)
    }
    else {
      setMessage("")
    }
  }, [props?.preDefinedMessage])


  return (
    <div className='msginputarea'>
      <input
        type='text'
        placeholder='Type to add your message'
        className="msgInput"
        value={message}
        onKeyPress={(e) => {if (e.key === 'Enter') {handleSubmit()} e.stopPropagation()}}
        onChange={(v) => {
          const value = v.target.value;
          if (value.slice(-1) !== '\n') setMessage(value)
          else handleSubmit();
        }}
        onKeyDown={(e) => {if (e.key === 'Enter') {handleSubmit()} e.stopPropagation()}}
        autoComplete="off"
        autoCapitalize='none'
        spellCheck={false}
        ref={inputRef}
      />
      <div className='sharebtn-right'>
        <button className='sharebtn' type='button' onClick={handleSubmit} >
          <SendArrow />
        </button>
      </div>
    </div>
  )

}

export default MessageInput;
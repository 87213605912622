import React from "react";

function Close() {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2929 13.7929C13.6834 13.4024 14.3166 13.4024 14.7071 13.7929L20 19.0858L25.2929 13.7929C25.6834 13.4024 26.3166 13.4024 26.7071 13.7929C27.0976 14.1834 27.0976 14.8166 26.7071 15.2071L21.4142 20.5L26.7071 25.7929C27.0976 26.1834 27.0976 26.8166 26.7071 27.2071C26.3166 27.5976 25.6834 27.5976 25.2929 27.2071L20 21.9142L14.7071 27.2071C14.3166 27.5976 13.6834 27.5976 13.2929 27.2071C12.9024 26.8166 12.9024 26.1834 13.2929 25.7929L18.5858 20.5L13.2929 15.2071C12.9024 14.8166 12.9024 14.1834 13.2929 13.7929Z" fill="#0CFDE4"/>
      <rect x="1" y="1.5" width="38" height="38" rx="19" stroke="#FAE8DC" stroke-width="2"/>
    </svg>


  );
}

export default Close;
import {Menu, Button, Modal, Input, Radio, message, Tooltip, Dropdown, Spin} from 'antd'
import React, {Suspense, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMatch} from 'react-router';
import {images} from '../../../../Assets/ImageConfig/images'
import {RootState} from '../../../../store';
import {blockUnblockUser, followUser, openLoginSignUpModal, reportUser, removeFollower} from '../../../../store/Actions/users';
import {displayErrorToast, displayInfoToast, placeHolderProfileHelper} from '../../../../Utils/helper';
import FollowingFollowersList from '../FollowingFollowersList';
import {Youtube, Tiktok, Facebook, Twitter, Instagram, Twitch, Profile, Block, Close, ProfileBadge, More, Chat, Ribbons} from '../../../Icons'
import './Profileaside.scss'
import BlockUnblockModal from './BlockUnblockModal';
import ReportUserModal from './ReportUserModal';
import LoadingSpinner from '../../../CommonComponents/LoadingSpinner';
import ImageComponent from '../ImageComponent';
import Ribbon from 'antd/lib/badge/Ribbon';
import ModBadge from '../../../CommonComponents/ModBadge';
import {user_follow, user_unfollow} from '../../../../Utils/mParticle';

const {Search} = Input;

function Profileaside(props: any) {

  const myProfile = useMatch('/my-profile')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<any>({})
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [blockModalType, setBlockModalType] = useState('')

  const [reportUserModal, setReportUserModal] = useState(false)

  const [usersType, setUsersType] = useState<string>('')

  const {hostUser} = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );
  const dispatch = useDispatch()

  useEffect(() => {
    if (myProfile) {
      setUser(users.user)
    }
    else {
      setUser(hostUser)
    }
  }, [hostUser, myProfile, users.user]);

  useEffect(() => {
    if (user?.isBlockedByMe) {
      setBlockModalType('unblock')
      setShowBlockModal(true)
    } else if (user?.isProfileHidden) {
      setBlockModalType('blockedBy')
      setShowBlockModal(true)
    }
  }, [user, user?.id]);;


  const handleFollow = async (showUserId: number, follow: boolean, shouldUpdateHost: boolean) => {
    if (users.user?.id) {
      if (users.user?.id === showUserId) {
        displayInfoToast("It's you!")
      }
      else {
        await dispatch(followUser(follow, showUserId, shouldUpdateHost))
        if(!follow){
          user_follow(users.user, showUserId, 'btn_follow', 'Profile', 'profile Screen');
        }else{
          user_unfollow(users.user, showUserId, 'btn_follow', 'Profile', 'profile Screen');
        }
      }
    }
    else {
      dispatch(openLoginSignUpModal(true))
    }
  }

  const handleRemoveFollower = async (userId: number) => {
    try {
      if (users.user?.id) {
        await dispatch(removeFollower(userId))
      }
      else {
        dispatch(openLoginSignUpModal(true))
      }
    } catch (error: any) {
      displayErrorToast(error.response?.data?.message)
    }
  }

  const showModal = (typeName: string) => {
    setUsersType(typeName)
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCancelBlock = () => {
    setShowBlockModal(false)
    setBlockModalType('')
  }

  const onBlockUser = async () => {
    if (users.user?.id) {
      onCancelBlock()
      await dispatch(blockUnblockUser(user?.id, true))
    }
    else {
      onCancelBlock()
      dispatch(openLoginSignUpModal(true))
    }
  }

  const onUnblockUser = async () => {
    if (users.user?.id) {
      await dispatch(blockUnblockUser(user?.id, false))
      onCancelBlock()
    }
    else {
      onCancelBlock()
      dispatch(openLoginSignUpModal(true))
    }
  }

  const onReportUser = async (userId: number, reasonId: number, description: string) => {
    try {
      await dispatch(reportUser(userId, reasonId, description))
    }
    catch (e: any) {
      if (e.response?.data?.message)
       displayErrorToast(e.response?.data?.message)
    }
  };

  const onBlockBtn = () => {
    if (users.user?.id) {
      setBlockModalType('block')
      setShowBlockModal(true)
    }
    else {
      onCancelBlock()
      dispatch(openLoginSignUpModal(true))
    }
  };

  const onUnblockBtn = () => {
    if (users.user?.id) {
      setBlockModalType('unblock')
      setShowBlockModal(true)
    }
    else {
      onCancelBlock()
      dispatch(openLoginSignUpModal(true))
    }
  };

  const onReportBtn = () => {
    if (users.user?.id) {
      setReportUserModal(true)
    }
    else {
      onCancelBlock()
      dispatch(openLoginSignUpModal(true))
    }
  };


  const menu = (
    <Menu>
      <Menu.Item key='block'>
        {
          user?.isBlockedByMe ? (<a onClick={onUnblockBtn}>
            <span><Profile /></span> Unblock this user
          </a>) : (<a onClick={onBlockBtn} >
            <span><Profile /></span> Block this user
          </a>)
        }

      </Menu.Item>
      <Menu.Item key='report'>
        <a onClick={onReportBtn}>
          <span><Block /></span> Report this user
        </a>
      </Menu.Item>
    </Menu>
  );

  const getProfileBadgesAndText = () => {
    return (
      <div className='profile-badge'>
        {user?.badgeTopSeller && <span className='badge-span'>
          <ProfileBadge /> Top Seller
        </span>}
        {user?.badgeFrequentHost && <span className='badge-span'>
          <Ribbons />  Frequent Host
        </span>}
      </div>
    );
  }

  return (

    <div className="Profileaside">
      <div className='Profileaside-userdetails'>
        <div className='user-profile'>
          <div className='userImg'>
            <Suspense fallback={<LoadingSpinner />}>
              <ImageComponent imgSrc={user?.pic ?? images.profiledummy} placeholderImg={images.profiledummy} altText='Profile' errorHandler={placeHolderProfileHelper} />
            </Suspense>
          </div>
          <div className='userprofile-details'>
            <div className='user-name'>
              <h4>{user?.username}
              {user?.admin && <ModBadge />}
              </h4>
              {user?.host && getProfileBadgesAndText()}
            </div>
            <div className='user-follower'>
              <div className='count-div' onClick={() => {
                showModal('follower')
              }}>
                <p>{user?.followed_by_ids?.length}</p>
                <span>Followers</span>
              </div>
              <div className='count-div' onClick={() => {
                showModal('following')
              }}>
                <p>{user?.followedByMeUserIds?.length ?? 0}</p>
                <span>Following</span>
              </div>
              {/*
                        {!myProfile && (
                          <div className='count-div'>
                            <p>-0.1</p>
                            <span>Rating</span>
                        </div>
                          )} */}
            </div>
            <div className='follow-block'>
              {!myProfile && (
                <>
                  {
                    !props?.shows ? (
                      <Button className={`${users.user?.followedByMeUserIds?.includes(hostUser?.id) && users.user.id ? 'FollowingActive' : ''}`} onClick={() => handleFollow(+hostUser?.id, users.user?.followedByMeUserIds?.includes(hostUser?.id), true)} type='primary'>
                        {users.user?.followedByMeUserIds?.includes(hostUser?.id) && users.user.id ? `Following` : ` + Follow`}
                      </Button>
                    ) : (
                      <Button className={`${props.show?.followed_by_me && users.user.id ? 'FollowingActive' : ''}`} onClick={() => handleFollow(+props.show?.user_id, props.show?.followed_by_me, hostUser?.id === props.show?.user_id)} type='primary'>
                        {props.show?.followed_by_me && users.user.id ? `Following` : ` + Follow`}
                      </Button>
                    )
                  }
                  {/* <Button onClick={ () => handleFollow(+props.show?.user_id, props.show?.followed_by_me, hostUser?.id === props.show?.user_id) } type='primary'>
                    { props.show?.followed_by_me && users.user.id  ? `Unfollow` : ` + Follow` }
                  </Button> */}
                  <Button  onClick={props?.onStartChat} className='about-link chat-link'>
                    <Chat />
                  </Button>
                  <Dropdown overlay={menu} placement="bottomCenter" arrow >
                    <a className='about-link'><More /></a>
                  </Dropdown>
                </>
              )}
            </div>
            <div className='soicialIocns'>
              <p>Social Links</p>
              {
                (user?.profileTwitter ||
                  user?.profileFacebook ||
                  user?.profileInstagram ||
                  user?.profileTikTok ||
                  user?.profileTwitch ||
                  user?.profileYoutube) ? (
                  <div className=' border-top bor0'>

                  </div>
                ) : <span>
                  {myProfile ? 'Please add your social account.' : 'No details found'}
                </span>
              }
              {
                user?.profileTwitch &&
                <Tooltip title={user?.profileTwitch} placement="top">
                  <a href={`https://www.twitch.tv/${user?.profileTwitch}/`} target="_blank" rel='noopener noreferrer'>
                    <Twitch />
                    {/* <span className='username'>{user?.profileTwitch}</span> */}
                  </a>
                </Tooltip>
              }
              {
                user?.profileYoutube &&
                <Tooltip title={user?.profileYoutube} placement="top">
                  <a href={`https://www.youtube.com/c/${user?.profileYoutube}/`} target="_blank" rel='noopener noreferrer'>
                    <Youtube />
                    {/* <span className='username'>{user?.profileYoutube}</span> */}
                  </a>
                </Tooltip>
              }
              {
                user?.profileFacebook &&
                <Tooltip title={user?.profileFacebook} placement="top">
                  <a href={`https://www.facebook.com/${user?.profileFacebook}`} target="_blank" rel='noopener noreferrer'>
                    <Facebook />
                    {/* <span className='username'>{user?.profileFacebook}</span> */}
                  </a>
                </Tooltip>
              }
              {
                user?.profileInstagram &&
                <Tooltip title={user?.profileInstagram} placement="top">
                  <a href={`https://www.instagram.com/${user?.profileInstagram}/`} target="_blank" rel='noopener noreferrer'>
                    <Instagram />
                    {/* <span className='username'>{user?.profileInstagram}</span> */}
                  </a>
                </Tooltip>
              }
              {
                user?.profileTwitter &&
                <Tooltip title={user?.profileTwitter} placement="top">
                  <a className='' href={`https://www.twitter.com/${user?.profileTwitter}/`} target="_blank" rel='noopener noreferrer'>
                    <Twitter />
                    {/* <span className='username'>{user?.profileTwitter}</span> */}
                  </a>
                </Tooltip>
              }


              {
                user?.profileTikTok &&
                <Tooltip title={user?.profileTikTok} placement="top">
                  <a href={`https://www.tiktok.com/@${user?.profileTikTok}/`} target="_blank" rel='noopener noreferrer'>
                    <Tiktok />
                    {/* <span className='username'>{user?.profileTikTok}</span> */}
                  </a>
                </Tooltip>
              }


            </div>
          </div>
          <div className='userprofile-details'>
            <div className='DetailInner'>
              <p>About</p>
              <span>


                {user?.profileBio ? (
                  <div style={{whiteSpace: "pre-line"}} >{user?.profileBio}</div>
                ) : <>
                  {myProfile ? 'Please add something about your business and products.' : 'No details found'}
                </>}
              </span>

            </div>
            {
              user?.host ? (
                <div className='DetailInner'>
                  <p>Interests</p>
                  <div className='hastags'>
                    {user?.userTopics?.length > 0 ?
                      <>{
                        user?.userTopics?.map((topic: any, i: number) => (
                          <span key={i} >{topic.topicName}</span>
                        ))
                      }</> : <span>
                        {myProfile ? 'Please add topics about your business and products.' : 'No details found'}
                      </span>
                    }
                  </div>
                </div>
              ) : null
            }

          </div>

          <div className='userprofile-details'>
            {
              !user?.host ? (
                <div className='DetailInner'>
                  <p>Interests</p>
                  <div className='hastags'>
                    {user?.userTopics?.length > 0 ?
                      <>{
                        user?.userTopics?.map((topic: any, i: number) => (
                          <span key={i} >{topic.topicName}</span>
                        ))
                      }</> : <span>
                        {myProfile ? 'Please add topics about your business and products.' : 'No details found'}
                      </span>
                    }
                  </div>
                </div>
              ) : null
            }
            {
              user?.host ? (
                <div className='Profile-about '>
                  <p>Host Imprint</p>
                  {(user?.hostCompany || user.hostVAT || user.hostImprint) ? (
                    <>
                      <div className='host-content'>
                        <h6>Name</h6>
                        <span>{user?.hostCompany ?? 'N/A'}</span>
                      </div>
                      <div className='host-content'>
                        <h6>VAT</h6>
                        <span>{user?.hostVAT ?? 'N/A'}</span>
                      </div>
                      <div className='host-content'>
                        <h6>Disclaimer</h6>
                        <div style={{whiteSpace: "pre-line", color: '#B2A8EE'}}>{user?.hostImprint ?? 'N/A'}</div>
                      </div>
                    </>
                  ) : (
                    <div className='host-content'>
                      <span>
                        {myProfile ? 'Please add host imprint information.' : 'No details found'}
                      </span>
                    </div>
                  )}

                </div>
              ) : null
            }
          </div>
        </div>

      </div>
      {
        showBlockModal && (
          <BlockUnblockModal user={user} blockModalType={blockModalType} isModalVisible={showBlockModal}
            onBlockUser={() => onBlockUser()} onUnblockUser={() => onUnblockUser()} handleCancel={onCancelBlock} />
        )
      }

      {
        reportUserModal && <ReportUserModal isModalVisible={reportUserModal} handleCancel={() => setReportUserModal(false)} onSubmit={onReportUser} />
      }

      {
        isModalVisible && (
          <FollowingFollowersList isMyProfile={myProfile} user={user} handleFollow={handleFollow} usersListType={usersType}
            isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} handleRemoveFollower={handleRemoveFollower}
          />
        )
      }
    </div>

  );
}

export default Profileaside

import {useFeature} from '@growthbook/growthbook-react';
import {PaymentRequestButtonElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Button} from 'antd';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {images} from '../../../../Assets/ImageConfig/images';
import {RootState} from "../../../../store";
import {fetchUserDetails} from "../../../../store/Actions/users";
import API from "../../../../Utils/api";
import {GrowthBookFeatures} from '../../../../Utils/constants';
import {displayErrorToast, displayInfoToast, getBrowser} from '../../../../Utils/helper';
import Loader from '../../../CommonComponents/Loader';
import {Close} from '../../../Icons';

// import './Payment.scss';
const TopUp = (props: any) => {

  const stripeObj = useStripe()
  const elementsObj = useElements()

  const [value, setValue] = useState(500);
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [isElementClicked, setElementClicked] = useState(false)
  const [paymentGateway, setPaymentGateway] = useState('')
  const [isPaymentInitiated, setPaymentInitiated] = useState(false)
  const [resultVar, setResultVar] = useState<any>(null)

  const flagPaymentApplePay = useFeature(GrowthBookFeatures.flagPaymentApplePay);
  const flagPaymentEPS = useFeature(GrowthBookFeatures.flagPaymentEPS);
  const flagPaymentGiroPay = useFeature(GrowthBookFeatures.flagPaymentGiroPay);
  const flagPaymentGooglePay = useFeature(GrowthBookFeatures.flagPaymentGooglePay);
  const flagPaymentPaypal = useFeature(GrowthBookFeatures.flagPaymentPaypal);
  const flagPaymentStripePaypal = useFeature(GrowthBookFeatures.flagPaymentStripePaypal);

  const dispatch = useDispatch()

  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const paymentSuccessMessage = () => {
    displayInfoToast(`Your balance top-up was successful!`)
  }

  const paymentFailedMessage = () => {
   displayErrorToast(`We couldn't add money to your pre-paid balance. Try once again! We are unable to authenticate your payment method. Please choose a different payment method and try again..`)
  }

  const onPageShowEvent = (event: PageTransitionEvent) => {
    if (event.persisted) {
      console.log('This page was restored from the bfcache.');
      setPaymentInitiated(false)
      setPaymentRequest(null);
    }
  };

  useEffect(() => {
    // NOTE: added to optimize page for bfcache
    window.addEventListener('pagehide', onPageShowEvent);

    return () => {
      window.removeEventListener('pagehide', onPageShowEvent);
    }
  }, []);


  useEffect(() => {

    (async () => {
      if (stripeObj && elementsObj) {

        if(paymentRequest) return
        const pr = stripeObj.paymentRequest({
          country: 'DE',
          currency: 'eur',
          total: {
            label: 'Top-up',
            amount: value,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        const result = await pr.canMakePayment()
        setResultVar(result)
        // let clientSecret: any;
        let gateway = '';
        console.log({result, pr});
        if (result) {
          if (result?.googlePay) {
            gateway = 'googlepay'
          }
          else if (result?.applePay) {
            gateway = 'applepay'
          }
          setPaymentGateway(gateway)
          setPaymentRequest(pr);
        }
        else {
          console.log('result is empty');
          setPaymentRequest(null)
          // clientSecret = null
        }
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };

  }, [stripeObj, elementsObj, paymentRequest]);

  useEffect(() => {
    if (stripeObj) {
      let clientSecret: any
      if (isElementClicked && paymentRequest) {
        paymentRequest.on('paymentmethod', (async (ev: any) => {
          console.log('result before generating client secret:', {value});

          console.log('paymentmethod is available', {ev});
          clientSecret = await API.postDataWithToken('payments/topup', {
            "amount": value,
            "currency": "EUR",
            "gateway": paymentGateway
          })
          console.log('result::', clientSecret);

          clientSecret = clientSecret.data.data.clientSecret

          if (!clientSecret) return;

          const {paymentIntent, error: confirmError} = await stripeObj.confirmCardPayment(
            clientSecret,
            {payment_method: ev.paymentMethod.id},
            {handleActions: false}
          );

          if (confirmError) {
            ev.complete('fail');
            paymentFailedMessage()
            !props?.isShowPage && props.handleCancel()
          }
          else {
            ev.complete('success');
            if (paymentIntent?.status === "requires_action") {
              const {error} = await stripeObj.confirmCardPayment(clientSecret);
              if (error) {
                console.log('there was an error', {error});
                paymentFailedMessage()
                !props?.isShowPage && props.handleCancel()
              }
              else {
                console.log('payment was successful')
                paymentSuccessMessage()
                !props?.isShowPage && props.handleCancel()
              }
            }
            else {
              console.log('no action required, payment succeeded');
              paymentSuccessMessage()
              !props?.isShowPage && props.handleCancel()
            }
          }
        }))

      }
      paymentRequest && paymentRequest.on('cancel', () => {
        // console.log({ev});
        if (isElementClicked) {
          displayErrorToast(`We couldn't add money to your pre-paid balance. Try once again! The payment has been canceled.`)
          console.log('result:cancel event', {isElementClicked});
          setPaymentRequest(null);
          clientSecret = null
          setElementClicked(false)
        }
      });
    }
  }, [paymentRequest, value, isElementClicked, paymentGateway]);

  const onGiroPayClick = async () => {
    setPaymentInitiated(true)
    const {stripe} = props;
    let clientSecret = await API.postDataWithToken('payments/topup', {
      "amount": value,
      "currency": "EUR",
      "gateway": "giropay"
    })

    clientSecret = clientSecret.data.data.clientSecret


    stripe
      .confirmGiropayPayment(clientSecret, {
        payment_method: {
          billing_details: {
            name: 'Jenny Rosen',
          },
        },
        // Return URL where the customer should be redirected after the authorization.
        return_url: `${window.location.href}?amount=${value}&gateway=giropay`,
      })
      .then(function (result: any) {
        setPaymentInitiated(false)
        props?.isShowPage && dispatch(fetchUserDetails())
        if (result.error) {

          // Inform the customer that there was an error.
        }
      });
  }

  const onEpsPayClick = async () => {
    setPaymentInitiated(true)
    const {stripe} = props;
    let clientSecret = await API.postDataWithToken('payments/topup', {
      "amount": value,
      "currency": "EUR",
      "gateway": "eps"
    })

    clientSecret = clientSecret.data.data.clientSecret
    stripe
      .confirmEpsPayment(clientSecret, {
        payment_method: {
          billing_details: {
            name: users?.user?.username,
          },
        },
        // Return URL where the customer should be redirected after the authorization.
        return_url: `${window.location.href}?amount=${value}&gateway=eps`,
      })
      .then(function (result: any) {
        setPaymentInitiated(false)
        props?.isShowPage &&  dispatch(fetchUserDetails())

        if (result.error) {
          // Inform the customer that there was an error.
        }
      });
  }

  // const browser = getBrowser()

  const getWalletMessage = () => {
    if (flagPaymentGooglePay.off && flagPaymentApplePay.off)
      return ''
    return 'Google Pay / Apple Pay is not supported. Please check if minimum one card is added in Google Pay / Apple Pay.'
  }

  return (
    <>
      {isPaymentInitiated && <Loader />}
      {props?.isShowPage && <div className='paymentcarsTitle'>
        <h3>Top up your balance</h3>
        <button type='button' onClick={() => {
          props.setIsTopUp(false)
          props.setIsCreditCard(false)
        }}>
          <Close />
        </button>
      </div>}

      <div className='TopUpModalStyle showScreen-topupmodal'>

        <div className='currentbalance '>
          <div className='Content'>
            <div className='Title'>Your current balance</div>
            <span>You can add up to 800 €</span>
          </div>
          <div className='value'>{props?.isShowPage ? props?.amount : users?.user?.walletAmount } €</div>
        </div>
        <div className='TopUpBalanceBtn'>
          <Button type='ghost' className={value === 500 ? 'selected-ghost' : ''} onClick={() => setValue(500)}>5 €</Button>
          <Button type='ghost' className={value === 1000 ? 'selected-ghost' : ''} onClick={() => setValue(1000)}>10 €</Button>
          <Button type='ghost' className={value === 2500 ? 'selected-ghost' : ''} onClick={() => setValue(2500)}>25 €</Button>
          <Button type='ghost' className={value === 5000 ? 'selected-ghost' : ''} onClick={() => setValue(5000)}>50 €</Button>
          <Button type='ghost' className={value === 10000 ? 'selected-ghost' : ''} onClick={() => setValue(10000)}>100 €</Button>
          <Button type='ghost' className={value === 15000 ? 'selected-ghost' : ''} onClick={() => setValue(15000)}>150 €</Button>
          <Button type='ghost' className={value === 20000 ? 'selected-ghost' : ''} onClick={() => setValue(20000)}>200 €</Button>
          <Button type='ghost' className={value === 40000 ? 'selected-ghost' : ''} onClick={() => setValue(40000)}>400 €</Button>
          <Button type='ghost' className={value === 80000 ? 'selected-ghost' : ''} onClick={() => setValue(80000)}>800 €</Button>
        </div>
        <div className='Topupwith'>
          <div className='Title'>Top up with:</div>
          {(!paymentRequest && (<p className='errorMsg'>{getWalletMessage()}</p>))}
          <div className='Topupwith_Detail'>
            <div className='TopupOptionButtons'>
            {((flagPaymentGooglePay.on && resultVar?.googlePay) || (flagPaymentApplePay.on && resultVar?.applePay)) && <>{(paymentRequest) && (<PaymentRequestButtonElement options={{
                paymentRequest, style: {
                  paymentRequestButton: {
                    theme: 'light',
                    height: '45px',
                },
                }
              }} onClick={(ev) => {
                console.log('result::', {ev});
                if (paymentRequest) {
                  paymentRequest.update({
                    total: {
                      label: 'Top-up',
                      amount: value
                    }
                  })
                }
                setElementClicked(true)
              }} className='googlePay' />)}</>}
            {flagPaymentGiroPay.on && <button disabled={isPaymentInitiated} onClick={onGiroPayClick}><img src={images.giropayTopup} alt="Payment" /></button>}
            {flagPaymentEPS.on && <button disabled={isPaymentInitiated} onClick={onEpsPayClick}><img src={images.epsTopup} alt="Payment" /></button>}
            {/* NOTE: will be uncommented once implemented */}
            {/* {flagPaymentPaypal.on && <button disabled={isPaymentInitiated} ><img src={images.paypalTopup} alt="Payment" /></button>} */}
            </div>
          </div>

        </div>
        <div className='ORText'>
          <span>OR</span>
        </div>
        <div className='otherpayment'>
          <a href='#'><img src={images.mastercard} alt="master card" /></a>
          <a href='#'> <img src={images.visacard} alt="visa card" /></a>
        </div>
        <Button type='ghost' className='w100 topup' onClick={() => {
          if (props?.isShowPage) {
            props.setIsTopUp(false)
            props.setIsCreditCard(true)
          }
          else {
            props.openCardModal()
          }
        }}>Add a new card</Button>
      </div>


    </>
  )
}

export default TopUp
const appConfig: any = {
  appId: "0055f4aa3edf469c8d653df4fb899687",
  token:
    "0060055f4aa3edf469c8d653df4fb899687IABdEwUEFnZ6uItx1aL/RVa3QYjzsHVYcBq3T0oxLvTwGmKjvysAAAAAIgBzRFmg54bqYQQAAQDXeOphAgDXeOphAwDXeOphBADXeOph",
  channel: "mobileanimation",
  clientRoleOptions: {
    // Set latency level to low latency
    level: 1,
  },
  rtc: {
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
    client: null,
  },
  // baseUrl: 'https://api-dev.letsgavel.com/',
  // baseUrl: 'https://api.letsgavel.com/',
  // baseUrl: 'https://stage.letsgavel.com/',
  // socketUrl: 'https://api.letsgavel.com',
  // socketUrl: 'https://stage.letsgavel.com',
  // socketUrl: 'https://api-dev.letsgavel.com',
  baseUrl: process.env.REACT_APP_API_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  visitor: process.env.REACT_APP_VISITOR,
  support: process.env.REACT_APP_SUPPORT,
  lobUrl: 'https://api.lob.com/',
  lobAPIKey: 'bGl2ZV84NDE5OGYyY2U4OWE1MzBkOTk5Mjc5Nzk2NmRjOWY2YWQ4ZTo6',
  googleClientId: '355181956086-ekidclkvt1cqjg0dkag8jdr495gvj1pu.apps.googleusercontent.com',
  sentryDns: 'https://88349fd6b406413496c6d8ff4b34a40c@o1149288.ingest.sentry.io/6230498',
  growthBookURL: process.env.REACT_APP_GROWTHBOOK_URL
  // GROWTHBOOK_LIVE_URL: 'https://cdn.growthbook.io/api/features/key_prod_687607608c97f2b1'
  // visitor: 11171, // stage
  // visitor: 48965, // live
  // support: 8,
};

export default appConfig;

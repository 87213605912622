import React, {Suspense, useEffect, useState} from 'react';
import {Row, Col, Button, message, Upload} from 'antd';
import {useNavigate} from "react-router-dom"
import Pagefooter from '../../CommonComponents/PageFooter'
import Profileaside from '../Components/ProfileView'
import Profileshowlist from '../Components/ProfileShowList'
import './Myprofile.scss'
import {Edit} from '../../Icons';
import {useDispatch, useSelector} from 'react-redux';
import {updateUserCoverImage} from '../../../store/Actions/users';
import {RootState} from '../../../store/index';
import Loader from '../../CommonComponents/Loader';
import {images} from '../../../Assets/ImageConfig/images';
import ImageComponent from '../Components/ImageComponent';
import LoadingSpinner from '../../CommonComponents/LoadingSpinner';
import {Helmet} from "react-helmet";
import {profile_screen_view} from '../../../Utils/mParticle';


function Myprofile() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const users = useSelector(
    (state: RootState) => state?.usersReducer
  );

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!users?.user?.id) {
      setLoading(true)
    }
    else {
      profile_screen_view(users.user)
      setLoading(false)
    }
  }, [users?.user?.id])

  const handleCoverImageUpdate = async (options: any) => {
    const {onSuccess, onError, file, onProgress} = options
    const fmData = new FormData()
    await fmData.append("file", file)
    try {
      setLoading(true)
      await dispatch(updateUserCoverImage(fmData))
    }
    catch (e: any) {
      if (e.response?.data?.error)
        e.response?.data?.error.forEach((error: any) => {
          // message.error(error.message)
          message.error({icon:'💥', content:error.message})
        })
    }
    setLoading(false)
  }

  return (

    <div className="Hostpublic">
      {<Helmet>
        <title>GAVEL - My Profile</title>
        <meta property="og:title" content="GAVEL - Live shows for trading cards and much more" />
        <meta property="title" content={`GAVEL - ${users?.user?.username}`} />
        <meta property="og:description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
        <meta property="description" content="Join the GAVEL community and bid on your favourite cards, booster packs and limited editions. Shop with confidence with our verified sellers and 100% buyer protection!" />
        <meta property="og:url" content="https://letsgavel.com" />
        <meta property="og:image" content="https://i.ibb.co/1KqZpJ7/Meta-Image-EN.png" />
        <link rel="canonical" href="https://letsgavel.com" />
      </Helmet>}
      {loading && <Loader />}
      <div className='Hostpublic-block'>
        <div className='Hostpublic-block__banner'>
          <div className='cover-banner'>
            <Suspense fallback={<LoadingSpinner />}>
              <ImageComponent imgSrc={users.user?.cover ?? images.CoverImage} placeholderImg={images.CoverImage} altText='Profile' />
            </Suspense>
          </div>
        </div>
        <div className='Hostpublick-grid'>
          <div className='container'>
            <div className='edit_btn'>
              <Upload
                accept='image/*'
                showUploadList={false}
                customRequest={handleCoverImageUpdate}>
                <Button type='primary' className='transparentBtn' icon={<Edit />}>  Edit cover photo</Button>
              </Upload>
              <Button type='primary' className='transparentBtn' onClick={() => navigate('/edit-profile')} icon={<Edit />}>  Edit profile</Button>
            </div>


            <div className='profile-wrapper'>
              <div className='profile-wrapper-left'>
                <Profileaside />
              </div>
              <div className='profile-wrapper-left'>
                <Profileshowlist />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagefooter />
    </div>

  )
}

export default Myprofile

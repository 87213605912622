import React, { useEffect } from 'react';
import {
    useParams,
} from "react-router-dom";
import Loader from "../../Components/CommonComponents/Loader"
const DeepLink = () => {
    const { user } = useParams()

    useEffect(() => {
        const path: any = window.location.pathname;
        const query = new URLSearchParams(window.location.search);
        const username = query.get('username')
        const user_id = query.get('user_id')
        const uuid = query.get('uuid')
        const phone = query.get('phone')
        switch (path) {
            case '/android':
                window.location.href = 'https://play.google.com/store/apps/details?id=com.gavel.collectibles';
                break;
            case '/ios':
                window.location.href = 'https://apps.apple.com/at/app/gavel-live-collectibles-sale/id1570409915';
                break;
            case '/collectibles/android':
                window.location.href = 'https://play.google.com/store/apps/details?id=com.gavel.collectibles';
                break;
            case '/collectibles/ios':
                window.location.href = 'https://apps.apple.com/at/app/gavel-live-collectibles-sale/id1570409915';
                break;
            case '/culture':
                window.location.href = 'https://www.notion.so/gavel/Join-the-Gavel-Journey-fa339dc6bb3740b2b9a63958fd0ca39e';
                break;
            case '/jobs':
                window.location.href = 'https://www.notion.so/gavel/Join-the-Gavel-Journey-fa339dc6bb3740b2b9a63958fd0ca39e';
                break;
            case '/imprint':
                window.location.href = 'https://gavel.notion.site/Imprint-4f287f375c5241acb5106d4a67460b9b';
                break;
            case '/impressum':
                window.location.href = 'https://gavel.notion.site/Imprint-4f287f375c5241acb5106d4a67460b9b'
                break;
            case '/terms':
                window.location.href = 'https://gavel.notion.site/Terms-Privacy-Policy-818157f51ef24a8598490ed3845deb2b';
                break;
            case '/privacy':
                window.location.href = 'https://gavel.notion.site/Terms-Privacy-Policy-818157f51ef24a8598490ed3845deb2b';
                break;
            case '/faq':
                window.location.href = 'https://gavel.notion.site/Frequently-Asked-Questions-63b29beec8e743f086676ce8fc1db5ad';
                break;
            case '/discord':
                window.location.href = 'https://discord.gg/hWjwDb3NVt';
                break;
            case '/facebook':
                window.location.href = 'https://www.facebook.com/groups/180390517529444';
                break;
            case '/dashboard':
                window.location.href = `https://gavel.retool.com/embedded/public/865c3cbc-ed9e-4bb0-aeb1-e4b34dfb489c#user_id=${user_id || ''}&username=${username || ''}&uuid=${uuid || ''}&phone=${phone || ''}`;
                break;
            case '/orders':

                window.location.href = `https://gavel.retool.com/embedded/public/865c3cbc-ed9e-4bb0-aeb1-e4b34dfb489c#user_id=${user_id || ''}&username=${username || ''}&uuid=${uuid || ''}&phone=${phone || ''}`;
                break;
            default:
                if (path.includes('/shipping')) {
                    window.location.href = `https://airtable.com/shrcuWanXBYSRjVOS?prefill_user=${user || user_id || ''}`;
                } else if (path.includes('/waitlist')) {
                    window.location.href = `https://airtable.com/shrl3kzicYqIz8GfF?prefill_user=${user || user_id || ''}&prefill_Name=${username || ''}`
                } else {
                    window.location.href = '/'
                }
                break;
        }
    }, [])
    return (
        <>
            <Loader />
        </>
    );
}

export default DeepLink;
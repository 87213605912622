import Axios, { AxiosRequestConfig } from 'axios';
import Config from './config';
import {getRefreshToken} from './helper';

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error)
    }
    else {
      prom.resolve(token);
    }
  })
  failedQueue = []
}

const axiosApiInstance = Axios.create()

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use((config) => {
  return config;
}, (error) => {
  Promise.reject(error)
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async (error: any) => {
  const originalRequest = error.config
  const accessToken = localStorage.getItem('access-token')
  if (accessToken) {
    // if token is expired then only
    if (error.response.data.message === 'Token expired' && error?.response.status === 401 && !originalRequest._retry) {

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({resolve, reject});
        }).then((token) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axiosApiInstance(originalRequest);
        }).catch((error) => {
          return Promise.reject(error);
        })
      }

      originalRequest._retry = true;
      isRefreshing = true;
      try {
        const responseToken = await getRefreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${responseToken}`;
        processQueue(null, responseToken);
        return axiosApiInstance(originalRequest);
      } catch (error) {
        processQueue(error, null)
        return Promise.reject(error)
      } finally {
        isRefreshing = false
      }
    }
    else {
      return Promise.reject(error)
    }
  }
  return Promise.reject(error)
});


const getDataWithToken = async (
  url: string,
  params: any = {},
) => {

  const accessToken = localStorage.getItem('access-token')
  const config = {
    baseURL: Config.baseUrl,
    url: url,
    method: 'get',
    params: { ...params },
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'token-type': "access_token",
      platform: 'web'
    }
  } as AxiosRequestConfig;

  return axiosApiInstance.get(url, config);
};
const getDataWithOutToken = async (
  url: string,
  params: any = {},
  headers: any = {}
) => {
  const implicitToken = localStorage.getItem('implicite')
  const config = {
    baseURL: Config.baseUrl,
    url: url,
    method: 'get',
    params: { ...params },
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${implicitToken}`,
      'token-type': "implicit",
      platform: 'web',
      ...headers
    }
  } as AxiosRequestConfig;

  return Axios.get(url, config);
};
const postDataWithToken = async (
  url: string,
  data: any,
  headers: any = {},
) => {

  const accessToken = localStorage.getItem('access-token')
  const config = {
    baseURL: Config.baseUrl,
    url: url,
    method: 'post',
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'token-type': "access_token",
      platform: 'web',
      ...headers
    }
  } as AxiosRequestConfig;

  return axiosApiInstance.post(url, { ...data }, config);
};
const postDataWithOutToken = async (
  url: string,
  data: any,
  headers: any = {},
) => {
  const implicitToken = localStorage.getItem('implicite')
  const config = {
    baseURL: Config.baseUrl,
    url: url,
    method: 'post',
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${implicitToken}`,
      'token-type': "implicit",
      platform: 'web',
      ...headers
    }
  } as AxiosRequestConfig;

  return Axios.post(url, { ...data }, config);
};

const postMedia = async (
  url: string,
  data: any,
) => {
  const implicitToken = localStorage.getItem('implicite')
  const accessToken = localStorage.getItem('access-token')
  const config = {
    baseURL: Config.baseUrl,
    url: url,
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken || implicitToken}`,
      'token-type': accessToken ? "access_token" : "implicit",
      platform: 'web',
    },

    responseType: 'json',
  } as AxiosRequestConfig;

  return Axios.post(url, data, config);
};
const putWithToken = async (
  url: string,
  data: any,
  headers: {} = {}
) => {

  const accessToken = localStorage.getItem('access-token')
  const config = {
    baseURL: Config.baseUrl,
    url: url,
    method: 'put',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'token-type': "access_token",
      platform: 'web',
      ...headers
    },
    responseType: 'json',
  } as AxiosRequestConfig;

  return axiosApiInstance.put(url, data, config);
};
const putWithOutToken = async (
  url: string,
  data: any,
) => {
  const implicitToken = localStorage.getItem('implicite')

  const config = {
    baseURL: Config.baseUrl,
    url: url,
    method: 'put',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${implicitToken}`,
      'token-type': "implicit",
      platform: 'web',
    },
    responseType: 'json',
  } as AxiosRequestConfig;

  return Axios.put(url, data, config);
};

const deleteWithToken = async (
  url: string,
  data: any,
  headers: {} = {}
) => {
  const accessToken = localStorage.getItem('access-token')
  const config = {
    baseURL: Config.baseUrl,
    url: url,
    method: 'delete',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'token-type': "access_token",
      platform: 'web',
      ...headers
    },
    responseType: 'json',
  } as AxiosRequestConfig;


  return axiosApiInstance.delete(url, { ...config, data });
};

const lobPostRequestVerifications = async (url: string, data: any, headers: {} = {}) => {
  const config = {
    baseURL: Config.lobUrl,
    url,
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Authorization': `Basic ${Config.lobAPIKey}`,
      ...headers
    },
    responseType: 'json'
  } as AxiosRequestConfig

  return Axios.post(url, data, config)
}

export default {
  getDataWithToken,
  postDataWithToken,
  getDataWithOutToken,
  postDataWithOutToken,
  postMedia: postMedia,
  putWithToken,
  putWithOutToken,
  deleteWithToken,
  lobPostRequestVerifications
};

import React from 'react'

type Props = {}

const ModBadge = (props: Props) => {
  return (
    <span className='modBadge'>MOD</span>
  )
}

export default ModBadge